import * as React from "react";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as Api from "app-api";

import { LY_NotesModuleState } from "./LY_NotesModuleState";
import { NotesManager } from "../Managers/NotesManager";

import { LY_NotesModuleProps } from "../Interfaces/LY_NotesModuleProps";

interface LY_NotesModuleContextProps {
  state: LY_NotesModuleState;

  addNewNote: () => void;
  addNewReply: () => void;

  deleteNote: () => void;
  deleteReply: () => void;

  selectReply: (noteId: string, replyId: string) => void;
  selectNote: (noteId: string) => void;

  setUpdatingReply: (value: boolean) => void;
  setUpdatingNote: (value: boolean) => void;

  setNewNoteMessage: (value: string) => void;
  setNewReplyMessage: (value: string) => void;

  toggleNotePin: () => void;
  toggleReplyPin: () => void;

  updateNoteMessage: () => void;
  updateReplyMessage: () => void;

  setReplyInputOn: (value: boolean) => void;
  setUpdatedMessage: (value: string) => void;
  fetchNotesAndSort: () => void;

  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const useListTableViewState = (
  props: LY_NotesModuleProps
): LY_NotesModuleContextProps => {
  const vm = new LIB.BaseVmModel();

  const initialState = new LY_NotesModuleState();

  const [state, setState] = React.useState<LY_NotesModuleState>(initialState);
  const [propsState, setPropsState] = React.useState<LY_NotesModuleState>(
    new LY_NotesModuleState()
  );

  const notesManager = new NotesManager(props, state, setState, forceUpdate);

  var currentUser = App.AppBase.currentUser;
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);

  let notes = [];

  React.useEffect(() => {
    fetchNotesAndSort();
  }, [props.recordId]);

  function forceUpdate() {
    //console.log("LY_ListFilterModuleContext forceUpdate");

    setState({ ...state });
  }

  state.searchNotes = React.useMemo(() => {
    if (state.searchTextInNotes) {
      return filterNotes(state.notes, state.searchTextInNotes);
    } else {
      return [];
    }
  }, [state.searchTextInNotes, state.notes]);

  async function fetchNotesAndSort() {
    state.replyInputOn = false;
    state.updatingNote = false;
    state.updatingReply = false;
    state.newNoteMessage = "";
    state.newReplyMessage = "";

    forceUpdate();

    try {
      await notesManager.getNotes(props.recordId);

      console.log(state.notes);

      state.searchNotes = LIB.ObjectHelper.clone(state.notes);

      forceUpdate();

      sortNotesByPinnedAndDate();

      initialRepliesSort();
    } catch (error) {
      console.error("Error while fetching initial Notes data", error);
    } finally {

      setTimeout(() => {
        scrollToTop(scrollContainerRef);
      }, 0);
    }
  }

  function scrollToTop(
    container: React.MutableRefObject<HTMLDivElement | null>
  ) {
    if (container.current) {
      container.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

 

  function setUpdatingNote(value: boolean) {
    state.updatingNote = value;

    forceUpdate();
  }

  function setUpdatingReply(value: boolean) {
    state.updatingReply = value;

    forceUpdate();
  }

  function setReplyInputOn(value: boolean) {
    state.replyInputOn = value;

    forceUpdate();
  }

  function setUpdatedMessage(value: string) {
    state.updatedMessage = value;

    forceUpdate();
  }

  function selectNote(noteId: string) {
    const note = state.notes.find((note) => note.id === noteId) || null;

    if (note) {
      state.selectedNote = note;

      forceUpdate();
    }
  }

  function selectReply(noteId: string, replyId: string) {
    const note = state.notes.find((note) => note.id === noteId);

    const reply = note?.replies?.find((reply) => reply.id === replyId) || null;

    if (reply) {
      state.selectedReply = reply;

      forceUpdate();
    }
  }

  function addNewNote() {
    if (!state.newNoteMessage.trim()) return;

    const generatedId = LIB.StringHelper.generateUUID();

    var newNote = new Api.Note();

    newNote.author = {
      name: props.currentUser?.fullName!,
    };
    newNote.id = generatedId;
    newNote.message = state.newNoteMessage;
    newNote._ly_date_created = new Date();
    newNote.pinned = false;
    newNote.author_id = props.currentUser?.id2!;
    newNote.replies = [];

    state.notes = [newNote, ...state.notes];
    state.newNoteId = generatedId;

    notesManager.createNote(newNote, props.recordId);

    state.newNoteMessage = "";

    forceUpdate();

    sortNotesByPinnedAndDate();
  }

  function setNewNoteMessage(value: string) {
    state.newNoteMessage = value;

    forceUpdate();
  }

  function deleteNote() {
    if (!state.selectedNote) return;

    state.notes = state.notes.filter((x) => x.id !== state.selectedNote.id);

    forceUpdate();

    notesManager.deleteNote(state.selectedNote.id!);
  }

  function updateNoteMessage() {
    if (!state.selectedNote) return;

    const updatedSelectedNote = {
      ...state.selectedNote,
      message: state.updatedMessage,
    };

    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedNote.id) {
        return updatedSelectedNote;
      } else {
        return note;
      }
    });

    forceUpdate();

    notesManager.updateNote(state.selectedNote);
  }

  function addNewReply() {
    if (!state.selectedNote || !state.newReplyMessage?.trim()) return;

    const generatedId = LIB.StringHelper.generateUUID();

    const newReply = new Api.NoteReply();

    newReply.message = state.newReplyMessage;
    newReply.author = {
      name: props.currentUser?.fullName!,
    };
    newReply._ly_date_created = new Date();
    newReply.pinned = false;
    newReply.author_id = props.currentUser?.id2;
    newReply.id = generatedId;
    newReply.note_id = state.selectedNote.id;

    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedNote.id) {
        note.replies = [newReply, ...(note.replies ?? [])];

        return note;
      } else {
        return note;
      }
    });

    notesManager.createNoteReply(newReply);

    state.newReplyMessage = "";

    forceUpdate();

    setReplyInputOn(false);
  }

  function setNewReplyMessage(value: string) {
    state.newReplyMessage = value;

    forceUpdate();
  }

  function updateReplyMessage() {
    if (!(state.selectedReply && state.updatedMessage.trim())) return;

    const updatedSelectedReply = {
      ...state.selectedReply,
      message: state.updatedMessage,
    };

    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedReply.note_id && note.replies) {
        return {
          ...note,
          replies: note.replies.map((reply) => {
            if (reply.id === state.selectedReply.id) {
              return updatedSelectedReply;
            } else {
              return reply;
            }
          }),
        };
      } else {
        return note;
      }
    });

    forceUpdate();

    notesManager.updateNoteReply(updatedSelectedReply);
  }

  function deleteReply() {
    if (!state.selectedReply) return;

    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedReply.note_id && note.replies) {
        return {
          ...note,
          replies: note.replies.filter(
            (reply) => reply.id !== state.selectedReply.id
          ),
        };
      } else {
        return note;
      }
    });

    forceUpdate();

    notesManager.deleteNoteReply(state.selectedReply.id!);
  }

  function toggleNotePin() {
    if (!state.selectedNote) return;

    let pinned = !state.selectedNote.pinned;

    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedNote.id) {
        return {
          ...state.selectedNote,
          pinned,
        };
      } else {
        return note;
      }
    });

    setState({ ...state });
    forceUpdate();

    sortNotesByPinnedAndDate();

    notesManager.updateNotePinned(state.selectedNote);
  }

  function toggleReplyPin() {
    if (!state.selectedReply) return;

    let pinned = !state.selectedReply.pinned;

    state.notes = state.notes.map((note) => {
      if (note.replies) {
        return {
          ...note,
          replies: note.replies.map((reply) => {
            if (reply.id === state.selectedReply.id) {
              return { ...reply, pinned };
            } else {
              return reply;
            }
          }),
        };
      } else {
        return note;
      }
    });

    setState({ ...state });
    forceUpdate();

    sortRepliesByPinnedAndDate();

    notesManager.updateNoteReplyPinned(state.selectedReply);
  }

  function sortNotesByPinnedAndDate() {
    state.notes = [...state.notes].sort((a, b) => {
      if (a.pinned === b.pinned) {
        return (
          new Date(b._ly_date_created!).getTime() -
          new Date(a._ly_date_created!).getTime()
        );
      }

      return a.pinned ? -1 : 1;
    });

    forceUpdate();
  }

  function sortRepliesByPinnedAndDate() {
    console.log(state.selectedReply.note_id);
    state.notes = state.notes.map((note) => {
      if (note.id === state.selectedReply.note_id && note.replies) {
        const sortedReplies = [...note.replies].sort((a, b) => {
          if (a.pinned === b.pinned) {
            return (
              new Date(b._ly_date_created!).getTime() -
              new Date(a._ly_date_created!).getTime()
            );
          }

          return a.pinned ? -1 : 1;
        });

        return { ...note, replies: sortedReplies };
      }

      return note;
    });

    setState({ ...state });
    forceUpdate();
  }

  function initialRepliesSort() {
    state.notes = state.notes.map((note) => {
      if (note.replies) {
        const sortedReplies = [...note.replies].sort((a, b) => {
          if (a.pinned === b.pinned) {
            if (a._ly_date_created && b._ly_date_created) {
              return (
                new Date(b._ly_date_created).getTime() -
                new Date(a._ly_date_created).getTime()
              );
            }

            return 0;
          }

          return a.pinned ? -1 : 1;
        });

        return {
          ...note,
          replies: sortedReplies,
        };
      }

      return note;
    });

    forceUpdate();
  }

  function filterNotes(
    notes: Array<Api.Note>,
    searchString: string
  ): Array<Api.Note> {
    return notes
      .filter((note) => {
        const noteMatches = note.message?.includes(searchString) ?? false;

        const filteredReplies = note.replies?.filter((reply) =>
          reply?.message?.includes(searchString)
        );

        return noteMatches || (filteredReplies && filteredReplies.length > 0);
      })
      .map((note) => ({
        ...note,
        replies: note.message?.includes(searchString)
          ? note.replies || []
          : (note.replies || []).filter((reply) =>
              reply?.message?.includes(searchString)
            ),
      }));
  }

  function onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    state.searchTextInNotes = value;
    forceUpdate();
    console.log(state.searchTextInNotes);
  }

  return {
    state,
    deleteNote,
    deleteReply,
    onSearchChange,
    setUpdatedMessage,
   
    fetchNotesAndSort,
    selectReply,
    setReplyInputOn,
    selectNote,
    setUpdatingReply,
    setUpdatingNote,
    updateReplyMessage,
    updateNoteMessage,
    setNewNoteMessage,
    addNewNote,
    setNewReplyMessage,
    addNewReply,
    toggleReplyPin,
    toggleNotePin,
  };
};

const LY_NotesModuleContext = React.createContext<
  LY_NotesModuleContextProps | undefined
>(undefined);

export const useNotesModuleContext = () => {
  const context = React.useContext(LY_NotesModuleContext);

  if (!context) {
    throw new Error(
      "useLY_NotesModuleContext must be used within a ListTableViewProvider"
    );
  }
  return context;
};

const LY_NotesModuleContextProvider: React.FC<LY_NotesModuleProps> = (
  props
) => {
  const contextValue = useListTableViewState(props);

  function render() {
    return (
      <LY_NotesModuleContext.Provider value={contextValue}>
        {props.children}
      </LY_NotesModuleContext.Provider>
    );
  }

  return render();
};
export { LY_NotesModuleContextProvider, LY_NotesModuleContext };
