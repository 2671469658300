
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ListColumnRelationshipSettingsVm  extends LIB.BaseVmModel {

    public workspaces: Api.Workspace[] =[];
    public workspaceLists:Api.List[] =[];
    public listColumns:Api.ListColumn[] =[];
    public listViews:Api.ListView[] =[]; // related list column list views
    public childListViews:Api.ListView[] =[]; // current column list views

    public selectedWorkspace: Api.Workspace = new Api.Workspace();
    public selectedList: Api.List = new Api.List();
    public selectedValueColumn: Api.ListColumn = new Api.ListColumn();
    public selectedDisplayColumn: Api.ListColumn = new Api.ListColumn();

    public selectedListView: Api.ListView = new Api.ListView();
    public selectedChildListView: Api.ListView = new Api.ListView(); // select a view to display in Related child list

    constructor(column: Api.ListColumn|undefined=undefined) { 

       super();

       this.selectedList = column?.fkList || new Api.List();
       this.selectedListView = column?.fkListView || new Api.ListView();
       this.selectedValueColumn = column?.fkListColumn || new Api.ListColumn();
       this.selectedDisplayColumn = column?.fkDisplayListColumn   || new Api.ListColumn();
       this.selectedChildListView = column?.fkChildListView || new Api.ListView();

       console.log('ListColumnRelationshipSettingsVm constructor column: ', column);

    }
     

   /*  public reset(){
        this.selectedWorkspace = new Api.Workspace();
        this.selectedList = new Api.List();
        this.selectedValueColumn = new Api.ListColumn();
        this.selectedDisplayColumn = new Api.ListColumn();
        this.selectedListView = new Api.ListView();
        this.selectedChildListView = new Api.ListView();

        this.workspaces =[];
        this.workspaceLists =[];
        this.listColumns =[];
        this.listViews =[];
        this.currentListViews =[];
 
    } */

}