import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, Stack, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Api from 'app-api';
import { useParams, useLocation } from 'react-router';

import { Badge, Tooltip, Tabs, rem } from '@mantine/core';
import * as Icons from '@tabler/icons-react';

 
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ResizeHandle from 'Components/Layouts/MainSplitPage/ResizeHandle';
import { DetailRightPanel } from 'Pages/Detail/DetailRightPanel/DetailRightPanel';

import './FolderPage.css';
 


interface FolderPageProps {
  

}
//export const FolderPage: React.FC<FolderPageProps> = (props) => {
  const FolderPage: React.FC<App.ListState> = (props) => {


  var validationManager: App.ValidationManager = new App.ValidationManager();
  var params = useParams();
  var location = useLocation();
  const [ viewId, setViewId ] = React.useState(-1);
  const forceUpdate = LIB.useForceUpdate();

 


  React.useEffect(() => {
    console.log('FolderPage useEffect');
    var state = props;
   // var vm = state.vm;

    // if (state.hasPageDataLoaded)
    //   return;

   // console.log('FolderPage useEffect id', params.id);
   // console.log('FolderPage useEffect params', params);
   // console.log('FolderPage useEffect state', state);

   // var sm = new  Api.ListGetDataSm(); //state.listFilterSm;
    var sm = state.listFilterSm;
    var id: any = params.id;
    sm.viewId = Number(id);
    var workspaceVm = App.AppBase.getCurrentWorkspaceVm();

  //  console.log('FolderPage useEffect viewId', sm.viewId);

    if (id == '' || id == undefined) {

      id = workspaceVm?.selectedItem?.defaultViewId;
    }
    

    if (!id)
      return;

    console.log('FolderPage useEffect id2', id);
    var workspace = App.AppBase.currentWorkspace;



    sm.viewId = Number(id);
    //vm.viewId = sm.viewId;

    sm.linkedWorkspaceId = Number(params.linked);

    //sm.filter = {...state.listFilter};
    //when first time, reset the filter
    sm.filter = new Api.QueryFilter();

    console.log('FolderPage sm.viewId', sm.viewId);
    console.log('FolderPage state', state);
    console.log('FolderPage workspace', workspace);
    


    forceUpdate();


  }, [ params.id ]);

 

  function getMainPanelContent() {

    var state = props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var workspaceVm = App.AppBase.getCurrentWorkspaceVm();
    var workspace = workspaceVm?.selectedWorkspace;
 
    
     var title = workspaceVm?.selectedItem?.name;
      state.listFilterSm.viewId = -1;
      
      return <>
      <h1>{title}</h1>

      <h2>
        Folder content coming soon...
      </h2>
      </>;
 
 

  }


  function render() {
    var state = props;

    console.log('FolderPage render');
    console.log('FolderPage render error: state.vm..apiErrorMessage:', state.vm.apiErrorMessage);

    //todo checkIfFirstTimeForward();
 
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;
    var filter = props.view.filter!;

    var list = props.list;
    var view = props.view;

    return <App.MainSplitPage
      //pageTitle={<span>{view?.name}</span>}
      state={vm}
      hasDataLoaded={true}//state.hasPageDataLoaded}
        showContentWhenInProgress={true}
       showModalForLoading={true}
      showLeftMenu={true}
    >

      {getMainPanelContent()}

       

    </App.MainSplitPage>


  }

  return render();
};
 export default connect(
  (state: App.AppState) => ({ ...state.listState })
)(FolderPage as any);  

