import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';

/* 
interface LoginProps {
  //vm: App.ProjectListState;
} */

//class Login extends React.Component<LoginProps, {}> {
class Login extends React.Component<App.GuestUserState, {}> {


  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Login componentDidMount');


     if(App.AppBase.isUserAuthenticated){
      App.AuthManager.logout();
      this.forceUpdate();
      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_LOGOUT_REQUEST);
    
    }  
 

    document.addEventListener('keydown',  (e:any)=>this.onKeyDown(e), false);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('Login componentDidUpdate');
  }
 
  public componentWillUnmount() {
    document.removeEventListener('keydown', (e:any)=>this.onKeyDown(e), false);
  }
 
  
  public onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
  //  console.log('onKeyDown e',e);
    
    if (e.key === 'Enter') {
      console.log('onKeyDown enter',e.key);
      this.onLoginClick(e);
    }  
  } 


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var sm = state.loginSm;


    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, sm);

    //console.log('onInputChange key',key);
    //console.log('onInputChange sm', sm);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateLogin(key, sm);

    this.forceUpdate();
  }


  public onLoginClick(e: React.ChangeEvent<{}>) {
    console.log('onLoginClick');

    var state = this.props;
    var vm = state.vm;
    var sm = state.loginSm;

    var isValid = this.validationManager.validateLogin(null, sm);

    if (!isValid) {
      console.log('validateLogin NOT valid');
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_LOGIN_REQUEST, sm);

    this.forceUpdate();

  }


  public render() {

    console.log("Login Render");

    var state = this.props;
    var vm = state.vm;
    var sm = state.loginSm;

    console.log("Login Render Error", vm);

    console.log("Login Render Error", vm.apiErrorMessage);


    const classes: any = createStyles({
      backdrop: {
        zIndex: 101,
        color: '#fff',
      }
    });

    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      marginTop: 10,
      width: '100%'
    };

    /*   if (App.AppBase.isUserAuthenticationInProgress  ) {
       
       return <Backdrop style={classes.backdrop} open={true}  >
         <CircularProgress  variant="default" />
       </Backdrop>
     }   */

  /*    var registerUrl = App.AppBase.appInfo.appWebsiteUrl + '/Pricing';

     if(App.AppBase.isDev())
        registerUrl = '/Register?planId=100'; */

      var registerUrl = App.AppBase.registerUrl;

      var globalError = vm.apiErrorMessage;


    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      showLeftMenu={false} 
      hasNoPageDesign={true}
      mainPageContainerClassName='MainPageContent'
     //errorMessage={vm.apiErrorMessage}
    >

     

        <Card className='LY_Login_Card' style={{ padding: 0, minWidth: 300, width:'30vw', maxWidth:400 }}>
          <CardContent  >

            <h1 style={{ textAlign: 'center' }}>
              Login
            </h1>

            {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}


            <div>
              <TextField
                name="email"
                label="Email"
                {...App.AppBase.getInputErrorProps("email")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                variant="standard"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons2.PersonOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div>
              <TextField
                name="password"
                label="Password"
                type='password'
                {...App.AppBase.getInputErrorProps("password")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                variant="standard"
                InputProps={{
                  className: 'standardFormInput30',
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icons2.LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className='standardFormInput30'>
              <MT.Button variant="contained" color="primary" className='LY_ButtonFullWidth'
                onClick={(e) => this.onLoginClick(e)}
              >Sign in</MT.Button>
            </div>
            <div style={{ width: '100%', marginBottom: 20, marginTop: 20 }} >

              <RouterLink to="/User/ForgotPassword" color="primary">Forgot Password</RouterLink>

            </div>

            Or {/* <a href="/Register" id="linkToRegisterFromLogIn">Register Now!</a> */}
            <RouterLink to={registerUrl} color="primary">Register Now!</RouterLink>

          </CardContent>
        </Card>

     

    </App.MainSplitPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.guestUserData })
)(Login as any);
