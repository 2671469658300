import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';

import {
  Button, Icon, Tooltip, CircularProgress
} from '@mui/material';
import axios from 'axios';

import * as RIcons from '@radix-ui/react-icons';


import * as DropdownMenu from '@radix-ui/react-dropdown-menu';


import Box from '@mui/material/Box';

import * as Icons from '@tabler/icons-react';

import * as Api from 'app-api';

import './WorkspaceDropdownContextMenu.css';

import { NewWorkspaceScreen } from '../NewWorkspaceScreen/NewWorkspaceScreen';
import { UpdateWorkspaceScreen } from '../UpdateWorkspaceScreen/UpdateWorkspaceScreen';



interface WorkspaceDropdownContextMenuProps {
  state: App.GlobalState;


}

export const WorkspaceDropdownContextMenu: React.FC<WorkspaceDropdownContextMenuProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);

  const [ bookmarksChecked, setBookmarksChecked ] = React.useState(true);
  const [ urlsChecked, setUrlsChecked ] = React.useState(false);
  const [ person, setPerson ] = React.useState('pedro');





  function onNewItemClick(itemType: App.WorkspaceItemTypeEnum) {

    console.log('onNewListClick');

    var state = props.state;
    var vm = state.workspaceVm;


    vm.setupNewItem();
    var newItem = vm.newItem;
    newItem.parentId = undefined;//root
    newItem.itemTypeId = itemType;

    if (itemType == App.WorkspaceItemTypeEnum.List)
      vm.isNewListModalOpen = true;
    else if (itemType == App.WorkspaceItemTypeEnum.Folder)
      vm.isNewFolderModalOpen = true;

    App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_MODAL_CHANGE, newItem);

  }

  function onNewWorkspaceClick() {
    console.log('onNewWorkspaceClick');
    var state = props.state;
    var vm = state.workspaceVm;

    vm.isNewWorkspaceScreenOpen = true;


    forceUpdate();


  }

  function onSettingsClick() {
    console.log('onSettingsClick');
    var state = props.state;
    var vm = state.workspaceVm;

    vm.isUpdateWorkspaceScreenOpen = true;
 
    forceUpdate();
  }




  function getTriggerButton() {
    return <button className='WorkspaceDropdownContextMenuBtn'
    //onClick={(e) => onItemContextMenuClick(e, item)}  
    >
      <Icons.IconDots />

    </button>

  }

  function render() {
    // console.log('WorkspaceDropdownContextMenu render');
    var state = props.state;
    var vm = state.workspaceVm;


    if (LIB.Common.isNullOrEmpty(state))
      return null;


    return <div>


      <DropdownMenu.Root  >
        <DropdownMenu.Trigger asChild className='WorkspaceDropdownContextMenuBtnContainer' >

          {getTriggerButton()}

        </DropdownMenu.Trigger>

        <DropdownMenu.Portal >
          <DropdownMenu.Content className="WorkspaceDropdownContextMenuContent"
            //onClick={(e: any) => {
            //  console.log('WorkspaceDropdownContextMenuContent onItemClick', e.target);
            // onItemClick(e.target.id);
            //}

            //}

            sideOffset={0}>

            <DropdownMenu.Item id='NewWorkspace' className="AddColumnMenuItem"
              onClick={(e: any) => onNewWorkspaceClick()}
            >
              <RIcons.TableIcon className='AddColumnMenuItemIcon' /> <span>New Workspace</span>
            </DropdownMenu.Item>

            <DropdownMenu.Item id='NewList'
              onClick={(e: any) => onNewItemClick(App.WorkspaceItemTypeEnum.List)}

              className="AddColumnMenuItem">
              <RIcons.ReaderIcon className='AddColumnMenuItemIcon' /> New List
            </DropdownMenu.Item>

            <DropdownMenu.Item id='NewFolder'
              onClick={(e: any) => onNewItemClick(App.WorkspaceItemTypeEnum.Folder)}

              className="AddColumnMenuItem">
              <RIcons.ReaderIcon className='AddColumnMenuItemIcon' /> New Folder
            </DropdownMenu.Item>

            {/* --------------------------------------------------------- */}
            <DropdownMenu.Separator className="AddColumnMenuSeparator" />
            {/* --------------------------------------------------------- */}

            <DropdownMenu.Item id='Settings' className="AddColumnMenuItem"
              onClick={(e: any) => onSettingsClick()}

            >
              <div style={{ textAlign: 'center', width: '100%' }}>Settings</div>
            </DropdownMenu.Item>

            <DropdownMenu.Arrow className='WorkspaceDropdownContextMenuArrow' />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      <NewWorkspaceScreen state={props.state} />
      <UpdateWorkspaceScreen state={props.state} />

    </div>;


  }

  return render();
};

