import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class NotesService extends App.BaseAppService {


    //private linkedNotesService: App.LinkedNotesService | undefined = undefined

    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        //  this.linkedNotesService = new App.LinkedNotesService(workspaceId);
    }


    public async getNotes(sm: Api.NotesGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.getNotes sm.listId is required', sm);
            return;
        }

        console.log("NotesService.getNotes sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/getNotes`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.getNotes onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }


    public async getNote(noteId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!noteId) {
            console.log('NotesService.getNote noteId is required', noteId);
            return;
        }

        console.log("NotesService.getNote noteId: ", noteId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/getNote?noteId=${noteId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //   data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.getNote onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'get'
        });


    }

    public async getNoteReplies(sm: Api.NoteRepliesGettDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.getNoteReplies sm.listId is required', sm);
            return;
        }

        console.log("NotesService.getNoteReplies sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/getNoteReplies`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.getNoteReplies onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }

    public async getNoteReply(replyId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!replyId) {
            console.log('NotesService.getNoteReply replyId is required', replyId);
            return;
        }

        console.log("NotesService.getNoteReply replyId: ", replyId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/getNoteReply?replyId=${replyId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //   data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.getNoteReply onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'get'
        });


    }


    public async createNote(sm: Api.Note, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.createNote sm.listId is required', sm);
            return;
        }

        console.log("NotesService.createNote sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/createNote`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.createNote onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }


    public async createNoteReply(sm: Api.NoteReply, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.createNoteReply sm.listId is required', sm);
            return;
        }

        console.log("NotesService.createNoteReply sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/createNoteReply`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.createNoteReply onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }


    public async updateNote(sm: Api.Note, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.updateNote sm.listId is required', sm);
            return;
        }

        console.log("NotesService.updateNote sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/updateNote`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.updateNote onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'patch'
        });


    }

    public async updateNoteReply(sm: Api.NoteReply, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('NotesService.updateNoteReply sm.listId is required', sm);
            return;
        }

        console.log("NotesService.updateNoteReply sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/updateNoteReply`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.updateNoteReply onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'patch'
        });


    }

    public async updateNotePinned(noteId: string, pinned: boolean, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!noteId) {
            console.log('NotesService.updateNotePinned noteId is required', noteId);
            return;
        }

        console.log("NotesService.updateNotePinned noteId: ", noteId);
        console.log("NotesService.updateNotePinned pinned: ", pinned);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/updateNotePinned?noteId=${noteId}&pinned=${pinned}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.updateNotePinned onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'patch'
        });


    }

    public async updateNoteReplyPinned(noteReplyId: string, pinned: boolean, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!noteReplyId) {
            console.log('NotesService.updateNoteReplyPinned noteReplyId is required', noteReplyId);
            return;
        }

        console.log("NotesService.updateNoteReplyPinned noteReplyId: ", noteReplyId);
        console.log("NotesService.updateNoteReplyPinned pinned: ", pinned);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/updateNoteReplyPinned?noteReplyId=${noteReplyId}&pinned=${pinned}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.updateNoteReplyPinned onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'patch'
        });


    }


    public async deleteNote(noteId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!noteId) {
            console.log('NotesService.deleteNote noteId is required', noteId);
            return;
        }

        console.log("NotesService.deleteNote noteId: ", noteId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/deleteNote?noteId=${noteId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //   data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.deleteNote onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'delete'
        });


    }
 
    public async deleteNoteReply(noteReplyId: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!noteReplyId) {
            console.log('NotesService.deleteNoteReply noteReplyId is required', noteReplyId);
            return;
        }

        console.log("NotesService.deleteNoteReply noteReplyId: ", noteReplyId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Notes/deleteNoteReply?noteReplyId=${noteReplyId}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //   data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("NotesService.deleteNoteReply onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'delete'
        });


    }






}
