import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class TemplateEditorState  extends LIB.BaseStateModel {
 
  
    public vm:App.TemplatesVm;
 
     
   
    constructor() { 
        
        super();
       
        this.vm = new App.TemplatesVm();

      
    }
     


}