import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
import * as MT from '@mantine/core';
import * as Icons from '@tabler/icons-react';

import {
  Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress,
  InputAdornment, FormControlLabel, Checkbox, Tooltip
} from '@mui/material';


import { useEffect, useState, useImperativeHandle, useRef, CSSProperties } from 'react';
 
 

interface CompanyProfileProps {
  state: App.AccountState;
  hideButtons?: boolean;
  children?: React.ReactNode;

}

interface CompanyProfileRef {
  onSaveClick: (e: React.ChangeEvent<{}>) => void;
}

export const CompanyProfile = React.forwardRef<CompanyProfileRef, CompanyProfileProps>((
  {
    state,
    ...props
  },
  ref
) => {

  const validationManager: App.ValidationManager = new App.ValidationManager();
  const profileManager: App.ProfileManager = new App.ProfileManager();

  const forceUpdate = LIB.useForceUpdate();

  useImperativeHandle(ref, () => ({
    onSaveClick
  }));

 
  function resetData() {
    console.log('CompanyProfile resetData');
    var item = state.company;


  }
 

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = state.company;
    var target: any = e.target;

    // item.firstName='bb';
    // console.log('onInputChange value', target.value);
    // console.log('onInputChange item', item);

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //
    console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    validationManager.validateCompanyProfileEdit(key, item);

    //console.log('onInputChange item', item);

    forceUpdate();
  }


  function onDateChange(date: any | null, value?: string | null) {

    console.log(date);
    console.log(value);

  }


  
  function onAutocompleteChange(name: string, value: any, item: any) {
    console.log('AreasImpactedUpdateModal onAutocompleteChange');

    var idProperty = name + "Id";

    const selectedItem = state.company;


    if (LIB.Common.isNullOrEmpty(selectedItem)) {
      console.log('selectedItem is empty!', item);
      return;
    }

    //set id
    var selectedId = { name: idProperty, value: value }
    var key = LIB.FormHelper.setInputChangeData(selectedId, selectedItem);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    //set type
    var selectedType = { name: name, value: item }
    var key2 = LIB.FormHelper.setInputChangeData(selectedType, selectedItem);

    validationManager.validateCompanyProfileEdit(key2, selectedItem);
 
   
   /*  console.log('onAutocompleteChange selectedId:', selectedId);
    console.log('onAutocompleteChange selectedType:', selectedType);
    console.log('onAutocompleteChange value:', value);
    console.log('onAutocompleteChange key:', key);
    console.log('onAutocompleteChange key2:', key2);
    console.log('onAutocompleteChange selectedItem:', selectedItem);  */

    forceUpdate();
   
  }



  function onAutocompleteAddressChange(name: string, value: any, details: any) {
    console.log('onAutocompleteAddressChange');

    const item = state.company;

    var selected = {
      name: name,
      value: value
    }

    console.log('onAutocompleteAddressChange selected', selected);


    var key = LIB.FormHelper.setInputChangeData(selected, item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    validationManager.validateCompanyProfileEdit(key, item);


    forceUpdate();
  }
 
  function onCloseClick() {

    resetData();
    App.RouteManager.routeToPath('/')

  }


  function onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var vm = state.vm;
    var company = state.company;

    var isValid = validationManager.validateCompanyProfileEdit(null, company);

    if (!isValid) {
      console.log('onSaveClick CompanyProfile NOT valid');
      forceUpdate();
      return;
    }

    var sm = new Api.AccountUpdateCompanySm();
    sm.company = company;
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_COMPANY_UPDATE_REQUEST, sm);

    vm.isActionInProgress = true;

    forceUpdate();

  }


  
  function getIncompleteAlert(){
    var item = state.company;

   // console.log('getIncompleteAlert hasDataLoaded', state.hasDataLoaded);
   // console.log('getIncompleteAlert isCompanyDetailsComplete', item.isCompanyDetailsComplete);

   const icon = <Icons.IconExclamationCircle />;

    if(state.hasDataLoaded && !item.isCompanyDetailsComplete)
    return <MT.Alert color="red" icon={icon}
    style={{ marginBlock: 20 }}>Please complete your profile to Continue! </MT.Alert>;

    return null;
  }

  function render() {

    //console.log('ProjectScopeForm render');

    var item = state.company;


    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);

    //if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || LIB.Common.isNullOrEmpty(item))
    //  return null;//<LIB.Loading />;

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0,
      // padding:'20px, 16px'
      marginTop: 0
    };
    var fullWidth: CSSProperties = {
      margin: 2,
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{  marginLeft: 10 }}>
    
      {getIncompleteAlert()}

      <h2 >Company Profile</h2>
 
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} >
            <TextField
              name="companyName" label="Company Name"
              variant='standard'
              value={item.companyName || ''}
              {...App.AppBase.getInputErrorProps('companyName')}
              onChange={onInputChange}
              style={formControl}
              inputProps={{ style: formInput }}
              disabled={!App.AppBase.currentUser.isManager && item.isCompanyDetailsComplete}
            />
          </Grid>
          <Grid item xs={12} sm={6}

          >
            <TextField
              name="phone" label="Company Phone"
              variant='standard'
              value={item.phone || ''}
              {...App.AppBase.getInputErrorProps('phone')}
              onChange={onInputChange}
              style={formControl}
              inputProps={{ style: formInput }}

            />
          </Grid>
        </Grid>

        <App.FullAddress
          address={item.address}
          city={item.city}
          state={item.state}
          zipCode={item.zipCode}
          country={item.country}
          onInputChange={onInputChange}
          onAutocompleteChange={onAutocompleteAddressChange}
          
        />

        <App.FormAutocomplete
          label="Company Size?"
          
          name="companySizeType"
          valueProperty='lookupTypeId'
          labelProperty="name"
          data={state.companySizeTypes}
          onChange={onAutocompleteChange}
          value={profileManager.getLookupById(item.companySizeTypeId, state.companySizeTypes)}
          textFieldClassName="standardAutocompleteTextField"

        />

         
        {!props.hideButtons &&
        <div style={{ marginTop: 50 }}>
          <MT.Button
            id="btnCancel"
             variant="default"
            disabled={!item.isCompanyDetailsComplete}
            onClick={() => onCloseClick()}
          >Close</MT.Button>

          <MT.Button
            id="btnSave"
            color="primary"
            variant="contained"
            style={{ marginLeft: 15 }}
            onClick={onSaveClick}

          >Save</MT.Button>


        </div>
         }

 


      {props.children}
    </div>;



}
return render();
});
