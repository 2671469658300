import { Alert, Button, Modal, Text } from '@mantine/core';

import React from 'react';

 
interface ConfirmModalProps {
  title: React.ReactNode;
  content: React.ReactNode;
  visible: boolean;
  confirmButtonLabel?: React.ReactNode;
  onCancelClick: Function;
  onConfirmClick: Function;
  disabled?: boolean;
  errorMessage?: React.ReactNode;
}


export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {

 

  function onConfirmClick() {
    console.log('onConfirmClick');
    if (props.onConfirmClick) {
      props.onConfirmClick();
    }
  }

  function onCancelClick() {
    if (props.onCancelClick) {
      props.onCancelClick();
    }
  }

  function getErrorAlert() {
    const { errorMessage } = props;
    if (!errorMessage) return null;

    return <Alert title="Error">{errorMessage}</Alert>;
  }

  function render() {
    const { visible, title, content, confirmButtonLabel, disabled } = props;

    return (
      <Modal
        centered
        opened={visible}
        onClose={onCancelClick}
        title={title}

        styles={{
          root: {
            zIndex: 9999,
          }
        }}
      >

        <Text size="sm" mb="md">
          {content}
        </Text>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

          <Button
            variant="default"
            onClick={onCancelClick}
            disabled={disabled}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>

          <Button
             color="red"
            onClick={onConfirmClick}
            disabled={disabled}
          >
            {confirmButtonLabel || 'Confirm'}
          </Button> 
 
      </div>
      </Modal >
    );
  }


return render();

  
}
