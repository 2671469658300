import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
 
import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge,  Accordion, rem,Tooltip } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


  
interface UpdateColumnAdvancedTab_TooltipProps {
  state: App.ListState;
 
}

export const UpdateColumnAdvancedTab_Tooltip: React.FC<UpdateColumnAdvancedTab_TooltipProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
    
  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;
  
  const vm = props.state.vm;
  const state = props.state;
 

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('UpdateColumnAdvancedTab_Tooltip onInputChange key:', key);
    // console.log('UpdateColumnAdvancedTab_Tooltip onInputChange selectedColumn:', selectedColumn);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('UpdateColumnAdvancedTab_Tooltip onValueChange key:', key);
    //console.log('UpdateColumnAdvancedTab_Tooltip onValueChange value:', value);

    //console.log('UpdateColumnAdvancedTab_Tooltip onValueChange selectedColumn:', selectedColumn);

    if (key == 'name') {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }  

    //@ts-ignore
    selectedColumn[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedColumn', selectedColumn);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = selectedColumn;

    vm.errors.clear();

    if (vAll || key == 'maxLength') {
       if (item.maxLength!=undefined && item.maxLength <1)
        vm.errors.set('maxLength', `Max Length must be more than 0`);
    }
     
    /* if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required!`);
    }
    else if ((vAll || key == 'listColumnTypeId') && isNaN(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type must be a number!`);
    } */


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }
 

  // when Type changes, let's change what's selected 
  var listColumnTypeId = selectedColumn.listColumnTypeId;

  //console.log('UpdateColumnAdvancedTab_Tooltip watch listColumnTypeId:', listColumnTypeId);
  //console.log('UpdateColumnAdvancedTab_Tooltip selectedColumn:', selectedColumn);
  //console.log('UpdateColumnAdvancedTab_Tooltip columnTypes:', columnTypes);
  //console.log('UpdateColumnAdvancedTab_Tooltip selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == selectedColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
   

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var column = selectedColumn 

   // console.log('UpdateColumnAdvancedTab_Tooltip render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || selectedColumn == undefined || !vm.isColumnSettingsScreenOpen)
      return null;
  
      var columns = props.state?.view?.columns?.filter(x=>!x.isSystemHidden);

      var globalError = vm.errors.get('all');
      var item = selectedColumn;

 
    return  <div className='UpdateColumnAdvancedTabGroups' >
  
          <LY.LY_TextArea
            name="columnHelpText"
            value={item.columnHelpText}
            label="Column Header Tooltip/Help Text"
           // placeholder="Column Header Tooltip/Help Text"
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('columnHelpText')}

            helpText="Tooltip will show on mouse-over/hover on the column name"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          
          /> 

      <LY.LY_TextArea
            name="helpText"
            value={item.helpText}
            label="Value Tooltip/Help Text"
            //placeholder="Column Header Tooltip/Help Text"
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('helpText')}

            helpText="Tooltip will show on mouse-over/hover on all values"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          
          /> 

         
 
      </div>;
   
  }

  return render();
};

