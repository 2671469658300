import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_TagsInput.css';
import moment from 'moment';
import { TagsInput, TagsInputProps } from '@mantine/core';

interface LY_TagsInputProps extends LY.LY_InputBaseProps {
  name: string;
  defaultChecked?: boolean;
  data?: string[];
  inputClassName?: string;
  style?: React.CSSProperties;
  tagsInputProps?: TagsInputProps;
  inputContainerClassName?: string;
  inputContainerWrapperClassName?: string;
    onChange?: ( model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}


type combinedProps = LY_TagsInputProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_TagsInput
export const LY_TagsInput = React.forwardRef<HTMLInputElement, LY_TagsInputProps>((
  {
    name,
    defaultChecked,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    disabled,
    style,
    onChange,
    model,
    readOnly,
    tagsInputProps,
    inputContainerClassName='',
    inputContainerWrapperClassName='',
    ...props
  },
  ref // This is the forwarded ref
) => {


  const [data, setData] = React.useState<string[]>([]);
  const [initialData, setInitialData] = React.useState<string[]>([]);

  React.useEffect(() => {
    //console.log('LY_TagsInput useEffect getTagsArray value: ', value);
     var arr = getTagsArray(model?.value);
     setData(arr);
     setInitialData(arr);
 
  }, [ value ]); 

  React.useEffect(() => {
   // console.log('LY_TagsInput useEffect model model?.value: ', model?.value, model?.rowId);

    
   var arr = getTagsArray(model?.value);
   setData(arr);
   setInitialData(arr);

   /*  if(model?.rowId=='6baeb63e-4a8e-4aa7-a2af-4bbdf005ec14'){

    console.log('LY_TagsInput useEffect model arr: ', arr);
    console.log('LY_TagsInput useEffect model data: ', data);

    } */

  }, [ model?.value ]); 

  React.useEffect(() => {

    if(props.data && Array.isArray(props.data))
      setData(props.data || []);
    
    
  }, [ props.data ]); 

  function getTagsArray(val:string) {

   // console.log('LY_TagsInput getTagsArray val: ', val);


    let arr = val?.split(",")?.filter(item => item.trim());


  /*   if(model?.rowId=='6baeb63e-4a8e-4aa7-a2af-4bbdf005ec14'){
      console.log('LY_TagsInput getTagsArray arr: ', arr);
 
    }
 */
    return arr || [];
  }

  function onLocalChange(newData: string[]) {

    console.log('LY_TagsInput onLocalChange newData: ', newData);

    if (model)
        model.value = newData.join(',');
 
     console.log('LY_TagsInput onLocalChange model.value: ', model?.value);

    setData(newData);

   if (onChange)
     onChange(model);
  
  }


  function render() {


    var combinedInputClassName = ` `;
 

    if (inputClassName)
      combinedInputClassName += ' ' + inputClassName;

    var readOnlyValue = model?.value;
    if (!readOnlyValue && value)
      readOnlyValue = value;

 
/* 
  if(model?.rowId=='6baeb63e-4a8e-4aa7-a2af-4bbdf005ec14'){
    console.log('LY_TagsInput render model: ', model);
    console.log('LY_TagsInput render data: ', data);
   // console.log('LY_TagsInput render arr: ', getTagsArray(model?.value));

  }
  */

    return (
      <LY.LY_InputBase  {...props} style={style} 
      inputContainerClassName={`LY_TagsInputContainer ${inputContainerClassName || ''}`}  >

        <TagsInput
          name={name}
          ref={ref}
          id={tagsInputProps?.id}
          value={data}
          data={initialData}
          //defaultChecked={defaultChecked}
         // className={combinedInputClassName}
         classNames={{ input: 'LY_TagsInputContainerInput', root:'LY_TagsInputContainerRoot',
         inputField:'LY_TagsInputContainerInputField', 
         wrapper:`LY_TagsInputContainerWrapper ${inputContainerWrapperClassName || ''}`,
         pillsList:'LY_TagsInputContainerPillsList' 
          }}
          style={{ width: 'auto', height: 'auto' }}
          disabled={readOnly || disabled}
          {...tagsInputProps}
        onChange={onLocalChange}
        //clearable
        />



      </LY.LY_InputBase>
    );

  }


  return render();




});


