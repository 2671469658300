import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class ListStateColumnReducers {


    constructor() {

    }

    public static getReducers(state: App.ListState | undefined, action: App.AppAction): App.ListState {
        var str = "";

        var result = new App.ListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("LIST_COLUMN"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);
        let selectedWorkspace= globalState.workspaceVm?.selectedWorkspace;

        var listColumnService = new App.ListColumnService(selectedWorkspace?.workspaceId);
        var recordManager = new App.RecordManager();
  
        // const action = incomingAction as KnownAction;
        switch (action.type) {

            
            case App.ActionTypes.LIST_COLUMN_SHOW_ADD_SCREEN: {
                console.log('LIST_COLUMN_SHOW_ADD_SCREEN:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               
                let data = action.payload as Api.ListColumn;

                let vm = result.vm;
                result.newColumn =  data;
                
                vm.isAddColumnScreenOpen=true;
 

                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.LIST_COLUMN_ADD_REQUEST: {
                console.log('LIST_COLUMN_ADD_REQUEST:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               

                let sm = action.payload;

              //todo  listColumnService.addColumn(sm);
 
                //result.hasDataLoaded = false;
                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.LIST_COLUMN_ADD_SUCCESS  : {
                console.log('LIST_COLUMN_ADD_SUCCESS  :', action.payload);

                result =  { ...state };
                result.apiErrorMessage='';

                let data = action.payload;
                let updatedItems = data.records as any[];

                
                console.log('LIST_COLUMN_ADD_SUCCESS updatedItems:',updatedItems);


                var updatedCount = recordManager.updateAllById(updatedItems,result.records);
                
                 console.log('LIST_COLUMN_ADD_SUCCESS updatedCount:',updatedCount);
 
 
 
                let vm = result.vm;
 
                result.hasDataLoaded = true;

                result.isServiceCallPending = false;
                result.forceUpdateCount++; 


                break;
            }
           
           
           
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}