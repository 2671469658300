import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import { connect } from 'react-redux';



import { MDXProvider } from '@mdx-js/react'
import MDX from '@mdx-js/runtime';


const PrivacyPolicy: React.FC = () => {

  function getPageContent() {

    return `
 
Last updated July 17, 2024
<br />
By using Loocey, you agree to the fallowing privacy policy.
<br />
This website and application is created and operated by Loocey, Inc.

<br />
Loocey, Inc., including Loocey, Inc.'s subsidiaries,
affiliates, divisions, contractors and all data sources and suppliers, (collectively "Loocey", "we", "us" or "our")
welcomes you to www.Loocey.com (the "Website") and app.Loocey.com (the "Application") . These terms and conditions of service (collectively, with Loocey's Privacy Policy,
located at /PrivacyPolicy page the "Terms of Service" or "Agreement") govern your use of the Website, Application and the services,
features, content or applications operated by Loocey (together with the Website, Application, and the "Services"), and provided to the Subscriber
(the "Subscriber", "user", "sub-user", "you" or "your"). 

<br />
### Website/Application Privacy Policy
By using this site, you consent to the terms of our privacy policy for the collection, use and disclosure of your personal information for the purposes set out below. We do not collect, use or disclose your personal information for any purpose other than those identified below, except with your consent or as required by law.
<br />
<br />
<br />


### The Information We Collect

(1) Site Activity Data

Each time a visitor comes to this website/application, our web server or visitor tracker script collects and logs certain information. These access logs may or may not be kept for some period of time. These logs include, but are not restricted to your machine’s TCP/IP address, your username (if applicable), date, time and files accessed. These logs also contain information about ‘referrer’ information if you clicked on an external link to an Loocey webpage. These logs are used for performance, site administration, reporting, security review purposes.

(2) Cookies
Portions of the Loocey website/application may use cookies only for security and authentication purposes. This information is used solely to maintain your computer’s session to the Loocey server. This information is not shared or sold to third party organizations for any purpose.

(3) Personal Information
Personal information, such as your name, personal postal and email address, or personal telephone number is collected only when you voluntarily provide it. Such information is received when you send an email through the site or make some other specific contact, such as completing an online form or registering for a course.
The personal information you provide to the association is forwarded only to the person or department equipped to handle your request and is used only to respond to your inquiry.

Personal information will not be sold to any third parties, nor will such information be added to bulk email lists.

Policy Revisions
Any changes to the association’s privacy policy will be updated on this page, you may check back for updates.





`;

  }


  function render() {
    console.log('TermsOfService render');

 

    var content: any = getPageContent();

    return <App.MainSplitPage
      pageTitle="Privacy Policy"
     // state={vm}
      hasDataLoaded={true}//state.hasPageDataLoaded}
      showLeftMenu={false}
      //  mainPageContainerClassName='MainSplitPageContainerForList'
      //  mainPanelsClassName='MainSplitPageMainPanelsForList'
      //showRightPanel={vm.isDetailScreenOpen}
      isFullHeightPage={true}
      isNarrowPage={true}

    >

      <MDXProvider >
        <MDX>{content}</MDX>

      </MDXProvider>
    </App.MainSplitPage>


  }

  return render();

};
export default PrivacyPolicy;
/* export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(PrivacyPolicy as any); */