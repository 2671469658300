import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class RecordManager extends LIB.BaseManager {


  constructor() {
    super('id');
  }


  
  public static getUpdateValues(columns:Api.ListColumn[], rowId:any,listId:any, item:any ):Api.UpdateValue{

    var update = new Api.UpdateValue();
    update.rowId = rowId;

/*     for(var col of columns){

        if(col.is)
    }  */
 
    update.listId = listId;
    
    update.data = item;

    return update;

}

  
  public static getUpdateValue(model:LY.DataItemModel):Api.UpdateValue{

    //console.log("getUpdateValue model: ", model);


    var update = new Api.UpdateValue();
    if(model.listId)
       update.listId = model.listId;

    if(model.rowId)
    update.rowId = model.rowId;
    update.data = {};

    var name = model.name;
    if(LIB.Common.isNullOrEmpty(name))
        name = model?.column?.name;
    update.data[ name ] = model.value;

    if(update.data[ name ]===undefined)
      update.data[ name ] = null;

    return update;

}

public static getInsertItem(model:LY.DataItemModel):Api.InsertItem{

    var item = new Api.InsertItem();
    //if(model.viewId)
    //item.viewId = model.viewId;
    
    if(model.listId)
      item.listId = model.listId;

    item.data = {};
    item.data[ model.name ] = model.value;
    item.groupId= model.groupId;

    return item;

}


  public updateAllById(updatedItems: any[], data: any[]): number {

    //console.log('updateAllById data', data);

    var updatedCount = 0;

    if (!Array.isArray(updatedItems) || !Array.isArray(data) || LIB.Common.isNullOrEmpty(updatedItems))
      return updatedCount;
 
    //foreach
    for (var item of updatedItems) {
     // console.log('updateAllById item', item);

      
      //data[ 0 ] = item;

      let id = item[ this.idProperty ];
      //console.log('updateAllById id', id);

      let index = data.findIndex((c) => c[ this.idProperty ] === id);
      let found = data[index];
      //console.log('updateAllById index', index);

      if (index < 0)
        continue

      //data[ index ] = item;
     
       LIB.ObjectHelper.setData(item,found);
 
      console.log('updateAllById found', found);
      console.log('updateAllById updated', item);


      updatedCount++;
    }

    return updatedCount;
  }
}