import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';
//import { withRouter } from "react-router-dom";
import { IconButton, Dialog , Button, DialogActions, Divider,DialogContent ,DialogContentText ,DialogTitle  } from '@mui/material';
import Alert from '@mui/material/Alert';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'


 
interface ImpersonateAdminModalProps {
  globalUIState: App.GlobalUIStateVm;
  //adminUserState:App.AdminUserState;
}
 
/*
export class ImpersonateAdminModalState {
  isOpen: boolean;
  constructor() {
    this.isOpen = false;
  }
} */
 

class ImpersonateAdminModal extends React.Component<App.AdminUserState & App.GlobalState , {}> {

  public adminDataService: App.AdminDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.adminDataService = new App.AdminDataService();
    this.validationManager = new App.ValidationManager();
  //  this.state = new ImpersonateAdminModalState();

  }

  

    // This method is called when the route parameters change
    public componentDidUpdate() {

      console.log('ImpersonateAdminModal componentDidUpdate');
    
    }
  

  // This method is called when the route parameters change
  public componentDidMount() {
    console.log('ImpersonateAdminModal componentDidMount');
    //var vm = this.props.globalUIState;
 
    var adminUserState = this.props;
    var globalUIState = this.props.globalUIState;

    var currentUser = App.AppBase.currentUser;
 
    //if(!globalUIState.isAdminImpersonateModalOpen)
    //   return;

     if(!currentUser?.isAdmin || adminUserState.appUsers?.length>0)
      return;

    console.log('ImpersonateAdminModal isAdminImpersonateModalOpen', globalUIState.isAdminImpersonateModalOpen);
    
    if(!adminUserState.vm.isActionInProgress && 
       (LIB.Common.isNullOrEmpty(adminUserState.userRoleTypes) || adminUserState.userRoleTypes.length<1)){
        adminUserState.vm.isActionInProgress=true;
        console.log('ImpersonateAdminModal getAdminUserData');
        console.log('ImpersonateAdminModal globalUIState apiErrorMessage ',globalUIState.apiErrorMessage);

        if(LIB.Common.isNullOrEmpty(adminUserState.vm.apiErrorMessage) && LIB.Common.isNullOrEmpty(globalUIState.apiErrorMessage) ){
          this.adminDataService.getAdminUserData();
          this.forceUpdate();
        }
     
      //this.forceUpdate();
    }
 
  }

  private get globalUIState():App.GlobalUIStateVm{
    return this.props.globalUIState;
    //var appState  = App.AppBase.getState();
   // var vm = appState.globalData.globalUIState;
   // return vm;
  }

  
  public onCloseClick() {

    var vm =  this.globalUIState;
    vm.isAdminImpersonateModalOpen=false;
    this.forceUpdate();
      

}

public onAutocompleteChange(name: string, value: any, item: any) {
  
  var selected = {
    name: name,
    value: value
  }

  var vm = this.props.vm;
  if(name=="impersonateUser"){
      vm.selectedUserImpersonator=item;
      this.validationManager.validateImpersonateUser(name, vm);
  }
  else if(name=="userRoleType"){
    vm.selectedUserRoleType=item;
    this.filterUsers();
  }
   
  console.log('onAutocompleteChange:',selected );

  this.forceUpdate();
}

  public filterUsers(){
    var vm = this.props.vm;

    var appUsers =  this.props.appUsers;
    vm.filteredImpersonateUserList = appUsers;
    if(LIB.Common.isNullOrEmpty(vm.selectedUserRoleType))
    return;

    vm.filteredImpersonateUserList = appUsers.filter((c)=> !LIB.Common.isNullOrEmpty(c.fullName) && 
    c.userRoleIds.indexOf(vm.selectedUserRoleType.userRoleTypeId)>-1);
   

  }

  public getForm(){
    console.log('ImpersonateAdminModal getForm');
    var state = this.props;

    var vm = this.props.vm;

   // this.filterUsers();
    var users = vm.filteredImpersonateUserList;
    //users =users.slice(0,10);

    //console.log('ImpersonateAdminModal getForm users',users);
   
    var textFieldStyle = {paddingTop: 20,marginBottom:10};

    return <div style={{ maxWidth: 700 }}>
      <form     >

        <div >

          <App.FormAutocomplete label="Filter Users By A Role" name="userRoleType" data={state.userRoleTypes}
             onChange={this.onAutocompleteChange.bind(this)}
             labelProperty="name"
             valueProperty="userRoleTypeId"
             textFieldStyle={textFieldStyle}
          />

        </div>

        <div >

          <App.FormAutocomplete label="Select A User To Impersonate" name="impersonateUser" data={users}
             onChange={this.onAutocompleteChange.bind(this)}
             // labelProperty="fullName"
             labelRenderer= {(item: any) => { return `${item?.fullName} (${item?.email})`;} }
             value={vm.selectedUserImpersonator}
             valueProperty="email"
             textFieldStyle={textFieldStyle}
          />
 
        </div>


        </form>


 
</div>;


  }

   
  
  public onImpersonateClick(e: React.ChangeEvent<{}>) {
    console.log('onImpersonateClick');
    // var globalStateVm =  this.globalUIState;
   //  globalStateVm.isImpersonateAdminModalOpen=true;

     var vm = this.props.vm;
 
    var isValid = this.validationManager.validateImpersonateUser(null, vm);

    if (!isValid) {
      console.log('validateImpersonateUser NOT valid');
      this.forceUpdate();
      return;
    } 
    vm.isActionInProgress = true;

    this.adminDataService.impersonateUser(vm.selectedUserImpersonator.email);

    //this.onCloseClick();
    //this.forceUpdate();
    

  }
 
  public onSessionResetClick(e: React.ChangeEvent<{}>) {
    console.log('onSessionResetClick');
     
    var vm = this.props.vm;
  
    vm.isActionInProgress = true;

    this.adminDataService.sessionReset();
  
  }
 

 
  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('ImpersonateAdminModal render');

    var vm = this.globalUIState;
    var isOpen = vm.isAdminImpersonateModalOpen;
    var state = this.props;

    if(!isOpen)
        return null;

   
      return (
          <App.MainSplitPage
      pageTitle="Jobs"
      state={state} 
     // hasDataLoaded={state.hasJobsDataLoaded}
      loading={this.adminDataService.isServiceCallPending}// && !vm.isActionInProgress)}
      errorMessage={state.apiErrorMessage}
      showModalForLoading={true}
      showContentWhenInProgress={true}
    >
          <Dialog
            open={isOpen}
            onClose={()=>this.onCloseClick()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Impersonate A User (Admin Feature Only)"}</DialogTitle>
            <DialogContent>

            {this.getAlert(vm.apiErrorMessage)}

              <DialogContentText id="alert-dialog-description">
              Select A User To Impersonate. You can also select a Role to filter the user drop down by users with the specific role. 
              </DialogContentText>

            {this.getForm()}

            </DialogContent>
            <DialogActions>
           
            <MT.Button 
            variant="contained"
            //color="default"
            style={{ margin: 10 }}
            onClick={this.onCloseClick.bind(this)}
          >Cancel</MT.Button>

     {/*     <MT.Button 
           
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={this.onSessionResetClick.bind(this)}
          >Session Reset</MT.Button>
  */}

           
            <MT.Button 
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={this.onImpersonateClick.bind(this)}
          >Impersonate</MT.Button>


            
            </DialogActions>
          </Dialog>
          </App.MainSplitPage>

      );
     


  }
}
export default connect(
  (state: App.AppState) => ({ ...state.adminUserData, ...state.globalData })
)(ImpersonateAdminModal as any);

