import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class ListStateDataItemReducers {


    constructor() {

    }

    public static getReducers(state: App.ListState | undefined, action: App.AppAction): App.ListState {
        var str = "";

        var result = new App.ListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("LIST_DATA_ITEM"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);
        let selectedWorkspace= globalState.workspaceVm?.selectedWorkspace;

        var listDataService = new App.ListDataService(selectedWorkspace?.workspaceId);
        var recordManager = new App.RecordManager();
  
        // const action = incomingAction as KnownAction;
        switch (action.type) {

            
      /*       case App.ActionTypes.LIST_DATA_ITEM_UPDATE_REQUEST: {
                console.log('LIST_DATA_ITEM_UPDATE_REQUEST:', action.payload);
                result =  state;//{ ...state };
                result.apiErrorMessage='';
               

                let sm = action.payload;
                let updatedRow = sm.updates[0];

                listDataService.updateValuesOld(sm);
 
               


                //result.hasDataLoaded = false;
                //result.isServiceCallPending = true;
                //result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.LIST_DATA_ITEM_UPDATE_SUCCESS  : {
                console.log('LIST_DATA_ITEM_UPDATE_SUCCESS  :', action.payload);


                result=state;
               // result =  { ...state };
                let data = action.payload;
                let updatedItems = data.records as any[];

                
                console.log('LIST_DATA_ITEM_UPDATE_SUCCESS updatedItems:',updatedItems);


                let updatedCount = recordManager.updateAllById(updatedItems,result.records);
                
                // console.log('LIST_DATA_ITEM_UPDATE_SUCCESS updatedCount:',updatedCount);
 
                 //console.log('LIST_DATA_ITEM_UPDATE_SUCCESS after update data:',result.records);

 

             

                //result.isServiceCallPending = false;
                //result.forceUpdateCount++;
                break;
            } */
           
           
            case App.ActionTypes.LIST_DATA_ITEM_ADD_REQUEST: {
                console.log('LIST_DATA_ITEM_ADD_REQUEST:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               

                let sm = action.payload;

                listDataService.insertItems(sm);
 
 
                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.LIST_DATA_ITEM_ADD_SUCCESS  : {
                console.log('LIST_DATA_ITEM_ADD_SUCCESS  :', action.payload);

                result =  { ...state, newItem:new LY.DataItemModel()};
               
                let data = action.payload as Api.ListState;
                let newItems = data.records as any[];
 
                console.log('LIST_DATA_ITEM_ADD_SUCCESS newItems:',newItems);

                    if(newItems && newItems.length>0){

                        console.log('LIST_DATA_ITEM_ADD_SUCCESS newItems adding...',newItems);

                        //add range
                        //result.records.push(...newItems);
                        result.records = state.records.concat(newItems);

                        var nn = new LY.DataItemModel();
                        result.newItem = new LY.DataItemModel();
                 
                        console.log('LIST_DATA_ITEM_ADD_SUCCESS result.newItem',result.newItem);

                    }

              /*   
  
               

                var updatedCount = recordManager.updateAllById(updatedItems,result.records);
                
                 console.log('LIST_DATA_ITEM_UPDATE_SUCCESS updatedCount:',updatedCount);
 
                let vm = result.vm;
 
                result.hasDataLoaded = true;

                result.isServiceCallPending = false;
                result.forceUpdateCount++; */

                result.forceUpdateCount++;
                break;
            }
            case App.ActionTypes.LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE : {
            console.log('LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE :', action.payload);
            result = { ...state };

            var data = action.payload;

            let vm = result.vm;
            vm.isDetailPopupModalOpen = data.isDetailPopupModalOpen;
            vm.selectedItem = data.selectedItem;
            vm.selectedItemOriginal = data.selectedItemOriginal;
 
            vm.selectedDetailListId = data.selectedDetailListId;
            vm.selectedDetailViewId = data.selectedDetailViewId;


             result.forceUpdateCount++;
            break;
        }
            
            default:
                result = state;
                //return result;
                break;
        }
       
       

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}