import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

export class LY_ListFilterModuleState extends LIB.BaseVmModel {

  public listColumns?: Api.ListColumn[];
  public view: Api.ListView = new Api.ListView();
  public searchColumns?: Api.ListColumn[];
  public selectedSearchColumns?: (string | undefined)[];
  public columns?: Api.ListColumn[];

  public newViewSm: Api.ListViewSm = new Api.ListViewSm();


  /* 
   public onAddNewSort: () => void;
   public onDeleteSort: (index: number) => void;
   public onChangeSort: (sort: Api.SortOrderBy, index: number) => void;
   public onAddNewGroup?: () => void;
   public onChangeJoinOperator: (value: Api.FilterConditionJoinOperatorEnum, conditionSetIndex?: number) => void;
   public onAddNewAdvancedFilterRow: (groupIndex?: number) => void;
   public onDelete: (rowIndex: number, groupIndex?: number) => void;
   public onClear: () => void;
   public onChangeFilterRow: (row: Api.FilterCondition,groupIndex?: number, rowIndex?: number, isConditionSet?: boolean ) => void;
   public setSearch: (value: string) => void;
   public onRemoveAllColumns: () => void;
   public onRemoveAllColumnsSearch: () => void;
   public onSelectAllColumns: () => void;
   public onSelectAllColumnsSearch: () => void;
   public onSelectColumns: ( value: string ) => void;
   public onSelectSearchColumns: (value: string) => void;
   public setInitialView: () => void;
   public onSearch: () => void;
   public onSaveViewSuccess: () => void;
   public onSaveAsNewViewSuccess: (value: string, close: Function) => void; */

  constructor() {
    super();
  }
}
