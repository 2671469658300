import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class GlobalWorkspaceItemReducers {


    constructor() {

    }


    public static getReducers(state: App.GlobalState | undefined, action: App.AppAction): App.GlobalState {
        var str = "";

        console.log('GlobalWorkspaceItemReducers start:', action.type);


        var result = new App.GlobalState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("GLOBAL_WORKSPACE_ITEM"))
            return state;



        let selectedWorkspace= state.workspaceVm?.selectedWorkspace;
        let workspaceId = selectedWorkspace?.workspaceId!;
        console.log('GlobalWorkspaceItemReducers workspaceId:', workspaceId);

        var workspaceItemService = new App.WorkspaceItemService(workspaceId);
 
        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE: {
                console.log('GLOBAL_WORKSPACE_ITEM_CHANGE:', action.payload);

                result = { ...state };

                var newVm = action.payload as App.WorkspaceVm;

                var workspaceVm = result.workspaceVm;
                let isNew = true;
                workspaceVm.selectedItem = newVm.selectedItem;
                workspaceVm.selectedItemOriginal = LIB.ObjectHelper.clone(newVm.selectedItem);

               /*  let isNew = false;
                if(newVm.selectedItem && workspaceVm.selectedItem != newVm.selectedItem
                    ){
                    workspaceVm.selectedItem = newVm.selectedItem;
                    workspaceVm.selectedItemOriginal = LIB.ObjectHelper.clone(newVm.selectedItem);

                    isNew=true;
                } */

                if(newVm.selectedItemElement)
                workspaceVm.selectedItemElement = newVm.selectedItemElement;
            
                workspaceVm.isListSettingsOpen = newVm.isListSettingsOpen;
                workspaceVm.isItemContextMenuOpen = newVm.isItemContextMenuOpen;
               //  console.log('GLOBAL_WORKSPACE_ITEM_CHANGE new: workspaceVm:', workspaceVm);

                //result.globalUIState.
                result.forceUpdateCount++;

                if(newVm.disableOnSelectRouteToPage)
                    break;

                let isLinkedItem  = newVm.selectedItem?.isLinkedItem; 
                let linkedWorkspaceId = -1;
                if(isLinkedItem)
                    linkedWorkspaceId = newVm.selectedItem?.workspaceId!;


                if(isNew && newVm.selectedItem?.itemTypeId === App.WorkspaceItemTypeEnum.List){
                   
                   console.log('GLOBAL_WORKSPACE_ITEM_CHANGE routeToList defaultViewId:', workspaceVm?.selectedItem?.defaultViewId);

                    App.RouteManager.routeToList(workspaceVm?.selectedItem?.defaultViewId,workspaceId,linkedWorkspaceId);

                }
                else  if(workspaceVm.selectedItem?.itemTypeId === App.WorkspaceItemTypeEnum.Folder){

                    App.RouteManager.routeToFolder(workspaceVm?.selectedItem,workspaceId,linkedWorkspaceId);
 
                }

                break;
            }
            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST: {
                console.log('GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload as App.WorkspaceVm;

                var updated = new Api.WorkspaceItem();
                updated = LIB.ObjectHelper.setData(data.selectedItem,updated);
                updated.workspaceItemId = data?.selectedItem?.workspaceItemId;

                if(data.selectedWorkspace)
                    updated.workspaceId = data.selectedWorkspace.workspaceId!;
              
                console.log('GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST updated:', updated);

                workspaceItemService.updateItem(updated);

                 result.forceUpdateCount++;
                break;

            }
            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_SUCCESS: {
                console.log('GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_SUCCESS:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload as Api.WorkspaceItem
 

                vm.isListSettingsOpen = false;
                result.forceUpdateCount++;
                break;

            }
            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_NEW_REQUEST: {
                console.log('GLOBAL_WORKSPACE_ITEM_NEW_REQUEST:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload as App.WorkspaceVm;
  
                let item = data.newItem;
                console.log('GLOBAL_WORKSPACE_ITEM_NEW_REQUEST new item:', item);

                vm.isActionInProgress = true;
                workspaceItemService.createItem(item);

                 result.forceUpdateCount++;
                break;

            }
            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_NEW_SUCCESS: {
                console.log('GLOBAL_WORKSPACE_ITEM_NEW_SUCCESS:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload as Api.WorkspaceItem 

               
                
                vm.selectedItem = data;
                vm.selectedWorkspace?.workspaceItems?.push(data);
 
           
                vm.isNewListModalOpen = false;
                vm.isNewFolderModalOpen = false;
                vm.isActionInProgress = false;
                result.forceUpdateCount++; 

                if(data.itemTypeId === App.WorkspaceItemTypeEnum.List){
                    App.RouteManager.routeToList(data.defaultViewId,workspaceId);
                    
                }


                break;

            }

            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_DELETE_REQUEST: {
                console.log('GLOBAL_WORKSPACE_ITEM_DELETE_REQUEST:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload as App.WorkspaceVm;
  
                let item = data.selectedItem;
                console.log('GLOBAL_WORKSPACE_ITEM_NEW_REQUEST DELETE item:', item);

                vm.isActionInProgress = true;
                workspaceItemService.deleteItem(item?.workspaceItemId!);

                 result.forceUpdateCount++;
                break;

            }
            case App.ActionTypes.GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS: {
                console.log('GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS:', action.payload);

                result = { ...state };
                let vm = result.workspaceVm
                let data = action.payload; 

                let selectedItem = vm.selectedItem;
                let items = vm.selectedWorkspace?.workspaceItems;

                if(items && data && selectedItem){
                  //delete item by id
                  let index = items.findIndex((item) => item.workspaceItemId === selectedItem?.workspaceItemId);
                  console.log('GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS index:',index);
                  console.log('GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS items:',items);

                  if(index>-1){

                    items.splice(index, 1);
                    vm.selectedItem = new Api.WorkspaceItem();

                  }
                
                }
 
                vm.isItemDeleteOpen = false;
                
                vm.isActionInProgress = false;
                result.forceUpdateCount++; 

                App.RouteManager.routeToPath(`/`);


                break;

            }
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}