import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
import * as MT from '@mantine/core';


import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_UpdateWorkspaceScreen.css';

interface UpdateWorkspaceScreenProps {
  state: App.GlobalState;


}

export const UpdateWorkspaceScreen: React.FC<UpdateWorkspaceScreenProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);

  const [ open, setOpen ] = React.useState(false);

  const [ selectedTypeInfo, setSelectedTypeInfo ] = useState('');


  const [ updatedWorkspace, setUpdatedWorkspace ] = useState(new Api.Workspace());

  //const workspace = App.AppBase.currentWorkspace;

  //const { view, list, updatedWorkspace, columnTypes,columns } = props.state;

  var vm = props.state.workspaceVm;
  const state = props.state;

  useEffect(() => {

    let selectedWorkspace = state.workspaceVm?.selectedWorkspace;
    console.log('UpdateWorkspaceScreen useEffect selectedWorkspace', selectedWorkspace);

    setUpdatedWorkspace(selectedWorkspace);

  }, [ state.workspaceVm.selectedWorkspace ]);




  function onSubmit(e: React.FormEvent<HTMLFormElement>) {

    e.preventDefault();

    console.log('UpdateWorkspaceScreen onSubmit updatedWorkspace:', updatedWorkspace);
    //console.log('UpdateWorkspaceScreen onSubmit configId:', data?.configId);
    //console.log('UpdateWorkspaceScreen onSubmit  updatedWorkspace configId:', updatedWorkspace?.configId);

    var isValid = validate('all');
    if (!isValid) {
      console.log('UpdateWorkspaceScreen onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    }


    var workspaceId = state.workspaceVm.selectedWorkspace?.workspaceId;

    console.log('UpdateWorkspaceScreen workspaceId:', workspaceId);

    var service = new App.WorkspaceService(workspaceId);


    //console.log('UpdateWorkspaceScreen updatedWorkspace send:', updatedWorkspace);
    //return;

    service.updateItem(updatedWorkspace,
      //onSuccess
      (result: any) => {
        console.log('UpdateWorkspaceScreen onSuccess:', result);

        let selectedWorkspace = state.workspaceVm?.selectedWorkspace;

        if (selectedWorkspace)
          LIB.ObjectHelper.setData(result, selectedWorkspace)

        resetAll();

        forceUpdate();


      },
      //onError
      (error: any) => {

        console.error('UpdateWorkspaceScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);

        forceUpdate();
      });


    vm.isActionInProgress = true;
    forceUpdate();


  }

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('DefaultListTableForm onInputChange key:', key);
    // console.log('DefaultListTableForm onInputChange updatedWorkspace:', updatedWorkspace);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('DefaultListTableForm onValueChange key:', key);
    //console.log('DefaultListTableForm onValueChange updatedWorkspace:', updatedWorkspace);

    if (key == 'name') {
      updatedWorkspace.schemaName = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }

    //@ts-ignore
    updatedWorkspace[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange updatedWorkspace', updatedWorkspace);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = updatedWorkspace;

    vm.errors.clear();


    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        vm.errors.set('name', `Workspace Name is required!`);
      else if (item.name.length > 50)
        vm.errors.set('name', `Workspace Name must be less than 50 characters!`);
    }
    if (vAll || key == 'schemaName') {
      if (LIB.Common.isNullOrEmpty(item.schemaName))
        vm.errors.set('schemaName', `Internal Name is required!`);
      else if (item.schemaName.length > 50)
        vm.errors.set('schemaName', `Internal Name must be less than 50 characters!`);
    }
    if ((vAll || key == 'tag') && LIB.Common.isNullOrEmpty(item.tag)) {
      vm.errors.set('tag', `Tag is required!`);
    }
    if ((vAll || key == 'color') && LIB.Common.isNullOrEmpty(item.color)) {
      vm.errors.set('color', `Color is required!`);
    }


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  function getForm() {
    //var vm = props.state.vm;
    //var columnTypes = props.state.columnTypes;

    var globalError = vm.errors.get('all');

    var isDeleteLocked = vm.isWorkspaceDeleteInProgress || vm.isActionInProgress || updatedWorkspace.isDeleteLocked;

    var form = (
      <form autoComplete="off"
        onSubmit={onSubmit}
        className='UpdateWorkspaceScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div className='UpdateWorkspaceScreenFormScrollArea' >

          <LY.LY_Input
            name="name"
            value={updatedWorkspace.name}
            type='text'
            label="Name"
            placeholder="Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('name')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />
          <LY.LY_Input
            name="schemaName"
            value={updatedWorkspace.schemaName}

            type='text'
            label="Internal Name"
            placeholder="Internal Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('schemaName')}

            helpText="This name will be used in APIs or Integrations"
            helpTextPlacement='bottom'
            disabled={updatedWorkspace.isNameLocked}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />



          <div style={{ display: 'flex', gap: '1rem' }}>
            <LY.LY_Input
              name="tag"
              value={updatedWorkspace.tag}

              type='text'
              label="Tag"
              //maxLength={3}
              placeholder="Short Tag"
              labelPlacement='top'
              withStar={true}
              error={vm.errors.get('tag')}

              //helpText="This name will be used in APIs or Integrations"
              // helpTextPlacement='bottom'
              inputProps={{ maxLength: 5 }}
              onChange={(m, e) => {
                onValueChange('tag', e!.currentTarget?.value?.toUpperCase());
              }}

            />

            <LY.LY_ColorInput
              name="color"
              value={updatedWorkspace.color || ''}

              // type='text'
              label="Color"
              placeholder="Workspace Color"
              labelPlacement='top'
              withStar={true}
              error={vm.errors.get('color')}

              // helpText="This name will be used in APIs or Integrations"
              //helpTextPlacement='bottom'
              onColorChange={(m, val) => {
                onValueChange('color', val);
              }}
            />
          </div>

          <div style={{ padding: '1rem', paddingLeft: 0 }}>
            <div style={{ display: 'flex', gap: '.7rem', alignItems: 'center' }}>
              <MT.Badge color={updatedWorkspace.color || 'gray'}
                style={{ width: MT.rem(40), height: MT.rem(40), fontSize: MT.rem(12), padding: '5px' }}
                circle size='xl'
                variant="filled"
                title={updatedWorkspace.tag || 'CRM'}
              >
                {updatedWorkspace.tag || 'CRM'}
              </MT.Badge>
              {updatedWorkspace?.name || 'Workspace Name'}
            </div>
          </div>
          {props.state.availableConnections?.length > 1 &&
            <LY.LY_Select
              name="configId"
              value={updatedWorkspace.configId}

              label="Connection"
              placeholder="Select Connection"
              //selectOptionValue={App.ListColumnTypeEnum.Other}
              data={props.state.availableConnections}
              //value={defaultTypeValue?.configId}
              labelProperty="connectionName"
              valueProperty="configId"
              withStar={false}
              error={vm.errors.get('configId')}
              // helpText="This name will be used in APIs or Integrations"
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
            />
          }




        </div>


        <Group mt="md" justify='right'>
          <MT.Button variant='default' onClick={() => onOpenChange(false)} >Cancel</MT.Button>
          <MT.Button color="red"


            disabled={isDeleteLocked}
            title={isDeleteLocked ? 'This Workspace cannot be deleted!' : ''}
            onClick={onDeleteColumnClick}
          >
            Delete
          </MT.Button>

          <MT.Button type='submit'
            loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}

          >
            Save
          </MT.Button>
        </Group>

      </form>
    );

    return form;

  }


  function closeScreen() {


    vm.isWorkspaceDeleteInProgress = false;
    vm.isWorkspaceDeleteOpen = false;
    vm.isActionInProgress = false;

    if (vm.errors)
      vm.errors.clear();

    resetAll();
  }


  function resetAll() {
    console.log('UpdateWorkspaceScreen resetAll');


    vm.errors.clear();
    vm.isActionInProgress = false;
    vm.isUpdateWorkspaceScreenOpen = false;

    setUpdatedWorkspace(new Api.Workspace());

    //console.log('UpdateWorkspaceScreen resetAll:', updatedWorkspace);
    //console.log('UpdateWorkspaceScreen resetAll ServerError:',serverError);

  }

  function onOpenChange(open: boolean) {
    console.log('UpdateWorkspaceScreen onOpenChange');


    vm.isUpdateWorkspaceScreenOpen = open;

    if (!open)
      resetAll();

    //setOpen(open);
    forceUpdate();
  }


  function onDeleteColumnClick(e: React.FormEvent<HTMLButtonElement>) {
    // console.log('UpdateWorkspaceScreen onDeleteColumnClick');

    vm.isWorkspaceDeleteOpen = true;

    if (vm.errors)
      vm.errors.clear();
    forceUpdate();
  }
  function onDeleteColumnCancel(e: React.FormEvent<HTMLFormElement>) {

    vm.isWorkspaceDeleteOpen = false;

    if (vm.errors)
      vm.errors.clear();
    forceUpdate();
  }
  function onDeleteColumnConfirm(e: React.FormEvent<HTMLFormElement>) {

    // e.preventDefault();

    console.log('UpdateWorkspaceScreen onDelete updatedWorkspace:',);


    var sm = updatedWorkspace;
    if (vm.errors)
      vm.errors.clear();


    console.log('UpdateWorkspaceScreen sm send:', sm);
    //return;

    var service = new App.WorkspaceService(sm.workspaceId);

    service.delete(sm.workspaceId!,
      //onSuccess
      (result: any) => {
        console.log('UpdateWorkspaceScreen onSuccess:', result);


        App.AppBase.showSaveSuccess();

        closeScreen();

        var findIndex = state.workspaceVm.workspaces.findIndex(x => x.workspaceId == sm.workspaceId);
        state.workspaceVm.workspaces.splice(findIndex, 1);
        state.workspaceVm.selectedWorkspace = state.workspaceVm.workspaces.find(x=>x.isDefault) || state.workspaceVm.workspaces[0];

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_LIST_CHANGED, state.workspaceVm);



      },
      //onError
      (error: any) => {

        console.error('UpdateWorkspaceScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isWorkspaceDeleteInProgress = false;
        vm.errors.set('all', errorMessage);
        forceUpdate();
      });


    vm.isWorkspaceDeleteInProgress = true;
    forceUpdate();



  }


  function render() {

    console.log('UpdateWorkspaceScreen render vm.isUpdateWorkspaceScreenOpen', vm.isUpdateWorkspaceScreenOpen);

    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    // var vm = state.vm;
    // var column = props.state.updatedWorkspace


    if (LIB.Common.isNullOrEmpty(state) || !vm.isUpdateWorkspaceScreenOpen)// || column == undefined)
      return null;

    var displayName = updatedWorkspace?.name;

    return (
      <>
        <Modal
          id='UpdateWorkspaceScreenModal'
          opened={vm.isUpdateWorkspaceScreenOpen}
          onClose={() => onOpenChange(false)}
          title="Update Workspace"
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}

          className={'UpdateWorkspaceScreenFormModal'}
          closeOnClickOutside={false}

        >

          {getForm()}

          <LIB.ConfirmModal
            visible={vm.isWorkspaceDeleteOpen}
            onCancelClick={onDeleteColumnCancel}
            onConfirmClick={onDeleteColumnConfirm}
            title={`Confirm Delete: ${displayName}`}
            // content={`Are you sure you want to delete item "${vm.selectedItem?.name}"?`}

            content={
              <div>
                <div>Are you sure you want to delete column <b>{displayName}</b>?</div>

                <MT.Alert variant="light" color="red"
                  title={`WORKSPACE DELETE WARNING: ${displayName}`}
                  style={{ marginTop: 10, minHeight: 50, padding: 8 }} >

                  THIS WILL DELETE ALL ASSOCIATED WORKSPACE DATA, INCLUDING LISTS AND VIEWS!!

                </MT.Alert>




              </div>}

            confirmButtonLabel="Delete"
            errorMessage={vm.apiErrorMessage}
            disabled={vm.isWorkspaceDeleteInProgress}
          />


        </Modal>

      </>
    );
  }

  return render();
};

