
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class ListVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;
  
    public isItemAddOpen: boolean = false;
    public isItemDeleteOpen: boolean = false;
    public isItemEditOpen: boolean = false;


    public selectedItem : LY.DataItemModel;
    public selectedItemOriginal : LY.DataItemModel;
    public selectedId: string='';

    public viewId:any=-1;

    public isAddColumnScreenOpen: boolean = false;
    public isColumnSettingsScreenOpen: boolean = false;
    public isColumnDeleteOpen: boolean = false;
    public isColumnDeleteInProgress: boolean = false;

    

    public isDetailRightPanelOpen: boolean = false;


    public isDetailPopupModalOpen: boolean = false;
    public selectedDetailListId?:string;
    public selectedDetailViewId?:number;

     
 
    public isRelActionInProgress: boolean = false;
    public isSearchInProgress: boolean = false;


 
    constructor() {

        super();
    }
 
       
    public setSelectedItem(item: LY.DataItemModel | undefined=undefined) {

     
        if (item === undefined)
            item = new LY.DataItemModel();


        this.selectedItem = item;
        this.selectedItemOriginal = LIB.ObjectHelper.clone(this.selectedItem);

    }

 
    
    

}