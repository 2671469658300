import * as App from 'AppReferences';
import * as Redux from 'react-redux';
import { BrowserHistory, History } from 'history';
import { MantineTheme,MantineThemeOverride } from '@mantine/core';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { Location, NavigateFunction } from 'react-router';
import Alert from '@mui/material/Alert';
import * as React from 'react';
import * as MUI from '@mui/material';
import { notifications } from '@mantine/notifications';


export class AppBase {


  public static appApiBaseUrl: string;
  public static appBaseUrl: string;
  // public static appPath:string;
  // public static baseUrl:string;
  public static envName: string;
  //public static appName:string;

  public static errorMessages: any;
  public static lastErrorMessage: string;

  public static hasinitialized: boolean;
  public static isIE: boolean;

  public static store: any;

  public static history: BrowserHistory;
  public static theme: MantineThemeOverride;
  public static hasAuthTokenError: boolean;

  public static baseFileBrowseUrl: string;


  public static snackMessage: any;
  public static snackMessageDuration: number | null =5000;
  public static snackError: boolean = false;

  private static _appInfo: App.AppInfoVm;

  public static authTokenKey: string = 'ApptokenId';

  public static isInitialDataCalled: boolean = false;
  public static isAppInfoDataCalled: boolean = false;

  public static navigateToPage: string;

  public static navigator:NavigateFunction;
  public static location:Location;
  public static params:any;

  public static registerUrl:any = '/Pricing';

 
 // public static _currentUser:App.AppUser;

  constructor() {


  }

  public static get appInfo(): App.AppInfoVm {
    return AppBase._appInfo;
  }

  
  public static getLoginToForFullAccessWarning(msg:string = ''){

    if(App.AppBase.isUserAuthenticatedAndEmailConfirmed)
      return null;

    if(LIB.Common.isNullOrEmpty(msg))
      msg = 'Your access is limited.'
 
    return  <Alert severity="warning">
          {msg} Please  <MUI.Link
   onClick={(e) =>App.RouteManager.routeToPath("/Login")}
   color="primary">Login</MUI.Link>      to get Full Access to Loocey
        </Alert>;

  } 
  

  public static setAppInfo(appInfo: App.AppInfoVm | null = null) {
    //console.log('setAppInfo', appInfo);

    if (LIB.Common.isNullOrEmpty(appInfo) || appInfo === null)
      appInfo = new App.AppInfoVm();

    //  if(!LIB.Common.isNullOrEmpty(appInfo.appReleaseDate)) // should only be called once from Global
    //      return;

    AppBase._appInfo = appInfo;

    AppBase.appBaseUrl = appInfo.appBaseUrl;
    AppBase.appApiBaseUrl = appInfo.appApiBaseUrl;
    AppBase.envName = appInfo.envName;
    AppBase.baseFileBrowseUrl = appInfo.appBaseFileBrowseUrl;

   // console.log('setAppInfo envName 1: ', AppBase.envName);

    //These are needed for local dev to work
    var envAppApiBaseUrl = AppBase.getEnvVariable('REACT_APP_API_BASE_URL');
    var envAppBaseUrl = AppBase.getEnvVariable('REACT_APP_BASE_URL');
    var envEnvName = AppBase.getEnvVariable('REACT_APP_ENV_NAME');

   /*  console.log('EnvVariable envEnvName: ', envEnvName);
    console.log('EnvVariable envAppBaseUrl: ', envAppBaseUrl);
    console.log('EnvVariable envAppApiBaseUrl: ', envAppApiBaseUrl);
 */
    if (LIB.Common.isNullOrEmpty(AppBase.envName) && !LIB.Common.isNullOrEmpty(envEnvName))
      AppBase.envName = envEnvName;

    if (!LIB.Common.isNullOrEmpty(envAppBaseUrl))
      AppBase.appBaseUrl = envAppBaseUrl;

    if (LIB.Common.isNullOrEmpty(AppBase.appApiBaseUrl) && !LIB.Common.isNullOrEmpty(envAppApiBaseUrl) ||
      LIB.Common.isNullOrEmpty(AppBase.envName)) {
      AppBase.appApiBaseUrl = envAppApiBaseUrl;
    } 

    console.log('setAppInfo envName: ', AppBase.envName);
    //console.log('setAppInfo appBaseUrl: ', AppBase.appBaseUrl);
    //console.log('setAppInfo appApiBaseUrl: ', AppBase.appApiBaseUrl);
    //console.log('setAppInfo appInfo: ', appInfo);  

  }

  public static setAuthToken(token: string) {
    console.log('setAuthToken token', token);

    // App.AppBase.authToken = token;
    sessionStorage.setItem(AppBase.authTokenKey, token);
  }

  public static init(reduxStore: any, history: BrowserHistory, theme: MantineThemeOverride) {

    // console.log('REACT_APP_API_BASE_URL2:'+process.env.REACT_APP_API_BASE_URL); 

    if (AppBase.hasinitialized)
      return;


    AppBase.setAppInfo();

    // console.log('appBaseUrl: ' + AppBase.appBaseUrl);
    // console.log('baseFileBrowseUrl: ' + AppBase.baseFileBrowseUrl);

    AppBase.theme = theme;
    AppBase.history = history;
    AppBase.store = reduxStore;
    AppBase.hasinitialized = true;
    AppBase.isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    AppBase.resetErrorMessages();
  

  }

  public static resetSnackMessages() {
    console.log('resetSnackMessages');

    AppBase.snackError = false;
    AppBase.snackMessage = '';

  }

  public static showSaveSuccess(autoClose:number|boolean =3000) {
   // console.log('showSaveSuccess');
    AppBase.showMessage('Successfully Saved!');
 

  }
  public static showError(message: string) {
    AppBase.showMessage(message, true);

  }
  public static showMessage(message: any, isError: boolean = false, autoClose:number|boolean =3000) {
   // AppBase.snackError = isError;
   // AppBase.snackMessage = message;
   // AppBase.snackMessageDuration=duration;
    console.log('AppBase showMessage', message);

/*      var style:React.CSSProperties = {  };
    if(isError)
      style = { backgroundColor: 'red' }; */
    var color = isError ? 'red' : 'lime';
   


    var options = {
      id: 'ly-notification',
      withCloseButton: true,
      //onClose: () => console.log('unmounted'),
      //onOpen: () => console.log('mounted'),
      autoClose: autoClose,
    //  title: "Message title",
      message: message,
    /*   color: 'black', */
      color:color ,
      radius:"md",
       limit:5,
      //icon: <IconX />,
      className: 'LY_Global_Notification',
      //style: style,
      loading: false,
    };
    /* color="lime" radius="md" title="We notify you that" withCloseButton={true} 
 */
       notifications.show(options);


  }

  public static async setAccessToken() {


    //AppBase.authToken = await App.AppBase.authProvider.getAccessToken();

  }
 
  public static getEnvVariable(name: string): string {

    var val = process.env[ name ];
    if (val !== undefined)
      return val;

    return "";

  }


  public static get hasErrors(): boolean {
    return (!LIB.Common.isNullOrEmpty(AppBase.lastErrorMessage));
  }
  public static resetErrorMessages(key: string = ''):boolean {
 
    var result = !LIB.Common.isNullOrEmpty(AppBase.lastErrorMessage);
    AppBase.lastErrorMessage = "";
    if (LIB.Common.isNullOrEmpty(key))
      AppBase.errorMessages = {};
    else {
      AppBase.errorMessages[ key ] = '';
      AppBase.errorMessages[ "error" ] = '';
    }
    return result;
  }

  public static getInputErrorProps(key: string): any {
    return LIB.ErrorHelper.getInputErrorProps(key, AppBase.errorMessages);
  }

  public static addError(key: string, errorEx: any) {
    //console.log('addError',key);
    var msg = LIB.ErrorHelper.getStringErrorMessage(key, errorEx);
    if (LIB.Common.isNullOrEmpty(msg))
      return;

    AppBase.lastErrorMessage = msg;

    //console.log('addError:',AppBase.lastErrorMessage);

    AppBase.errorMessages[ key ] = msg;

  }


  public static getValidationErrorAlert(key: string) {
    // console.log('getValidationErrorAlert',AppBase.errorMessages);
    var msg = AppBase.errorMessages[ key ];

    // console.log('getValidationErrorAlert msg:' + msg);

    var alert = LIB.ErrorHelper.getAlertError(msg);
    return alert;
  }


  public static getValidationErrorMessage(key: string) {
    var msg = AppBase.errorMessages[ key ];
    return msg;
  }


  public static getAnyErrorMessage(key: string, errorMessage: string) {

    if (LIB.Common.isNullOrEmpty(errorMessage))
      errorMessage = App.AppBase.getValidationErrorMessage(key);
    if (LIB.Common.isNullOrEmpty(errorMessage))
      errorMessage = App.AppBase.getValidationErrorMessage("error");

    return errorMessage;
  }


  /* 
      public static dispatch(event:any){
          var store = AppBase.store;
          store.dispatch(event);
      } */

  public static getState(): App.AppState {
    var store = AppBase.store;

    return store.getState();
  }
   
  public static setApiError(action:any, appState:App.AppState|undefined=undefined ) {
    // console.log('setApiError action:',action);
   // var apiErrorMessage = '';

   if(appState===undefined)
      appState = AppBase.getState();

    var apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);

     //console.log('setApiError apiErrorMessage:',apiErrorMessage);

    appState.globalData.globalUIState.apiErrorMessage = apiErrorMessage;

    //console.log('setApiError globalUIState:', appState.globalData.globalUIState.apiErrorMessage);

}


  public static setCurrentUser(user:App.AppUser){
    //console.log('setCurrentUser',user);
    var appState = AppBase.getState();
    appState.globalData.initialData.currentUser.setData(user);
    
    //AppBase._currentUser = user;
  }
  
  public static getCurrentUser(appState:App.AppState|null=null): App.AppUser {

    if(appState===null)
        appState = AppBase.getState();

    var user = appState?.globalData?.initialData?.currentUser;

   //  console.log('test22 getCurrentUser 1',user);

    if(!user?.isAuthenticated){
      user = App.AuthManager.getUserFromLocalStorage();
    //   console.log('test22 getUserFromLocalStorage getCurrentUser 2',user);
     //  console.log('test22 getUserFromLocalStorage getCurrentUser isAuthenticated 2',user?.isAuthenticated);
      if(user?.isAuthenticated)
          AppBase.setCurrentUser(user);
    }

    //if(LIB.Common.isNullOrEmpty(user))
    //    user = new App.AppUser();

  //  console.log('getCurrentUser user',user);
    // console.log('currentUser isUserAuthenticated 3',user?.isUserAuthenticated);

    return user;  

  }
 
  
  public static getGlobalState(appState:App.AppState|null=null): App.GlobalState {

    if(appState===null)
        appState = AppBase.getState();

    var result = appState?.globalData;
     
    return result;  

  }

  public static getCurrentWorkspaceVm(appState:App.AppState|null=null): App.WorkspaceVm {

    if(appState===null)
        appState = AppBase.getState();

    var workspaceVm = appState?.globalData?.workspaceVm;
     
    return workspaceVm;  

  }
  
  public static get currentWorkspace(): Api.Workspace {
   
    var result = AppBase.getCurrentWorkspaceVm();
    var wp = result?.selectedWorkspace;
    return wp;  
  }

  public static get currentUser(): App.AppUser {
   
    var user = AppBase.getCurrentUser();
    return user;  
  }

  public static getIsMobile():boolean {
   
    var result =  (window.innerWidth <= 760);
    return result;  
  }

  public static isMobile: boolean = false;

 
  public static get isUserAuthenticated(): boolean {
    if(AppBase.currentUser?.isAuthenticated)
      return true;
    else 
     return false;
  }

  public static get isUserAuthenticatedAndEmailConfirmed(): boolean {
    if(AppBase.isUserAuthenticated && AppBase.currentUser?.isEmailConfirmed)
      return true;
    else 
     return false;
  }

  public static isDebug(): boolean { return (AppBase.envName?.toLowerCase() === "debug"); }
  public static isLocalDev(): boolean { return (AppBase.envName?.toLowerCase() === "local-dev"); }

  public static isDev(): boolean { return (AppBase.envName?.toLowerCase().indexOf("dev") > -1 || AppBase.isDebug()); }
  public static isProd(): boolean { return (AppBase.envName?.toLowerCase() === "prod" || AppBase.isDebug()); }
  public static isStaging(): boolean { return (AppBase.envName?.toLowerCase() === "staging" || AppBase.isDebug()); }



  public static saveRedirectToLocalStorage() {
    console.log('saveRedirectToLocalStorage, loc', window.location);

    var key = 'redirect';

    localStorage.setItem(key, window.location.href);


  }

  public static redirectFromLocalStorage(): boolean {

   // console.log('test22 redirectFromLocalStorage, isUserAuthenticated', AppBase.isUserAuthenticated);
    var key = 'redirect';

    var redirect: string | null = localStorage.getItem(key);
    if (redirect !== null && AppBase.isUserAuthenticated) {


      localStorage.removeItem(key);
      console.log('test22 redirectFromLocalStorage redirect.... ', redirect);

      window.location.href = redirect;

      return true;
    }

    return false;

  }




}