import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Table, Typography, NativeSelect, InputLabel, FormControl, Container, Button, Select, Backdrop, MenuItem, CircularProgress } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import { Tabs, Tab } from '@mui/material';
import * as Api from 'app-api';
/* import { AdministrationCMSProfile } from './Profile/AdministrationCMSProfile';
import { CompanyProfile } from './Company/CompanyProfile';
import { AdministrationCMSSecurity } from './Security/AdministrationCMSSecurity';
import { AdministrationCMSBilling } from './Billing/AdministrationCMSBilling';
 */


//import { AdministrationCMSForm } from './AdministrationCMSForm';



class AdministrationCMS extends React.Component<App.AdministrationState, {}> {

  // public projectDataService: App.ProjectDataService;

  public validationManager: App.ValidationManager;


  constructor(props: any) {

    super(props);
    //  this.projectDataService = new App.ProjectDataService();
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationCMS componentDidMount');
    var vm = this.props.vm;

/* todo
    if (!this.props.hasDataLoaded) {
      App.AppStore.dispatch(App.ActionTypes.AdministrationCMS_GET_PAGE_DATA_REQUEST);
    }
 */
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationCMS componentDidUpdate');

  }




  public onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
    console.log('onTabChange');

    //var project = this.props.project;

    // if (LIB.Common.isNullOrEmpty(project) || LIB.Common.isNullOrEmpty(project.aliasId))
    //   return;

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
      id = id.replace('Tab', '');

    var vm = this.props.vm;
    vm.selectedTabIndex = newValue;

    vm.selectedTab = id;
    //  App.AppBase.history.push(`AdministrationCMS?id=${project.aliasId}&tab=${vm.selectedTab}`);

    this.forceUpdate();
  }


  private getTabList(): string[] {

    
    var result: string[] = [];
    result.push('Pages');
    result.push('Page Blocks');
    result.push('Categories');
    result.push('Settings');

    return result;

  }


  public onTabUpdate(){
    console.log('onTabUpdate');
    this.forceUpdate();
  }


  public getTabs() {
   
    var theme = App.AppBase.theme;
    var vm = this.props.vm;
    var state = this.props;

    console.log('getTabs selectedTab', vm.selectedTab);


    var selected = vm.selectedTabIndex;
    var theme = App.AppBase.theme;
    var tabList = this.getTabList();
    //var startUrl = `AdministrationCMS?&tab=`;
    var startUrl = `/Administration/CMS/`;

    //var user = state.profile;

    return <App.SmartTabs
      tabList={tabList}
      selectedTab={vm.selectedTab}
      startUrl={startUrl}
      forceUpdate={() => { this.onTabUpdate();}}
      onSelectedChange={(newTab: string) => {
        console.log("onSelectedChange newTab", newTab);
        vm.selectedTab = newTab;
      }}
    >
      <LIB.TabPanel name="Pages" selectedKey={vm.selectedTab}>
      <h1>Pages</h1>
       {/*  <AdministrationCMSProfile state={state} /> */}
      </LIB.TabPanel>

      <LIB.TabPanel name="PageBlocks" selectedKey={vm.selectedTab}>
      <h1>Page Blocks</h1>

      </LIB.TabPanel>

      <LIB.TabPanel name="Categories" selectedKey={vm.selectedTab}>
    
      <h1>Categories</h1>

      </LIB.TabPanel>

      <LIB.TabPanel name="Settings" selectedKey={vm.selectedTab}>
    
      <h1>Settings</h1>
      
      </LIB.TabPanel>

     {/*  {user.isManager && 
      <LIB.TabPanel name="Billing" selectedKey={vm.selectedTab}>
   <h1>Security</h1>
      </LIB.TabPanel>
      
      
      } */}

    </App.SmartTabs>;


  }

  public render() {
    console.log('AdministrationCMS render');

    var state = this.props;
    var vm = this.props.vm;

    return <App.MainSplitPage
    pageTitle="CMS"
    state={vm}
    hasDataLoaded={state.hasJobDataLoaded}
    loading={(state.isServiceCallPending && !vm.isActionInProgress)}
    showContentWhenInProgress={true}
    showModalForLoading={true}
    errorMessage={state.apiErrorMessage}
    isChildPage={true}
  >
 
      <div style={{   width: '85vw' }}>

        {this.getTabs()}

      </div>


     </App.MainSplitPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationCMS as any);
