import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import * as Icons2 from '@mui/icons-material/';

import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';


import { createStyles,  Theme } from '@mui/material/styles';

//import './AccountMenuItem.css'


interface AccountMenuItemProps { 
  currentUser: App.AppUser;
  globalState: App.GlobalUIStateVm;
}

export class AccountMenuItemState {
  open: boolean;
  anchorEl: null | HTMLElement;
  constructor() {
    this.open = false;
    this.anchorEl = null;
  }
}

export class AccountMenuItem extends React.Component<AccountMenuItemProps, AccountMenuItemState> {

  constructor(props: any) {
    super(props);

    this.state = new AccountMenuItemState();

  }
 
  
  public onEditProfileClick(e: React.ChangeEvent<{}>) {
    console.log('onEditProfileClick');
  
    App.RouteManager.routeToPath("/Account/Profile");
    var vm = { ...this.state };
    vm.open = false;
    this.setState(vm);
    //this.forceUpdate();
    
 
  }
  
  public onLogoutClick(e: React.ChangeEvent<{}>) {
    console.log('onLogoutClick');
 
    // var state = this.props;
    // var vm = state.vm; 

    //call logout
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_LOGOUT_REQUEST);
    App.AuthManager.logout();
    var state = App.AppBase.getState();
    state?.globalData?.initialData?.currentUser.reset();

    App.RouteManager.redirect("/Login");

    this.forceUpdate();
 
  }

  public getAccountChildMenuBox(currentUser:App.AppUser) {
 
    if (!currentUser?.isAuthenticated)
      return null;

    var editProfileBtn = null;
    if(currentUser?.isEmailConfirmed){
      editProfileBtn =  <MT.Button variant="contained" color="primary"
                        onClick={(e) => this.onEditProfileClick(e)} 
                         >
                         EDIT PROFILE</MT.Button>
    }

    var impersonating:any = null;
    if(currentUser.isImpersonating  ){
       
      impersonating =  <UI.Typography style={{ flexGrow: 1, color:'red' }}  >
                      **Impersonating**
                      </UI.Typography>;
    }

    return <div className="TopMenuAccountChildMenu">


      <div>
        <UI.Toolbar>
          <UI.Typography style={{ flexGrow: 1 }}     >
            Account
          </UI.Typography>
 
          
        </UI.Toolbar>

        <Divider light />

        <UI.Toolbar>
          <Icons2.AccountCircle style={{ fontSize: 100 }} />
          <UI.Typography variant="h6" style={{ flexGrow: 1 }}   >
           {currentUser.fullName}

            <UI.Typography style={{ flexGrow: 1 }}  >
               {currentUser.email}
            </UI.Typography>
            <UI.Typography style={{ flexGrow: 1 }}  >
               {currentUser.companyName}
            </UI.Typography>

            {impersonating}
          </UI.Typography>

       

        </UI.Toolbar>

        <Divider light />

        <UI.Toolbar>

         <UI.Typography style={{ flexGrow: 1 }}  >
          {editProfileBtn}
          </UI.Typography>

          <MT.Button variant="contained" color="primary"
           onClick={(e) => this.onLogoutClick(e)} 
           >LOGOUT</MT.Button>
        </UI.Toolbar>
        


      </div>


    </div>
  }



  public onAccountMenuClick(event: React.MouseEvent<HTMLElement> | null) {

    var vm = { ...this.state };
    if (event == null)
      vm.anchorEl = null;
    else
      vm.anchorEl = event.currentTarget;
    vm.open = Boolean(vm.anchorEl);

    this.setState(vm);

  }

  public getAccountMenuOrLoginButton() {
 

  }

  public getUserMenuText(currentUser:App.AppUser){
 
    if(currentUser==undefined)
      return null;
 
    return null;
  }

  public getUserOrGuestMenu(currentUser:App.AppUser) {
 

    if (currentUser==undefined)
      return null;
/* 
    if (!accountInfo)
      return <MT.Button variant="contained" style={{ backgroundColor: '#ffffff' }} onClick={() => auth.login()} >Login</MT.Button>
 */
    const open = Boolean(this.state.anchorEl);

    var accountIconStyle:any = {  };

  
    if(currentUser.isImpersonating ){
      accountIconStyle = {  color:'red' };
 
    }
 
    return <div>
      <UI.IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={this.onAccountMenuClick.bind(this)}
        color="inherit"
        className="TopMenuBarUserButton"
      >
        <div className="TopMenuBarUserText" >
          <div className='navMenuBtn' style={{paddingLeft:0}} > {currentUser.fullName}  </div>
         {/*  <div >    {currentUser.email}</div> */}

        
        </div>

        <Icons2.AccountCircle style={accountIconStyle} />


      </UI.IconButton>
      <UI.Menu
        id="menu-appbar"
        anchorEl={this.state.anchorEl}

        open={this.state.open}
        onClose={() => this.onAccountMenuClick(null)}
        style={{ zIndex: 99999 }}
        PopoverClasses={{
          paper: "TopMenuBarMenuPopover"
        }}
      >
        {this.getAccountChildMenuBox(currentUser)}

      </UI.Menu>
    </div>
  }




  public render() {
  
    var currentUser = this.props.currentUser;
    
   // console.log('render AccountMenuItem, currentUser',currentUser);

    return this.getUserOrGuestMenu(currentUser);

  }
}

