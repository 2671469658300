import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { useEffect, useState, useRef } from 'react';



import axios from 'axios';

import Box from '@mui/material/Box';

 
import './DefaultListTableForm.css';
import { Badge, Tooltip, Tabs, Button, Group, Notification} from '@mantine/core';
import * as Icons2 from '@tabler/icons-react';

 
import { useFocusTrap } from '@mantine/hooks';



interface DefaultListTableFormProps {
  state?: App.ListState | App.DetailState;
  model?: LY.DataItemModel;
  onChange?: Function;
  onClose?: Function;
  formContainerClassName?: string;
  formContainerItemClassName?: string;

  excludeFKMultiple?: boolean;
  excludeButtons?: boolean;
  readOnly?: boolean;
  forceReadOnly?: boolean;
  onlyIncludeColumns?: string[];
  skipColumns?: string[]; 
  
}

export const DefaultListTableForm: React.FC<DefaultListTableFormProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
  const [ item, setItem ] = React.useState({... props.model?.row}); // Add this line
  const [ rowUpdates, setRowUpdates] = React.useState<Map<string, any>>(new Map<string, any>()); // Add this line

  var state = props.state;
  var vm = props.state?.vm || new LIB.BaseVmModel();
  const currentWorkspace = App.AppBase.currentWorkspace;
 
  useEffect(() => {

    var model = props.model;

    if (model == undefined)
      return;

    var state = props.state;
   // item =  {... props.model?.row};
    setItem({... props.model?.row});
 
     vm.errors.clear();

    console.log('DefaultListTableForm useEffect model', model);
 
    // vm.isActionInProgress=true;
    

  }, [ props.model?.row ]);



  function onSubmit() {
    var model = props.model;

    console.log('DefaultListTableForm onSubmit item:', item);
    console.log('DefaultListTableForm onSubmit model:', model);

    vm.errors.clear();
 
    var isValid = validateAll();
    if (!isValid || !model) {
      console.log('DefaultListTableForm onSubmit isValid:', isValid);
      console.log('DefaultListTableForm onSubmit Errors:', vm.errors);

      forceUpdate();
      return false;
    }


    console.log('DefaultListTableForm onSubmit state:', state);

    var workspaceId = currentWorkspace?.workspaceId;

    console.log('DefaultListTableForm onSubmit workspaceId:', workspaceId);
    console.log('DefaultListTableForm onSubmit model:', model);
    console.log('DefaultListTableForm onSubmit rowUpdates:', rowUpdates);

    var service = new App.ListDataService(workspaceId);

    var updates = App.RecordManager.getUpdateValue(model!);
    updates.data = Object.fromEntries(rowUpdates);

    var sm = new Api.ListUpdateDataSm();
    sm.updates = [ updates ];
    console.log('DefaultListTableForm onSubmit sm:', sm);
    console.log('DefaultListTableForm onSubmit updates:', updates);

   //console.log('DefaultListTableForm onSubmit Save send model:', model);
   // return;

    service.updateValues(sm,
      //onSuccess
      (result: any) => {
        console.log('DefaultListTableForm onSuccess:', result);
 
        var updated = result?.records[0];

        if(vm){
          vm.errors.clear();
          vm.isActionInProgress = false;
        }
  

        if(updated && model){
          LIB.ObjectHelper.setData(updated, model.row);
          setItem(updated);
        }

        App.AppBase.showSaveSuccess(false);
 
        forceUpdate();


      },
      //onError
      (error: any) => {

        console.error('DefaultListTableForm onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);
        forceUpdate();
      });

 
    vm.isActionInProgress = true;
    forceUpdate();

  
  }

   

  function validateAll(): boolean {

    var columns = state?.view?.columns;

    if (!columns)
      columns = state?.view?.columns;

    if (!columns) return false;

    var isValid = true;

    for (var col of columns) {

      if (col.isSystemHidden || col.hasAutoGenerate)
        continue;
  
      if (isValid && !validateOne(col))
           isValid= false;
    }

    return isValid;

  }
  
  function validateOne(col:Api.ListColumn): boolean {

    if(!col){
      vm.errors.set('all', 'Invlaid input, column is empty!');
      return false;
    } 

    console.log('validateOne item:', item);
    var value = item[col.name];
    console.log('validateOne item value:', value);

    if(typeof value === 'string')
     value = value.trim();

    console.log('validateOne value:', value);

    vm.errors.delete(col.name);
    var isRequired = (!col.isNull || col.isRequired);
 
    console.log('validateOne isRequired:', isRequired);


    //console.log('validateOne is required:', (!col.isNull || col.isRequired));
    //console.log('validateOne isNullOrEmpty:', LIB.Common.isNullOrEmpty(value));

    //if not required, return true if empty
    if(!isRequired && LIB.Common.isNullOrEmpty(value))
      return true;

    if(isRequired && LIB.Common.isNullOrEmpty(value)){
      //console.log('validateOne got into isRequired:', value);
      vm.errors.set(col.name, `${col.displayName} is Required!`);
       
    }
    else if(col.listColumnTypeId == App.ListColumnTypeEnum.Email && !LIB.ValidationHelper.isValidEmail(value)){
      vm.errors.set(col.name, `${col.displayName} is not a valid email address!`);
    }
    else if(col.listColumnTypeId == App.ListColumnTypeEnum.Phone && !LIB.ValidationHelper.isValidPhoneNumber(value)){
      vm.errors.set(col.name, `${col.displayName} is not a valid phone number!`);
    }

    else if(col.listColumnTypeId == App.ListColumnTypeEnum.Website && !LIB.ValidationHelper.isValidUrl(value, false)){
      vm.errors.set(col.name, `${col.displayName} is not a valid url!`);
    }
    else if (col.minLength && value.length < col.minLength) {
      vm.errors.set(col.name, `${col.displayName} must have minimum of ${col.minLength} characters!`);
    }
    else if (col.maxLength && value.length > col.maxLength)  {
      vm.errors.set(col.name, `${col.displayName} must have maximum of ${col.maxLength} characters!`);
    }
    else if(col.validationRegex && !LIB.ValidationHelper.isValidRegex(value,col.validationRegex)){
      vm.errors.set(col.name, `${col.displayName} is not valid!`);
    }
 
    var isValid = (vm.errors.size<1); 

    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  
  function onInputChange(col:Api.ListColumn, e: React.ChangeEvent<any> | undefined,  m: LY.DataItemModel|undefined) {
    //e.preventDefault();
 
    //  console.log('DefaultListTableForm onInputChange m:', m);
    // console.log('DefaultListTableForm onInputChange e.target.name:', e.target);

    if (col.isSystemHidden || col.hasAutoGenerate)
         return;
 
      //   App.AppBase.showSaveSuccess(100000);
      var key:any = col.name;
      //console.log('DefaultListTableForm onInputChange key1:', key);

      if(m){
        item[key] = m.value;

/*         console.log('DefaultListTableForm onInputChange value2:', m.value);
        console.log('DefaultListTableForm onInputChange key2:', key);
        console.log('DefaultListTableForm onInputChange item[key]1:',item[key]); */

      }
      else if(e)
        key = LIB.FormHelper.setInputChangeData(e.target, item);
       
  
      if(item[key]===undefined)
        item[key] = null;
/*      console.log('DefaultListTableForm onInputChange key:', key);
     console.log('DefaultListTableForm onInputChange value:',m?.value);
     console.log('DefaultListTableForm onInputChange item:',item);
     console.log('DefaultListTableForm onInputChange item[key]:',item[key]); */

    // if (LIB.Common.isNullOrEmpty(key))
    //   return;

      validateOne(col);
   
     //todo this.validationManager.validateAccountProfileEdit(key, item);

     rowUpdates.set(key,item[key]);
     console.log('onInputChange rowUpdates', rowUpdates);

     /* console.log('onInputChange item', item);
     console.log('onInputChange rowUpdates', rowUpdates);
     console.log('onInputChange rowUpdates size', rowUpdates.size);
 */
     forceUpdate();
     
    
  }
 
  function getForm() {
    var model = props.model;
    var state = props.state;
    var columns = state?.view?.columns;
    var view = state?.view;

    if (!columns)
      columns = state?.view?.columns;

    /*   console.log('DefaultListTableForm getForm columns:', columns);
      console.log('DefaultListTableForm getForm row:', item?.row);
      console.log('DefaultListTableForm getForm selectColumns:', view?.filter?.selectColumns);
     */
      
    var selectColumns = view?.filter?.selectColumns;

    if (!columns) return null;

    if(props.onlyIncludeColumns && props.onlyIncludeColumns.length>0){
      columns = columns.filter(col=>props.onlyIncludeColumns!.includes(col.name));
    }
    else if(props.skipColumns && props.skipColumns.length>0){
      columns = columns.filter(col=>!props.skipColumns!.includes(col.name));
    }
     

    var colList = columns.map(col => col.name).join(',');
    console.log('DefaultListTableForm getForm colList:', colList);
 
    var elements = columns.map((col, index) => {

      //console.log(col.name);

     // console.log('DefaultListTableForm getForm col:', col.name);

      if (col.isSystemHidden || col.hasAutoGenerate)
        return null;

      if (col.fkIsMultiple && props.excludeFKMultiple)
        return null;
 
       if(selectColumns && selectColumns.length>0 && !selectColumns.includes(col.name))
          return null;

       var result = getInputByType(col);
       return result;
     
    });

    return elements;

  }

  function getInputByType(col: Api.ListColumn) {

    var result: any;

    if (col?.listColumnTypeId == App.ListColumnTypeEnum.LongText) {
      result = getLongTextRenderer(col);
    }
    else
      result = getInputSelectorRenderer(col);


    return result;
  }


  function getLongTextRenderer(col: Api.ListColumn) {

 
    var columns = state?.view?.columns;


    if (props.forceReadOnly)
      return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;

    var type: any = 'text';
    if (!LIB.Common.isNullOrEmpty(col.listColumnClientType))
      type = col.listColumnClientType;


    var result = (

      <LY.LY_TextArea
        key={`formItem_${col.name}_${item?.rowId}`}
        name={col.name}
        label={col.displayName}
        placeholder="Enter Value"
        //form={{ register }}
        labelPlacement='top'
        withStar={!col.isNull}
        readOnly={props.readOnly}
        //{...field}
        value={item[col.name]}
        error={vm.errors.get(col.name)}
        helpText={col.description}
        // helpTextPlacement='bottom'
        onChange={(model, e) => {
          onInputChange(col,e!, model);
        //  console.log('DefaultListTableForm LY_TextArea onChange', model);
        }
        
        }

        inputContainerClassName={`DefaultListTableFormContainerItem ${props.formContainerItemClassName || ''}`}
      />
      /*  <div key={index}>
       {col.displayName}
     </div> */
    );


    return result;

  }

  
  function getInputSelectorRenderer(col: Api.ListColumn) {

    
    var columns = state?.view?.columns;

/* 
    if (props.forceReadOnly)
      return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;
 */

 /*      if(col.name=='fsc_code'){
        console.log('DefaultListTableForm fsc_code getInputSelectorRenderer col:', col);
        console.log('DefaultListTableForm fsc_code getInputSelectorRenderer item:', item);
      }
 */
    var value = item[ col.name ];  





    var itemModel = {...props.model || new LY.DataItemModel()};

   // value = LY.ListColumnManager.getCalculatedValue(col, itemModel?.row, value);
    
  //  if(col?.name=='diibs_nsn'){
   /*    console.log('DefaultListTableForm diibs_nsn getInputSelectorRenderer item:', item);
      console.log('DefaultListTableForm diibs_nsn getInputSelectorRenderer value:', value);
 */
   // }

    itemModel.row = item;
    itemModel.column = col;
    itemModel.value = value;

    var linkedWorkspaceId = state?.linkedWorkspaceId;

    if(col.fkIsLinkedWorkspace && col.fkWorkspaceId)
      linkedWorkspaceId = col.fkWorkspaceId;

    var result = (

      <LY.LA_InputSelector
        key={`formItem_${col.name}_${item?.rowId}`}
        model={itemModel}
        linkedWorkspaceId={linkedWorkspaceId}
        workspaceId={currentWorkspace?.workspaceId}
        column={col}
        lookupTypes={currentWorkspace?.lookupTypes}
        dropDownData={state?.dropDownData}
        forceReadOnly={props.readOnly}
        name={col.name}
        label={col.displayName}
        value={value}
        
       // placeholder={'Select ' + col.displayName}

        labelPlacement='top'

        withStar={!col.isNull || col.isRequired}
         error={vm.errors.get(col.name)}

        onChange={(model, e) => {
              console.log('DefaultListTableForm onChange', model);
              console.log('DefaultListTableForm e', e);

          onInputChange(col,e, model);
        }
        }
        maxDisplayLength={30}

        isForList={false}
        inputContainerClassName={`DefaultListTableFormContainerItem ${props.formContainerItemClassName || ''}`}

      />
      /*  <div key={index}>
       {col.displayName}
     </div> */
    );


    return result;

  }

  function getInputRenderer(col: Api.ListColumn) {

    
    var columns = state?.view?.columns;


    if (props.forceReadOnly)
      return <span className='LY_ListColumnCellReadOnly'>{item?.value}</span>;

    var type: any = 'text';
    if (!LIB.Common.isNullOrEmpty(col.listColumnClientType))
      type = col.listColumnClientType;

    var value = item[ col.name ];

  /*   if (col?.listColumnTypeId == App.ListColumnTypeEnum.Date) {
      console.log('DefaultListTableForm getInputRenderer Date', value);
    } */
   // console.log('DefaultListTableForm getInputRenderer item:', item);
   // console.log('DefaultListTableForm getInputRenderer value:', value);


    var result = (

      <LY.LY_Input
        key={`formItem_${col.name}_${item?.rowId}`}

        name={col.name}
        type={type}
        label={col.displayName}
        placeholder="Enter Value"
        labelPlacement='top'
        withStar={!col.isNull || col.isRequired}
        readOnly={props.readOnly}
        //model={item}
          value={value}
         error={vm.errors.get(col.name)}
        //helpText={col.description}
        // helpTextPlacement='bottom'
        onChange={(model, e) => {
          //field.onChange(event);
          onInputChange(col,e!, model);
          //console.log('DefaultListTableForm onChange', model);
        }
        }
      />
      /*  <div key={index}>
       {col.displayName}
     </div> */
    );


    return result;

  }

  function onClose() {
    console.log('DefaultListTableForm onClose', props.onClose);

    //reset({});

    if (props.onClose)
      props.onClose();

  }

  function render() {
    console.log('DefaultListTableForm render state', state);

    var item = props.model;
    var columns = state?.view?.columns;

    //var globalError = vm.errors.get('all');
    var globalError = vm.errors?.values()?.next()?.value;


    // console.log('DefaultListTableForm render name:', item?.row['name']);

    /* console.log('DefaultListTableForm form:', item?.rowId);
    console.log('DefaultListTableForm render rowUpdates size', rowUpdates.size);
    console.log('DefaultListTableForm render vm.errors', vm.errors); */

    var result = (
      <form id={'form_' + item?.rowId} key={'form' + item?.rowId}
   
        className='DefaultListTableForm'
        ref={focusTrapRef}
      >
          {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div 
         className={`DefaultListTableFormContainer LY_Scrollbar ${props.formContainerClassName || ''}`}>


          {getForm()}

        </div>

        {!props.excludeButtons &&
          <Group mt="xl" justify='right'>
            <MT.Button variant='default'
              onClick={() => onClose()} >Close</MT.Button>
            <MT.Button  
              loading={vm.isActionInProgress} 
              loaderProps={{ type: 'dots' }}
              onClick={onSubmit}
              disabled={(rowUpdates.size<1)}
              hidden={props.forceReadOnly}
            >
              Save
            </MT.Button>
          </Group>
        }

 

      </form>

 
    );

    return result;

  }




  return render();
};
