import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
//import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { composeWithDevTools } from "redux-devtools-extension";
import * as Api from 'app-api';

export class AppStore  {
 
    // constructor() { 
 
    // }


    public static propertyChange(type:string, obj:any, propertyName:string, newVal:any){
      
        if(LIB.Common.isNullOrEmpty(obj) || LIB.Common.isNullOrEmpty(propertyName))
            return;

        var data = {...obj};
        var dataType = typeof (data[propertyName]);
        if ( dataType !== "undefined") {
            data[propertyName] = newVal;
        }
 
        AppStore.dispatch(type,data);

    }

    public static dispatch(type:string, payload:any=undefined, postActionCallBack:Function|null=null, appState:App.AppState|null=null){

        console.log(`dispatch type:, ${type} payload`);//,payload);

        var store = App.AppBase.store;

        if(appState===null)
           appState = App.AppBase.getState();

        var payloadType = typeof(payload);
        console.log('dispatch type, payloadType:',payloadType);

        if(!LIB.Common.isNullOrEmpty(payload) && payloadType === "object")
            payload={...payload};

        var event = {type:type, payload:payload, appState:appState, postActionCallBack:postActionCallBack};
        var res=  store.dispatch(event);
                   // .then(()=>console.log('dispatch THENN..................................NNNNNNNNNN'));
    }
 
     
    public static getCombinedReducers(history: History){
      
        var reducers = combineReducers({
            globalData: App.GlobalReducers.getActionHandlersReducers,
            guestUserData: App.GuestUserReducers.getActionHandlersReducers,
            adminUserData: App.AdminUserReducers.getActionHandlersReducers,
            account: App.AccountReducers.getActionHandlersReducers,
          
            router: App.RouterStateReducers.getActionHandlersReducers,
            //router: connectRouter(history)
            administrationState: App.AdministrationReducers.getActionHandlersReducers,
           // cmsState: App.CMSReducers.getActionHandlersReducers,
            listState: App.ListStateReducers.getActionHandlersReducers,
            templateEditorState: App.TemplateEditorReducers.getActionHandlersReducers,
 
        });
        
        return reducers;
    }
 
    public static configureStore(history: History, initialState?: App.AppState) {

        // if(initialState==undefined)
        //          initialState = new App.AppState();

        const middleware = [
            thunk
        ];


        const enhancers = [];
        const windowIfDefined = typeof window === 'undefined' ? null : window as any;
        if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
            enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
        }

        //@ts-ignore
        return createStore(
            this.getCombinedReducers(history),
            initialState,
            compose(applyMiddleware(...middleware), ...enhancers)
        );

    }
}