import * as React from 'react';
import {CircularProgress, Backdrop} from '@mui/material';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';


export class ErrorHelper {


    /*  constructor() {
  
     }
  */

     public static getErrorMessage(error:any):string {

        var result = ErrorHelper.getStringErrorMessage('error', error );

        if(!LIB.Common.isNullOrEmpty(result) && !result.toLocaleLowerCase().startsWith('error'))
            result = 'Error: ' + result;
        return result;
     }
    public static getStringErrorMessage(key:string, errorEx:any, ) {
       // console.log('getStringErrorMessage');

       // console.log('getStringErrorMessage 1:', errorEx?.error?.Message);

        if (LIB.Common.isNullOrEmpty(key)) {
            key = "error";
        }
        if(LIB.Common.isNullOrEmpty(errorEx))
            return "";

        //console.log('getStringErrorMessage 2:', errorEx?.error?.Message);

        var msg: string = "";
        if (typeof (errorEx) === "string")
            msg = errorEx;
        else if (errorEx.error !== undefined && !LIB.Common.isNullOrEmpty(errorEx.error.Message))
            msg = errorEx.error.Message;
        else if (errorEx.error !== undefined && !LIB.Common.isNullOrEmpty(errorEx.error.message))
            msg = errorEx.error.message;
        else if (!LIB.Common.isNullOrEmpty(errorEx.Message))
            msg = errorEx.Message;
       else if (!LIB.Common.isNullOrEmpty(errorEx.message))
            msg = errorEx.message;


        if (LIB.Common.isNullOrEmpty(msg))
            return "";
  
      // console.log('getStringErrorMessage 3 result:', msg);

        return msg;
    }
     
    public static getInputErrorProps(key:string, errorData:any ): any {
 
        if (LIB.Common.isNullOrEmpty(key)) 
        return null; 
 
        var errorMsg = errorData[key];

        if (LIB.Common.isNullOrEmpty(errorMsg)) 
            return null; 

        const errorProps = {
            error: true,
            helperText: errorMsg 
        };
        return errorProps;
    }
    
    public static getAlertError(error:string){
        if(LIB.Common.isNullOrEmpty(error))
        return null;

        return  <Alert severity="error">{error}</Alert>;
    }



}