import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class AccountState  extends LIB.BaseStateModel {
 
  
    public vm:App.AccountVm;
 
    public profile:App.AppUser= new App.AppUser();
    public profileOriginal:App.AppUser= new App.AppUser();

    public company:Api.Company= new Api.Company();
    public companyOriginal:Api.Company= new Api.Company();
 

    public confirmEmailSm:Api.ConfirmEmailSm = new Api.ConfirmEmailSm();
    public confirmEmailVm:Api.ConfirmEmailVm = new Api.ConfirmEmailVm();
 

    public companySizeTypes:Api.LookupType[]= [];
    
    public billingPlans:Api.BillingPlan[]= [];

    public hasBillingPlansDataLoaded:boolean;
    public activeStep:number = 0;

    public offer:Api.Offer;
   
    constructor() { 
        
        super();
       
        this.vm = new App.AccountVm();

      
    }
     


}