
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class TemplatesVm  extends LIB.BaseVmModel {

 
    public isSourceViewVisible:boolean=false;
  
    constructor() { 

       super();
    }
     


}