import * as LIB from '_LIB';
import * as React from 'react';
import * as App from 'AppReferences';
import { CSSProperties } from 'react';


export class StyleHelper {


    public static getFormControlStyle(): CSSProperties {


        var theme = App.AppBase.theme;

        var formControl: CSSProperties = {
            margin: 2,
            minWidth: 300,
            maxWidth: 300,
            marginLeft: 0
            // padding:'20px, 16px'
        };

        return formControl;
    }

    
    public static getFormControlHalfWidthStyle(): CSSProperties {
 
        var theme = App.AppBase.theme;

        var formControlHalf: CSSProperties = {
            margin: 2,
            minWidth: 300,
            maxWidth: 300,
            marginLeft: 0,
            marginTop: 25
            // padding:'20px, 16px'
        }; 

        return formControlHalf;
    }

    
    public static getFormControlFullWidthStyle(): CSSProperties {
 
        var theme = App.AppBase.theme;
 
        var fullWidth: CSSProperties = {
            margin: 2,
            paddingRight: 20,
            marginLeft: 0


        };
        return fullWidth;
    }

    public static getFormInputStyle(): CSSProperties {
  
        var formInput: CSSProperties = {

            height: 50
            // padding:'20px, 16px'
        };

        return formInput;
    }





}