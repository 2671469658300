import "./LY_ListFilterModule_Sort.css";

import * as Api from 'app-api';
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Button, Popover, Select } from "@mantine/core";
import React, { useState } from "react";

import { LY_FiltersModule_Helpers } from "../../Helpers/LY_FiltersModule_Helpers";
import { LY_ListFilterModule_Select_Data } from "../../Interfaces/LY_ListFilterModuleProps";
import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";

export const LY_FiltersModule_Sort: React.FC<LY.LY_ListFilterModuleProps> = (props) => {

  const context = LY.useListFilterModuleContext();
  const state = context.state;
  const { view, columns } = context.state;

  const { isMobile } = useIsMobile(768);
  const [opened, setOpened] = useState(false);

  function getColumnCount() {
    return view.filter?.orderByColumns?.length || 0;
  }

  const sortValues = view.filter?.orderByColumns?.map((item: any) => ({
    label: columns?.find((col: any) => col.name === item.columnName)?.displayName,
    value: item.direction,
  })) as LY_ListFilterModule_Select_Data[];

  function getColumnsSelectData(item: LY_ListFilterModule_Select_Data) {
    return LY_FiltersModule_Helpers.getSelectAdopterData<Api.ListColumn>(columns?.
      filter((col: any) => sortValues?.every(i => i.value !== col?.name) || item.value === col.name), "displayName", "name");
  }

  function onInputChange(key: string, value: any, index: number) {
    var sort = view.filter?.orderByColumns?.[index];
    if (!sort) return;

    //@ts-ignore
    sort[key] = value;

    validate(key + index);
    context.forceUpdate();
  }

  function validate(key: string): boolean {
    var vAll = (key === 'all');
    var item = state.newViewSm;
    state.errors.clear();

    view.filter?.orderByColumns?.map((item, index) => {
      var sortColInputName = 'columnName' + index;
      var sortDirectionInputName = 'direction' + index;

      if ((vAll || key == sortColInputName) && LIB.Common.isNullOrEmpty(item.columnName)) {
        state.errors.set(sortColInputName, `Sort column is required!`);
      }

      if ((vAll || key == sortDirectionInputName) && LIB.Common.isNullOrEmpty(item.direction)) {
        state.errors.set(sortDirectionInputName, `Sort direction is required!`);
      }
    });

    return (state.errors.size < 1);
  }

  function onApplyClick(e: React.FormEvent<any>) {
    e.preventDefault();
    var isValid = validate('all');
    if (!isValid) {
      context.forceUpdate();
      return false;
    }
    context.onSearch();
    setOpened(false);
    context.setDropdownOpen(false)
  }

  function getOptions() {
    var result: any[] = [];
    if (!view?.filter?.orderByColumns) return result;

    let sortData = columns?.map((x: any) => ({ label: x.displayName, value: x.name }));

    var columnOptionsRemaining = columns?.filter((col: any) =>
      view?.filter?.orderByColumns?.find(x => x.columnName === col.name) === undefined
    );

    view?.filter?.orderByColumns?.map((item, index) => {
      var sortColInputName = 'columnName' + index;
      var sortDirectionInputName = 'direction' + index;

      var line = (
        <div key={index} className="LY_ListFiltersModule_Sort_optionItem">
          <Icons.IconGripVertical />
          <div className="LY_ListFiltersModule_Sort_optionItem_selects">
            <LY.LY_DropDown
              key={sortColInputName}
              name={sortColInputName}
              displayProperty="displayName"
              valueProperty="name"
              value={item.columnName}
              data={columnOptionsRemaining}
              selectedItem={columns?.find((col: any) => col.name === item.columnName)}
              isAutoCompleteStyle={true}
              error={state?.errors?.get(sortColInputName)}
              onChange={(value, option: any) => onInputChange('columnName', option?.value, index)}
              className="LY_ListFiltersModule_Sort_Select_Columns"
              inputContainerClassName="LY_ListFiltersModule_Sort_Select_Columns"
            />

            <Select
              key={sortDirectionInputName}
              name={sortDirectionInputName}
              classNames={{
                root: "LY_ListFiltersModule_Sort_Select_Sort_Direction",
                input: "LY_ListFiltersModule_Sort_Select_Sort_Direction_Input",
              }}
              rightSection={<Icons.IconCaretDownFilled width={10} height={10} />}
              onChange={(value) => onInputChange('direction', value, index)}
              data={[
                { label: "Ascending", value: "asc" },
                { label: "Descending", value: "desc" }
              ]}
              value={item ? item.direction : null}
              error={state?.errors?.get(sortDirectionInputName)}
            />

            <MT.CloseButton
              onClick={() => context.onDeleteSort(index)}
              icon={<Icons.IconTrash width={16} height={16} />}
              style={{ marginTop: '5px' }}
            />
          </div>
        </div>
      );
      result.push(line);
    });

    return result;
  }

  function popoverTargetRenderer() {
    if (isMobile) {
      return (
        <Icons.IconArrowsUpDown
          color="#757B87"
          width={36}
          height={36}
          stroke={1.5}
          className="LY_ListFiltersModule_Sort_button_Mobile"
          onClick={() => {
            setOpened(true)
          }}
        />
      );
    }
    return (
      <Button
        onClick={() => {
          setOpened(true);
        }}
        className={`LY_ListFiltersModule_Sort_button ${getColumnCount() > 0 && 'LY_ListFiltersModule_Filter_button_active'}`}
        variant="transparent"
        leftSection={<Icons.IconArrowsUpDown width={16} height={16} stroke={1.5} />}
      >
        Sort
        {!!getColumnCount() && <span className="LY_ListFiltersModule_Filter_count">{getColumnCount()}</span>}
      </Button>
    );
  }

  function popoverFooterRenderer() {
    return (
      <div className="LY_ListFiltersModule_Sort_footer">
        <Button
          className="LY_ListFiltersModule_Sort_button"
          variant="transparent"
          onClick={() => context.onAddNewSort()}
          leftSection={<Icons.IconPlus width={16} height={16} />}
        >
          New Sort
        </Button>
        <div className="LY_ListFiltersModule_Sort_footer_buttons">
          <Button
            className="LY_ListFiltersModule_Sort_Apply_button"
            variant="filled"
            onClick={onApplyClick}
          >
            Apply
          </Button>
          <Button
            className="LY_ListFiltersModule_Sort_Cancel_button"
            variant="transparent"
            onClick={() => {
              context.setInitialView();
              setOpened(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Popover
      width={isMobile ? 300 : 570}
      position="bottom"
      withinPortal={true}
      opened={opened}
      onChange={setOpened}
      closeOnClickOutside={false}
      withArrow
    >
      <Popover.Target >
        {popoverTargetRenderer()}
      </Popover.Target>
      <Popover.Dropdown className="LY_ListFiltersModule_Sort_comboboxDropdown" classNames={{
        arrow: 'LY_PopoverRoundCorners-arrow',
        dropdown: 'LY_PopoverRoundCorners'
      }}>
        <div className="LY_ListFiltersModule_Sort_header">
          <span>Sort</span>
          <Icons.IconHelp width={20} height={20} />
        </div>
        <div className="LY_ListFiltersModule_Sort_options LY_ScrollbarDropDown">
          {getOptions()}
        </div>
        {popoverFooterRenderer()}
      </Popover.Dropdown>
    </Popover>
  );
};
