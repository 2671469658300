import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';


class AdministrationRoles extends React.Component<App.AdministrationState, {}> {


  constructor(props: any) {
    super(props);

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationRoles componentDidMount');

    if (!this.props.hasDataLoaded) {
      console.log('AdministrationRoles getCandidateListData');
     /*  this.candidateService.getCandidateListData(null,
        (result:any) => {
          console.log('getCandidateListData success...');
          this.checkShowRequestResume(result);
      }); */
 
      this.forceUpdate();
    }

     
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationRoles componentDidUpdate');
 
 
  }

  /* public checkShowRequestResume(state:App.AdministrationState){
 
   // var state = this.props;
    console.log('checkShowRequestResume state', state);

    var requestResumeId = App.RouteManager.getRouterQueryParam('requestResumeId');
    
   // console.log('checkShowRequestResume requestResumeId',requestResumeId);

    if(LIB.Common.isNullOrEmpty(requestResumeId))
      return;

       
      var vm = state.vm;
      var candidates = state.candidates;

     // console.log('checkShowRequestResume candidates',candidates);

      var item = candidates?.find(x => x.candidateId == requestResumeId);

      //console.log('checkShowRequestResume item',item);


      if(item==undefined)
          return;
 
      //  vm.isRequestResumeOpen = true;
        vm.setSelectedCandidate(item);
       // App.RouteManager.routeToPath('?');

     //   this.forceUpdate();
       
 
  } */

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  } 
   

  public getTopRow(){

    var fullWidth: CSSProperties = {
     // paddingRight: 20,
      paddingLeft: 0,
     //  marginBottom: 10,
   //  padding: 10,
    
      width: '30vw'
    };
    var formInput: CSSProperties = {
      height: 50,
    //  marginTop: 10,
      //paddingTop: 20,
      padding: 10,
    };

    return <Grid container spacing={2} style={{marginBottom:10}}>
    <Grid item xs={12} sm={6} >
      
      <TextField
        name="keyword" 
        //label="Search"
        //value={item.location || ''}
        placeholder='Search'
        {...App.AppBase.getInputErrorProps('keyword')}
        onChange={this.onInputChange.bind(this)}
        style={fullWidth}
        inputProps={{ style: formInput }}
        variant="standard"
        
        InputProps={{
         // className: 'standardFormInput30',
          startAdornment: (
            <InputAdornment position="start">
              <Icons2.Search />
            </InputAdornment>
          ),
            endAdornment: (
            <InputAdornment position="end">
            <MT.Button
              id="btnCreate"
               variant="contained"
              //style={{padding:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</MT.Button>
            </InputAdornment>
          ), 
        }}

      />

       {/*   <MT.Button
              id="btnCreate"
              variant="contained"
              style={{marginTop:13, marginLeft:10}}
              color="primary"
              
            //  onClick={this.onCreateClick.bind(this)}
            >Search</MT.Button>  */}
 
    </Grid>
 
    <Grid item xs={12} sm={4} >

 


    </Grid>

  </Grid>

  }

  /* public onSearchClick(){
    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;
 
   // this.candidateService.getAdministrationRolesData(searchFilter);


  } */

  /* public onTest(){

    

    var location = App.AppBase.location;

    console.log('onTest location',location);


    var params = App.AppBase.params;

    console.log('onTest params',params);

    App.RouteManager.routeToPath('?requestResumeId=67519');

  

    console.log('onTest location2',location);
    console.log('onTest params2',params);

    this.forceUpdate();
  } */


  public render() {

    console.log('AdministrationRoles render');

    var state = this.props;
    var vm = state.vm;
    //var searchFilter = state.searchFilter;
 
    console.log('CandidateList hasDataLoaded', state.hasDataLoaded);
 //   console.log('CandidateList isServiceCallPending',this.candidateService.isServiceCallPending);
    console.log('CandidateList vm.isActionInProgress',vm.isActionInProgress);
 
    var keyword = '';

    return <App.MainSplitPage
      pageTitle="Administration > Roles"
      state={vm} 
      hasDataLoaded={state.hasDataLoaded}
      //loading={(this.candidateService.isServiceCallPending && !vm.isActionInProgress)}
      alignError='left'
      isChildPage={true}
    >
      <div>
{/* 
        {<LIB.MSearchBar filter={searchFilter} onSearchClick={()=>this.onSearchClick()} />}
 */}
      {/*   <MT.Button
               variant="contained"
              color="primary"
              onClick={()=>this.onTest()}
            >Test</MT.Button> */}

        {/* {this.getTopRow()}
 
        <div style={{ textAlign: 'right', width: '100%'  }} >

            <MT.Button
              id="btnCreate"
              variant="contained"
              style={{marginBottom:10}}
              color="primary"
              
              onClick={this.onCreateClick.bind(this)}
            >New Request</MT.Button>
 
        </div>
 */}
        {/* <AdministrationRolesTable state={state} onChange={() => this.forceUpdate()}/> */}

       {/*  <RequestResumeModal state={state} onChange={() => this.forceUpdate()} /> */}


      </div>

      

    </App.MainSplitPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationRoles as any);

