import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class GlobalState  extends LIB.BaseStateModel {
 
    public isInitialDataLoaded:boolean;
    public isAppInfoDataLoaded:boolean;
    public initialData:App.InitialDataVm;
 
    public isServiceCallPending:boolean;
    public globalUIState:App.GlobalUIStateVm;

    public params?:any;

    public workspaceVm:App.WorkspaceVm;
    public listVm:App.ListVm;

    public availableConnections:Api.CompanyDbConfigVm[];
 
    constructor() { 
 
        super();
        this.isInitialDataLoaded=false;
        this.isServiceCallPending = false;
        this.initialData= new App.InitialDataVm();
        this.globalUIState = new App.GlobalUIStateVm();
        this.workspaceVm = new App.WorkspaceVm();
        this.listVm = new App.ListVm();
    }
      

}