import * as LIB from '_LIB';
import moment from "moment";


export class ConvertHelper {


  public static getNumberOrEmptyString(value: number, toFixed: number = -1) {
    if (LIB.Common.isNullOrEmpty(value) || isNaN(value))
      return '';
    var ret = value.toString();
    if (toFixed > -1)
      ret = value.toFixed(toFixed);
    return ret;
  }

  public static toMoney(value: any, toFixed: number = 2): string {

    if (LIB.Common.isNullOrEmpty(value) || isNaN(value))
      return '';

   // var amount = value.toFixed(toFixed);

    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    let result = formatter.format(value); // "$1,234.56"
    return result;
  }

  
  public static toPercentage(value: any, toFixed: number =1): string {

    if (LIB.Common.isNullOrEmpty(value) || isNaN(value))
      return '';

    var amount = value.toFixed(toFixed);
 
    let result = `${amount}%`;
    return result;
  }


  public static isBool(value: any, checkIntValues: boolean = false): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'boolean') {
      return true;
    }

    const str = value?.toString()?.toLowerCase()?.trim();
    if (str === 'true' || str === 'yes') {
      return true;
    }

    if (typeof value === 'number' && checkIntValues) {
      return value === 0 || value === 1;
    }

    if (typeof value === 'string') {
      const strValue = value as string;
      if (strValue !== null && strValue !== undefined) {
        if (strValue === 'true' || strValue === 'false') {
          return true;
        }
      }
    }

    return false;
  }


}