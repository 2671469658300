import React, { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';

import * as LY from '_LY_Components';

import './LY_Button.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';

interface LY_ButtonDeleteProps   {
  // ... additional props
  children?: any;  

};


export const LY_ButtonDelete: React.FC<LY_ButtonDeleteProps & MT.ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ((
  {
    color="red",  
    ...props

  }) => { 


  return <MT.Button
    color={color}
     {...props}
 
  >{props.children}</MT.Button>
 

  });
