import "./LY_ListFilterModule_Filter_Footer.css";

import * as LY from '_LY_Components';

import React from "react";

interface ILY_FiltersModule_Filter_Footer {
  leftActionName?: string;
  rightActionName?: string;
  leftAction?: () => void;
  rightAction?: () => void;
}
export const LY_FiltersModule_Filter_Footer: React.FC<
  ILY_FiltersModule_Filter_Footer
> = (props) => {
  return (
    <div className="LY_ListFiltersModule_Filter_Footer">
      <div onClick={props.leftAction}>{props.leftActionName}</div>
      <div onClick={props.rightAction}>{props.rightActionName}</div>
    </div>
  );
};
