import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid, Stack,
  DialogTitle, TextField, Switch, Badge, Popper, Typography
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';


//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationCompaniesAddEditModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationCompaniesAddEditModal extends React.Component<AdministrationCompaniesAddEditModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    // console.log('AdministrationCompaniesAddEditModal componentDidMount');

    if (!this.props.state.hasCompanyDataLoaded) {
      //console.log('AdministrationCompaniesAddEditModal getCompaniesListData for owner drop down list');

      //App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST, null);

      this.forceUpdate();
      console.log('getUsersListData forceUpdate...');

    }
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    // console.log('AdministrationCompaniesAddEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;

  }


  public onAddOrUpdateClick(e: React.ChangeEvent<{}>) {
    console.log('onAddOrUpdateClick');

    var state = this.props.state;
    var vm = state.vm;
     var item = vm.selectedCompany;
  

    var isValid = this.validationManager.validateAdminCompanyCreate(null, item, true);

    if (!isValid) {
      console.log('onSaveClick validateAdminCompanyCreate: NOT valid');
      this.forceUpdate();
      return;
    }

    if (vm.isCompaniesEditOpen)
      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_UPDATE_REQUEST, item);
    else if (vm.isCompaniesAddOpen)
      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_COMPANIES_ADD_REQUEST, item);

    vm.isActionInProgress = true;

    this.onCloseClick();

  }

  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
   

    //reset edits
    //todo App.CompanyManager.update(vm.selectedCompanyOriginal, state.candidates);

    vm.isCompaniesAddOpen = false;
    vm.isCompaniesEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

  }
 
  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {
    console.log('onAutocompleteChange name', name);
    
    var state = this.props.state;
    var vm = state.vm;
 

    var item = vm.selectedCompany;

    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

   
    this.validationManager.validateAdminCompanyCreate(key, item, true);

    this.forceUpdate();
  }


  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    //console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCompany;

    var target: any = e.target;

    var name = LIB.FormHelper.setInputChangeData(e.target, item);
 
    if (LIB.Common.isNullOrEmpty(name))
      return;


    this.validationManager.validateAdminCompanyCreate(name, item, true);


    this.forceUpdate();
  }

  public getForm() {
    //console.log('AdministrationCompaniesAddEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedCompany;
    console.log('AdministrationCompaniesAddEditModal getForm state', state);


    var theme = App.AppBase.theme;

    var textFieldStyle = {
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      paddingTop: 10,
      marginBottom: 0
    };


    var autocompleteInput: CSSProperties = {
      // height: 50
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      paddingTop: 0,
      width: '100%',
      marginTop: 30,
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var fullWidth: CSSProperties = {
      paddingRight: 10, //20,
      paddingLeft: 0,
      marginBottom: 10,
      height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      width: '100%',
      marginTop: 10,
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    var item = vm.selectedCompany;
    var appUsers = this.props.state.appUsers;



    if (item === undefined)
      return null;


    // console.log('getForm selectedCompany:',item);
   
    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded && state.hasCompanyDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >

      <form     >
        <div style={{ padding: 0 }}>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                name="companyName" label="Company Name"
                value={item.companyName || ''}
                {...App.AppBase.getInputErrorProps('companyName')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
            <Grid item xs={12} sm={6}
            >
              <TextField
                name="domain" label="Domain"
                value={item.domain || ''}
                {...App.AppBase.getInputErrorProps('domain')}
                onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
              />
            </Grid>
          </Grid>
 

          <Stack style={{ marginTop: 20, marginBottom: 10 }} direction="row" alignItems="left" spacing={2}>
 
          <TextField
                name="maxActionsAllowed" label="Max Actions Allowed"
                value={item.maxActionsAllowed || ''}
                {...App.AppBase.getInputErrorProps('maxActionsAllowed')}
                onChange={this.onInputChange.bind(this)}
                //style={fullWidth}
                inputProps={{ style: formInput }}
              />

        <TextField
                name="actionCount" label="Action Count"
                value={item.actionCount || ''}
                {...App.AppBase.getInputErrorProps('actionCount')}
                onChange={this.onInputChange.bind(this)}
               // style={fullWidth}
                inputProps={{ style: formInput }}
              />

          <LIB.MCheckbox {...App.AppBase.getInputErrorProps('forceEndFree')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="forceEndFree"
                        color="primary"

                        checked={item.forceEndFree}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: -10, marginTop:30  }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Force End Free Plan
                    </span>}
                  />}
                >

                </LIB.MCheckbox>

          </Stack>


      
          <div
            style={{ marginTop: 20 }}
          >
          </div>

        </div>

      </form>

    </App.MainSplitPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('AdministrationCompaniesAddEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = (vm.isCompaniesAddOpen || vm.isCompaniesEditOpen);
    var item = vm.selectedCompany;

    var title = "Add Company";
    var saveBtnTitle = "Add";
    if (vm.isCompaniesEditOpen) {
      title = `Edit Company (${item.companyId})`;
      saveBtnTitle = "Save";
    }

    var buttonMargin = -50;

    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              /* minHeight: '50%',
              maxHeight: '50%', */
              minHeight: 650,
              maxHeight: '80vh',
              minWidth: 800,
              maxWidth: '40vw',
              paddingBottom: 0
            }
          }}
        >
          <DialogTitle  >{title}</DialogTitle>
          <DialogContent
            style={{ paddingBottom: 0 }}
          >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <MT.Button
              variant="contained"
              //color="default"
              style={{ margin: 10, marginTop: buttonMargin }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</MT.Button>

            <MT.Button
              color="primary"
              variant="contained"
              style={{ margin: 10, marginTop: buttonMargin }}
              onClick={this.onAddOrUpdateClick.bind(this)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</MT.Button>

          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 