import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as UI from '@mui/material/';
import * as MT from '@mantine/core';

import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';

import './TopMenu.css'


import { AdminMenuItem } from './../AdminMenuItem';
import { AboutMenuItem } from './../AboutMenuItem';
import { TutorialsMenuItem } from './../TutorialsMenuItem';



import { Link as RouterLink } from 'react-router-dom';
import * as Router from 'react-router-dom';

import { createStyles, Theme } from '@mui/material/styles';


/* 
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 2,
    },
    title: {
      flexGrow: 1,
    },
  }),
); */

export class TopMenuState {
  open: boolean;
  anchorEl: null | HTMLElement;
  constructor() {
    this.open = false;
    this.anchorEl = null;
  }
}
//export const LeftMenu: React.FC<App.GlobalState> = (props) => {
class TopMenu extends React.Component<App.GlobalState, TopMenuState> {

  constructor(props: any) {
    super(props);

    this.state = new TopMenuState();

  }




  public onTabChange(value: any) {
    // this.props.history.push(value);
  }


  public onLogoClick() {

    App.RouteManager.routeToPath("/");

  }

  public onMobileMenuClick() {
    console.log('onMobileMenuClick');
    var vm = this.props.globalUIState;
    vm.isMobileMenuOpen = !vm.isMobileMenuOpen;

    //console.log('onMobileMenuClick isMobileMenuOpen:', vm.isMobileMenuOpen);
    App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

  }



  public getTopButtons() {

    if (App.AppBase.isUserAuthenticated)
      return null;


    return <div  >
      <Button className='TopMenuLinks'
        onClick={(e) => App.RouteManager.routeToPath("/Login")}
      >Login</Button>
      <Button className='TopMenuLinks'
        onClick={(e) => App.RouteManager.routeToPath(App.AppBase.registerUrl)}
      >Register</Button>
    </div>
  }

  public render() {


    // var globalData = App.AppBase.getState().globalData;
    //  var globalState = globalData.globalUIState;

    var currentUser = App.AppBase.currentUser;
    var aboutMenu = null;
    if (currentUser?.realUserIsAdmin) {
      aboutMenu = <AboutMenuItem />;
    }

    var vm = this.props.globalUIState;
    //console.log('TopMenu globalUIState:',vm);

    var logoTxt = '';


    if (!App.AppBase.isProd() && !LIB.Common.isNullOrEmpty(App.AppBase.envName))
      logoTxt = `${App.AppBase.appInfo.appName} (${App.AppBase.envName})`;

    /*  if(!App.AppBase.isProd() && !LIB.Common.isNullOrEmpty(App.AppBase.envName))
     logoTxt = ` (${App.AppBase.envName})`; */

    var logoTag: any = null;
    if (!LIB.Common.isNullOrEmpty(logoTxt))
      logoTag = <span style={{ marginTop: 10 }}>{logoTxt}</span>

    var logoOld: any = <UI.Typography variant="h6" style={{ flexGrow: 1, }}
      onClick={(e: any) =>this.onLogoClick() } className="MouseHand" >
      <img src="/appLogo.png?v2" style={{ border: 0, height: 45 }} ></img>
    </UI.Typography>

    var logo: any = <a style={{ flexGrow: 1, }}
      href='/'
      onClick={(e:any) =>  { this.onLogoClick();  }} className="MouseHand" >
      <img src="/appLogo.png?v2" style={{ border: 0, height: 45 }} ></img>
    </a>

    var isMobile = App.AppBase.getIsMobile();

    if (vm.isMobile != isMobile) {
      vm.isMobile = isMobile;
      App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED, vm);

    }

    if (isMobile) {
    /*   logo = <div style={{ flexGrow: 1 }}
       onClick={() => this.onMobileMenuClick()} className="MouseHand" >
        <Icons.Menu />
      </div> */
      logo =  <MT.Burger 
      opened={vm.isMobileMenuOpen} 
      style={{ flexGrow: 1, }}
      onClick={() => this.onMobileMenuClick()} 
      aria-label="Toggle navigation" />;
   
    }

    //console.log('TopMenu Render isMobile',isMobile);

    var supportEmail = 'support@loocey.com';
    return (
      <UI.AppBar position="fixed" style={{ zIndex: 7000 }} className='ly-main-top-nav'>
        <UI.Toolbar>
          {/*    <UI.IconButton edge="start"    variant="default" aria-label="menu">
            <MenuIcon />
          </UI.IconButton> */}

          {logo}

          {aboutMenu}

          {/*      <TutorialsMenuItem  globalState={vm}   />
          | */}


        {App.AppBase.isUserAuthenticated && 
           <UI.MenuItem  className="TopMenuLinks"  title={supportEmail}  >
          <a className='navMenuBtn' href={`mailto:${supportEmail}?subject=Loocey Support Request`}  >SUPPORT</a>
          </UI.MenuItem>
        }
          <App.AccountMenuItem
            currentUser={App.AppBase.currentUser}
            globalState={vm}
          />
          <AdminMenuItem
            currentUser={App.AppBase.currentUser}
            globalState={vm}
          />


          {this.getTopButtons()}


          {/*   <AdminMenuItem  />
     */}
        </UI.Toolbar>
      </UI.AppBar>
    );
  }
}
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(TopMenu as any);



