import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class LinkedDetailDataService extends App.BaseAppService {



    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
    }

    public getDetailsById(sm: Api.DetailGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("DetailDataService.getDetailsById sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/DetailData/getDetailsById`;

        this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("DetailDataService.getDetailsById onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });
 
    }

    public getDetails(sm: Api.DetailGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("DetailDataService.getRecords sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedDetailData/getDetails`;

        this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("LinkedDetailDataService.getDetails onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'POST'
        });



    }


}
