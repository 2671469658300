import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { BrowserHistory, History,Location } from 'history';

export class RouterState  extends LIB.BaseStateModel {
 
  
    public history: BrowserHistory;
    public location: Location;
    public action:string;
  
    constructor(history:BrowserHistory|null=null) { 
         
        super();

        if(history!==null){
            this.history = history;
            this.location = history.location;
            this.action = history.action;
        }
         
    }
     


}