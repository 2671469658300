import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class AdminCompaniesService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getCompaniesListData(filter:Api.CompanyFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCompany/getCompanyListData?${urlPrams}`;
         
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
    public createCompany(data:Api.Company) {
        console.log("AdminCompanyService.createUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCompany/createCompany`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_COMPANIES_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }
    public updateCompany(data:Api.Company) {
        console.log("AdminCompanyService.updateUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCompany/updateCompany`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_COMPANIES_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public deleteCompany(candidateId:number) {
        console.log("AdminCompanyService.deleteUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/AdminCompany/deleteCompany?candidateId=${candidateId}`;
        this.post(apiUrl, candidateId, 
            App.ActionTypes.ADMINISTRATION_COMPANIES_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    

    
 
}
