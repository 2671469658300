import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class WorkspaceItemService extends App.BaseAppService  {
 


    constructor(workspaceId:number|undefined) { 

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
     } 


     
    public copyListFromItem(sm:Api.ListCopySm, onSuccess: Function | undefined, onError: Function | undefined = undefined) {
        console.log("WorkspaceItemService.copyListFromItem sm: ", sm);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/WorkspaceItem/copyListFromItem`;
 
            this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess?.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("WorkspaceItemService.copyListFromItem onError: ", error);
                        this.onErrorDefault(error);
                    }
                },
                method: 'POST'
            });
        

    }


     
     public updateItem(data:Api.WorkspaceItem) {
        console.log("WorkspaceItemService.updateItem data: ", data);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/WorkspaceItem/updateItem`;
        this.post(apiUrl, data, 
            App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            //onAfterSuccess
          /*   ()=>{
                App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

            } */
        );
 
    }

    public createItem(data:Api.WorkspaceItem) {
        console.log("WorkspaceItemService.createItem data: ", data);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/WorkspaceItem/createItem`;
        this.post(apiUrl, data, 
            App.ActionTypes.GLOBAL_WORKSPACE_ITEM_NEW_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            "",
            //onAfterSuccess
            /* (result:boolean)=>{ 
                console.log("WorkspaceItemService.createItem onAfterSuccess");

                App.AppStore.dispatch(App.ActionTypes.GLOBAL_UI_STATE_CHANGED);
                  
            } */
             
        );
 
    }

    public deleteItem(workspaceItemId:string) {
        console.log("WorkspaceItemService.deleteItem: ", workspaceItemId);
 
        if(LIB.Common.isNullOrEmpty(workspaceItemId))
            return;
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/WorkspaceItem/deleteItem?workspaceItemId=${workspaceItemId}`;
        this.get(apiUrl, 
            App.ActionTypes.GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            //onAfterSuccess
          /*   ()=>{
                App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

            } */
        );
 
    }

 
  
  
}
