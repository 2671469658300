import React, { HTMLAttributes } from 'react';

import { Badge, Tooltip } from '@mantine/core';

import * as LY from '_LY_Components';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as MT from '@mantine/core';

import './WorkspaceSelectionDropDown.css';
import { TooltipBaseProps } from '@mantine/core/lib/components/Tooltip/Tooltip.types';

interface WorkspaceSelectionDropDownProps extends LY.LY_DropDownProps {

  workspaces: Api.Workspace[];
 
  onWorkspaceChange?: (model: LY.DataItemModel, val: any | null, inSelectedItem:any, optionProps?: MT.ComboboxOptionProps | undefined) => void;

} 

type combinedProps = WorkspaceSelectionDropDownProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_TextArea
export const WorkspaceSelectionDropDown = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    workspaces,
    name='selectedWorkspace',
    valueProperty='workspaceId',
    displayProperty='name',
    isAutoCompleteStyle = true,
    onWorkspaceChange,
    ...props
  },
  ref // This is the forwarded ref
) => {

/* 
  React.useEffect(() => {
    console.log('WorkspaceSelectionDropDown useEffect currentWorkspaceId: ', currentWorkspaceId);

    if (currentWorkspaceId)
      loadData(currentWorkspaceId)

  }, [ currentWorkspaceId ]);
 */

  function onServiceError(error: any) {

    console.error('ListColumnRelationshipSettings onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
  //todo  vm.isActionInProgress = false;
  //todo  vm.errors.set('all', errorMessage);
  //todo  forceUpdate();

  }

  function loadData(currentWorkspaceId: number) {

    // e.preventDefault();

    console.log('ListColumnRelationshipSettings loadData currentWorkspaceId:', currentWorkspaceId);

 
    if (!currentWorkspaceId )
      return;

    var service = new App.WorkspaceService(currentWorkspaceId);

    service.getWorkspaceListsWithColumns(currentWorkspaceId,
      //onSuccess
      (result: any) => {
        console.log('getWorkspaceListsWithColumns onSuccess:', result);

     /*  todo
       var updatedVm = new ListColumnRelationshipSettingsVm();
        updatedVm.selectedWorkspace = workspace;
        updatedVm.workspaceLists = result;
        // setSelectedList(updatedVm.selectedList)
        setVm(updatedVm);

        console.log('getWorkspaceListsWithColumns onSuccess updatedVm:', updatedVm);
        forceUpdate(); */
      },
      //onError
      onServiceError
    );

   //todo vm.isActionInProgress = true;
  //todo  forceUpdate();

  }
 



  function render(){

   return <div className='LY_ListAndColumnSelection'
       
    >
      <LY.LY_DropDown
        key={'Workspace' + props.key}
        {...props}
        name={name}
        valueProperty={valueProperty}
        displayProperty={displayProperty}
        optionsMaxHeight={140}
        isAutoCompleteStyle={isAutoCompleteStyle}
        data={workspaces}
        error={props.error}
        onValueChange={onWorkspaceChange}
     
      />

      
    </div>
  }

  return render();
});


