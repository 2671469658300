import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
 
import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge,  Accordion, rem,Tooltip } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


  
interface UpdateColumnAdvancedTab_MiscProps {
  state: App.ListState;
 
}

export const UpdateColumnAdvancedTab_Misc: React.FC<UpdateColumnAdvancedTab_MiscProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
    
  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;

  const vm = props.state.vm;
  const state = props.state;
  
 

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('UpdateColumnAdvancedTab_Misc onInputChange key:', key);
    // console.log('UpdateColumnAdvancedTab_Misc onInputChange selectedColumn:', selectedColumn);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('UpdateColumnAdvancedTab_Misc onValueChange key:', key);
    //console.log('UpdateColumnAdvancedTab_Misc onValueChange value:', value);

    //console.log('UpdateColumnAdvancedTab_Misc onValueChange selectedColumn:', selectedColumn);

    if (key == 'name') {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }  

    //@ts-ignore
    selectedColumn[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedColumn', selectedColumn);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = selectedColumn;

    vm.errors.clear();

    if (vAll || key == 'maxLength') {
       if (item.maxLength!=undefined && item.maxLength <1)
        vm.errors.set('maxLength', `Max Length must be more than 0`);
    }
     
    /* if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required!`);
    }
    else if ((vAll || key == 'listColumnTypeId') && isNaN(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type must be a number!`);
    } */


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }
 

  // when Type changes, let's change what's selected 
  var listColumnTypeId = selectedColumn.listColumnTypeId;

  //console.log('UpdateColumnAdvancedTab_Misc watch listColumnTypeId:', listColumnTypeId);
  //console.log('UpdateColumnAdvancedTab_Misc selectedColumn:', selectedColumn);
  //console.log('UpdateColumnAdvancedTab_Misc columnTypes:', columnTypes);
  //console.log('UpdateColumnAdvancedTab_Misc selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == selectedColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
   

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var column = selectedColumn 

   // console.log('UpdateColumnAdvancedTab_Misc render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || selectedColumn == undefined || !vm.isColumnSettingsScreenOpen)
      return null;
  
      var columns = props.state?.view?.columns?.filter(x=>!x.isSystemHidden);

      var globalError = vm.errors.get('all');
      var item = selectedColumn;

 
    return  <div className='UpdateColumnAdvancedTabGroups' >
  
          <LY.LY_Input
            name="dataImportColumnIndex"
            value={item.dataImportColumnIndex}
            type='number'
            label="Import Column Index"
            placeholder="Import Column Index"
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('dataImportColumnIndex')}

            helpText="Used to do auto-match columns in csv files with no header row"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          /> 


            {/*     <div> List: {list?.listId}</div>
                <div> Column: {item?.listColumnId}</div>
          */}
 
      </div>;
   
  }

  return render();
};

