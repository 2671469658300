import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';
 

export class TemplateEditorReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.TemplateEditorState | undefined, action: App.AppAction): App.TemplateEditorState {
        var str = "";

        var result = new App.TemplateEditorState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION"))
            return state;

       
 
        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.ADMINISTRATION_API_ERROR:
                console.log('ADMINISTRATION_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                var vm = result.vm;
                //App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                vm.isActionInProgress = false;
                break;

            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;

    }



}