import "./LY_ListFilterModule_Save.css";

import * as Icons from "@tabler/icons-react";
import * as LY from '_LY_Components';

import { Button, Menu, Modal } from "@mantine/core";
import React, { useState } from "react";

import { useDisclosure } from "@mantine/hooks";

export const LY_FiltersModule_Save: React.FC = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [value, setValue] = useState("")

  const context = LY.useListFilterModuleContext();


  function getSaveAsNewModal() {

    return <Modal
      opened={opened}
      onClose={close}
      centered
      title="View Name"
    >
      <LY.LY_Input
        type={"input"}
        placeholder="Enter View Name"
        name="save"
        value={value}
        onChange={(_, event) => {
          const value = event?.target?.value;
          setValue(value);

        }}
      />
      <div className="LY_ListFiltersModule_Save_Modal_Buttons">
        <Button
          onClick={() => context.onSaveAsNewViewSuccess(close)}
        >
          Save
        </Button>
        <Button
          variant="default"
          onClick={close}
        >
          Cancel
        </Button>
      </div>
    </Modal>;

  }


  function render() {

    return (
      <>
        {getSaveAsNewModal()}

        <Menu position="bottom-start"
          offset={2}
          withinPortal={false}
          withArrow
          width={150}
        >
          <Menu.Target>
            <Button
              variant="outline"
              rightSection={
                <Icons.IconChevronDown width={15} height={16} stroke={1.5} />
              }
              className="LY_ListFiltersModule_Save_button"
            >
              Save
            </Button>
          </Menu.Target>

          <Menu.Dropdown
          >
            <Menu.Item
              value={"saveView"}
              onClick={() => context.onSaveViewSuccess()}
            >
              Save View
            </Menu.Item>
            <Menu.Item
              onClick={open}
              value={"saveasNew"}
            >
              Save as New
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </>
    );


  }

  return render();

};
