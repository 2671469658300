import './LY_StaticDropDown_Edit.css';

import * as Api from 'app-api'
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { LY_StaticDropDown_Edit_Options } from '../LY_StaticDropDown_Edit_Options/LY_StaticDropDown_Edit_Options';
import { LY_StaticDropDown_Footer } from '../LY_StaticDropDown_Footer/LY_StaticDropDown_Footer';
import LY_StaticSelect_Styles from '../LY_StaticSelect_Styles/LY_StaticSelect_Styles';
import { useState } from 'react';

export const LY_StaticDropDown_Edit: React.FC<LY.LY_StaticDropDownProps> = (props) => {



    const context = LY.useStaticDropDownContext();
    const state = context.state;
    const [activeTab, setActiveTab] = useState<string | null>('options');

    function onSaveClick() {


        var isValid = validateAll();
        if (!isValid) {
            console.log('LY_StaticDropDown_Edit onSaveClick isValid:', isValid);

            context.forceUpdate();
            return false;
        }
        context.saveColumnChanges();
        context.saveChanges();





    }

    function onInputChange(key: string, value: any, item: Api.SystemLookupType, index: number) {

        console.log('LY_StaticDropDown_Edit_Options onInputChange key:', key, index);


        //@ts-ignore
        item[key] = value;


        validateOne(key, item, index);

        context.itemChanged(item);


    }

    function validateAll(): boolean {

        var items = state?.items;

        if (!items) return false;


        state.errors.clear();

        items.map((item, index) => {
            validateOne('all', item, index);

        });

        var isValid = (state.errors.size < 1);

        return isValid;

    }

    function validateOne(key: string, item: Api.SystemLookupType, index: number): boolean {

        //  console.log('LY_StaticDropDown_Edit_Options validate key:', key);

        var nameInputName = 'edit_option_name' + index;
        var valueInputName = 'edit_option_value' + index;

        var vAll = (key === 'all'); //validate all

        if (!vAll)
            state.errors.clear();



        if ((vAll || key == 'name') && LIB.Common.isNullOrEmpty(item.name)) {
            state.errors.set(nameInputName, `Name is required!`);
        }

        console.log(props.valueProperty, item.value_id, item.value);
        if ((!props.valueProperty || props.valueProperty == 'value') && LIB.Common.isNullOrEmpty(item.value)) {
            state.errors.set(valueInputName, `Value is required!`);

        }
        else if ((!props.valueProperty || props.valueProperty == 'value_id') && item.value_id == undefined) {
            state.errors.set(valueInputName, `Value is required!`);

        }
        var isValid = (state.errors.size < 1);
        return isValid;

    }




    function tabsRenderer() {
        return <MT.Tabs.List>
            <MT.Tabs.Tab
                classNames={{
                    tab: 'LY_StaticSettings_tab_section',
                }}
                value="options"
            >
                Options
            </MT.Tabs.Tab>
            <MT.Tabs.Tab
                classNames={{
                    tab: 'LY_StaticSettings_tab_section',
                }}
                value="style"
            >
                Style
            </MT.Tabs.Tab>
        </MT.Tabs.List>
    }


    function panelRenderer() {
        return <>
            <MT.Tabs.Panel value="options" classNames={{ panel: 'LY_StaticSettings_Options_panel' }}>
                <LY_StaticDropDown_Edit_Options  {...props} setActiveTab={setActiveTab} onInputChange={onInputChange} />
            </MT.Tabs.Panel>
            <MT.Tabs.Panel value="style">
                <MT.Stack>
                    <LY_StaticSelect_Styles />
                </MT.Stack>
            </MT.Tabs.Panel>
            {!state.disableEditOptions && <LY_StaticDropDown_Footer {...props} onSaveClick={onSaveClick} />}
        </>
    }


    function render() {
        return <MT.Tabs value={activeTab} onChange={setActiveTab} styles={{ root: { width: '300px' } }}>
            {tabsRenderer()}
            {panelRenderer()}
        </MT.Tabs>
    }


    return render();
}