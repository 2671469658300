import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class DLADataService extends App.BaseAppService {


  //  private linkedListDataService: App.LinkedListDataService | undefined = undefined

    constructor(workspaceId: number | undefined, linkedWorkspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
     ///   this.linkedListDataService = new App.LinkedListDataService(workspaceId);

        this.linkedWorkspaceId = linkedWorkspaceId;

    }
 

    public async copyToBiddingBoard(sm: Api.CopyToBiddingBoardSm, onSuccess: Function, onError: Function | undefined = undefined) {

 
        console.log("ListDataService.copyToBiddingBoard sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/DLAData/copyToBiddingBoard`;
 
            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("ListDataService.copyToBiddingBoard onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        


    }
}
