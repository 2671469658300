import React, { ButtonHTMLAttributes, ChangeEvent, ChangeEventHandler, HTMLAttributes, ReactElement } from 'react';

import * as LY from '_LY_Components';

import './LY_Button.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';

interface LY_ButtonCancelProps   {
  // ... additional props
  children?: any; // This line allows the component to accept children

};


export const LY_ButtonCancel: React.FC<LY_ButtonCancelProps & MT.ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ((
  {
    variant='default',
    ...props

  }) => { 


  return <MT.Button
    variant={variant}
   
   // onClick={props.onClick}
    {...props}
  >{props.children}</MT.Button>
 

  });
