import * as React from 'react';
import { connect } from 'react-redux';
import * as App from 'AppReferences';
import { Table, TableBody, TableCell, Link, TableContainer, Menu, Paper, MenuItem, TableRow, TableHead, Button, Icon } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableState } from "mui-datatables";
import * as LIB from '_LIB';
//
import * as Icons from '@mui/icons-material/';

interface AdministrationUsersTableProps {
  state: App.AdministrationState;
  onChange?: Function;

}

export class AdministrationUsersTable extends React.PureComponent<AdministrationUsersTableProps> {

  public adminUsersService: App.AdminUsersService;

  constructor(props: any) {
    super(props);
    this.adminUsersService = new App.AdminUsersService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationUsersTable componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {

  }


  public onActionMenuItemClick(e: React.MouseEvent<HTMLElement>, key: string, id: any, rowIndex: number) {

    var vm = this.props.state.vm;

    vm.apiErrorMessage = '';
    this.setSelected(id);

    console.log('onActionMenuItemClick 1: ', key);

    if (key == "Edit") {
      vm.isUsersEditOpen = true;
    }
    else if (key == "Delete") {
      vm.isUsersDeleteOpen = true;
    }
    else if (key == "ChangePassword") {
      vm.isUsersChangePasswordOpen = true;
    }
    else if (key == "EditRoles") {
      vm.isUsersEditRolesOpen = true;
    }


    this.forceUpdate();

    if (this.props.onChange)
      this.props.onChange();

  }


  public setSelected(id: any) {
    var state = this.props.state;
    var vm = state.vm;
    var found = state.appUsers.find(x => x.userId === id);

    vm.setSelectedUser(found);
  }

  public getActionMenuItems(id: any, tableMeta: any, updateValue: any): any[] {
    //console.log('getActionMenuItems tableMeta:', tableMeta);
    //console.log('getActionMenuItems updateValue:', updateValue);

    var state = this.props.state;
    var appUsers = state.appUsers;
    var currentUser = App.AppBase.currentUser;


    var result = [

      <MenuItem key="Edit"
        onClick={(e) => this.onActionMenuItemClick(e, 'Edit', id, tableMeta.rowIndex)}
      >Edit</MenuItem>,

      <MenuItem key="ChangePassword"
        onClick={(e) => this.onActionMenuItemClick(e, 'ChangePassword', id, tableMeta.rowIndex)}
      >Change Password</MenuItem>,



    ];


    var editRoles = <MenuItem key="EditRoles"
      onClick={(e) => this.onActionMenuItemClick(e, 'EditRoles', id, tableMeta.rowIndex)}
    >Edit Roles</MenuItem>

    if (currentUser.hasRole(App.UserRoleTypeEnum.Admin)) {
      result.push(editRoles);
    }

    var deleteMenuItem = <MenuItem key="Delete"
      onClick={(e) => this.onActionMenuItemClick(e, 'Delete', id, tableMeta.rowIndex)}
    >Delete</MenuItem>;

    result.push(deleteMenuItem);

    return result;


  }

  public getTableColumns() {

    var pkColumn = LIB.MUITableHelper.getColumnSetup("ID", "userId", false);
    // pkColumn.options.display = 'excluded';
    pkColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      <div>
        <Link
          onClick={(e: any) => this.onActionMenuItemClick(e, 'Edit', value, tableMeta.rowIndex)}
          color="primary">{value}</Link>
      </div>

    );

    var actionsColumn = LIB.MUITableHelper.getColumnSetup("Actions", "userId", false);
    actionsColumn.options.customBodyRender = (value: any, tableMeta: any, updateValue: any) => (
      LIB.MUITableHelper.getActionsButton(value, tableMeta, updateValue,
        this.getActionMenuItems(value, tableMeta, updateValue)
      )
    );

    var dateCreatedCol = LIB.MUITableHelper.getColumnSetupDateTime("Created", "dateCreated");
    var dateModifiedCol = LIB.MUITableHelper.getColumnSetupDateTime("Modified", "dateModified");
    var lastLoginDateCol = LIB.MUITableHelper.getColumnSetupDateTime("Last Login", "lastLoginDate");
    //var datePublishedCol = LIB.MUITableHelper.getColumnSetupDateTime("Published", "datePublished");

    //console.log("company: ", this.props.state?.appUsers[0]?.company?.companyName);
    console.log("roles ", this.props.state?.appUsers[ 0 ]?.roleTypeNames);


    var columns = [
      pkColumn,
      LIB.MUITableHelper.getColumnSetup("First Name", "firstName", true),
      LIB.MUITableHelper.getColumnSetup("Last Name", "lastName", true),
      LIB.MUITableHelper.getObjectChildPropertyColumn("Company", "company", "companyName", true),
      LIB.MUITableHelper.getColumnSetup("Company ID", "companyId", true),
      LIB.MUITableHelper.getColumnSetup("Phone", "phone", true),
      LIB.MUITableHelper.getColumnSetup("Email", "email", true),
      LIB.MUITableHelper.getCustomRenderValueColumn("Email Confirmed", "emailConfirmed", (val: boolean) => String(val)),
      LIB.MUITableHelper.getColumnSetup("Roles", "roleTypeNames", false,'',undefined,false),
      dateCreatedCol,
      dateModifiedCol,
      lastLoginDateCol,
      actionsColumn,
    ];


    return columns;
  }

  public onRowSelectionChange(currentRowsSelected: any[], allRowsSelected: any[], rowsSelected?: any[]) {

    console.log('onRowSelectionChange');
    var vm = this.props.state.vm;
    var state = this.props.state;

    var dataIndex = currentRowsSelected[ 0 ].dataIndex;

    var item = state.appUsers[ dataIndex ];

    //  console.log('onRowSelectionChange item', item);

    vm.setSelectedUser(item);

    //console.log('onRowSelectionChange selectedUser', vm.selectedUser);


  }

  public onRowClick(rowData: string[], rowMeta: { dataIndex: number, rowIndex: number }) {

    console.log('onRowClick');
    console.log(rowData);
    console.log(rowMeta);
    var vm = this.props.state.vm;

    // vm.selectedRowIndex = rowMeta.rowIndex;
    this.forceUpdate();

  }


  public onPagingChange(tableState: MUIDataTableState) {

    var vm = this.props.state.vm;

    var searchFilter = this.props.state.appUsersSearchFilter;

    searchFilter.pageNum = tableState.page;
    searchFilter.maxRows = tableState.rowsPerPage;
    searchFilter.sortOrder = tableState.sortOrder.name;
    searchFilter.sortDirection = tableState.sortOrder.direction;
    searchFilter.isSearch = false;

 
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST, searchFilter);

  }




  public render() {
    console.log('AdministrationUsersTable render');
    var state = this.props.state;
    var vm = state.vm;

    if (LIB.Common.isNullOrEmpty(state) || !state.hasUserDataLoaded)
      return <LIB.Loading />;

    var rows = state.appUsers;

    //@ts-ignore
    const oldRender = TableCell.render

    //@ts-ignore
    TableCell.render = function (...args) {
      const [ props, ...otherArgs ] = args
      if (typeof props === 'object' && props && 'isEmpty' in props) {
        const { isEmpty, ...propsWithoutEmpty } = props
        return oldRender.apply(this, [ propsWithoutEmpty, ...otherArgs ])
      } else {
        return oldRender.apply(this, args);
      }
    }


    const options: MUIDataTableOptions = {
      filterType: 'dropdown',
      responsive: "standard",
      resizableColumns: true,
      download: false,
      print: false,
      filter:false,
      viewColumns:false,
      selectableRows: 'single',
      onRowClick: this.onRowClick.bind(this),
      selectableRowsHideCheckboxes: true,
      selectableRowsOnClick: true,
      // rowsSelected:[vm.selectedRowIndex],
      selectToolbarPlacement: 'none',
      onRowSelectionChange: this.onRowSelectionChange.bind(this),
      search: false,
      rowsPerPage: 10,
      jumpToPage: true,
      // rowsPerPageOptions: [10, 50, 100, 200],      
      rowsPerPageOptions: [],
      serverSide: true,
      count: state.appUsersCount,

      onTableChange: (action, tableState) => {
        console.log('onTableChange action: ', action);
        console.log('onTableChange tableState: ', tableState);

        if (action == 'changePage' || action == 'sort') {
          this.onPagingChange(tableState)
        }


      }
      //,viewColumns:false

    };


    return <div>
      <MUIDataTable
        title={""}
        data={rows}
        columns={this.getTableColumns()}
        options={options}

      />



    </div>
  }
};

