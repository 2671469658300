import React, { HTMLAttributes } from 'react';

import { Badge, Tooltip } from '@mantine/core';

import * as LY from '_LY_Components';

import './LY_Badge.css';
import { TooltipBaseProps } from '@mantine/core/lib/components/Tooltip/Tooltip.types';

interface LY_BadgeProps {
  value: any | undefined;
  tooltipProps?:TooltipBaseProps

}



type combinedProps = LY_BadgeProps & React.InputHTMLAttributes<HTMLDivElement>;

// Use React.forwardRef to forward the ref to the select element - LY_Badge
export const LY_Badge = React.forwardRef<HTMLDivElement, combinedProps>((
  {
    value,
    //style,
    //onChange,
    ...props
  },
  ref // This is the forwarded ref
) => {
 
  //console.log('LY_Badge name', name);
  //console.log('LY_Badge value', value);

  return <div ref={ref} {...props}>
   {/*  <Tooltip
      position="right-start"
      label="Tooltip"
      offset={{ mainAxis: 4, crossAxis: -11 }}
    > */}
      <Badge
        className='LY_ListColumnCellReadOnly'
        color="rgba(148, 148, 148, 1)"
        
      >
        {value}
      </Badge>
   {/*  </Tooltip> */}
  </div>
});


