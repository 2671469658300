import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import { Alert } from '@mui/material';


interface AccountBillingProps {
  state: App.AccountState;
  children?: React.ReactNode;

}

 

export class AccountBilling extends React.Component<AccountBillingProps> {

  public validationManager: App.ValidationManager;
  public profileManager: App.ProfileManager;
 

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();
    this.profileManager = new App.ProfileManager();
  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AccountBilling componentDidMount');
    /* 
        var profile = this.props.state.profile;
        var user = App.AppBase.currentUser;
    
        //  console.log('AccountBilling profile',profile);
        //  console.log('AccountBilling user',user);
    
        if (LIB.Common.isNullOrEmpty(profile.userId)) {
    
          this.resetData();
          this.forceUpdate();
        } */


  }

  public resetData() {
    console.log('AccountBilling resetData');
    var profile = this.props.state.profile;

    profile.setData(this.props.state.profileOriginal);
  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    //console.log('AccountBilling componentDidUpdate');


  }

  public getActivePlanInfo(){
    var state = this.props.state;
    var item = state.profile;
    var company = state.company;

    console.log('getActivePlanInfo company',company);

    if(!company.hasActiveBillingPlan)
      return null;

    return <div>
        <h3>Active Plan Details:</h3>
        <div>Plan Name: todo{/*  {company.billingPlanName} */}</div>
        <div>Next Payment Date: {LIB.DateHelper.getFormattedShortDate(company.billingNextPaymentDate)}</div>

    </div>


  }




  public render() {

    //console.log('ProjectScopeForm render');

    var state = this.props.state;
    var item = state.profile;

    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);


    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      margin: 2,
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{  marginLeft: 10 }}>


      <h2 >Billing</h2>


      {this.getActivePlanInfo()}
       
      <App.BillingPricingTable 

      billingPlans={state.billingPlans}
      
       />
 

    </div>;


  }
};
