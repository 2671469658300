
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';


export class SelectedItemActionsVm extends LIB.BaseVmModel {

    
    public isDeleteItemsOpen: boolean = false;
    public isDeleteItemsInProgress: boolean = false;

    public isCopyToBiddingBoardOpen: boolean = false;

      
    constructor() {

        super();
    }
 
        
 
    
    

}