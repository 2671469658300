import "./LY_NoteModule_Input.css";

import React from "react";

import * as Icons from "@tabler/icons-react";

import { Textarea } from "@mantine/core";
import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";
import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";

interface LY_NoteModule_Input_Props extends LY_NotesModuleProps {
  reply?: boolean;
}

export const LY_NoteModule_Input: React.FC<LY_NoteModule_Input_Props> = (
  props
) => {
  const context = useNotesModuleContext();

  function render() {
    let inputContainerClass = "";
    let messageValue = "";
    let placeholder = "";
 
    if (props.reply) {
      inputContainerClass = "LY_Note_Module_IsReply";
      messageValue = context.state.newReplyMessage;
      placeholder = "Write a reply";
    } else {
      inputContainerClass = "LY_Note_Module_IsNewNote";
      messageValue = context.state.newNoteMessage;
      placeholder = "Write a note";
    }

    function handleMainInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
      if (props.reply) {
        context.setNewReplyMessage(e.currentTarget.value);
      } else {
        context.setNewNoteMessage(e.currentTarget.value);
      }
    }

    function onAddNoteOrReplyClick(e: React.MouseEvent<any>) {

      e.preventDefault();

      if (props.reply) 
        context.addNewReply();

      else 
        context.addNewNote();
      
    }

    return (
      <div className={inputContainerClass}>
        <Textarea
          className="LY_NoteModule_Main_Input"
          placeholder={placeholder}
          classNames={{
            input: "LY_NoteModule_Main_Input_Container",
            wrapper: "LY_NoteModule_Main_Input_Wrapper",
          }}
          autosize
          disabled={context.state.isActionInProgress}
          value={messageValue}
          onChange={handleMainInputChange}
          rightSectionPointerEvents="all"
          mt="lg"
          rightSection={
            <div
              onClick={(e) => onAddNoteOrReplyClick(e)}
              className="LY_NoteModule_Main_Input_SendIcon_Link">
              <Icons.IconSend2
                className="LY_NoteModule_Main_Input_SendIcon"
                title="Send"
              />
            </div>
          }
        />
      </div>
    );
  }

  return render();
};
