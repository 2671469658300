import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_ColorInput.css';
import * as MT from '@mantine/core';
import * as LIB from '_LIB';

interface LY_ColorInputProps extends LY.LY_InputBaseProps {
  name: string;
  //type: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  inputInnerClassName?: string;
  //onChange?: React.ChangeEventHandler<HTMLSelectElement>;
   onColorChange?: (model: LY.DataItemModel | undefined, val:string | undefined) => void;
 // form?: { register: UseFormRegister<any> };//UseFormReturn<any, any, any>; //{ register: UseFormRegister<any> };
  // hookForm?: UseFormReturn<any, any, undefined>
  //style?: React.CSSProperties;
  inputProps?: MT.ColorInputProps;
  wrapperProps?: any;
  defaultColorOptions?: string[];
}

 

type combinedProps = LY_ColorInputProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_ColorInput
export const LY_ColorInput = React.forwardRef<HTMLInputElement, LY_ColorInputProps>((
  {
    name,
    //type,
    placeholder,
    defaultValue,
    inputClassName,
    inputInnerClassName,
    value,
    style,
    onColorChange,
    model,
    wrapperProps,
    inputProps,
    defaultColorOptions = ['#2e2e2e', '#868e96', '#fa5252', '#e64980', '#be4bdb', 
    '#7950f2', '#4c6ef5', '#228be6', 
    '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14'],
    ...inputBaseProps
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();


  var combinedInputClassName = ` LY_ColorInput`;
  var wrapperClassName = ` LY_ColorInputWrapper`;

  if(inputBaseProps.error)
  combinedInputClassName+=' LY_ColorInput_error'
  else 
  combinedInputClassName+=' LY_ColorInput_focus'
 
  if(inputClassName)
  combinedInputClassName+=' ' + inputClassName;

  // if(!form)
  //   return;


  function onLocalChange(val: string | undefined) {

    if(model?.value && model?.value == val)
      return;

    if(model)
      model.value = val;

      forceUpdate();
      
      if(onColorChange)
        onColorChange(model,val)
  }
 
   //const { register } = form!;

  /* console.log('LY_ColorInput name', name); 
  console.log('LY_ColorInput value', value);
  console.log('LY_ColorInput register', register); */

 var val = model?.value || value;

 inputInnerClassName = `LY_ColorInputInput + ${inputInnerClassName || ''}`;

  return (
    <LY.LY_InputBase  {...inputBaseProps}  style={style} tooltip={inputBaseProps.tooltip || model?.column?.helpText} >

     
        <MT.ColorInput
          name={name}
          ref={ref}
          id={inputProps?.id}
            //type={type}
          value={val}
          className={combinedInputClassName}
          placeholder={placeholder}
          {...inputProps}
         // autoComplete={Math.random().toString()}
        //  onChange={onLocalChange}
          onChangeEnd={onLocalChange}
          wrapperProps={{classNames:wrapperClassName }}
          classNames={{input:inputInnerClassName, wrapper:'LY_ColorInputWrapper'}}
          swatches={defaultColorOptions}
          style={style}
        />
    

    </LY.LY_InputBase>
  );
});


