import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_Input.css';
import moment from 'moment';

interface LY_InputProps extends LY.LY_InputBaseProps {
  name: string;
  type?: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  //onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  //onChange?: (model: LY.DataItemModel | undefined, event: ChangeEvent<HTMLInputElement> | undefined) => void;
  // form?: { register: UseFormRegister<any> };//UseFormReturn<any, any, any>; //{ register: UseFormRegister<any> };
  // hookForm?: UseFormReturn<any, any, undefined>
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange?: (model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}

//export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((

type combinedProps = LY_InputProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_Input
export const LY_Input = React.forwardRef<HTMLInputElement, LY_InputProps>((
  {
    name,
    type,
    disabled,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    style,
    onChange,
    model,
    readOnly,
    inputProps,
    ...inputBaseProps
  },
  ref // This is the forwarded ref
) => {

  var combinedInputClassName = `mantine-Input-input mantine-TextInput-input LY_InputInput`;

  if (inputBaseProps.error)
    combinedInputClassName += ' LY_InputInput_error'
  else
    combinedInputClassName += ' LY_InputInput_focus'

  if (disabled)
    combinedInputClassName += ' LY_InputInput_disabled'

  if (inputClassName)
    combinedInputClassName += ' ' + inputClassName;

  // if(!form)
  //   return;



  var readOnlyValue = model?.value;
  if (!readOnlyValue && value)
    readOnlyValue = value;


  /*   if(name=='name'){
     console.log('LY_Input render name', name); 
     console.log('LY_Input render value', value);
     console.log('LY_Input render model', model);  
     console.log('LY_Input render defaultValue', defaultValue);  
     console.log('LY_Input render readOnlyValue', readOnlyValue);  
     console.log('LY_Input render row', model?.row);  
 
     
      
   
    } */


  if (type == 'date') {
    value = moment(value).format('YYYY-MM-DD');
    readOnlyValue = moment(readOnlyValue).format('DD/MM/YYYY');
  }
  else if (type?.includes('datetime')) {
    value = moment(value).format('YYYY-MM-DDTHH:mm');
    readOnlyValue = moment(readOnlyValue).format('YYYY-MM-DDTHH:mm');

  }


  /*    else if(type=='time'){
      value =  moment(value).format('HH:mm');
     } */

  return (
    <LY.LY_InputBase  {...inputBaseProps}
      tooltip={inputBaseProps.tooltip || model?.column?.helpText}
      readOnly={readOnly}
      style={inputBaseProps.containerStyle}
    >

      {readOnly && (
        <div className='LY_Input_ReadOnly'>{readOnlyValue}</div>
      )}

      {!readOnly && (
        <input
          name={name}
          ref={ref}
          id={inputProps?.id}
          type={type}
          value={value || ''}
          className={combinedInputClassName}
          placeholder={placeholder}
          checked={type == 'checkbox' ? value : undefined}

          {...inputProps}
          // autoComplete={Math.random().toString()}
          onChange={(e) => {
            if (model)
              model.value = e.target.value;

            //  console.log('LY_Input onChange currentTarget.value:',e.currentTarget.value);
            //  console.log('LY_Input onChange target.value:',e.target.value);

            if (onChange)
              onChange(model, e)
          }
          }

          disabled={disabled}
        />
      )}

      {/* )}
       {!register && (
        <input
          type={type}
          value={value}
          className={combinedInputClassName}
          placeholder={placeholder}
         {...inputProps}
        />
      )} */}


    </LY.LY_InputBase>
  );
});


