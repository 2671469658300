import './LY_StaticDropDown_Footer.css'

import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { Button } from "@mantine/core";
import React from "react"

interface LY_StaticDropDown_FooterProps extends LY.LY_StaticDropDownProps {
    onSaveClick?: () => void;
}


export const LY_StaticDropDown_Footer: React.FC<LY_StaticDropDown_FooterProps> = (props) => {


    const context = LY.useStaticDropDownContext();
    const state = context.state;


    if (!state.isEditMode) {
        return <div className='LY_StaticDropdown_footer'>
            <div onClick={() => {
                context.changeSelectedValue(undefined);
                if (props.onChange)
                    props.onChange(state.model, undefined)
            }}> Clear
            </div>
            <div
                onClick={() => {
                    context.changeEditMode(true);
                }}
            >
                Edit Options
            </div>
        </div>
    }


    if (state.isEditMode) {

        return <div className='LY_StaticSelect_ButtonGroup'>
            <LY.LY_ButtonCancel
                onClick={() => {
                    //  context.changeFooterType('default');
                    context.changeEditMode(false);
                }}
            // variant='outline'
            //classNames={{ root: 'LY_StaticSelect_ButtonGroup_Cancel' }}
            >
                Close
            </LY.LY_ButtonCancel>

            <LY.LY_Button
                onClick={props.onSaveClick}

                loading={state.isActionInProgress}

            >
                Save
            </LY.LY_Button>

        </div>
    }


    return null
}
