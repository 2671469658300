import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AccountBillingReducers {


    constructor() {

    }

    public static getReducers(state: App.AccountState | undefined, action: App.AppAction): App.AccountState {
        var str = "";

        var result = new App.AccountState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ACCOUNT_BILLING"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);
 
        var billingService = new App.BillingService();
         

         switch (action.type) {
  
            case App.ActionTypes.ACCOUNT_BILLING_PLANS_REQUEST: {
                console.log('ACCOUNT_BILLING_PLANS_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                let vm = result.vm;
                let data = action.payload;
                billingService.getPricingPlans();

                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ACCOUNT_BILLING_PLANS_SUCCESS: {
                console.log('ACCOUNT_BILLING_PLANS_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                let data = {... action.payload };

                result.billingPlans = data.billingPlans;

               
                result.hasBillingPlansDataLoaded = true;
                result.isServiceCallPending = false;
           
               result.forceUpdateCount++;
                break;
            }   
            
            case App.ActionTypes.ACCOUNT_BILLING_CREATE_STRIPE_SESSION_REQUEST: {
                console.log('ACCOUNT_BILLING_PLANS_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';

                let data = action.payload;
                let vm = result.vm;
                let billingPlanId = data?.plan?.billingPlanId;
                billingService.createStripeSession(billingPlanId);

                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ACCOUNT_BILLING_CREATE_STRIPE_SESSION_SUCCESS: {
                console.log('ACCOUNT_BILLING_CREATE_STRIPE_SESSION_SUCCESS:', action.payload);
                result = { ...state };
                let vm = result.vm;
                let url = action.payload;

                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

                window.location.href = url;
                //App.RouteManager.redirect(url);
           
               result.forceUpdateCount++;
                break;
            }   
            
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}