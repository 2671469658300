import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';


import { AdministrationProspectsTable } from './AdministrationProspectsTable';
import { AdministrationProspectsEditModal } from './AdministrationProspectsEditModal';
import { AdministrationProspectsCreateModal } from './AdministrationProspectsCreateModal';
import { AdministrationProspectsBulkImportModal } from './AdministrationProspectsBulkImportModal';


class AdministrationProspects extends React.Component<App.AdministrationState, {}> {

  //public adminUsersService: App.AdminUsersService;
  public prospectsService: App.ProspectsService;


  constructor(props: any) {
    super(props);
    this.prospectsService = new App.ProspectsService();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationProspects componentDidMount');

    if (!this.props.hasProspectDataLoaded) {
      console.log('AdministrationProspects getUsersListData');
      /* this.prospectsService.getProspectsListData(null,
        (result: any) => {
          console.log('getUsersListData success...');
        }); */

        App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST, null);


      this.forceUpdate();
      console.log('getProspectsListData forceUpdate...');

    }


  }

  public componentWillUnmount() {
    console.log("componentWillUnmount");
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET,null);

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationProspects componentDidUpdate');


  }

  public onCreateClick() {
    console.log('onCreateClick');
    var state = this.props;
    var vm = state.vm;

    vm.isProspectsAddOpen = true;
    this.forceUpdate();


  }

  public onBulkImportClick() {
    console.log('onBulkImportClick');
    var state = this.props;
    var vm = state.vm;

    vm.isProspectsBulkImportOpen = true;
    this.forceUpdate();
  }

  public onInputChange(newVal: any) {
    console.log('onInputChange:' + newVal);


  }


  
  public onSearchClick() {
    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.prospectSearchFilter;
    //console.log("searchFilter", searchFilter);
    searchFilter.keyword = searchFilter.keyword?.trim();
    searchFilter.isSearch=true;
    //console.log("searchFilter", searchFilter);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST, searchFilter);

  }


  
  public onDeleteConfirmClick() {
    console.log('onDeleteConfirmClick');

    var state = this.props;
    var vm = state.vm;

    var itemId = vm.selectedProspect?.prospectId;

    console.log('onDeleteConfirmClick itemId', itemId);

    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_DELETE_REQUEST, itemId);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

  public render() {

    console.log('AdministrationProspects render');

    var state = this.props;
    var vm = state.vm;
    var searchFilter = state.prospectSearchFilter;

    console.log('AdministrationProspectsList hasDataLoaded', state.hasDataLoaded);
    console.log('AdministrationProspectsList isServiceCallPending', this.prospectsService.isServiceCallPending);
    console.log('AdministrationProspectsList vm.isActionInProgress', vm.isActionInProgress);

    var keyword = '';


    var createBtn = <MT.Button
      id="btnCreate"
      variant="contained"
      style={{ marginBottom: 10 }}
      color="primary"
      onClick={() => this.onCreateClick()}
    >Create</MT.Button>

    var bulkImportBtn = <MT.Button
      id="btnBulkImport"
      variant="contained"
      style={{ marginBottom: 10 }}
      color="primary"
      onClick={() => this.onBulkImportClick()}
    >Bulk Import</MT.Button>

    return <App.MainSplitPage
      pageTitle="Administration > Prospects"
      state={vm}
      hasDataLoaded={state.hasProspectDataLoaded}
      loading={(state.isServiceCallPending && !vm.isActionInProgress)}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      alignError='left'
      isChildPage={true}
    >
     <div style={{   width: '85vw' }} >

        {<LIB.MSearchBar
          filter={searchFilter}
          onSearchClick={() => this.onSearchClick()}
          isSearchInProgress={state.isServiceCallPending}
        //  onValidate={(e:any) => this.onInputChange(e)}
        />}

        <div style={{ textAlign: 'right', width: '100%' }} >

          {createBtn} {bulkImportBtn}


        </div>

       
        <AdministrationProspectsTable state={state} onChange={() => this.forceUpdate()} />

        <AdministrationProspectsEditModal state={state} onChange={() => this.forceUpdate()} />

        <AdministrationProspectsCreateModal state={state} onChange={() => this.forceUpdate()} />

        <AdministrationProspectsBulkImportModal state={state} onChange={() => this.forceUpdate()} />


      </div>

      <LIB.ConfirmModal
        visible={vm.isProspectDeleteOpen}
        onCancelClick={() => { vm.isProspectDeleteOpen = false; this.forceUpdate(); vm.apiErrorMessage = '' }}
        onConfirmClick={() => this.onDeleteConfirmClick()}
        title={`Confirm Delete: (${vm.selectedProspect?.prospectId}) ${vm.selectedProspect?.email} `}
        content={`Are you sure you want to delete prospect Id "${vm.selectedProspect?.prospectId}" ?`}
        confirmButtonLabel="Delete"
        disabled={vm.isActionInProgress}
      />

    </App.MainSplitPage>


  }
};
export default connect(
  (state: App.AppState) => ({ ...state.administrationState })
)(AdministrationProspects as any);

