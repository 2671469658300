import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';


import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_NewWorkspaceScreen.css';

interface NewWorkspaceScreenProps {
  state: App.GlobalState;


}

export const NewWorkspaceScreen: React.FC<NewWorkspaceScreenProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);

  const [ open, setOpen ] = React.useState(false);

  const [ selectedTypeInfo, setSelectedTypeInfo ] = useState('');


  const [ newWorkspace, setNewWorkspace ] = useState(new Api.Workspace());


  //const { view, list, newWorkspace, columnTypes,columns } = props.state;

  var vm = props.state.workspaceVm;
  const state = props.state;


  /*   useEffect(() => {
      console.log('NewWorkspaceScreen useEffect newWorkspace', newWorkspace);
      // vm.isActionInProgress=true;
       reset(newWorkspace);
      //resetAll();
  
    }, [ newWorkspace ]); */




    function onSubmit(e: React.FormEvent<HTMLFormElement>) {

      e.preventDefault();

    console.log('NewWorkspaceScreen onSubmit newWorkspace:', newWorkspace);
    //console.log('NewWorkspaceScreen onSubmit configId:', data?.configId);
    //console.log('NewWorkspaceScreen onSubmit  newWorkspace configId:', newWorkspace?.configId);

    var isValid = validate('all');
    if (!isValid) {
      console.log('NewWorkspaceScreen onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    }


    var workspaceId = state.workspaceVm.selectedWorkspace?.workspaceId;

    console.log('NewWorkspaceScreen workspaceId:', workspaceId);

    var service = new App.WorkspaceService(workspaceId);


    //console.log('NewWorkspaceScreen newWorkspace send:', newWorkspace);
    //return;

    service.createItem(newWorkspace,
      //onSuccess
      (result: any) => {
        console.log('NewWorkspaceScreen onSuccess:', result);

        state.workspaceVm.workspaces.push(result);

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST, result);

        resetAll();

        forceUpdate();


      },
      //onError
      (error: any) => {

        console.error('NewWorkspaceScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);
        
        forceUpdate();
      });

 
    vm.isActionInProgress = true;
    forceUpdate();

 

  }



  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('DefaultListTableForm onInputChange key:', key);
    // console.log('DefaultListTableForm onInputChange newWorkspace:', newWorkspace);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('DefaultListTableForm onValueChange key:', key);
    //console.log('DefaultListTableForm onValueChange newWorkspace:', newWorkspace);

    if (key == 'name') {
      newWorkspace.schemaName = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }

    //@ts-ignore
    newWorkspace[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange newWorkspace', newWorkspace);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = newWorkspace;

    vm.errors.clear();


    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        vm.errors.set('name', `Workspace Name is required!`);
      else if (item.name.length > 50)
        vm.errors.set('name', `Workspace Name must be less than 50 characters!`);
    }
    if (vAll || key == 'schemaName') {
      if (LIB.Common.isNullOrEmpty(item.schemaName))
        vm.errors.set('schemaName', `Internal Name is required!`);
      else if (item.schemaName.length > 50)
        vm.errors.set('schemaName', `Internal Name must be less than 50 characters!`);
    }
    if ((vAll || key == 'tag') && LIB.Common.isNullOrEmpty(item.tag)) {
      vm.errors.set('tag', `Tag is required!`);
    }
    if ((vAll || key == 'color') && LIB.Common.isNullOrEmpty(item.color)) {
      vm.errors.set('color', `Color is required!`);
    }


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }

  function getForm() {
    //var vm = props.state.vm;
    //var columnTypes = props.state.columnTypes;

    var globalError = vm.errors.get('all');

    var form = (
      <form autoComplete="off"
        onSubmit={onSubmit}
        className='NewWorkspaceScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div className='NewWorkspaceScreenFormScrollArea' >

          <LY.LY_Input
            name="name"
            value={newWorkspace.name}
            type='text'
            label="Name"
            placeholder="Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('name')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

          
          <LY.LY_Input
            name="schemaName"
            value={newWorkspace.schemaName}

            type='text'
            label="Internal Name"
            placeholder="Internal Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('schemaName')}

            helpText="This name will be used in APIs or Integrations"
            helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />



          <div style={{ display: 'flex', gap: '1rem' }}>
            <LY.LY_Input
              name="tag"
              value={newWorkspace.tag}

              type='text'
              label="Tag"
              //maxLength={3}
              placeholder="Short Tag"
              labelPlacement='top'
              withStar={true}
              error={vm.errors.get('tag')}

              //helpText="This name will be used in APIs or Integrations"
              // helpTextPlacement='bottom'
              inputProps={{ maxLength: 5 }}
              onChange={(m, e) => {
                onValueChange('tag', e!.currentTarget?.value?.toUpperCase());
              }}

            />

            <LY.LY_ColorInput
              name="color"
              value={newWorkspace.color}

              // type='text'
              label="Color"
              placeholder="Workspace Color"
              labelPlacement='top'
              withStar={true}
              error={vm.errors.get('color')}

              // helpText="This name will be used in APIs or Integrations"
              //helpTextPlacement='bottom'
              onColorChange={(m, val) => {
                onValueChange('color', val);
              }}
            />
          </div>

          <div style={{ padding: '1rem', paddingLeft: 0 }}>
            <div style={{ display: 'flex', gap: '.7rem', alignItems: 'center' }}>
              <MT.Badge color={newWorkspace.color || 'gray'}
                style={{ width: MT.rem(40), height: MT.rem(40), fontSize: MT.rem(12), padding: '5px' }}
                circle size='xl'
                variant="filled"
                title={newWorkspace.tag || 'CRM'}
              >
                {newWorkspace.tag || 'CRM'}
              </MT.Badge>
              {newWorkspace?.name || 'Workspace Name'}
            </div>
          </div>
          {props.state.availableConnections?.length > 1 &&
            <LY.LY_Select
              name="configId"
              value={newWorkspace.configId}

              label="Connection"
              placeholder="Select Connection"
              //selectOptionValue={App.ListColumnTypeEnum.Other}
              data={props.state.availableConnections}
              //value={defaultTypeValue?.configId}
              labelProperty="connectionName"
              valueProperty="configId"
              withStar={false}
              error={vm.errors.get('configId')}
              // helpText="This name will be used in APIs or Integrations"
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
            />
          }




        </div>


        <MT.Group mt="md" justify='right'>
          <MT.Button variant='default' onClick={() => onOpenChange(false)} >Cancel</MT.Button>
          <MT.Button  type='submit'
            loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}
            
          >
            Create Workspace
          </MT.Button>
        </MT.Group>

      </form>
    );

    return form;

  }



  function resetAll() {
    console.log('NewWorkspaceScreen resetAll');


    vm.errors.clear();
    vm.isActionInProgress = false;
    vm.isNewWorkspaceScreenOpen = false;

    setNewWorkspace(new Api.Workspace());

    //console.log('NewWorkspaceScreen resetAll:', newWorkspace);
    //console.log('NewWorkspaceScreen resetAll ServerError:',serverError);

  }

  function onOpenChange(open: boolean) {
    console.log('NewWorkspaceScreen onOpenChange');


    vm.isNewWorkspaceScreenOpen = open;

    if (!open)
      resetAll();

    //setOpen(open);
    forceUpdate();
  }


  function render() {

    console.log('NewWorkspaceScreen render vm.isNewWorkspaceScreenOpen', vm.isNewWorkspaceScreenOpen);

    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    // var vm = state.vm;
    // var column = props.state.newWorkspace


    if (LIB.Common.isNullOrEmpty(state))// || column == undefined)
      return null;



    return (
      <>
        <Modal
          id='NewWorkspaceScreenModal'
          opened={vm.isNewWorkspaceScreenOpen}
          onClose={() => onOpenChange(false)}
          title="New Workspace"
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}

          className={'NewWorkspaceScreenFormModal'}
          closeOnClickOutside={false}


        >



          {getForm()}




        </Modal>

      </>
    );
  }

  return render();
};

