import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import { Table, Card, CardContent, Paper, Container, Box, Backdrop, CircularProgress, InputAdornment, FormControl, FormHelperText } from '@mui/material';

import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationUsersCreateModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationUsersCreateModal extends React.Component<AdministrationUsersCreateModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationUsersCreateModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationUsersCreateModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onCloseClick() {

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateUserFormSm;

    //reset edits
    // App.AdministrationManager.clearUserCreateForm(sm);

    sm.firstName = '';
    sm.lastName = '';
    sm.companyName = '';
    sm.phone = '';
    sm.email = '';
    sm.password = '';


    console.log("closeclick sm", state.adminCreateUserFormSm);

    vm.isUsersAddOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();

  }

  public onAutocompleteChange(name: string, value: any, selectedTypes: any, valueProperty: any = '') {

    // console.log('onAutocompleteChange name', name);
    // console.log('onAutocompleteChange value', value);
    // console.log('onAutocompleteChange selectedTypes', selectedTypes);

    var state = this.props.state;
    var vm = state.vm;

    var sm = state.adminUserSm;

    var item = vm.selectedUserCreate;


    var key = LIB.FormHelper.setAutocompleteChangeForIdAndType(name, item, selectedTypes, valueProperty);

    console.log('onAutocompleteChange setAutocompleteChangeForIdAndType key', key);
    console.log('onAutocompleteChange selectedItem', item);

    this.validationManager.validateAdministrationUserCreate(key, item);

    this.forceUpdate();
  }

  public onInputChange(e: React.ChangeEvent<any>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = state.adminCreateUserFormSm;

    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateRegister(key, item);

    this.forceUpdate();
  }

  public onRegisterClick(e: React.ChangeEvent<{}>) {
    console.log('onRegisterClick');

    var state = this.props.state;
    var vm = state.vm;
    var sm = state.adminCreateUserFormSm;

    var isValid = this.validationManager.validateAdministrationUserCreate(null, sm);

    if (!isValid) {
      console.log('validateRegister NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    //call api
    App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_USERS_ADD_REQUEST, sm);

    vm.isActionInProgress = true;

    this.forceUpdate();

  }

  public getForm() {
    console.log('AdministrationUsersCreateModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    //var item = vm.selectedUser;
    var sm = state.adminCreateUserFormSm;
    console.log(sm);



    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      width: '100%',
      // height: '70px',//set a fixed height so error helpertext does not trigger auto resize
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 5,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };


    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={state.hasUserDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      //  errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form >
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}<div style={{ padding: 8 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >

                <TextField
                  name="firstName"
                  label="First Name"
                  variant="standard"
                  value={sm.firstName || ''}
                  {...App.AppBase.getInputErrorProps('firstName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />

              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="lastName"
                  label="Last Name"
                  variant="standard"
                  value={sm.lastName || ''}
                  {...App.AppBase.getInputErrorProps('lastName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>

            </Grid>




            <Grid container spacing={2} >
              <Grid item xs={12} sm={6}>

                <TextField
                  name="companyName"
                  label="Company Name"
                  variant="standard"
                  value={sm.companyName || ''}
                  {...App.AppBase.getInputErrorProps('companyName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />

              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="phone"
                  label="Phone"
                  variant="standard"
                  value={sm.phone || ''}
                  {...App.AppBase.getInputErrorProps('phone')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>

            </Grid>



            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  variant="standard"
                  value={sm.email || ''}
                  {...App.AppBase.getInputErrorProps("email")}
                  onChange={(e: any) => this.onInputChange(e)}
                  ///style={fullWidth}
                  fullWidth
                  style={formControl}
                  inputProps={{ style: formInput }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="password"
                  label="Password"
                  type='password'
                  variant="standard"
                  value={sm.password || ''}
                  {...App.AppBase.getInputErrorProps("password")}
                  onChange={(e: any) => this.onInputChange(e)}
                  ///style={fullWidth}
                  fullWidth
                  style={formControl}
                  inputProps={{ style: formInput }}
                />
              </Grid>


              <LIB.MCheckbox {...App.AppBase.getInputErrorProps('emailConfirmed')}
                formControlLabel={<FormControlLabel
                  control={
                    <Checkbox
                      name="emailConfirmed"
                      color="primary"

                      checked={sm.emailConfirmed || false}
                      onChange={(e: any) => this.onInputChange(e)}
                      //style={fullWidth}
                      inputProps={{ style: formInput }}

                    />
                  }
                  style={{ marginLeft: 0 }}
                  labelPlacement="end"
                  label={<span style={{ fontSize: '16px' }}>
                    Confirm Email
                  </span>}
                />}
              >

              </LIB.MCheckbox>




            </Grid >

            <div style={{ display: 'flex', gap:10 }}>



              <LY.LY_Input
                name="databaseName"
                type="text"
                label="Custom Database Name"
                value={sm.databaseName || ''}
               // {...App.AppBase.getInputErrorProps('databaseName')}
                onChange={(m,e)=>this.onInputChange(e!)}
 
              />

              <LY.LY_Select
                name="connectionPoolId"
                label="Connection Pool"
                placeholder="Select Connection Pool"
                //selectOptionValue={App.ListColumnTypeEnum.Other}
                data={state.connectionPools}
                value={sm.connectionPoolId}
                labelProperty="name"
                valueProperty="connectionPoolId"
                withStar={false}
                error={vm.errors.get('configId')}
                // helpText="This name will be used in APIs or Integrations"
                // helpTextPlacement='bottom'
                onChange={(m, e) => {
                  this.onInputChange(e!);
                }}
              />

            </div>

          </div>
        </form>

      }

    </App.MainSplitPage>;


  }


  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    console.log('AdministrationUsersCreateModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isUsersAddOpen;
    var sm = state.adminCreateUserFormSm;

    if (!isOpen)
      return;

    var title = "Create User";


    var useTestData = LIB.UrlHelper.getQueryString("useTestData");

    if (useTestData == "1" && LIB.Common.isNullOrEmpty(sm.firstName)) {
      console.log('Register useTestData');
      sm = App.TestDataManager.setTestRegisterData(sm);
      // console.log('Register useTestData sm', sm);
      this.forceUpdate();
    }


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"

        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ minWidth: 800, width: '100%' }} >

            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <MT.Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</MT.Button>

            <MT.Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onRegisterClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Add</MT.Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 