import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';
//import { withRouter } from "react-router-dom";
import { IconButton, Dialog , Button, DialogActions, Divider,DialogContent ,DialogContentText ,DialogTitle  } from '@mui/material';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Alert from '@mui/material/Alert';
import { CSSProperties } from "react";


import { createStyles,  Theme } from '@mui/material/styles';

 

 
interface ListSettingsModalProps {
  vm: App.WorkspaceVm;
 }

export class ListSettingsModal extends React.Component<ListSettingsModalProps, {}> {

  public adminDataService: App.AdminDataService;
  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.adminDataService = new App.AdminDataService();
    this.validationManager = new App.ValidationManager();
 
  }

  

    // This method is called when the route parameters change
    public componentDidUpdate() {

      //console.log('ListSettingsModal componentDidUpdate');
    
    }
  

  // This method is called when the route parameters change
  public componentDidMount() {
    //console.log('ListSettingsModal componentDidMount');
    //var vm = this.props.globalUIState;
 
    //var adminUserState = this.props;
    //var globalUIState = this.props.globalUIState;

   // var currentUser = App.AppBase.currentUser;
    
  }

    
  public onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');
    
    var vm = this.props.vm;
    var item = vm.selectedItem;


/* 
    var isValid = this.validationManager.validateImpersonateUser(null, vm);

    if (!isValid) {
      console.log('validateImpersonateUser NOT valid');
      this.forceUpdate();
      return;
    } 
    vm.isActionInProgress = true;

    this.adminDataService.impersonateUser(vm.selectedUserImpersonator.email);
 
     */

    App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST, vm);
 
  
  }
  


  public onCloseClick() {
 
   var vm =  this.props.vm;
   vm.isListSettingsOpen=false;
   //vm.selectedItem = LIB.ObjectHelper.clone(vm.selectedItemOriginal);
   LIB.ObjectHelper.setData(vm.selectedItemOriginal,vm.selectedItem);
   this.forceUpdate();
       
}

/* public onAutocompleteChange(name: string, value: any, item: any) {
  
  var selected = {
    name: name,
    value: value
  }

  var vm = this.props.vm;
  if(name=="impersonateUser"){
      vm.selectedUserImpersonator=item;
      this.validationManager.validateImpersonateUser(name, vm);
  }
  else if(name=="userRoleType"){
    vm.selectedUserRoleType=item;
    this.filterUsers();
  }
   
  console.log('onAutocompleteChange:',selected );

  this.forceUpdate();
} */
/* 
  public filterUsers(){
    var vm = this.props.vm;

    var appUsers =  this.props.appUsers;
    vm.filteredImpersonateUserList = appUsers;
    if(LIB.Common.isNullOrEmpty(vm.selectedUserRoleType))
    return;

    vm.filteredImpersonateUserList = appUsers.filter((c)=> !LIB.Common.isNullOrEmpty(c.fullName) && 
    c.userRoleIds.indexOf(vm.selectedUserRoleType.userRoleTypeId)>-1);
   

  } */

  
  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = this.props.vm.selectedItem;
    var target: any = e.target;

    
    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

   // this.validationManager.validateAccountProfileEdit(key, item);

    console.log('onInputChange item', item);

    this.forceUpdate();
  }


  public getForm(){
    console.log('ListSettingsModal getForm');
    var state = this.props;

     var vm = this.props.vm;
 
    var item = vm.selectedItem;

     
    var textFieldStyle = {paddingTop: 20,marginBottom:10};

    
  var fullWidth: CSSProperties = {
    // paddingRight: 20,
    paddingLeft: 0,
    //  marginBottom: 10,
    //  padding: 10,
    width: '100%'
  };
  var formInput: CSSProperties = {
    height: 36,
    //  marginTop: 10,
    //paddingTop: 20,
    padding: 10,
  };

    return <div >
      <form  style={{ maxWidth: 700, display:'flex', gap:'1rem', flexDirection:'column', minWidth:300 }}   >


      <div style={{marginTop:10}}>
      <UI.TextField
                  name="name"
                  label="Name:"
                  variant="outlined"
                  value={item?.name || ''}
                  {...App.AppBase.getInputErrorProps('name')}
                  onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}
                disabled={item?.isNameLocked}

       />
      </div>

      <div style={{marginTop:10}}>
      <UI.TextField
                  name="sortOrder"
                  label="Sort Order:"
                  type='number'
                  variant="outlined"
                  value={item?.sortOrder || ''}
                  {...App.AppBase.getInputErrorProps('sortOrder')}
                  onChange={this.onInputChange.bind(this)}
                style={fullWidth}
                inputProps={{ style: formInput }}

       />
      </div>

      <DialogContentText id="alert-dialog-description">
             Type: {vm.selectedItem?.itemTypeName}
       </DialogContentText>
       <DialogContentText id="alert-dialog-description">
              View ID: {vm.selectedItem?.defaultViewId}
       </DialogContentText>

       

      
        <div >
{/* 
          <App.FormAutocomplete label="Filter Users By A Role" name="userRoleType" data={state.userRoleTypes}
             onChange={this.onAutocompleteChange.bind(this)}
             labelProperty="name"
             valueProperty="userRoleTypeId"
             textFieldStyle={textFieldStyle}
          /> */}

        </div>

        

        </form>


 
</div>;


  }

   

 
  public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  }

  public render() {
    //console.log('ListSettingsModal render');

    var vm = this.props.vm;
    var isOpen = vm.isListSettingsOpen;
    var state = this.props;

    if(!isOpen)
        return null;

   
      return (
         /*  <App.MainSplitPage
      pageTitle="List Settings"
      state={state} 
     // hasDataLoaded={state.hasJobsDataLoaded}
      loading={this.adminDataService.isServiceCallPending}// && !vm.isActionInProgress)}
     // errorMessage={state.apiErrorMessage}
      showModalForLoading={true}
      showContentWhenInProgress={true}
      isChildPage={true}

    > */
          <Dialog
            open={isOpen}
            onClose={()=>this.onCloseClick()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{(vm.selectedItem as any)?.name +" - Settings"}</DialogTitle>
            <DialogContent>

            {this.getAlert(vm.apiErrorMessage)}
 
            {this.getForm()}

            </DialogContent>
            <DialogActions>
           
            <MT.Button 
            variant="contained"
            //color="default"
            style={{ margin: 10 }}
            onClick={this.onCloseClick.bind(this)}
          >Cancel</MT.Button>
  
        <MT.Button 
            color="primary"
            variant="contained"
            style={{ margin: 10 }}
            onClick={this.onSaveClick.bind(this)}
          >Save</MT.Button>


            
            </DialogActions>
          </Dialog>
       /*    </App.MainSplitPage> */

      );
     


  }
}

