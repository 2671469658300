import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';


//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import { Alert } from '@mui/material';

 

class ConfirmEmail extends React.Component<App.AccountState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('ConfirmEmail componentDidMount');

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('ConfirmEmail componentDidUpdate');
  }

  
  public onResendInviteClick(e: React.ChangeEvent<{}>) {
    console.log('onResendInviteClick');

    var state = this.props;
      
    //call api
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST);

   // this.forceUpdate();

  }

  public getSuccessMsg(){

    var state=this.props;
    var vm = state.vm;

    if(!vm.hasResentConfirmEmail)
        return null;

   var appInfo = App.AppBase.appInfo;

   App.AppBase.showMessage('Successfully Sent!');

    return <div style={{paddingLeft:'8rem', paddingRight:'8rem', margin:'3rem'}}>
    
    <Alert severity="success">Successfully Sent! You should get an email with subject "Confirm your {appInfo.appName} Account" 
    from <strong>{appInfo.supportEmail}</strong>. Please Check your spam folder if you don't see it.</Alert>
     </div>
  }

  public render() {

    console.log("ConfirmEmail Render");
    var state=this.props;
    var vm = state.vm;
    var currentUser = App.AppBase.currentUser;

    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      hasNoPageDesign={true}
      mainPageContainerClassName='MainPageContentTop'
    >
      
       <App.QuickMessageContainer
      icon={<Icons.IconMail style={{ fontSize:'7rem' }} color='primary' fontSize='large' />}
      message='Confirm Email'
    >
      <div style={{ margin:20 }}>{currentUser?.email}</div>

      <div>Confirm your email by clicking the verification link we just sent to your inbox.</div>

      {this.getSuccessMsg()}

      <div style={{ marginTop: 30, textAlign: 'center' }}>
        <MT.Button variant="contained" color="primary"
          onClick={(e) => this.onResendInviteClick(e)}
        >Resend Invite</MT.Button>
      </div>

    </App.QuickMessageContainer>;


    </App.MainSplitPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.account })
)(ConfirmEmail as any);
