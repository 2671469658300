import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import { connect } from 'react-redux';



import { MDXProvider } from '@mdx-js/react'
import MDX from '@mdx-js/runtime';


const TermsOfService: React.FC<App.GlobalState> = (props) => {

  function getPageContent() {

    return `
 
Last updated July 17, 2024 (BETA Version)

<br />
This website and application is created and operated by Loocey, Inc.

<br />
Loocey, Inc., including Loocey, Inc.'s subsidiaries,
affiliates, divisions, contractors and all data sources and suppliers, (collectively "Loocey", "we", "us" or "our")
welcomes you to www.Loocey.com (the "Website") and app.Loocey.com (the "Application") . These terms and conditions of service (collectively, with Loocey's Privacy Policy,
located at /PrivacyPolicy page the "Terms of Service" or "Agreement") govern your use of the Website, Application and the services,
features, content or applications operated by Loocey (together with the Website, Application, and the "Services"), and provided to the Subscriber
(the "Subscriber", "user", "sub-user", "you" or "your"). 
 
Please read these Terms of Service carefully before using the Services. These Terms of Service apply to all users of the Services.
If you are using the Services on behalf of an entity, organization, or company, you represent and warrant that you have the authority to
bind such organization to these Terms of Service and you agree to be bound by these Terms of Service on behalf of such organization. 
 
If you do not agree with any of the terms and conditions of this agreement, then you may not access the website, use any of our services
and purchase any of our products/services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. 
 
Agreeing to use the Services by clicking "Sign up" constitutes your acceptance and agreement to be bound by these Terms of Service,
and all other operating rules, policies and procedures that may be published from time to time on the Website by us,
each of which is incorporated by reference and each of which may be modified from time to time without notice to you.
You acknowledge receipt of our Privacy Policy. If you ordered the Services on our Website, use the our Website, or otherwise
engage in any electronic transaction with respect to the Services, then you agree to receive any updates to our Privacy Policy
by accessing our Website. By using our Website or purchasing our products or services, you agree that we may use and
share your personal information in accordance with the terms of our Privacy Policy. 
 
Loocey reserves the right, at any time and from time to time, to amend or to modify these Terms of Service without prior notice to you.
Amendments and modifications shall take effect immediately when posted on the Website. By continuing to access or use the Services after any
such amendments or modifications, you agree to be bound by such amended or modified Terms of Service. For this reason, we encourage you to review
the Terms of Service whenever you use the Services. If you do not agree to any change to these Terms of Services, then you must immediately stop
using the Services and the Website.
<br />
SOME JURISDICTIONS HAVE CONSUMER PROTECTION AND OTHER LEGISLATION WHICH MAY APPLY TO THE SERVICES AND WHICH DO NOT ALLOW
CERTAIN PROVISIONS SUCH AS LIMITATIONS OF LIABILITY AND EXCLUSION OF CERTAIN WARRANTIES, AMONG OTHERS. TO THE EXTENT THAT A
 LIMITATION, EXCLUSION, RESTRICTION OR OTHER PROVISION SET OUT BELOW IS SPECIFICALLY PROHIBITED BY APPLICABLE LAW, SUCH LIMITATION,
EXCLUSION, RESTRICTION OR PROVISION MAY NOT APPLY TO YOU.\
<br />
<br />
### 1. Registration & Eligibility
1.1 The Services are not targeted towards, nor intended for use by, anyone under the age of 13. By using our Services,
you represent and warrant that you are 13 years of age or older. If you are under the age of 13, you may not, under any
 circumstances or for any reason, use our Services.\
1.2 We may, in our sole discretion, refuse to offer the Services to any person or entity and change its eligibility criteria at
any time, for any reason. You are solely responsible for ensuring that these Terms of Service are in
compliance with all laws, rules and regulations applicable to you and the right to access the Services
is revoked where these Terms of Service or use of the Services is prohibited or to the extent offering,
sale or provision of the Services conflicts with any applicable law, rule or regulation. Further, the Services are offered
 only for your use, and not for the use or benefit of any third party.\
<br />
<br />
### 2. Description of Service
2.1 Loocey is a cloud-based server and computer management platform. Loocey may provision, configure,
manage servers on user's behalf from supported 3rd party providers. However, users are still responsible for
maintaining their account status with such providers without violating neither our nor provider's terms of service agreements.
2.2 Loocey does NOT provide a “Managed Server” services at this time. This means, you are still solely responsible for managing
all aspects of the servers.\
<br />
<br />
### 3. Server Security and Maintenance
3.1 User is solely responsible for maintaining the security of User's servers that are created or managed by Loocey. User is also
responsible for monitoring, patching and maintaining user's servers. Loocey shall have no liability for any security or data breaches
 of User's servers, even if those servers are managed by Loocey. Loocey does not guarantee the security of any of User's servers,
even if those servers were initially created and provisioned by Loocey or managed by Loocey. We will NEVER access your server without
your explicit request for us to do so. If your server needs manual intervention from us, you must request our help and give us your permission
to access your server before we will be able to assist you.
<br />
<br />
### 4. Backups
Loocey does daily backup of its databases. However, Loocey is currently in **BETA** and does 
not guarantee the accuracy or reliability of the backups nor any data on Loocey's website or application. Any **Delete** action performed in the 
application will permanently delete the data and is solely responsible of the Subscriber. 
EVEN WITH RESPECT TO DATA AS TO WHICH SUBSCRIBER CONTRACTS FOR SERVICES PROVIDED BY Loocey, 
TO THE EXTENT PERMITTED BY APPLICABLE LAW, Loocey SHALL HAVE NO RESPONSIBILITY TO PRESERVE DATA. Loocey SHALL HAVE NO LIABILITY FOR ANY DATA THAT MAY BE LOST, OR UNRECOVERABLE,
 BY REASON OF SUBSCRIBER'S FAILURE TO BACKUP ITS DATA OR FOR ANY OTHER REASON.
<br />
<br />
### 5. Content
5.1 For purposes of these Terms of Service, the term "Content" includes, without limitation, information, data, text, written posts,
 reviews, and comments, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or
through the Services. For the purposes of this Agreement, "Content" also includes all User Content (as defined below).
<br />
5.2 All Content added, created, uploaded, submitted, distributed, or posted to the Services by users (collectively "User Content"),
whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such User Content. You represent
that all User Content provided by you is accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.
You acknowledge that all Content, including User Content, accessed by you using the Services is at your own risk and you will be solely responsible for
any damage or loss to you or any other party resulting therefrom. We do not guarantee that any Content you access on or through the Services is or will
continue to be accurate.
<br />
5.3 The Services may contain Content specifically provided by us, our partners or our users and such Content is protected by copyrights, trademarks, service
 marks, patents, trade secrets or other proprietary rights and laws. You shall abide by and maintain all copyright notices, information, and
 restrictions contained in any Content accessed through the Services.
 <br />
5.4 Subject to these Terms of Service, we grant each user of the Services a worldwide, non-exclusive, revocable, non-sublicensable
and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction,
modification, distribution or storage of any Content for other than purposes of using the Services is expressly prohibited without prior written
 permission from us. You shall not sell, license, rent, or otherwise use or exploit any Content for commercial use or in any way that violates
any third party right.
<br />
5.5 By submitting any User Content to the Website, excluding privately transmitted User Content, you hereby do and
shall grant us a worldwide, non-exclusive, perpetual, irrevocable, royalty-free, fully paid, sublicensable and transferable license to use,
aggregate, reproduce, distribute, prepare derivative works of, display, perform, and otherwise fully exploit such User Content in connection with
the Website, the Services and our (and our successors' and assigns') businesses, including without limitation for promoting and redistributing part
or all of the Website or the Services (and derivative works thereof) in any media formats and through any media channels (including, without limitation,
 third party websites and feeds), and including after your termination of your Account or the Services. You also hereby do and shall grant each user of
 the Website and/or the Services a non-exclusive, perpetual license to access any of your User Content that is available to such user on the Website,
and to use, reproduce, distribute, prepare derivative works of, display and perform such User Content, including after your termination of your Account
 or the Services. By submitting any User Content to the Services other than on the Website, you hereby do and shall grant us a worldwide, non-exclusive,
perpetual, irrevocable, royalty-free, fully paid, sublicensable and transferable license to use, aggregate, reproduce, distribute, prepare derivative
works of, display, and perform such User Content solely for the purpose of providing the Services. For clarity, the foregoing licenses granted to us and
our users does not affect your other ownership or license rights in your User Content, including the right to grant additional licenses to your User
Content, unless otherwise agreed in writing. You represent and warrant that you have all rights to grant such licenses to us without infringement or
violation of any third-party rights, including without limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or
any other intellectual property or proprietary rights.
<br />
<br />
### 6. Rules of Conduct
6.1 As a condition of use, you promise not to use the Services for any purpose that is prohibited by these Terms of Service. You are responsible for all of your activity in connection with the Services and the activity of any sub-user that uses your access code or Account.

6.2 You shall not: (i) take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or disproportionately large load on our (or our third party providers') infrastructure; (ii) interfere or attempt to interfere with the proper working of the Services or any activities conducted on the Services; (iii) bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or restrict access to the Services (or other accounts, computer systems or networks connected to the Services); (iv) run any form of auto-responder or "spam" on the Services; (v) use manual or automated software, devices, or other processes to "crawl" or "spider" any page of the Website; (vi) harvest or scrape any Content from the Services; (vii) use the Services for high risk activities including but not limited to the operation of nuclear facilities, air traffic control, life support systems, or any other use where the failure of service could lead to death, personal injury, or environmental damage; or (viii) otherwise take any action in violation of our guidelines and policies.

6.3 You shall not (directly or indirectly): (i) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Services (including without limitation any application), except to the limited extent applicable laws specifically prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of any part of the Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder. You shall abide by all applicable local, state, national and international laws and regulations.

6.4 We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request, (ii) enforce these Terms of Service, including investigation of potential violations hereof, (iii) detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support requests, or (v) protect the rights, property or safety of us, our users and the public.

6.5 Subscribers are restricted from registering multiple Accounts with the same billing details without first notifying Loocey of that intent. Otherwise, Loocey shall have the right to automatically flag such Accounts as fraudulent or abusive, and Loocey may, without notification to the Subscriber of such Account, suspend the service of such Account or any other Account used by such Subscriber. The use of referral codes by multiple Accounts having the same billing profile is not allowed. Loocey also reserves the right to terminate a Subscriber's Account if it is targeted by malicious activity from other parties.
<br />
<br />
### 7. Modification, Termination and Discontinuation
You will lose your license to use the Service if you violate any provision of this Agreement. Additionally, we may at our sole discretion terminate your account or suspend or terminate your access to the Service at any time, with or without notice for any reason or no reason at all. All fees owed to Loocey and charges accrued before such termination shall be immediately due and payable. We reserve the right to modify or discontinue the Service at any time (including, without limitation, by limiting or discontinuing certain features of the Service) without notice to you. We will have no liability whatsoever on account of any change to the Service or any suspension or termination of your access to or use of the Service. You may terminate your account at any time through the Service's Website.
<br />
<br />
### 8. Ownership and Rights
The visual interfaces, graphics, design, compilation, information, data, computer code (including source code or object code), products, software, services, and all other elements of the Service (the "Materials") provided by Loocey are protected by all relevant intellectual property and proprietary rights and applicable laws. All Materials contained in the Service are the property of Loocey or our third-party licensors. Except as expressly authorized by Loocey you may not make use of the Materials. Loocey reserves all rights to the Materials not granted expressly in this Agreement.
<br />
<br />
### 9. Indemnification
You agree that you will be responsible for your use of the Service, and you agree to defend, indemnify, and hold harmless
Loocey and its officers, directors, employees, consultants, contractors, Affiliates, subsidiaries and agents
(collectively, the " Loocey Entities") from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys'
fees and costs, arising out of or in any way connected with (i) your access to, use of, or alleged use of the Service; (ii) your violation of this Agreement
or any representation, warranty, or agreements referenced herein, or any applicable law or regulation; (iii) your violation of any third-party right, including
without limitation any intellectual property right, publicity, confidentiality, property or privacy right; or (iv) any disputes or issues between you and any third
party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you
(and without limiting your indemnification obligations with respect to such matter), and in such case, you agree to cooperate with our defense of such claim.
<br />
<br />


### 10. BETA Services / BETA Version Application
From time to time, we may make early stage or non-production Services ("Beta Services" or “BETA Version Application”) 
available at discounted rates. Beta Services are intended for evaluation purposes only, and may be subject to additional 
terms that will be presented to you at the time of sign-up. Loocey may, but is not obligated to, provide you with support 
for the Beta Services or correct any bugs, defects, or errors in the Beta Services. 
Unless otherwise stated, any Beta Services' trial period will expire upon notice. We may discontinue Beta Services/BETA Version at any time in our sole discretion and may never make them generally available. We will have no liability for any harm or damage arising out of or in connection with any Beta Services or BETA Version Application.
<br />
**Loocey website and Loocey Application is currently in BETA Version.**



<br />
<br />
### 11. Sub-Users
Subscribers are responsible for the acts of their sub-users, utilizing their access to the Services, and will be held
responsible for violations of the Services by their sub-users or persons who gain access to the Services using the Subscriber's
access codes. Any activity that a Subscriber is prohibited from performing by these Terms of Services is equally prohibited to anyone
using the access to the Services of the Subscriber.
<br />
<br />
### 12. Promotional Credit
Only one promotional code is permitted per customer and may be redeemed only within 30 days of creating your account. Promotional credit will expire after 12 consecutive months unless otherwise defined in the terms of the promotion. Credit earned on sign-up via a referral link or code is considered promotional credit.
<br />
<br />
### 13. Payments and Billing
#### 13.1 Payment Processors
We use third-party payment processors (the "Payment Processors"). The processing of payments may be subject to the terms, conditions, and privacy
policies of the Payment Processors in addition to this Agreement. We are not responsible for errors by the Payment Processors. The processing of
payments may be subject to the terms, conditions and privacy policies of the Payment Processors in addition to this Agreement. We are not responsible
for error by the Payment Processors. By choosing to use paid Services, you agree to pay us, through the Payment Processors, all charges at the prices
then in effect for any use of such paid Services in accordance with the applicable payment terms and you authorize us, through the Payment Processors,
to charge your chosen payment provider (your "Payment Method"). You agree to make payment using that selected Payment Method. We reserve the right to
 correct any errors or mistakes that it makes even if it has already requested or received payment
#### 13.2 Price Changes
We reserve the right to change the subscription fees that we charge for the Service, at any time in our sole discretion, provided that we give you at
 least thirty (30) days' prior notice of such changes. Unless otherwise specified in such notice to you, any changes to the subscription fees will take effect in the billing period immediately following our notice to you.
#### 13.3 Billing and Terms
The term of this Agreement shall be monthly, to commence on the date that the Subscriber signs up electronically for the Services by creating an Account with an email address. All invoices are denominated, and Subscriber must pay, in U.S. Dollars. Subscribers are typically billed monthly on or about the first day of each month, with payment due immediately via payment method assigned to the account. We will attempt to process all payments on or about the first day of each month.   You may cancel the Services at any time by logging into your Control Panel and removing the servers or other services utilized by your account.  Any Annual Subscription commitments you have agreed to are nonrefundable even if canceled sooner. If you cancel, you agree that fees occurred during up until cancelation day and time and any start-up costs associated with setting up your Account ("Start-up Costs") shall be nonrefundable, as permitted by law.
#### 13.4 Payment Information
YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY US OR OUR PAYMENT PROCESSORS IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT SETTINGS PAGE OF YOUR ACCOUNT. IF YOU FAIL TO PROVIDE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT WE MAY CONTINUE CHARGING YOU FOR ANY USE OF PAID SERVICES UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR PAID SERVICES AS SET FORTH ABOVE.

<br />
#### 13.5 Taxes
Subscriber is responsible for any duties, customs fees, taxes, and related penalties, fines, audits, interest and back-payments relating to Subscriber's purchase of the Services, including but not limited to national, state or local sales taxes, use taxes, value-added taxes (VAT) and goods and services taxes (GST) (collectively, "Taxes"). Loocey's standard pricing policies do not include and are not discounted or enhanced for any such Taxes. If Loocey becomes obligated to collect or pay Taxes in connection with Subscriber's purchase of the Services, those Taxes will be invoiced to that Subscriber as part of a billing process or collected at the time of purchase. In certain states, countries and territories, Loocey may determine if Subscriber's purchase of Services is subject to certain Taxes, and if so, may collect such Taxes and remit them to the appropriate taxing authority.
<br />
#### 13.6 Suspension and Termination for Nonpayment
If a Subscriber is past due on their balance, Loocey may send up to three (3) email notifications within a fifteen (15) day period before suspending the Subscriber's account. All Servers will be temporarily in-activated and disconnected from the platform. The Loocey server agent will be automatically uninstalled. When payment is received and service is resumed, you may be required to re-install agents on all of your servers.    Loocey reserves the right to delete the Subscriber's suspended machines and any data associated with them after the final termination notice.
<br />
<br />
### 14 No Class Actions
YOU AND Loocey AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Loocey agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
<br />
<br />
### 15. Limitation of Liability
IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF FEES PAID TO US FOR THE PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS ONE MONTH PERIOD, EVEN IF Loocey HAD BEEN ADVISED OF, KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY THEREOF. SUBSCRIBER ACKNOWLEDGES THAT THE FEES PAID BY HIM OR HER REFLECT THE ALLOCATION OF RISK SET FORTH IN THIS AGREEMENT AND THAT Loocey WOULD NOT ENTER INTO THIS AGREEMENT WITHOUT THESE LIMITATIONS. SUBSCRIBER HEREBY WAIVES ANY AND ALL CLAIMS AGAINST Loocey ARISING OUT OF SUBSCRIBER'S PURCHASE OR USE OF THE SERVICES, OR ANY CONDUCT OF Loocey'S DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR REPRESENTATIVES. YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SERVICES OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO OR USE OF THE SERVICES.
<br />
<br />
IN ADDITION, YOU AGREE THAT Loocey IS NOT RESPONSIBLE FOR ANY DATA COMPILED BY OUR SERVICES AND THAT Loocey WILL NOT BE LIABLE, IN ANY MANNER, AS A RESULT OF YOUR EXPOSURE TO ANY DEFAMATORY, LIBELOUS, THREATENING, UNLAWFULLY HARASSING, OBSCENE OR OTHERWISE UNLAWFUL CONTENT OR DATA. IN NO EVENT SHALL Loocey, OR ANY THIRD PARTY PROVIDER OF ANY COMPONENT OF THE SERVICES OR OF ANY INFORMATION DELIVERED AS PART OF THE SERVICES, BE LIABLE TO YOU AND/OR ANY PARTY FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, SPECIAL, EXEMPLARY, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES ARISING OUT OF OR RELATED TO THE SERVICES, CONTENT, PRODUCTS, THE USE OR INABILITY TO USE THIS WEBSITE, OR ANY LINKED WEBSITE, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOSS OF USE, BUSINESS INTERRUPTION, OR OTHER ECONOMIC LOSSES, LOSS OF PROGRAMS OR OTHER DATA, WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR OTHER TORTIOUS ACTION, EVEN IF Loocey IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING LIABILITY ASSOCIATED WITH ANY VIRUSES WHICH MAY INFECT YOUR COMPUTER EQUIPMENT.
<br />
<br />
SOME JURISDICTIONS LIMIT OR PROHIBIT THE FOREGOING LIMITATIONS, AND IN SUCH JURISDICTIONS THE FOREGOING LIMITATIONS SHALL BE APPLIED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
<br />
### 16. No Warranty
Loocey PROVIDES THE SERVICE "AS IS", "WITH ALL FAULTS" AND "AS AVAILABLE". TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Loocey MAKES NO (AND SPECIFICALLY DISCLAIMS ALL) REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE OR FREE OF HARMFUL COMPONENTS, THAT THE CONTENT WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED, OR ANY IMPLIED WARRANTY OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND ANY WARRANTY ARISING OUT OF ANY COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE OF TRADE. SOME JURISDICTIONS DO NOT ALLOW THE FOREGOING EXCLUSIONS. IN SUCH AN EVENT SUCH EXCLUSION WILL NOT APPLY SOLELY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
<br />
### 17. Third-Party Sites
Our website may include links to other sites on the Internet that are owned and operated by online merchants and other third parties.  You acknowledge that we are not responsible for the availability of, or the content located on or through, any third-party site.  You should contact the site administrator or webmaster for those third-party sites if you have any concerns regarding such links or the content located on such sites.  Your use of those third-party sites is subject to the terms of use and privacy policies of each site, and we are not responsible therein. We encourage all Members to review said privacy policies of third-parties' sites.
<br />
### 18. Trademarks
All other trademarks displayed on Loocey are the trademarks of their respective owners, and constitute neither an endorsement nor a recommendation of those Vendors.  In addition, such use of trademarks or links to the web sites of Vendors is not intended to imply, directly or indirectly, that those Vendors endorse or have any affiliation with Loocey.
<br />
### 19. Early Stage Application 
Loocey is currently an early-stage application and is available in its BETA version. 
Despite our comprehensive testing efforts and the Beta launch, it is important to note that there may still be bugs, 
security vulnerabilities, or other issues present. Users should be aware that Loocey and its owners cannot be held 
responsible for any data loss, calculation errors, inaccuracies, or reliability issues that may arise from using the application. 
Therefore, we strongly advise against using Loocey without performing your own validations, 
calculations and data accuracy confirmations. Use Loocey at your own risk. <br />
<br />
### 20. Termination and Access
Loocey reserves the right, in our sole discretion, to terminate your access to all or any part of the Services at any time, with or without notice, effective immediately, including but not limited to as a result of your violation of any of these Terms of Service or any law, or if you misuse system resources or services.
<br />
### 21. Fair Use Policy
Loocey's Fair Use Policy is designed to ensure that all users have a positive and equitable experience while using our services. This policy sets forth the acceptable limits and behaviors for using our resources, including but not limited to bandwidth, storage, and computational capacity. Any activities that excessively burden our infrastructure, interfere with other users' experiences, or violate legal regulations are considered outside the bounds of fair use. Loocey reserves the right to monitor usage patterns and enforce this policy. In instances where a subscriber's behavior is deemed to be in violation of the Fair Use Policy, Loocey will terminate the subscriber's 
account without prior notice to protect the integrity and performance of our services for all users.
<br />
### 22. Refund Policy 

During the BETA stage of Loocey, we operate under a strict **NO Refund** policy. As our application is still in development and undergoing continuous testing, users may encounter bugs, security vulnerabilities, or other issues. By participating in the BETA stage, users acknowledge and accept these potential risks. Therefore, all purchases, subscriptions, and transactions made during this period are non-refundable. 
We appreciate your understanding and support as we work to improve Loocey and deliver a robust final product.
`;

  }


  function render() {
    console.log('TermsOfService render');

    var state = props;
    var vm = state.initialData;

    var content: any = getPageContent();

    return <App.MainSplitPage
      pageTitle="Terms Of Service"
      state={vm}
      hasDataLoaded={true}//state.hasPageDataLoaded}
      showLeftMenu={false}
      //  mainPageContainerClassName='MainSplitPageContainerForList'
      //  mainPanelsClassName='MainSplitPageMainPanelsForList'
      //showRightPanel={vm.isDetailScreenOpen}
      isFullHeightPage={true}
      isNarrowPage={true}

    >

      <MDXProvider >
        <MDX>{content}</MDX>

      </MDXProvider>
    </App.MainSplitPage>


  }

  return render();

  /*   return <MDXProvider >
    {render()}
  </MDXProvider>; */
};
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(TermsOfService as any);