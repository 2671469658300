import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import {
  IconButton, Dialog, Button, DialogActions, Divider, DialogContent, DialogContentText, Grid,
  DialogTitle, TextField, Switch, Badge, Popper, Typography, Snackbar
} from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';
import Alert from '@mui/material/Alert';
import { Checkbox, Chip, Tooltip, FormControlLabel } from '@mui/material';
import { ToggleButton } from '@mui/material';


interface AdministrationProspectsEditModalProps {
  state: App.AdministrationState;
  onChange?: Function;
}


export class AdministrationProspectsEditModal extends React.Component<AdministrationProspectsEditModalProps> {

  public validationManager: App.ValidationManager;


  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }


  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('AdministrationProspectsEditModal componentDidMount');


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('AdministrationProspectsEditModal componentDidUpdate');
    //var vm = this.props.globalUIState;



  }


  public onUpdateClick(e: React.ChangeEvent<{}>) {
    console.log('onUpdateClick');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedProspect;
    var items = state.prospects;
    console.log(items);

    //validate 
    var isValid = this.validationManager.validateProspect(null, item);

    if (!isValid) {
      console.log('validateAccountProfileEditModal NOT valid');
      this.forceUpdate();
      return;
    }

    if (vm.isProspectsEditOpen) {
      var sm = state.adminUpdateProspectSm;
      sm = vm.selectedProspect;
      console.log("item: ", item);
      console.log("sm: ", sm);

      App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_UPDATE_REQUEST, sm);

    }


    vm.isActionInProgress = true;

    this.forceUpdate();

    //re render parent to clear any api errors
    if (this.props.onChange)
      this.props.onChange();

  }

  public onCloseClick() {
    console.log("onCloseClick");
    var state = this.props.state;
    var vm = state.vm;

    //reset edits
    App.AdministrationManager.updateProspect(vm.selectedProspectOriginal, state.prospects);

    vm.isProspectsEditOpen = false;

    App.AppBase.resetErrorMessages();
    vm.apiErrorMessage = '';

    this.forceUpdate();
    // console.log("need to re render table");
    if (this.props.onChange)
      this.props.onChange();
  }



  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedProspect;
    console.log("item:", item);

    var target: any = e.target;
    console.log("target:", target.name, target.type);

    var name;

    name = LIB.FormHelper.setInputChangeData(e.target, item);


    if (LIB.Common.isNullOrEmpty(name))
      return;

    this.validationManager.validateProspect(name, item);

    this.forceUpdate();
  }

  public getForm() {
    console.log('AdministrationProspectsEditModal getForm');

    var state = this.props.state;
    var vm = state.vm;
    var item = vm.selectedProspect;

    var theme = App.AppBase.theme;

    var textFieldStyle = { paddingTop: 10, marginBottom: 0 };


    var autocompleteInput: CSSProperties = {
      // height: 50
      paddingTop: 0,
      width: '100%'
    };

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };

    var formInput: CSSProperties = {
      // height: 50
      marginTop: 10,
      paddingTop: 20
    };

    var fullWidth: CSSProperties = {
      paddingRight: 20,
      paddingLeft: 0,
      marginBottom: 10,
      width: '100%'
    };
    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20, marginTop: 10, paddingBottom: 10 }
    };

    if (item === undefined)
      return null;

    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={state.hasProspectDataLoaded}
      loading={(state.isServiceCallPending || vm.isActionInProgress)}
      errorMessage={vm.apiErrorMessage}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      isChildPage={true}
    >
      {

        <form >
          {/*   add div https://stackoverflow.com/questions/56156589/simple-material-ui-dialog-example-has-unwanted-scrollbar
 */}
          <div style={{ padding: 8 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>

                <TextField
                  name="firstName"
                  label="First Name"
                  value={item.firstName || ''}
                  {...App.AppBase.getInputErrorProps('firstName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}
                />

              </Grid>

              <Grid item xs={12} sm={6} >
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={item.lastName || ''}
                  {...App.AppBase.getInputErrorProps('lastName')}
                  onChange={this.onInputChange.bind(this)}
                  style={formControl}
                  inputProps={{ style: formInput }}

                />
              </Grid>

            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Email"
                  value={item.email || ''}
                  {...App.AppBase.getInputErrorProps("email")}
                  onChange={(e: any) => this.onInputChange(e)}
                  ///style={fullWidth}
                  fullWidth
                  style={formControl}
                  variant="standard"
                  inputProps={{ style: formInput }}
                  disabled="true"
                />
              </Grid>

              <Grid item xs={12} sm={6}>

                <Grid item xs={12} sm={6}
                >
                  <TextField
                    name="phone" label="Phone"
                    value={item.phone || ''}
                    {...App.AppBase.getInputErrorProps('phone')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    fullWidth
                    inputProps={{ style: formInput }}

                  />
                </Grid>

              </Grid>

              <Grid container spacing={2} style={{ marginTop: 10, marginLeft: 16 }}>

                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('isVendor')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="isVendor"
                        color="primary"
                        checked={item.isVendor}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: 0 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Is Vendor
                    </span>}
                  />}
                >
                </LIB.MCheckbox>

                <LIB.MCheckbox {...App.AppBase.getInputErrorProps('enableProductUpdatesEmail')}
                  formControlLabel={<FormControlLabel
                    control={
                      <Checkbox
                        name="enableProductUpdatesEmail"
                        color="primary"

                        checked={item.enableProductUpdatesEmail}
                        onChange={(e: any) => this.onInputChange(e)}
                        //style={fullWidth}
                        inputProps={{ style: formInput }}

                      />
                    }
                    style={{ marginLeft: 0 }}
                    labelPlacement="end"
                    label={<span style={{ fontSize: '16px' }}>
                      Receive Product Update Emails?
                    </span>}
                  />}
                >
                </LIB.MCheckbox>
 
              
                <FormControlLabel 
                  control={
                    <Switch
                      checked={item.isActive}
                      onChange={(e: any) => this.onInputChange(e)}
                      //   inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                      name="isActive"
                    />
                  }
                  label="Is Active"
                />


              </Grid>

            </Grid >

          </div>
        </form>

      }

    </App.MainSplitPage>;


  }


  /* public getAlert(error: string) {
    if (LIB.Common.isNullOrEmpty(error))
      return null;

    return <Alert severity="error">{error}</Alert>;
  } */

  public render() {
    console.log('AdministrationProspectsEditModal render');

    var state = this.props.state;
    var vm = state.vm;
    var isOpen = vm.isProspectsEditOpen;
    var item = vm.selectedProspect;

    if (!isOpen)
      return;

    var title;
    var saveBtnTitle;

    if (vm.isProspectsEditOpen) {
      title = `Edit Prospect (${item?.prospectId})`;
      saveBtnTitle = "Save";
    }


    return (
      <div>
        <Dialog
          open={isOpen}
          onClose={() => this.onCloseClick()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle >{title}</DialogTitle>
          <DialogContent style={{ height: "60vh", minHeight: 300, minWidth: 800 }} >


            {this.getForm()}

          </DialogContent>
          <DialogActions>

            <MT.Button
              variant="contained"
              //color="default"
              style={{ margin: 10 }}
              onClick={this.onCloseClick.bind(this)}
              disabled={vm.isActionInProgress}
            >Cancel</MT.Button>

            <MT.Button
              color="primary"
              variant="contained"
              style={{ margin: 10 }}
              onClick={this.onUpdateClick.bind(this)}
              disabled={vm.isActionInProgress}
            >{saveBtnTitle}</MT.Button>



          </DialogActions>
        </Dialog>
      </div>
    );



  }
} 