import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ListStateSettingsReducers {


    constructor() {

    }

    public static getReducers(state: App.ListState | undefined, action: App.AppAction): App.ListState {
        var str = "";

        var result = new App.ListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("LIST_SETTINGS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        let selectedWorkspace= globalState.workspaceVm?.selectedWorkspace;

        var listDataService = new App.ListDataService(selectedWorkspace?.workspaceId);
        var listSettingsService = new App.ListSettingsService(selectedWorkspace?.workspaceId);
 
        // const action = incomingAction as KnownAction;
        switch (action.type) {

            
        /*     case App.ActionTypes.LIST_SETTINGS_GET_VIEW_REQUEST: {
                console.log('LIST_SETTINGS_GET_VIEW_REQUEST:', action.payload);
                result =  { ...state };
                result.apiErrorMessage='';
               

                var data = action.payload;

                listSettingsService.getListByViewId(data?.viewId);
 
                result.isServiceCallPending = true;
                result.forceUpdateCount++;

                 break;
            }   
            case App.ActionTypes.LIST_SETTINGS_GET_VIEW_SUCCESS : {
                console.log('LIST_SETTINGS_GET_VIEW_SUCCESS :', action.payload);
                result = { ...state };


                var data = action.payload;

                result.list = data.list;
 
                //LIB.ObjectHelper.setData(data.list, result.list,'vm');
 
                console.log('LIST_SETTINGS_GET_VIEW_SUCCESS result:',result.list);
                
                let vm = result.vm;
 
                result.hasPageDataLoaded = true;

                result.isServiceCallPending = false;
                result.forceUpdateCount++;
                break;
            } */
            case App.ActionTypes.LIST_SETTINGS_DETAIL_PANEL_CHANGE : {
                console.log('LIST_SETTINGS_DETAIL_PANEL_CHANGE :', action.payload);
                result = { ...state };
 
                var data = action.payload;
  
                let vm = result.vm;
                vm.isDetailRightPanelOpen = data.isDetailRightPanelOpen;
                vm.selectedItem = data.selectedItem;
                vm.selectedItemOriginal = data.selectedItemOriginal;

                //LIB.ObjectHelper.setData(data.vm,result.vm);
 
                 result.forceUpdateCount++;
                break;
            }
           
           
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}