import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


import { ListStateDataRecordsReducers } from './ListStateDataRecordsReducers';
import { ListStateSettingsReducers } from './ListStateSettingsReducers';
import { ListStateDataItemReducers } from './ListStateDataItemReducers';
import { ListStateColumnReducers } from './ListStateColumnReducers';
 

export class ListStateReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.ListState | undefined, action: App.AppAction): App.ListState {
        var str = "";

        var result = new App.ListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("LIST"))
            return state;

       
        result.apiErrorMessage = '';
        result.vm.apiErrorMessage = '';

        if (action.type.startsWith("LIST_SETTINGS"))
            return ListStateSettingsReducers.getReducers(state, action);
  
        else if (action.type.startsWith("LIST_DATA_RECORDS"))
            return ListStateDataRecordsReducers.getReducers(state, action);
  
        else if (action.type.startsWith("LIST_DATA_ITEM"))
            return ListStateDataItemReducers.getReducers(state, action);
   
        else if (action.type.startsWith("LIST_COLUMN"))
            return ListStateColumnReducers.getReducers(state, action);
   
            

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

        var adminUsersService = new App.AdminUsersService();


        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.LIST_API_ERROR:
                console.log('LIST_API_ERROR:');
                console.log('action.payload:', action);
                result = { ...state };
                let vm = result.vm;
                //App.AppBase.resetErrorMessages();
                App.AppBase.setApiError(action, appState);
                vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action?.payload);
                result.forceUpdateCount++;
                result.isServiceCallPending = false;
                result.isPageCreateUpdatePending = false;
                vm.isActionInProgress = false;
                break;

            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;

    }



}