
import * as Api from "app-api";
import * as App from "AppReferences";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";
import * as MT from "@mantine/core";
import * as React from "react";

import { rem } from "@mantine/core";
import { useEffect } from "react";
import { useFocusTrap } from "@mantine/hooks";


import "./SelectedItemActions.css";
import { CopyToBiddingBoardScreen } from "./CopyToBiddingBoardScreen/CopyToBiddingBoardScreen";


interface SelectedItemActionsProps {
  refreshData: () => void;
  selectedItems: any[];
  listId: string;
  onClose?: () => void;
  forceReadOnly?: boolean;
  linkedWorkspaceId: number | undefined;
  customMenuItems?: any[];
}

export const SelectedItemActions: React.FC<SelectedItemActionsProps> = (props) => {

  const libForceUpdate = LIB.useForceUpdate();
  //const focusTrapRef = useFocusTrap();

  const currentWorkspace = App.AppBase.currentWorkspace;

  const { selectedItems } = props;

  const [ vm, setVm ] = React.useState<LY.SelectedItemActionsVm>(
    new LY.SelectedItemActionsVm()
  );

  //const state = props.state;

  useEffect(() => {
    console.log("SelectedItemActions useEffect selectedItems:", selectedItems);
  }, [ selectedItems ]);

  function forceUpdate() {
    var newVm = { ...vm, errors: vm.errors };

    setVm(newVm);
    libForceUpdate();
  }

  function onServiceError(error: any) {
    // console.error('SelectedItemActions onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    vm.isActionInProgress = false;

    // console.error('SelectedItemActions onServiceError2 errorMessage:', errorMessage);

    vm.errors.set("all", errorMessage);

    forceUpdate();
  }

  // function onColumnChange(item: Api.ListColumn) {
  //   //console.log('SelectedItemActions onColumnChange item:', item);

  //   if (!item || !item.listColumnId || !item.fkListId || !item.fkListColumnId) {
  //     return;
  //   }

  //   var service = new App.ListColumnService(currentWorkspace?.workspaceId);
  //   service.getColumnInfoWithDetails(
  //     item.listColumnId,
  //     //onSuccess
  //     (result: any) => {
  //       //console.log('SelectedItemActions getColumnInfoWithDetails onSuccess:', result);

  //       LIB.ObjectHelper.setData(result, item);
  //       vm.isActionInProgress = false;
  //       forceUpdate();
  //     },
  //     //onError
  //     onServiceError
  //   );

  //   vm.isActionInProgress = true;
  //   forceUpdate();
  // }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    vm.isActionInProgress = false;
    //  console.log('SelectedItemActions onSubmit selectedColumn:', selectedColumn);
    //console.log('SelectedItemActions onSubmit configId:', data?.configId);
    //console.log('SelectedItemActions onSubmit  selectedColumn configId:', selectedColumn?.configId);

    var isValid = validate("all");
    if (!isValid) {
      console.log("SelectedItemActions onSubmit isValid:", isValid, vm.errors);
      forceUpdate();
      return false;
    }

    var sm = selectedItems;
 
    vm.isActionInProgress = true;
    forceUpdate();
  }

  function onDeleteItemsClick(e: React.MouseEvent) {
    // console.log('SelectedItemActions onDeleteColumnClick');

    vm.isDeleteItemsOpen = true;

    if (vm.errors) 
        vm.errors.clear();

        
    forceUpdate();
  }

  function onDeleteColumnCancel(e: React.FormEvent<HTMLFormElement>) {
    vm.isDeleteItemsOpen = false;

    if (vm.errors) 
        vm.errors.clear();


     forceUpdate();
  }

  function closeScreen() {
    vm.isDeleteItemsOpen = false;
    vm.isDeleteItemsInProgress = false;
    vm.isActionInProgress = false;

    if (vm.errors) vm.errors.clear();

    if (props.onClose) props.onClose();

    forceUpdate();
  }

  function onCopyToBiddingBoardClick(e: React.MouseEvent) {
      console.log('onCopyToBiddingBoardClick');

    vm.isCopyToBiddingBoardOpen = true;
 
    forceUpdate();
  }


  // function onDuplicateClick() {
  //   var service = new App.ListDataService(currentWorkspace.workspaceId);
  //   service.deleteItems(
  //     sm,
  //     //onSuccess
  //     (result: any) => {
  //       console.log("SelectedItemActions onSuccess:", result);
  //       App.AppBase.showSaveSuccess();
  //       closeScreen();
  //       props.refreshData();
  //     },
  //     //onError
  //     (error: any) => {
  //       console.error("SelectedItemActions onError:", error);
  //       let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
  //       vm.isDeleteItemsInProgress = false;
  //       vm.errors.set("all", errorMessage);
  //       forceUpdate();
  //     }
  //   );
  // }
  function onDeleteColumnConfirm(e: React.FormEvent<HTMLFormElement>) {
    // e.preventDefault();

    console.log("SelectedItemActions onDelete selectedItems:", selectedItems);
    //console.log('SelectedItemActions onSubmit configId:', data?.configId);
    //console.log('SelectedItemActions onSubmit  selectedColumn configId:', selectedColumn?.configId);

    if (vm.errors) vm.errors.clear();

    var sm = new Api.ListDeleteDataSm();
    sm.listId = props.listId;
    sm.items = selectedItems.map((item) => item.id);

    console.log("SelectedItemActions sm send:", sm);

    var service = new App.ListDataService(currentWorkspace.workspaceId);
 
    service.deleteItems(
      sm,
      //onSuccess
      (result: any) => {
        console.log("SelectedItemActions onSuccess:", result);

       
        closeScreen();
        App.AppBase.showSaveSuccess();

        props.refreshData();
    },
       //onError
       (error: any) => {
        console.error("SelectedItemActions onError:", error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isDeleteItemsInProgress = false;
        vm.errors.set("all", errorMessage);
        forceUpdate();
    }
     );

    vm.isDeleteItemsInProgress = true;
    forceUpdate();
  }

  function onInputChange(e: React.ChangeEvent<any>) {
    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('SelectedItemActions onInputChange key:', key);
    // console.log('SelectedItemActions onInputChange selectedColumn:', selectedColumn);

    onValueChange(key, value);
  }

  function onValueChange(key: string, value: any) {
    //console.log('SelectedItemActions onValueChange key:', key);
    //console.log('SelectedItemActions onValueChange value:', value);

    //console.log('SelectedItemActions onValueChange selectedColumn:', selectedColumn);

    if (key == "name") {
      value = LIB.StringHelper.replaceAll(value, " ", "_")?.toLowerCase();
    }

    //@ts-ignore
    selectedColumn[ key ] = value;

    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedColumn', selectedColumn);

    forceUpdate();
  }

  function validate(key: string): boolean {
    var vAll = key === "all"; //validate all
    var item = selectedItems;

    if (vm.errors) vm.errors.clear();

    /*   if (vAll || key == 'name') {
        if (LIB.Common.isNullOrEmpty(item.name))
          vm.errors.set('name', `Internal Name is required!`);
        else if (item.name.length > 50)
          vm.errors.set('name', `Internal Name must be less than 50 characters!`);
      } */

    var isValid = vm.errors.size < 1;
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;
  }

  //console.log('SelectedItemActions watch listColumnTypeId:', listColumnTypeId);
  //console.log('SelectedItemActions selectedColumn:', selectedColumn);
  //console.log('SelectedItemActions columnTypes:', columnTypes);
  //console.log('SelectedItemActions selectedColumnType:', selectedColumnType);


  function getCustomMenuItems() {

    if(props.customMenuItems && props.customMenuItems?.length>0){
      return props.customMenuItems;
   }

   return null;
  }

  function getActionsBox() {
    const iconStyle = { width: rem(12), height: rem(12) };
    var globalError = vm.errors.get("all");

    // console.log('SelectedItemActions globalError:', globalError);
    //console.log('SelectedItemActions globalError vm.errors:', vm.errors);

    return (
      <div className="SelectedItemActionsBox">
        <form
          onSubmit={onSubmit}
          className="SelectedItemActionsForm"
          //ref={focusTrapRef}
        >
          {
            <div
              className="SelectedItemActionsForm_Close"
              onClick={props.onClose}
            >
              <Icons.IconX
                width={22}
                height={22}
                stroke={"1.5"}
                color="#757B87"
              />
            </div>
          }
          {globalError && (
            <div style={{ color: "red", marginBottom: 10 }}>{globalError}</div>
          )}

          <div
            className="SelectedItemActionsCounter"
            title={selectedItems?.length + " Items Selected"}
          >
            <div className="SelectedItemActions_Badge">
              {selectedItems?.length}
            </div>
            <div className="SelectedItemActionsCounter_Label">
              Selected Records
            </div>
          </div>
          <div className="SelectedItemActionsButton_wrapper">
            {!props.forceReadOnly && (
              <div
                className="SelectedItemActionsButton"
                title={`Delete Selected Items: ${selectedItems?.length}`}
                onClick={onDeleteItemsClick}
              >
                <Icons.IconTrash
                  width={16}
                  height={16}
                  stroke={"1.5"}
                  color="#757B87"
                />
                <span className="SelectedItemActionsButton_label">Delete</span>
              </div>
            )}
            <div
              className="SelectedItemActionsButton"
              title={`Disabled, coming soon!`}
            // onClick={onDuplicateClick}
              
            >
              <Icons.IconCopyPlus
                stroke={"1.5"}
                width={16}
                height={16}
                color="#757B87"
              />
              <span className="SelectedItemActionsButton_label">Duplicate</span>
            </div>
          </div>
          <MT.Menu shadow="md">
            <MT.Menu.Target>
              <div
                className="SelectedItemActionsButton"
                title={`Delete Selected Items: ${selectedItems?.length}`}
              >
                <Icons.IconDots stroke={"1.5"} color="#757B87" />
              </div>
            </MT.Menu.Target>
            <MT.Menu.Dropdown>
              <MT.Menu.Item
                leftSection={
                  <Icons.IconCopyPlus
                    stroke={"1.5"}
                    width={16}
                    height={16}
                    color="#757B87"
                  />
                }
              >
                Duplicate
              </MT.Menu.Item>

              {!props.forceReadOnly && (
              <MT.Menu.Item
               onClick={onDeleteItemsClick}
                leftSection={
                  <Icons.IconTrash className='SelectedItemActionsContextMenuIcon' 
                    
                  />
                }
              >
                Delete
              </MT.Menu.Item>
        )}

              {props.listId=='cd99e4a3-08cc-4d01-b579-8e81e25da999' &&
              <MT.Menu.Item
                onClick={onCopyToBiddingBoardClick}
                leftSection={
                  <Icons.IconCopyCheck className='SelectedItemActionsContextMenuIcon' 
                   
                  />
                }
              >
                Copy To Bidding Board
              </MT.Menu.Item>
           }
       
              {getCustomMenuItems()}
 

            </MT.Menu.Dropdown>
          </MT.Menu>
        </form>
      </div>
    );
  }

  function render() {
    if (!selectedItems || selectedItems?.length < 1) return null;

    return (
      <div className="SelectedItemActionsContainer">
        {getActionsBox()}


        <CopyToBiddingBoardScreen 
        records={selectedItems}
        listId={props.listId}
        onClose={() => {
          vm.isCopyToBiddingBoardOpen = false;
          forceUpdate();
        }}
        linkedWorkspaceId= {props.linkedWorkspaceId}
        vm={vm}
        />


        <LIB.ConfirmModal
          visible={vm.isDeleteItemsOpen}
          onCancelClick={onDeleteColumnCancel}
          onConfirmClick={onDeleteColumnConfirm}
          title={`Confirm Delete: ${selectedItems?.length} Selected Items`}
          content={
            <div>
              <div>
                Are you sure you want to delete the{" "}
                <b>{selectedItems?.length}</b> selected items?
              </div>

              <MT.Alert
                variant="light"
                color="red"
                icon={<Icons.IconAlertTriangle />}
                //  title={`COLUMN DELETE WARNING: ${displayName}`}
                style={{ marginTop: 10, padding: 8 }}
              >
                There is NO Rollback for this action!
              </MT.Alert>
            </div>
          }
          confirmButtonLabel="Delete"
          errorMessage={vm.apiErrorMessage}
          disabled={vm.isDeleteItemsInProgress}
        />



      </div>
    );
  }

  return render();
};
