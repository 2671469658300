import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationProspectReducers {


    constructor() {

    }

    public static getReducers(state: App.AdministrationState | undefined, action: App.AppAction): App.AdministrationState {
        var str = "";

        var result = new App.AdministrationState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("ADMINISTRATION_PROSPECTS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);

        // console.log('GuestUser.getActionHandlersReducers', appState);

      // var adminUsersService = new App.AdminUsersService();
        var prospectsService = new App.ProspectsService();

        
        // const action = incomingAction as KnownAction;
        switch (action.type) {
 
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST: {
                console.log('ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST:', action.payload);
                result = { ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var searchFilter = action.payload;
                prospectsService.getProspectsListData(searchFilter);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED: {
                console.log('ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED:');
                result = { ...state };
                console.log("payload", action.payload);
                if (LIB.Common.isNullOrEmpty(result.prospectSearchFilter?.keyword)) {
                    result.prospectsFullList = action.payload.prospects;
                }
                result.prospects = action.payload.prospects; 
                result.prospectsCount = action.payload.prospectsCount; 

                
                result.isServiceCallPending = false;
               result.hasProspectDataLoaded = true;
                console.log("result" , result);
                break;
            }
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET: {
                console.log('ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET:');
                result = { ...state };
                console.log("payload", action.payload);
                result.prospects = result.prospectsFullList;
                result.prospectSearchFilter.keyword = '';
              //  result.isServiceCallPending = false;
             //  result.hasProspectDataLoaded = true;
                console.log("result" , result);
                break;
            }
            
             case App.ActionTypes.ADMINISTRATION_PROSPECTS_UPDATE_REQUEST: {
                console.log('ADMINISTRATION_PROSPECTS_UPDATE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                prospectsService.update(data);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS: {
                console.log('ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;
                var item = {... action.payload };
               
                App.AdministrationManager.update(item,result.appUsers);
                
                vm.apiErrorMessage=''; //to clear any api error msg
                vm.isProspectsEditOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                vm.setSelectedUser(item);//
                  
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_ADD_REQUEST: {
                console.log('ADMINISTRATION_PROSPECTS_ADD_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                prospectsService.create(data);
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_ADD_SUCCESS: {
                console.log('ADMINISTRATION_PROSPECTS_ADD_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
                var item = {... action.payload };
                              //  var item = vm.;

                console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

                result.prospects.splice(0, 0, item); //push at the beginning
               vm.apiErrorMessage='';
                vm.isProspectsAddOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

                vm.setSelectedUser(); //
                console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                result.adminCreateProspectSm = new Api.Prospect();
  
                
               result.forceUpdateCount++;
                break;
            }
              
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_DELETE_REQUEST: {
                console.log('ADMINISTRATION_PROSPECTS_DELETE_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var itemId = action.payload;

                prospectsService.deleteProspect(itemId);
                result.isServiceCallPending = true;
                result.forceUpdateCount++;
                break;
            }   
            case App.ActionTypes.ADMINISTRATION_PROSPECTS_DELETE_SUCCESS: {
                console.log('ADMINISTRATION_PROSPECTS_DELETE_SUCCESS:', action.payload);
                result = { ...state };
                var vm = result.vm;

                var itemId:any = vm.selectedProspect?.prospectId;
                var success = action.payload;
 
                if(success)
                    App.AdministrationManager.deleteProspectById(itemId,result.prospects);
                
                vm.isProspectDeleteOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;
                //vm.setSelectedUser();
                  
                result.forceUpdateCount++;
                break;
            }   
           

            case App.ActionTypes.ADMINISTRATION_PROSPECTS_IMPORT_REQUEST: {
                console.log('ADMINISTRATION_PROSPECTS_IMPORT_REQUEST:', action.payload);
                result = state;//{ ...state };
                result.apiErrorMessage='';
                var vm = result.vm;
                var data = action.payload;
                prospectsService.import(data, 
                    (() => App.AppStore.dispatch(App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST)));
                result.isServiceCallPending = true;
                //result.forceUpdateCount++;
                break;
            }

            case App.ActionTypes.ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS: {
                console.log('ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS:', action.payload);

                result = { ...state };
                var vm = result.vm; 
             //   var item = {... action.payload };
              //  console.log("item pushed: ", (item));
               // console.log(item.constructor.name); 

              //  result.prospects.splice(0, 0, item); //push at the beginning
               vm.apiErrorMessage='';
                vm.isProspectsBulkImportOpen=false;
                vm.isActionInProgress=false;
                result.isServiceCallPending = false;

             //   vm.setSelectedUser(); //
              //  console.log("vm.setSelectedUser();:, ", vm.selectedUser);
                result.adminBulkImportProspectsSm = new Api.BulkEmailProspectsSm();

                var msg = `Successfully Imported, New Prospect Count:${action.payload}`;
                App.AppBase.showMessage(msg,false);

               result.forceUpdateCount++;
                break;
            }

            
            
            default:
                result = state;
                //return result;
                break;
        }
        console.log('ADMINISTRATION_PROSPECTS_DATA_RECEIVED result2:',result);
      //  console.log('AdministrationProspect_DATA_RECEIVED action:',action);


        if (action.postActionCallBack){
            console.log("postactioncallback");
                action.postActionCallBack(result);
            }
        return result;
    }



}