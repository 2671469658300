import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class UserService extends App.BaseAppService  {
 

    constructor() { 

        super();

    }
 
     
    public getGuestUserData(sm:Api.RegisterFormSm, onAfterSuccess:Function|null=null) {
        console.log('getGuestUserData sm',sm);
        
        var apiUrl = `${App.AppBase.appApiBaseUrl}/User/getGuestUserData?`;
        if(sm.planId)
            apiUrl+=`&planId=${sm.planId}`;
       // if(sm.offerId)
       //     apiUrl+=`&offerId=${sm?.offerId}`;
       if(sm.coupon)
            apiUrl+=`&coupon=${sm?.coupon}`;
        
        this.get(apiUrl, App.ActionTypes.GUESTUSER_PAGE_DATA_RECEIVED,
            App.ActionTypes.GUESTUSER_API_ERROR,
            "",onAfterSuccess
            );
    }
 

    public login(data: Api.LoginFormSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/Login`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_LOGIN_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR 
            );

    }

    public register(data: Api.RegisterFormSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/Register`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_REGISTER_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR
           // ,"/ConfirmEmail"
            );

       
    }

    public forgotPassword(data: Api.ForgotPasswordFormSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/ForgotPassword`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR,
            "/User/ForgotPasswordSuccess"
            );


    }

    public resetPassword(data: Api.PasswordResetFormSm) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/ResetPassword`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR,
            "/User/PasswordResetSuccess"
            );
    }
 
/* 
    public confirmEmail(data: Api.ConfirmFormSm, onSuccess: Function, onError: Function = this.onErrorDefault) {

        const apiUrl = `${App.AppBase.appApiBaseUrl}/User/confirmEmail`;
 
        this.post(apiUrl,data, 
            App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_SUCCESS,
            App.ActionTypes.GUESTUSER_API_ERROR 
            );

    } */
 
}
