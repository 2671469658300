import * as Api from 'app-api'
import * as Icons from "@tabler/icons-react";
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import { LY_DropDown_View } from "../LY_StatusDropDown_View/LY_StatusDropDown_View";
import { LY_StatusDropDown_Edit } from '../LY_StatusDropDown_Tabs/LY_StatusDropDown_Edit';
import { LY_StatusDropDown_Options } from '../LY_StatusDropDown_Options/LY_StatusDropDown_Options';
import { Popover } from "@mantine/core";

export const LY_DropDown_Container: React.FC<LY.LY_StatusDropDownProps> = (props) => {




    const context = LY.useStatusDropDownContext();
    const state = context.state;



    function getPopOver() {


        return <Popover
            closeOnClickOutside={!state.isEditMode}
            opened={context.state.isSelectStatusPopoverOpen}
            onChange={context.setPopoverOpened}
            position="bottom"
            offset={1}
            withArrow
            arrowSize={12}
        >
            <Popover.Target>
                <LY_DropDown_View {...props} />

            </Popover.Target>
            {!state.readOnly && <Popover.Dropdown
                className={`LY_StatusDropDownPopoverDropdown LY_PopoverRoundCorners`}
            >
                {!state.isEditMode ? <LY_StatusDropDown_Options {...props} /> : <LY_StatusDropDown_Edit {...props} />}

            </Popover.Dropdown>}
        </Popover>
    }

    function render() {

        return <LY.LY_InputBase {...props}
            style={{ width: '100%' }}

        >
            {getPopOver()}

        </LY.LY_InputBase>

    }

    return render();
}