import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';


import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './CopyListScreen.css';


interface CopyListScreenProps  {

  vm: App.WorkspaceVm;
  onCopyDone?: (result: Api.List) => void;
  onClose?: () => void;


} 

type combinedProps = CopyListScreenProps & React.InputHTMLAttributes<HTMLInputElement>;
 
export const CopyListScreen = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    onCopyDone,
    onClose,
    ...props
  },
  ref // This is the forwarded ref
) => {

  const currentWorkspace = App.AppBase.currentWorkspace;
  
  const libForceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();


  var initialVm = new LY.CopyListScreenVm();
  initialVm.isCopyListScreenOpen = props.vm.isCopyListScreenOpen;
  const [ vm, setVm ] = React.useState<LY.CopyListScreenVm>(initialVm); 


  var initialCopySm = new Api.ListCopySm();
  if(props.vm.selectedItem?.listId)
  initialCopySm.fromTemplateListIdOrListId = props.vm.selectedItem?.listId;

  const [ newItem, setNewItem ] = useState<Api.ListCopySm>(initialCopySm);
  //var propsVm = props.state.workspaceVm;
 

   useEffect(() => {
      console.log('CopyListScreen useEffect props.vm', props.vm);
      console.log('CopyListScreen useEffect props.vm.isCopyListScreenOpen', props.vm.isCopyListScreenOpen);

      vm.isCopyListScreenOpen = props.vm.isCopyListScreenOpen;

      if(props.vm.selectedWorkspace)
        newItem.toWorkspaceId = props.vm.selectedWorkspace.workspaceId!;

       if( props.vm.selectedItem?.listId)
         newItem.fromTemplateListIdOrListId = props.vm.selectedItem.listId
 
    }, [ props.vm.isCopyListScreenOpen ]);  

 
    function forceUpdate( ) {

      var newVm = {...vm, errors: vm.errors};
   
      setVm(newVm);
      libForceUpdate();
  
    } 

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {

      e.preventDefault();

    console.log('CopyListScreen onSubmit newItem:', newItem);
    //console.log('CopyListScreen onSubmit configId:', data?.configId);
    //console.log('CopyListScreen onSubmit  newItem configId:', newItem?.configId);

    var isValid = validate('all');
    if (!isValid) {
      console.log('CopyListScreen onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    }


    var workspaceId = currentWorkspace?.workspaceId;

    console.log('CopyListScreen workspaceId:', workspaceId);

    var service = new App.WorkspaceItemService(workspaceId);


    //console.log('CopyListScreen newItem send:', newItem);
    //return;

    service.copyListFromItem(newItem,
      //onSuccess
      (result: any) => {
        console.log('CopyListScreen onSuccess:', result);
 
        var sm = new Api.Workspace();
        sm.workspaceId = newItem.toWorkspaceId;
        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_REQUEST, sm);

        resetAll();

        forceUpdate();


      },
      //onError
      (error: any) => {

        console.error('CopyListScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);
        
        forceUpdate();
      });

 
    vm.isActionInProgress = true;
    forceUpdate();

 

  }



  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('DefaultListTableForm onInputChange key:', key);
    // console.log('DefaultListTableForm onInputChange newItem:', newItem);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    console.log('DefaultListTableForm onValueChange key:', key);
    console.log('DefaultListTableForm onValueChange value:', value);

    console.log('DefaultListTableForm onValueChange newItem:', newItem);

/*     if (key == 'name') {
      newItem.schemaName = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    } */

    //@ts-ignore
    newItem[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange newItem', newItem);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = newItem;

    vm.errors.clear();


    if (vAll || key == 'newListName') {
      if (LIB.Common.isNullOrEmpty(item.newListName))
        vm.errors.set('newListName', `New List Name is required`);
      else if (item.newListName.length > 50)
        vm.errors.set('newListName', `New List Name must be less than 50 characters`);
    }
    if (vAll || key == 'toWorkspaceId') {
      if (LIB.Common.isNullOrEmpty(item.toWorkspaceId))
        vm.errors.set('toWorkspaceId', `Copy To Workspace is required`);
    }
 
    if ((vAll || key == 'fromTemplateListIdOrListId') && 
          LIB.Common.isNullOrEmpty(item.fromTemplateListIdOrListId)) {
      vm.errors.set('fromTemplateListIdOrListId', `From List is required`);
    } 
    var isValid = (vm.errors.size < 1);
    console.log('CopyListScreen validateOne isValid:', isValid);
    console.log('CopyListScreen validateOne vm.errors:', vm.errors);
    console.log('CopyListScreen validateOne col.name error:', vm.errors.get(key));

    return isValid;

  }

  function getForm() {
    //var vm = props.state.vm;
    //var columnTypes = props.state.columnTypes;

    var globalError = vm.errors.get('all');

    var form = (
      <form autoComplete="off"
        onSubmit={onSubmit}
        className='CopyListScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div className='CopyListScreenFormScrollArea' >
          <div>
            List: <b>{props.vm.selectedItem?.name}</b> 
          </div>
          <LY.LY_Input
            name="newListName"
            value={newItem.newListName}
            type='text'
            label="New List Name"
            placeholder="New List Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('newListName')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />


          <LY.WorkspaceSelectionDropDown 
            name="toWorkspaceId"
            valueProperty='workspaceId'
            displayProperty='name'
            workspaces={props.vm.workspaces}
            selectedItem={props.vm.selectedWorkspace}
        //    value={newItem.toWorkspaceId}
            label="Copy To Workspace"
            placeholder="Select Workspace"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('toWorkspaceId')}
            onWorkspaceChange={(m, wp) => {

              console.log('DefaultListTableForm onValueChange val:', wp);

              onValueChange('toWorkspaceId', wp.workspaceId);
            }}
         />
         
        </div>


        <Group mt="md" justify='right'>
          <MT.Button variant='default' onClick={() => onOpenChange(false)} >Cancel</MT.Button>
          <MT.Button  type='submit'
            loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}
            //disabled={true} //todo
            //onClick={onSubmit}
          >
            Copy List
          </MT.Button>
        </Group>

      </form>
    );

    return form;

  }



  function resetAll() {
    console.log('CopyListScreen resetAll');


    vm.errors.clear();
    vm.isActionInProgress = false;
    vm.isCopyListScreenOpen = false;

    setNewItem(new Api.ListCopySm());

    //console.log('CopyListScreen resetAll:', newItem);
    //console.log('CopyListScreen resetAll ServerError:',serverError);

  }

  function onOpenChange(open: boolean) {
    console.log('CopyListScreen onOpenChange');
 
    vm.isCopyListScreenOpen = open;

    if (!open){

      resetAll();

      if(onClose)
        onClose();
    }
 
    forceUpdate();
  } 


  function render() {

    console.log('CopyListScreen render vm.isCopyListScreenOpen', vm.isCopyListScreenOpen);
 
    if (LIB.Common.isNullOrEmpty(vm.isCopyListScreenOpen))// || column == undefined)
      return null;

 
    return (
      <>
        <Modal
          id='CopyListScreenModal'
          opened={vm.isCopyListScreenOpen}
          onClose={() => onOpenChange(false)}
          title={`Copy List: ${props.vm.selectedItem?.name}`}
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0, 
          
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}

          className={'CopyListScreenFormModal'}

        >



          {getForm()}




        </Modal>

      </>
    );
  }

  return render();

});

