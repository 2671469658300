import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class LinkedListDataService extends App.BaseAppService {



    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
    }


    public getRecords(sm: Api.ListGetDataSm, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {
        console.log("LinkedListDataService.getRecords sm: ", sm);

        this.linkedWorkspaceId = sm.linkedWorkspaceId;

        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedListData/getRecords`;

      
            this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess?.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("LinkedListDataService.getRecords onError: ", error);
                        this.onErrorDefault(error);

                    }
 
                },
                method: 'POST'
            });
        
    }




}
