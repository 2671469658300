import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';



import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, Accordion, rem, Tooltip } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_UpdateColumnScreen.css';
import { UpdateColumnAdvancedTab_ValueFormatting } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_ValueFormatting';
import { UpdateColumnAdvancedTab_Size } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Size';

import { UpdateColumnAdvancedTab_ValueTemplates } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_ValueTemplates';
import { UpdateColumnAdvancedTab_ValueOptions } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_ValueOptions';
import { UpdateColumnAdvancedTab_Tooltip } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Tooltip';
import { UpdateColumnAdvancedTab_Misc } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Misc';
import { UpdateColumnAdvancedTab_ValueFormattingBackend } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_ValueFormattingBackend';
import { UpdateColumnAdvancedTab_Calculations } from './UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Calculations';
import { ListColumnRelationshipSettings } from '../_shared/ListColumnRelationshipSettings';



interface UpdateColumnAdvancedTabProps {
  state: App.ListState;
  onRelationshipSelectionsDone?: (selections: LY.ListColumnRelationshipSettingsVm) => void;

  onForceUpdate?: () => void;

}

interface UpdateColumnAdvancedTabPropsRef {
  validateAll: () => void;
}


export const UpdateColumnAdvancedTab = React.forwardRef<UpdateColumnAdvancedTabPropsRef, UpdateColumnAdvancedTabProps>((
  {
    ...props
  },
  ref // This is the forwarded ref
) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;

  const vm = props.state.vm;
  const state = props.state;
  const relationshipSettingsRef = useRef<any>();

  React.useImperativeHandle(ref, () => ({
    validateAll
  }));

  function validateAll(): boolean {


    var isValid = relationshipSettingsRef?.current?.validateAll();

    if (!isValid)
      return false;


    return true;
  }
  /* 
    function onRelationshipSelectionsDone(svm: LY.ListColumnRelationshipSettingsVm){
      console.log('ListColumnRelationshipSettings onSelectionsDone svm: ', svm);
  
  
  
  
    } */

  function getPanels() {

    var item = selectedColumn;

    //console.log('ListColumnRelationshipSettings validate sart');


    const iconStyle = { width: rem(12), height: rem(12) };
    //chevronPosition="left"
    return (
      <Accordion className='UpdateColumnAdvancedAccordion' defaultValue="ValueOptions" >
        <Accordion.Item value="ValueOptions" >
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}

          >
            Value Options
          </Accordion.Control>
          <Accordion.Panel>
            <UpdateColumnAdvancedTab_ValueOptions state={props.state} onForceUpdate={forceUpdate} />


          </Accordion.Panel>
        </Accordion.Item>


        {item.isRelationship && (
          <Accordion.Item value="RelationshipOptions">
            <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
              classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
            >
              Relationship Options
            </Accordion.Control>
            <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>
            
              <ListColumnRelationshipSettings
                ref={relationshipSettingsRef}
                state={props.state}


                onSelectionsDone={(svm: LY.ListColumnRelationshipSettingsVm) => {
                  console.log('ListColumnRelationshipSettings onSelectionsDone svm: ', svm);
                    if(props.onRelationshipSelectionsDone)
                      props.onRelationshipSelectionsDone(svm);

                }}
              />

            </Accordion.Panel>
          </Accordion.Item>
        )}

        <Accordion.Item value="Size">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Size
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_Size state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="Style">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Style
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>Content</Accordion.Panel>
        </Accordion.Item>


        <Accordion.Item value="ValueFormatting">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Client Value Formatting

          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_ValueFormatting state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="BackendValueFormatting">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Backend Value Formatting

          </Accordion.Control>

          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_ValueFormattingBackend state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>



        <Accordion.Item value="ValueTemplates">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Value Templates
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_ValueTemplates state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="Tooltip">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Tooltip
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_Tooltip state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>


        <Accordion.Item value="Calculations">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Calculations
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_Calculations state={props.state} />

          </Accordion.Panel>
        </Accordion.Item>







        <Accordion.Item value="Misc">
          <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
            classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
          >
            Misc
          </Accordion.Control>
          <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

            <UpdateColumnAdvancedTab_Misc state={props.state} />


          </Accordion.Panel>
        </Accordion.Item>

       {/*  {App.AppBase.isDev() && */}
          <Accordion.Item value="debugInfo">
            <Accordion.Control className='UpdateColumnAdvancedAccordionControl'
              classNames={{ label: 'UpdateColumnAdvancedAccordionControlLabel' }}
            >
              Debug Info
            </Accordion.Control>
            <Accordion.Panel className='UpdateColumnAdvancedAccordionPanel'>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <span> List: {list?.listId}</span>
                <span> Column: {item?.listColumnId}</span>
                <span> fkWorkspaceId: {item.fkWorkspaceId}</span>
                <span> fkListId: {item.fkListId}</span>
                <span> fkListViewId: {item.fkListViewId}</span>
                <span> fkListColumnId: {item.fkListColumnId}</span>
                <span> fkDisplayListColumnId: {item.fkDisplayListColumnId}</span>
                <span> fkDisplayTypeId: {item.fkDisplayTypeId}</span>



              </div>

            </Accordion.Panel>
          </Accordion.Item>

       {/*  } */}

      </Accordion>
    );

  }

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    var vm = state.vm;
    var column = selectedColumn

    // console.log('UpdateColumnAdvancedTab render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || column == undefined || !vm.isColumnSettingsScreenOpen)
      return null;

    console.log('UpdateColumnAdvancedTab render selectedColumn', selectedColumn);

    return getPanels();
  }

  return render();
});

