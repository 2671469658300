import * as LIB from '_LIB';
import moment from "moment";


export class ObjectHelper {


  public static getMaxValue(list: any[], propertyName: string): number {
    console.log('getMaxValue list:', list);

    if (LIB.Common.isNullOrEmpty(list) || LIB.Common.isNullOrEmpty(propertyName) || !Array.isArray(list))
      return -1;
    if (list.length < 1)
      return 0;
    var maxValue = Math.max(...list.map(o => o[ propertyName ]), 0);

    console.log('getMaxValue maxValue:', maxValue);

    if (isNaN(maxValue))
      return -1;

    return maxValue;

  }


  ///Gets the object with only keys that have values
  public static getCleanObjectWithValues(data: any): any {

    if (LIB.Common.isNullOrEmpty(data))
      return data;

    var newObj: any = {};
    for (var key in data) {

      if (LIB.Common.isNullOrEmpty(data[ key ]))
        continue;

      newObj[ key ] = data[ key ];
    }
    return newObj;
  }


  public static clone(obj: any): any {

   if(LIB.Common.isNullOrEmpty(obj))
      return undefined;

    let copy = (JSON.parse(JSON.stringify(obj)));
    return copy;
  }

  ///Gets the object with only keys that have values
  public static setData(from: any, to: any, exludeList:string=''): any {
 
    var toKeys = Object.keys(to)
    for (var key in from) {

      if (toKeys.indexOf(key) === -1 || exludeList.includes(key))
        continue;
        
      try {
        to[ key ] = from[ key ];
      } catch { }

    }
 
    return to;
  }

  
  public static sortArray(items:any[], sortField:string='sortOrder', isAscending:boolean=true):any[]{
    if(LIB.Common.isNullOrEmpty(items))
      return items;
     
      return items.sort((a, b) => {
        if (a[sortField] < b[sortField]) {
          return isAscending ? -1 : 1;
        }
        if (a[sortField] > b[sortField]) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });

  }


}