import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment, Checkbox, FormControlLabel, FormControl, FormHelperText } from '@mui/material';
import axios from 'axios';
import { Alert, AlertTitle } from '@mui/material';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import { Link as RouterLink } from 'react-router-dom';
import * as Icons2 from '@mui/icons-material/';
import { CSSProperties } from "react";

/* 
interface RegisterProps {
  sm: Api.RegisterFormSm;
} */

//export class Register extends React.PureComponent<RegisterProps> {
class Register extends React.Component<App.GuestUserState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Register componentDidMount');

    if(App.AppBase.isUserAuthenticated){
      App.AuthManager.logout();
      this.forceUpdate();
    }
    const vm = this.props.vm;
    var sm = this.props.registerSm;

    
   // sm.offerId = LIB.UrlHelper.getQueryString("offerId");
    sm.coupon = LIB.UrlHelper.getQueryString("coupon");
   // sm.planName = LIB.UrlHelper.getQueryString("plan");
    sm.planId = LIB.UrlHelper.getQueryString("planId");

    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_GET_PAGE_DATA,sm);

   
    console.log('Register componentDidMount sm',sm);


  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Register componentDidUpdate');


  }



  public onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    var state = this.props;
    var vm = state.vm;
    var item = state.registerSm;
     
    var target: any = e.target;

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    console.log('onInputChange key', key);
    //console.log('onInputChange item', item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    this.validationManager.validateRegister(key, item);

    this.forceUpdate();
  }


  public onRegisterClick(e: React.ChangeEvent<{}>) {
    console.log('onRegisterClick');

    var state = this.props;
    var vm = state.vm;
    var sm = state.registerSm;

    var isValid = this.validationManager.validateRegister(null, sm);

    if (!isValid) {
      console.log('validateRegister NOT valid, ', App.AppBase.lastErrorMessage);
      this.forceUpdate();
      return;
    }

    console.log('onRegisterClick sm',sm);

    //call api
    App.AppStore.dispatch(App.ActionTypes.GUESTUSER_REGISTER_REQUEST,sm ); 

    this.forceUpdate();

  }

  public getOfferText() {
    console.log("getOfferText");
    var state = this.props;
    var vm = state.vm;
    var offer = vm?.offer;
    var plan = vm?.billingPlan;

    //console.log("getOfferText offer",offer);

    if (LIB.Common.isNullOrEmpty(offer) || !offer?.isVisibleOnSignup)
      return null;

    return <Alert severity="info"  style={{ marginBottom:0, marginTop:20, paddingBottom:0 }}>
      <AlertTitle>{`Offer: ${offer?.coupon?.toUpperCase()}`} </AlertTitle>
      {offer.description}
    </Alert>

  
  }

  
  public getBillingPlanText() {
    console.log("getBillingPlanText");
    var state = this.props;
    var vm = state.vm;
    var plan = vm?.billingPlan;

    //console.log("getOfferText offer",offer);

    if (LIB.Common.isNullOrEmpty(plan))
      return null;

    return <Alert severity="info"  style={{ marginBottom:0, marginTop:0, paddingBottom:0 }}>
      <AlertTitle>{'Selected Plan: ' + plan?.name}</AlertTitle>
{/*       {plan?.description}
 */}    </Alert>

  
  }


  public render() {

    console.log("Register Render");

    var state = this.props;
    var vm = state.vm;
    var sm = state.registerSm;

    var useTestData = LIB.UrlHelper.getQueryString("useTestData");

    if (useTestData == "1" && LIB.Common.isNullOrEmpty(sm.firstName)) {
        console.log('Register useTestData');
        sm = App.TestDataManager.setTestRegisterData(sm);
       // console.log('Register useTestData sm', sm);
        this.forceUpdate(); 
    }

    //console.log("Register Render sm",sm);

    const classes: any = createStyles({
      backdrop: {
        zIndex: 101,
        color: '#fff',
      }
    });

    var formInput: CSSProperties = {
      height: 50
    };

    var formControl: CSSProperties = {
      marginTop: 10,
      width: '100%'
    };
 
    var globalError = vm.apiErrorMessage;


    var termsOfServiceUrl = "/TermsOfService";
    var privacyPolicyUrl = "/PrivacyPolicy";



    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={state.isServiceCallPending}
      //loading={true}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      hasNoPageDesign={true}
      //={vm.apiErrorMessage}
    >
 

      <Grid container
        spacing={0}
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: '100%', marginTop: 20 }}

      >

    <Card className='LY_Register_Card' style={{ padding: 0, minWidth: 300, width:'40vw', maxWidth:400  }}>
          <CardContent  >

            <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
              Sign Up
            </Typography>

            <div>
            {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

              <Grid container spacing={2}>
                <Grid item xs={6}>

                  <TextField
                    name="firstName"
                    label="First Name"
                    variant="standard"
                    value={sm.firstName || ''}
                    {...App.AppBase.getInputErrorProps('firstName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />

                </Grid>

                <Grid item xs={6} >
                  <TextField
                    name="lastName"
                    label="Last Name"
                    variant="standard"
                    value={sm.lastName || ''}
                    {...App.AppBase.getInputErrorProps('lastName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />
                </Grid>

              </Grid>
 
            </div>

            <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>

                  <TextField
                    name="companyName"
                    label="Company Name"
                    variant="standard"
                    value={sm.companyName || ''}
                    {...App.AppBase.getInputErrorProps('companyName')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />

                </Grid>

                <Grid item xs={6} >
                  <TextField
                    name="phone"
                    label="Phone"
                    variant="standard"
                    value={sm.phone || ''}
                    {...App.AppBase.getInputErrorProps('phone')}
                    onChange={this.onInputChange.bind(this)}
                    style={formControl}
                    inputProps={{ style: formInput }}

                  />
                </Grid>

              </Grid>

              
            </div>

            <div>
            <TextField
                name="email"
                label="Email (Work Email)"
                value={sm.email || ''}
                {...App.AppBase.getInputErrorProps("email")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                fullWidth
                style={formControl}
                 variant="standard"
                placeholder='Work Email Address'
                inputProps={{ style: formInput }}
              />
            </div>
            
            <div>
              <TextField
                name="password"
                label="Password"
                type='password'
                value={sm.password || ''}
                {...App.AppBase.getInputErrorProps("password")}
                onChange={(e: any) => this.onInputChange(e)}
                ///style={fullWidth}
                variant="standard"
                fullWidth
                style={formControl}
                inputProps={{ style: formInput }}
              />
            </div>

            <LIB.MCheckbox {...App.AppBase.getInputErrorProps('acceptTerms')}
            formControlLabel={ <FormControlLabel
              control={
                <Checkbox
                  name="acceptTerms"
                  color="primary"
                  
                  checked={sm.acceptTerms}
                  onChange={(e: any) => this.onInputChange(e)}
                  //style={fullWidth}
                  inputProps={{ style: formInput }}
  
                />
              }
              style={{ marginLeft: 0 }}
              labelPlacement="end"
              label={ <span style={{ fontSize: '11px' }}>
              I hereby agree to 
              <a className="registerFormTermsOfService" href={termsOfServiceUrl} 
              target="_blank"> Terms of Service </a>
          and <a className="registerFormPrivacyPolicy" href={privacyPolicyUrl} 
          target="_blank">Privacy Policy</a>
          </span>}
            />}
            >
 
            </LIB.MCheckbox>

          

            {this.getOfferText()}

            {this.getBillingPlanText()}
 
            <div className='standardFormInput30'>
              <MT.Button variant="contained" color="primary" className='LY_ButtonFullWidth'
                onClick={(e) => this.onRegisterClick(e)}
                disabled={state.isServiceCallPending}
              >Sign Up</MT.Button>
            </div>


            <div style={{ width: '100%', marginBottom: 0, marginTop: 50, fontWeight: 500 }} >
              <span style={{ marginRight: 5 }}> Already have an account?</span>
              <RouterLink to="/Login" color="primary">Login</RouterLink>
            </div>



          </CardContent>
        </Card>

      </Grid >

      </App.MainSplitPage>  ;

  }
};
      export default connect(
  (state: App.AppState) => ({...state.guestUserData})
      )(Register as any);
