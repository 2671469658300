import React, { ChangeEventHandler, HTMLAttributes, useImperativeHandle, forwardRef } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';

import './_LY_InputBaseViewEdit.css';
import { Button, Badge, Tooltip } from '@mantine/core';
import { useToggle } from '@mantine/hooks';

interface _LY_InputBaseViewEditProps extends LY.LY_InputBaseProps {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  //onItemChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;  
  onValueChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;

  onItemClick?: Function; //React.MouseEvent<HTMLElement>
  viewRenderer?: React.ReactNode;
  editRenderer?: React.ReactNode;
  inputViewEditContainerClassName?: string;
}

//export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((

type combinedProps = _LY_InputBaseViewEditProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element
export const _LY_InputBaseViewEdit = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    name,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    style,
    model,
    onItemClick,
    onValueChange,
    onChange,
    viewRenderer,
    inputViewEditContainerClassName = '',
  //  editRenderer,
    readOnly,
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();

  const [ mode, setMode ] = React.useState('view'); // Add this line
  const [ localValue, setLocalValue ] = React.useState(value); // Add this line

  const combinedInputClassName = `mantine-Input-input mantine-TextInput-input LY_InputInput ${inputClassName || ''}`;

  //useImperativeHandle(ref, onLocalChange);
  
  

  //const [ mode, toggle ] = useToggle([ 'view', 'edit' ]);


/*    React.useEffect(() => {

     console.log('_LY_InputBaseViewEdit useEffect model', model);
     setLocalValue(model?.value);
   

 }, [ model?.value ]);  */


 // React.useEffect(() => {


  /*   if (model?.isEditMode) {
    //  console.log('_LY_InputBaseViewEdit useEffect model?.isEditMode', model?.isEditMode);

      setMode('edit');

    } */

 // }, [ model?.isEditMode ]);

  

  function getActions() {


    // console.log('_LY_InputBaseViewEdit getActions mode:', mode);

    if(readOnly)
      return null;

    return <div className='_LY_InputBaseViewEditActions'>

      {(mode == 'view' && !readOnly) &&

        <Tooltip label='Edit Item' position='right'
          withArrow
          arrowSize={6}
        >
          <div onClick={() => setMode('edit')}
            className='_LY_InputBaseViewEditActionsButton'

          >
            <RIcons.Pencil1Icon />

          </div>
        </Tooltip>
      }

      {
        (mode == 'edit' && !readOnly) &&
        <div onClick={(e:any) => onLocalChange(model!,e)}
          className='_LY_InputBaseViewEditActionsButton'
        >
          <RIcons.CheckIcon />
        </div>
      }
 

    </div >
  }


  function onLocalNameClick(e: React.MouseEvent<HTMLElement>) {

    //e.preventDefault();

    if (onItemClick)
      onItemClick(model, e);

    /*  console.log('_LY_InputBaseViewEdit onItemClick');
     var detailUrl = getDetailUrl();
 
     console.log('_LY_InputBaseViewEdit onItemClick URL: ',detailUrl);
     console.log('_LY_InputBaseViewEdit onItemClick Model: ',model);
  */

  }

   

  function getDetailUrl() {

    var detailUrl = `/${model?.workspaceId}/item/${model?.rowId}`;
    return detailUrl;
  }


 

  function onLocalChange(m: LY.DataItemModel, e: ChangeEventHandler | undefined) {

    console.log('_LY_InputBaseViewEdit onLocalChange model: ', m);


    if (model == undefined)
      model = m;

    if (!m.valueChanged) {
      console.log('_LY_InputBaseViewEdit onLocalChange setMode view: ',m.valueChanged);

      setMode('view');
      return;
    }

    model.value = m.value?.trim();

    //if (value)
      value = model.value;

     if (onValueChange)
       onValueChange(model!, e);

    //console.log('LY_WebsiteInput getViewMode model.value2: ',model?.value);
    //console.log('_LY_InputBaseViewEdit onLocalChange value: ',value);


    //forceUpdate();

    //setLocalValue(value);
    setMode('view');


  }

  /* function onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    //e.preventDefault();
    //  console.log('LY_TextInput onKeyPress key:', e.key);
    if (e.key == 'Enter' || e.key == 'Tab') {
       console.log('LY_TextInput onKeyPress Enter:', model);

     
    }

  } */

 function onDoubleClick(e: React.MouseEvent<HTMLElement>) {
      
      //e.preventDefault();
     // console.log('_LY_InputBaseViewEdit onDoubleClick model: ', model);

     if(readOnly)
        return;
      
      setMode('edit');
    
 }

  function getEditMode() {

    var col = model?.column;
    var result = (
      <div 
      className={`_LY_InputBaseViewEditContainer ${inputViewEditContainerClassName}`}

       >
        <LY.LY_TextInput
          viewId={model?.viewId}
          rowId={model?.rowId}
          name={col?.name!}
          value={model?.value}

          type='text'
          originalValue={model?.originalValue}
          inputStyle={{ borderColor: 'transparent', paddingLeft: 8 }}
          style={{ width: '100%', height: '100%', paddingLeft: 0, marginLeft: 0 }}
          onChange={onLocalChange}
          onEnterBlur={true}
          onChangesDone={onLocalChange}
          autoFocus={true}
          onEnter={onLocalChange}
        />
        {getActions()}
      </div>
    );


    return result;
  }
/*   function getEditMode() {

    var col = model?.column;
    var result = (
      <div className='_LY_InputBaseViewEditContainer' >
        {editRenderer}
        {getActions()}
      </div>
    );


    return result;
  } */

  function getViewMode() {

 
    return <div
       onClick={onDoubleClick}
      //onDoubleClick={onDoubleClick}
      className={`_LY_InputBaseViewEditContainer LY_OverflowDots _LY_InputBaseViewEditContainerViewMode ${inputViewEditContainerClassName}`}
      >
     {/*    {localValue} */}
     {viewRenderer}
  
      {getActions()}
    </div>

  }

  function getRenderByMode() {

     if (mode == 'view')
      return getViewMode();
 
    return getEditMode();

  }

  function render() {

    return (
      <LY.LY_InputBase  {...props}  style={style}
      >
        {getRenderByMode()}

      </LY.LY_InputBase>
    );
  } 

  return render();

});


