import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';
import { createContext, useContext, useState, ReactNode, FC } from 'react';


import * as Icons from '@mui/icons-material/';

import * as Api from 'app-api';


interface ListTableViewContextProps {
   state: LY.ListTableViewContextState;
   triggerDropDown: (model: LY.DataItemModel, e: React.MouseEvent<HTMLElement>) => void;

} 

const useListTableViewState = (): ListTableViewContextProps => {
   const initialState = new LY.ListTableViewContextState();
   const [ state, setState ] = useState<LY.ListTableViewContextState>(initialState);

   function triggerDropDown(model: LY.DataItemModel, e: React.MouseEvent<HTMLElement>) {
      console.log('ListTableViewContextState triggerDropDown model:', model);
      var open = true;
      var existingColId = state.selectedCellModel?.column?.listColumnId;
      var existingRowId = state.selectedCellModel?.rowId;
      var existingOpen = state.selectedCellModel?.open;
      /*       console.log('ListTableViewContextState triggerDropDown state1:', state);
      
            console.log('ListTableViewContextState triggerDropDown existingRowId:', (model?.rowId == existingRowId));
            console.log('ListTableViewContextState triggerDropDown listColumnId:', (model.column?.listColumnId == existingColId));
       */
      if (model?.rowId == existingRowId && model.column?.listColumnId == existingColId) {
         console.log('ListTableViewContextState triggerDropDown existingOpen:', existingOpen);
         open = !existingOpen;
      }
      else
         state.selectedCellModel = model;

      //console.log('ListTableViewContextState triggerDropDown open:', open);

      state.selectedCellModel!.open = open;
      state.selectedCellElement = e.currentTarget;


      setState({ ...state });
      // console.log('ListTableViewContextState triggerDropDown state2:', state);

   }

   // Define other functions here...

   return {
      state,
      triggerDropDown
   };
};



const ListTableViewContext = createContext<ListTableViewContextProps | undefined>(undefined);


/* const ListTableViewContext = createContext<{ state: LY.ListTableViewContextState;
    triggerDropDown: (model: LY.DataItemModel) => void } | undefined>(undefined); */

export const useListTableViewContext = () => {
   const context = useContext(ListTableViewContext);
   if (!context) {
      throw new Error('useListTableViewContext must be used within a ListTableViewProvider');
   }
   return context;
};

const ListTableViewContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

   const contextValue = useListTableViewState();

   function render() {

      return (
         <ListTableViewContext.Provider value={contextValue}>
            {children}
         </ListTableViewContext.Provider>
      );

   }

   return render();
}
export { ListTableViewContextProvider, ListTableViewContext };