import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class GuestUserState  extends LIB.BaseStateModel {
 
    public isInitialDataLoaded:boolean;
    public isAppInfoDataLoaded:boolean;
    public isServiceCallPending:boolean;

    public registerSm:Api.RegisterFormSm;
    public loginSm:Api.LoginFormSm;
    public forgotPasswordSm:Api.ForgotPasswordFormSm;
    public passwordResetSm:Api.PasswordResetFormSm;
    public billingPlans:Api.BillingPlan[] = [];

    public vm:Api.GuestUserVm;
   
    constructor() { 
        
        super();
        this.isInitialDataLoaded=false;
        this.isServiceCallPending = false;
        this.registerSm= new Api.RegisterFormSm();
        this.loginSm = new Api.LoginFormSm();
        this.forgotPasswordSm = new Api.ForgotPasswordFormSm();
        this.passwordResetSm = new Api.PasswordResetFormSm();
        this.vm = new Api.GuestUserVm();

       // console.log('GuestUserState', this);
    }
     


}