import "./LY_ListFilterModule_Filter_Actions.css";

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as LY from '_LY_Components';

import { Button, Menu } from "@mantine/core";
import React, { FC, SetStateAction } from "react";

import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";

interface LY_FiltersModule_Filter_ActionsProps extends LY.LY_ListFilterModuleProps {
    setOpened: (opened: boolean) => void;
    handleScroll: (isBottom?: boolean) => void;
}
export const LY_FiltersModule_Filter_Actions: FC<LY_FiltersModule_Filter_ActionsProps> = ({
    setOpened,
    handleScroll
}) => {

    const context = LY.useListFilterModuleContext();
    const { state } = context;
    const errors = state.errors
    function onSubmit() {
        context.validateManager.validateAll();
        if (errors.size < 1) {
            context.onSaveViewSuccess();
            setOpened(false)
        };
    }
    const { isMobile } = useIsMobile(768)

    function mobileRenderer() {
        return <Menu shadow="md" width={120} classNames={{ dropdown: 'LY_ListFiltersModule_Filter_footer_dropdown' }}>
            <Menu.Target>
                <Button
                    variant="transparent"
                    classNames={{
                        root: "LY_ListFiltersModule_Filter_footer_action_button",
                    }}>
                    <Icons.IconDots width={22} height={22} />
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    classNames={{ item: "LY_ListFiltersModule_Filter_Item" }}
                    leftSection={<Icons.IconPlus width={14} height={14} />}
                    onClick={() => {
                        handleScroll();
                        context.onAddNewAdvancedFilterRow()
                    }}
                >
                    Add Filter
                </Menu.Item>
                <Menu.Item
                    classNames={{ item: "LY_ListFiltersModule_Filter_Item" }}
                    leftSection={<Icons.IconPlus width={14} height={14} />}
                    onClick={() => {
                        context.onAddNewGroup && context.onAddNewGroup();
                        handleScroll(true);
                    }}
                >
                    Add Group
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                    classNames={{ item: "LY_ListFiltersModule_Filter_Item" }}
                    onClick={context.onClear}
                >
                    Clear All
                </Menu.Item>

            </Menu.Dropdown>
        </Menu>
    }
    function desktopRenderer() {
        return <div className="LY_ListFiltersModule_Filter_footer_actions">
            <Button
                variant="transparent"
                leftSection={<Icons.IconPlus width={16} height={16} />}
                onClick={() => {
                    handleScroll();
                    context.onAddNewAdvancedFilterRow()
                }}
            >
                Add Filter
            </Button>
            <Button
                variant="transparent"
                leftSection={<Icons.IconPlus width={16} height={16} />}
                onClick={() => {
                    context.onAddNewGroup && context.onAddNewGroup();
                    handleScroll(true);
                }}
            >
                Add Group
            </Button>
            <Button variant="transparent" onClick={context.onClear}>
                Clear All
            </Button>
        </div>
    }

    function buttonGroupRenderer() {
        return <div className="LY_ListFiltersModule_Filter_footer_buttons">
            <Button
                classNames={{ root: "LY_ListFiltersModule_Filter_Apply_button" }}
                variant="filled"
                onClick={() => onSubmit()}
            >
                Apply
            </Button>
            <Button
                classNames={{
                    root: "LY_ListFiltersModule_Filter_Cancel_button",
                    label: "LY_ListFiltersModule_Filter_Cancel_button_label",
                }}
                variant="transparent"
                onClick={() => {
                    context.setInitialView();
                    setOpened(false)
                }}
            >
                Cancel
            </Button>
        </div >
    }

    function render() {
        return (
            <div className={"LY_ListFiltersModule_Filter_footer"}>
                {isMobile ? mobileRenderer() : desktopRenderer()}
                {buttonGroupRenderer()}
            </div>)
    }



    return render();
}
