import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class DropDownService extends App.BaseAppService {


    private linkedDropDownService: App.LinkedDropDownService | undefined = undefined

    constructor(workspaceId: number | undefined, linkedWorkspaceId: number | undefined = undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        this.linkedWorkspaceId = linkedWorkspaceId;
        this.linkedDropDownService = new App.LinkedDropDownService(workspaceId,linkedWorkspaceId);
    }


    public async getData(sm: Api.ListGetDropDownDataSm, onSuccess: Function, onError: Function | undefined = undefined) {
 
        console.log("DropDownService.getData linkedWorkspaceId: ", this.linkedWorkspaceId);

        if (this.linkedWorkspaceId && this.workspaceId !== this.linkedWorkspaceId && this.linkedDropDownService) {
            return this.linkedDropDownService.getData(sm, onSuccess, onError);
        }

        console.log("DropDownService.getData sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/DropDown/getData`;
 
            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("DropDownService.getRecords onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        

    }

    
}
