import * as React from 'react';
import {
  DataGridPremium,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridEventListener,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import { Theme, styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGridPropsWithDefaultValues } from '@mui/x-data-grid/internals';
import { DataGridPremiumProps, DataGridPremiumPropsWithDefaultValue } from '@mui/x-data-grid-premium/models/dataGridPremiumProps';
import * as MUIP from  '@mui/x-data-grid-premium/models/';

import * as LY from '_LY_Components';

import './LY_DataGrid_MUI.css';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';




interface StyledDataGridPremiumProps {
  minTableHeight?:string;
}
const StyledDataGridPremium = styled(DataGridPremium)<StyledDataGridPremiumProps>(({ theme,minTableHeight }) => ({
  border: 0,
  color:
    theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGridPremium-columnsContainer': {
    backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  },
  '& .MuiDataGridPremium-iconSeparator': {
    display: 'none',
  },
  '& .MuiDataGridPremium-columnHeader, .MuiDataGridPremium-cell': {
    borderRight: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGridPremium-columnsContainer, .MuiDataGridPremium-cell': {
    borderBottom: `1px solid ${
      theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    }`,
  },
  '& .MuiDataGridPremium-cell': {
    color:
      theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  '& .MuiDataGrid-virtualScroller': {
    minHeight: minTableHeight//? minTableHeight:'50vh',
  },
  //...customCheckbox(theme),
}));
 

interface LY_DataGrid_MUIProps {
 // onNewItemSave?: Function;
  newItemPlaceholder?: string;
  tableHeight?: string;
  minTableHeight?: string;

}
 
 export const LY_DataGrid_MUI: React.FC<LY_DataGrid_MUIProps & DataGridPremiumProps> = (props) => {

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const apiRef = React.useRef<GridApiPremium>({} as GridApiPremium);
  //const combinedProps = { ...defaultProps, ...props };

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      event.stopPropagation();

      if (!params.isEditable) {
        return;
      }

      // Ignore portal
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }

      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode:  GridCellModes.View },
                }),
                {},
              ),
            }),
            {},
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
              {},
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    },
    [],
  );

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    [],
  );

  /*     <div style={{ height: '100%', width: '100%', border:'1px solid #ccc' }}> */

 {/*  
        <div className='addNewItemContainer'>
        <LY.LY_TextInput  
              name='newItem' 
              placeholder='+ Add Item' 
              inputClassName='addNewItemInput'  
              onChange={props.onNewItemSave}
              />

        </div>

    </div> */}

    var maxHeight:any = props.rowHeight?props.rowHeight*10+60:600;
    maxHeight='60vh';

    /* const handleCellKeyDown = (params:any, event:React.KeyboardEvent<HTMLElement>) => {
      console.log('handleKeyDown:',event)
      if (event.key === ' ') {
        console.log('handleKeyDown preventDefault YESSS:')
        event.preventDefault();
        event.stopPropagation();
      }
    }; */


    var tableHeight = "auto";
    if(props.tableHeight)
      tableHeight = props.tableHeight;

     // console.log('LY_DataGrid_MUI tableHeight:',tableHeight);
    
  return (
      <StyledDataGridPremium
         {...props}
         apiRef={apiRef}
         style={{ height:tableHeight, maxHeight:maxHeight, width: '100%' }}
        showColumnVerticalBorder={true}
        showCellVerticalBorder={true}
       // hideFooter={true}
        disableColumnReorder ={false}
          editMode='row'
         cellModesModel={cellModesModel}
         onCellModesModelChange={handleCellModesModelChange}
         onCellClick={handleCellClick}
         onRowClick={(params:any, e:any) => {e.stopPropagation();}}
        // onCellClick={(params:any, e:any) => {e.stopPropagation();}}
       // onCellKeyDown={handleCellKeyDown}
       columnBuffer={30}
       rowBuffer={100}
       minTableHeight={props.minTableHeight}
       />
 
     
  );
}



 