import * as React from 'react';
import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField,Box,Button,Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import * as App from 'AppReferences';
import * as LIB from '_LIB';
//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons from '@mui/icons-material/';
import * as Api from 'app-api';

/* 
interface TestProps {
  //vm: App.ProjectListState;
} */

//class Test extends React.Component<TestProps, {}> {
class Test extends React.Component<App.GlobalState, {}> {


  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('Test componentDidMount');

    // LIB.LocalStorageHelper.removeByKeyword("msal");
    // LIB.LocalStorageHelper.removeByKeyword("Test.microsoftonline.com");
    this.getData();



  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('Test componentDidUpdate');
  }

  public onServiceError(error: any) {

    console.error('LY_DynamicDropDown onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
 

  }

  public getData(searchKeyword:string|undefined=undefined) {
 
    console.log('Test getData searchKeyword:', searchKeyword);

    var workspaceId = 133;
 
    workspaceId = 128;

    var service = new App.ListViewService(workspaceId);

    var sm = new Api.ListViewGetDataSm();
    sm.listId = "ab1d53df-9ef9-4533-91ed-98134fa24626";

    sm.listId='a804fd32-e676-49b4-9506-7092794e189c';



  
 
    service.getListViews(sm,
      //onSuccess
      (result: Api.ListDropDownDataResult) => {
        console.log('LY_DynamicDropDown onSuccess:', result);
 

      },
      //onError
      this.onServiceError
    );

    this.forceUpdate();

  }
   
  public render() {

    console.log("Test Render, isUserAuthenticated", App.AppBase.isUserAuthenticated);

    var state = this.props;
    var vm = state.initialData;

 
  
     return <App.MainSplitPage
     pageTitle="Test"
     state={vm}
     hasDataLoaded={true}
 
   >
     <div>Test Page</div>

      </App.MainSplitPage>  ;

  }
};
export default connect(
  (state: App.AppState) => ({...state.globalData})
)(Test as any);
