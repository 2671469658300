import React, { ReactElement } from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

import './LY_TreeMenu.css';
//import * as Icons from '@mui/icons-material/';
import * as Icons from '@tabler/icons-react';

interface LY_TreeMenuProps {
  name: string;
  items: Api.WorkspaceItem[] | undefined;
  onItemContextMenuClick?: Function;
  onSelectItem?: Function;
  selectItem?: Api.WorkspaceItem;
  preItemIcon?: any;
  onDeleteKeyPress?: Function;
  hideContextMenu?: any;
}

export const LY_TreeMenu: React.FC<LY_TreeMenuProps> = (props) => {


  /*   React.useEffect(() => {
     
  }, []); */


  function onMenuItemClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: Api.WorkspaceItem) {
    console.log('onMenuItemClick item ', item);
    if (props.onSelectItem)
      props.onSelectItem(e, item);

  }

  function onKeyPress(e: React.KeyboardEvent<HTMLDivElement>, item: Api.WorkspaceItem) {
    console.log('onKeyPress key:', e.key);
    if (e.key == 'Delete' && props.onDeleteKeyPress)
      props.onDeleteKeyPress(e, item);
   
  }

  function getItemContextMenu(item: Api.WorkspaceItem) {

    if(props.hideContextMenu)
      return '';


    return <button className='LY_TreeMenuItemContextMenuBtn'
      onClick={(e) => onItemContextMenuClick(e, item)}  >
      <Icons.IconDots size={18}   />
      
    </button>

  }
  function onItemContextMenuClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: Api.WorkspaceItem) {
    console.log('onItemDotMenuClick');
    if (props.onItemContextMenuClick)
      props.onItemContextMenuClick(e, item);
  }

 

  function getSubMenu(item: Api.WorkspaceItem, itemIndex: number) {

    if (!item.isFolder)
      return '';

    var selectedItem = props.selectItem;

    var isOpen = (selectedItem?.workspaceItemId == item.workspaceItemId || selectedItem?.parentId == item.workspaceItemId)
    //isOpen = true;
   
       var selectedItemClass = '';
        if (props.selectItem?.workspaceItemId == item.workspaceItemId)
          selectedItemClass = 'LY_TreeMenuSelectedSubMenu';
  
    var children = getChildrenDataItems(item.workspaceItemId!);

    return <li key={`${props.name}Item${itemIndex}`}
      className={`LY_TreeMenuSubMenu ${selectedItemClass}`}
      tabIndex={item.tabIndex}>
      <details className="LY_TreeMenuSubMenuDetails" open={isOpen}>
        <summary className="LY_TreeMenuSubMenuSummary" >
          {getItemDisplayInfo(item, itemIndex)}
        </summary>

        <ul key={props.name} className='LY_TreeMenuList'>

          {children?.map((child, index) => {
            return getItem(child, index);
          })}

        </ul>

      </details>

    </li>

  }

  function getPreItemIcon(item: Api.WorkspaceItem, index: number) {

    if (!props.preItemIcon || item.isFolder)
      return '';

    return <span className='LY_TreeMenuItemPreIcon'>
      {props.preItemIcon}
    </span>

  }
  function getItemDisplayInfo(item: Api.WorkspaceItem, index: number) {

    var selectedItemClass = '';
    if (props.selectItem?.workspaceItemId == item.workspaceItemId)
      selectedItemClass = 'LY_TreeMenuSelectedItem';

    return <div
      className={`LY_TreeMenuItemDisplayInfo ${selectedItemClass}`}
    >
      <div className='LY_TreeMenuItemDisplayInfoContent'
        onClick={(e) => onMenuItemClick(e, item)}
        onKeyUp={(e) => onKeyPress(e, item)}
        tabIndex={item.tabIndex}
      >
        {getPreItemIcon(item, index)}
        <span className='LY_TreeMenuSubMenuSummaryTitle' >
          {item.name}
        </span>
      </div>
      {getItemContextMenu(item)}
    </div>

  }


  function getItem(item: Api.WorkspaceItem, index: number) {

    var classes = '';
    var isFolder = item.isFolder;// (item.children!=undefined && item.children?.length>0);
    if (isFolder)
      classes = 'LY_TreeMenuSubMenu';

    var result: any = '';

    /*     var selectedItemClass = '';
        if (props.selectItem?.workspaceItemId == item.workspaceItemId)
          selectedItemClass = 'LY_TreeMenuSelectedItem';
     */
    if (!item.isFolder) {
      result = (
        <li key={`${props.name}Item${index}`}
          className={`LY_TreeMenuItem`}
          // onClick={(e) => onMenuItemClick(e, item)}
          tabIndex={item.tabIndex}>
          {getItemDisplayInfo(item, index)}
        </li>
      );
    }
    else
      result = getSubMenu(item, index);

    return result
  }


  function getRootDataItems(){

    var list = props.items?.filter((item) => item.parentId == undefined);
    return list;
  }
  function getChildrenDataItems(parentId: string){

    var list = props.items?.filter((item) => item.parentId == parentId);
    return list;
  }

  function render() {
    console.log('LY_TreeMenu render');

    var rootItems = getRootDataItems();

    return (
      <ul key={props.name} className='.LY_TreeMenu LY_TreeMenuList'>

        {rootItems?.map((item, index) => {
          return getItem(item, index);
        })}

      </ul>


    );

  }


  return render();

}

