import './LY_StatusDropDown.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_DropDown_Container } from './Components/LY_StatusDropDown_Container/LY_StatusDropDown_Container';
import { LY_StatusDropDownContextProvider } from "./Context/LY_StatusDropDownContext";
import React from 'react';

export const LY_StatusDropDown = React.forwardRef<HTMLSelectElement, LY.LY_StatusDropDownProps>((
    props, ref
) => {

    return (
        <LY_StatusDropDownContextProvider {...props}>
            <LY_DropDown_Container {...props} />
        </LY_StatusDropDownContextProvider>

    );
});
