import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { IconButton, Menu, Button, Grid, Divider } from '@mui/material';
import * as App from 'AppReferences';

import * as LIB from '_LIB';
import { Tabs, Tab } from "@mui/material";


import { createStyles, Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';

//import './AccountMenuItem.css'


interface SmartTabsProps {
  tabList: any[];
  selectedTab?: string;
  forceUpdate?: Function;
  startUrl?: string;
  onSelectedChange?: Function;
  children?: React.ReactNode;
  orientation?: string;
  tabListWidth?:number;
}

//export class SmartTabs extends React.Component<SmartTabsProps, {}> {
export const SmartTabs: React.FC<SmartTabsProps> = (props) => {


  //var selectedTab:string|undefined;
  var params = useParams();
  var location = useLocation();
  var locationState = location.state;
  const [ forceUpdate ] = React.useReducer((x) => x + 1, 0);

  const [ selectedTab, setSelectedTab ] = React.useState(props.tabList[ 0 ]);

  /*  constructor(props: any) {
     super(props);
 
     // state = new AccountMenuItemState();
    // props.selectedTab = props.tabList[0].replace(" ", "");
   }
  */
  /*   // This method is called when the component is first added to the document
    public componentDidMount() {
     console.log("SmartTabs componentDidMount");
     checkSetSelected();
   }
 
     // This method is called when the route parameters change
     public componentDidUpdate() {
       console.log("SmartTabs componentDidUpdate");
       checkSetSelected();
     }
  */
  React.useEffect(() => {
    console.log("SmartTabs useEffect update");

    checkSetSelected();
  });

  /*   React.useEffect(() => {
      console.log("SmartTabs useEffect update first time");
 
      checkSetSelected(true)
    },[]);

  */
  function checkSetSelected(isFirstTime: boolean = false) {
    //console.log("SmartTabs checkSetSelected");
    //var selectedTab = props.selectedTab;
    var tabList = props.tabList;

    var queryTab = params.tab;

    if (LIB.Common.isNullOrEmpty(selectedTab)) {
     // console.log("SmartTabs checkSetSelected setSelectedTab to default:", tabList[ 0 ]);

      //undo setSelectedTab(tabList[0]);
      onSelectedChange(tabList[ 0 ])
    }

    //undo selectedTab= tabList[0];

  //  console.log("SmartTabs checkSetSelected URL Tab:", queryTab);
  //  console.log("SmartTabs checkSetSelected selectedTab:", selectedTab);
   // console.log("SmartTabs checkSetSelected props.selectedTab:", props.selectedTab);


    if (!LIB.Common.isNullOrEmpty(queryTab) && LIB.Common.isNullOrEmpty(props.selectedTab)) {
     // console.log("SmartTabs checkSetSelected props.selectedTab!=queryTab:", queryTab);

      onSelectedChange(queryTab)

      // update();
    }
    else  if (!LIB.Common.isNullOrEmpty(selectedTab) && LIB.Common.isNullOrEmpty(props.selectedTab)) {
      //console.log("SmartTabs checkSetSelected props.selectedTab!=selectedTab:", selectedTab);

      onSelectedChange(selectedTab)

      // update();
    }
    else if (!LIB.Common.isNullOrEmpty(queryTab) && queryTab !== selectedTab) {

      onSelectedChange(queryTab)

      //undo setSelectedTab(queryTab);
      //undo selectedTab = queryTab;

      // update();
    }
    else if (LIB.Common.isNullOrEmpty(selectedTab) && tabList.length > 0) {

      let selectedTabItem = tabList[ 0 ]?.replace(" ", "")?.replace(' ', '')?.trim();
      //undo setSelectedTab(selectedTabItem);

      console.log(`SmartTabs NO URL tab, set to 1st: |${selectedTab}|`);

      onSelectedChange(selectedTabItem)


      //update();
    }

    //if(isFirstTime)
    //    update();

  //  console.log('SmartTabs componentDidUpdate selectedTab 2', selectedTab);
  //  console.log('SmartTabs componentDidUpdate queryTab', queryTab);

  }



  function onTabChange(e: React.ChangeEvent<{}>, newValue: number) {
   // console.log("SmartTabs onTabChange");

    var id: string = (e.currentTarget as any).id;
    if (!LIB.Common.isNullOrEmpty(id))
      id = id.replace("Tab", "");

    var selectedTab2 = id;

   // console.log('SmartTabs selectedTab2', selectedTab2);


    if (!LIB.Common.isNullOrEmpty(props.startUrl))
      App.RouteManager.routeToPath(`${props.startUrl}${selectedTab2}`);

    //App.AppBase.history.push(`${props.startUrl}${selectedTab2}`);

    onSelectedChange(selectedTab2)

    //undo setSelectedTab(selectedTab2);

    /*   console.log('onTabChange new tab', id);
    console.log('onTabChange selectedTab', selectedTab);
    console.log('onTabChange selectedTabIndex', selectedTabIndex);
 */
    update();
  }

  function onSelectedChange(newTab: any) {

  //  console.log('SmartTabs onSelectedChange, tabs changed', newTab);

    if(!LIB.Common.isNullOrEmpty(newTab))
      newTab = LIB.StringHelper.replaceAll(newTab,' ','');


    if (props.onSelectedChange)
      props.onSelectedChange(newTab);
    else
      setSelectedTab(newTab);


  }


  function update() {
  //  console.log('SmartTabs update, tabs changed');
    //forceUpdate();

    if (props.forceUpdate)
      props.forceUpdate();
  }


  function getTab(name: string) {
    var id = name.replace(" ", "");
    //console.log('getTab id:', id);

    return (
      <Tab
        id={id + "Tab"}
        label={<div style={{ width: "100%", textAlign: "left" }}>{name}</div>}
        style={{ color: "#0c71d6" }}
        key={id}
      />
    );

    return;
  }


  console.log("getTabs");

  var isMobile = App.AppBase.getIsMobile();



  var theme = App.AppBase.theme;
  const classes: any = {
    root: {
      //flexGrow: 1,
/*       backgroundColor: "white",
 */      display: "flex",
      // maxWidth:200
      // height: 224,
      height: "90%",
    },
    tabs: {
      borderRight: `1px solid #ccc`,
      height: "100%",
      // maxWidth:200,
      minHeight: "700px",
      overflow: 'visible',
      minWidth:props.tabListWidth?props.tabListWidth:undefined
    },
  };

  var state = props;
  var tabList = state.tabList;

  //undo selectedTab = props.selectedTab;

  if (!LIB.Common.isNullOrEmpty(props.selectedTab) && selectedTab != props.selectedTab) {
    console.log("getTabs setSelectedTab");
    setSelectedTab(props.selectedTab);

  }

  //  var selected = selectedTabIndex;
  var theme = App.AppBase.theme;



  var selectedIndex = tabList.findIndex((c) => c.replace(' ', '') === selectedTab);
  if (selectedIndex < 0) {
    selectedIndex = 0;
  }

 // console.log("SmartTabs getTabs selectedIndex", selectedIndex);
 // console.log("SmartTabs getTabs tabList", tabList);
 // console.log("SmartTabs getTabs selectedTab", selectedTab);
 // console.log("SmartTabs getTabs props.selectedTab", props.selectedTab);


  var orientation: any = "vertical";

  if (props.orientation)
    orientation = props.orientation;

  if (isMobile) {
    orientation = "horizontal";

  }

  if (orientation === "horizontal") {
    classes.root.display = 'block';
    classes.tabs.minHeight = undefined;
  }


  return (
    <div  style={classes.root}>
      <Tabs

        orientation={orientation}
        value={selectedIndex}
        onChange={onTabChange.bind(this)}
        style={classes.tabs}
      >

        {tabList.map((item) => getTab(item))}

      </Tabs>

      {props.children}


    </div>
  );


}

