import React, { ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';

import './LY_WebsiteInput.css';

import { Button, Badge, Tooltip } from '@mantine/core';
import { useToggle } from '@mantine/hooks';

interface LY_WebsiteInputProps extends LY.LY_InputBaseProps {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  onItemClick?: Function; //React.MouseEvent<HTMLElement>
  viewRenderer?: React.ReactNode;
  editRenderer?: React.ReactNode;
  target?: string;
  href?: string;
  linkProps?: React.InputHTMLAttributes<HTMLAnchorElement>;
  onValueChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;
  maxDisplayLength?: number;
  inputViewEditContainerClassName?: string;
  isForList?: boolean;

}
 
type combinedProps = LY_WebsiteInputProps & React.InputHTMLAttributes<HTMLInputElement>;

export const LY_WebsiteInput = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    onItemClick,
    onValueChange,
    onChange,
    viewRenderer,
    editRenderer,
    href,
    title,
    target = '_blank',
    readOnly,
    linkProps,
    maxDisplayLength,
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();
    
  const [model, setModel] = React.useState(props.model || new LY.DataItemModel());


  React.useEffect(() => {

    setModel(props.model || new LY.DataItemModel());

  }, [ props.model ]); 

  

  function getViewMode() {

    // if(model?.rowId=='7da8432c-ec88-47bd-93e0-584d530bfd11')
   // console.log('LY_WebsiteInput getViewMode model: ',model);


    let value = model?.value;
    var col = model?.column;

/*     if(!props.isForList)
        console.log('LY_WebsiteInput getViewMode value1: ',value);
 */
    if (LIB.Common.isNullOrEmpty(href))
      href = value;

    if (col?.clientPrepend)
      href = `${col.clientPrepend}${href}`;

    if (col?.clientAppend)
      href = `${href}${col.clientAppend}`;

    if (LIB.Common.isNullOrEmpty(title))
      title = href;

    var columnWidth = Number(col?.columnWidth);

   // console.log('LY_WebsiteInput columnWidth: ',columnWidth);

    if(!columnWidth || isNaN(columnWidth) || columnWidth>300)
      columnWidth = 300;
 
    if(!maxDisplayLength)
       maxDisplayLength = columnWidth/9;

    var displayValue =  value;//LIB.StringHelper.maxLength(value, maxDisplayLength);
   
    if(href && !href.toLocaleLowerCase().startsWith('http'))
      href = `http://${href}`;

    return <a
    className='LY_WebsiteInputLink'
    target={target}
    href={href}
    title={title}
    {...linkProps}
  // onClick={onLocalNameClick}
  >{displayValue}</a>

  }
 
 

  function onLocalValueChange(m: LY.DataItemModel, e: ChangeEventHandler | undefined) {

    console.log('LY_WebsiteInput onLocalChange model: ', m);

    if(model)
      model.value = m.value?.trim();

      setModel({...model});

    // forceUpdate();

    if (onValueChange)
       onValueChange(model!, e);

  }

  function render() {

  /*   if (mode == 'view')
      return getViewMode();

    return getEditMode();
 */

    var result = <LY._LY_InputBaseViewEdit 
 
      {...props}
      model={model}
      viewRenderer={getViewMode()}
     // editRenderer={getEditMode()}
      onValueChange={onLocalValueChange}
      readOnly={readOnly}
      
      />;

    return result;
 
  }


  return render();

});


