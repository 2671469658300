import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
import * as MT from '@mantine/core';
import * as Icons from '@tabler/icons-react';

import {
  Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress,
  InputAdornment, FormControlLabel, Checkbox, Tooltip
} from '@mui/material';


import { useEffect, useState, useImperativeHandle, useRef, CSSProperties } from 'react';
 
interface AccountProfileProps {
  state: App.AccountState;
  hideButtons?: boolean;
  children?: React.ReactNode;

}

//export class AccountProfile extends React.Component<AccountProfileProps> {

interface AccountProfileRef {
  onSaveClick: (e: React.ChangeEvent<{}>) => void;
}


export const AccountProfile = React.forwardRef<AccountProfileRef, AccountProfileProps>((
  {
    state,
    ...props
  },
  ref
) => {


  const validationManager: App.ValidationManager = new App.ValidationManager();
  const profileManager: App.ProfileManager = new App.ProfileManager();

  const forceUpdate = LIB.useForceUpdate();

  useImperativeHandle(ref, () => ({
    onSaveClick
  }));

  function resetData() {
    console.log('AccountProfile resetData');
    var profile = state.profile;

    profile.setData(state.profileOriginal);
  }


  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    console.log('onInputChange');

    const item = state.profile;
    var target: any = e.target;

    // item.firstName='bb';
    // console.log('onInputChange value', target.value);
    // console.log('onInputChange item', item);

    var key = LIB.FormHelper.setInputChangeData(e.target, item);

    //console.log('onInputChange key', key);
    //console.log('onInputChange value2', target.value);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    validationManager.validateAccountProfileEdit(key, item);

    console.log('onInputChange item', item);

    forceUpdate();
  }


  function onDateChange(date: any | null, value?: string | null) {

    console.log(date);
    console.log(value);

  }



  function onAutocompleteChange(name: string, value: any, details: any) {
    console.log('onAutocompleteChange');

    const item = state.profile;

    var selected = {
      name: name,
      value: value
    }

    console.log('onAutocompleteChange selected', selected);


    var key = LIB.FormHelper.setInputChangeData(selected, item);

    if (LIB.Common.isNullOrEmpty(key))
      return;

    validationManager.validateAccountProfileEdit(key, item);


    forceUpdate();
  }

  function onCloseClick() {

    resetData();
    App.RouteManager.routeToPath('/')

  }


  function onSaveClick(e: React.ChangeEvent<{}>) {
    console.log('onSaveClick');


    var vm = state.vm;
    var profile = state.profile;

    var isValid = validationManager.validateAccountProfileEdit(null, profile);

    if (!isValid) {
      console.log('validateAddCandidateModal NOT valid');
      forceUpdate();
      return;
    }

    var sm = new Api.AccountUpdateProfileSm();
    sm.profile = profile.getAppUserVm();
    App.AppStore.dispatch(App.ActionTypes.ACCOUNT_PROFILE_UPDATE_REQUEST, sm);

    vm.isActionInProgress = true;

    forceUpdate();

  }



  function getIncompleteAlert() {
    var item = state.profile;

    console.log('getIncompleteAlert hasDataLoaded', state.hasDataLoaded);
    console.log('getIncompleteAlert isProfileDetailsComplete', item.isProfileDetailsComplete);

    const icon = <Icons.IconExclamationCircle />;

    if (state.hasDataLoaded && !item.isProfileDetailsComplete)
      return <MT.Alert color="red" icon={icon}
      style={{ marginBlock: 20 }}>Please complete your profile to Continue! </MT.Alert>;

    return null;
  }

  function render() {

    //console.log('ProjectScopeForm render');

    var item = state.profile;

    var user = state.profile;//App.AppBase.currentUser;
    //console.log('render user',user);

    //if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || LIB.Common.isNullOrEmpty(item))
    //  return null;//<LIB.Loading />;

    var theme = App.AppBase.theme;

    var formControl: CSSProperties = {
      margin: 2,
      //minWidth:'25rem',
      width: '100%',
      paddingRight: 20,
      marginLeft: 0
      // padding:'20px, 16px'
    };
    var fullWidth: CSSProperties = {
      margin: 2,
      paddingRight: 20,
      marginLeft: 0


    };

    var formInput: CSSProperties = {

      height: 50,
      //padding:15
      // padding:'20px, 16px'
    };

    var dateInputLabelProps = {
      shrink: true,
      style: { fontSize: 20 }

    };

    var textFieldStyle = { paddingTop: 20, marginBottom: 10 };


    var formControlHalf = App.StyleHelper.getFormControlHalfWidthStyle();

    var countries = LIB.LocationCountryHelper.getCountries();


    return <div style={{ marginLeft: 10 }}>

      {getIncompleteAlert()}

      <h2 >Profile</h2>
 
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} >
            <TextField
              name="firstName" label="First Name"
              value={user.firstName || ''}
              {...App.AppBase.getInputErrorProps('firstName')}
              onChange={onInputChange}
              style={formControl}
              variant='standard'
              inputProps={{ style: formInput }}
            />
          </Grid>
          <Grid item xs={12} sm={6}

          >
            <TextField
              name="lastName" label="Last Name"
              variant='standard'
              value={user.lastName || ''}
              {...App.AppBase.getInputErrorProps('lastName')}
              onChange={onInputChange}
              style={formControl}
              inputProps={{ style: formInput }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} >
            <TextField
              name="email" label="Email"
              variant='standard'
              value={user.email || ''}
              {...App.AppBase.getInputErrorProps('email')}
              style={formControl}
              inputProps={{ style: formInput }}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}
          >
            <TextField
              name="phone" label="Phone"
              variant='standard'
              value={user.phone || ''}
              {...App.AppBase.getInputErrorProps('phone')}
              onChange={onInputChange}
              style={formControl}
              inputProps={{ style: formInput }}

            />
          </Grid>
        </Grid>


        {/*   <App.FullAddress
          address={user.address}
          city={user.city}
          state={user.state}
          zipCode={user.zipCode}
          country={user.country}
          onInputChange={onInputChange}
          onAutocompleteChange={onAutocompleteChange}
        />
 */}

        <div >
          <FormControlLabel
            control={
              <Checkbox
                name="enableProductUpdatesEmail"
                color="primary"
                {...App.AppBase.getInputErrorProps('enableProductUpdatesEmail')}
                checked={user.enableProductUpdatesEmail}
                onChange={onInputChange}
                style={fullWidth}
                inputProps={{ style: formInput }}

              />
            }
            style={{ marginLeft: 0 }}
            labelPlacement="start"
            label="Receive Product Updates Emails?"
          />

          <Tooltip arrow placement="right" className='formHelperTooltip'
            title="Stay updated on the Loocey product updates and chagnes."

          >
            <Icons.IconInfoCircle style={{ marginLeft: 0, marginBottom: -8, color:'#ccccc'  }}   />
          </Tooltip>





        </div>


        {!props.hideButtons &&
          <div style={{ marginTop: 50 }}>
            <MT.Button
              id="btnCancel"
              variant="default"
              disabled={!user.isProfileDetailsComplete}
              onClick={() => onCloseClick()}
            >Close</MT.Button>

            <MT.Button
              id="btnSave"
              color="primary"
              variant="contained"
              style={{ marginLeft: 15 }}
              onClick={onSaveClick}

            >Save</MT.Button>

          </div>
        }
 
      {props.children}
    </div>;


  }
  return render();
});

