import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class ListStateDataRecordsReducers {


    constructor() {

    }

    public static getReducers(state: App.ListState | undefined, action: App.AppAction): App.ListState {
        var str = "";

        var result = new App.ListState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("LIST_DATA_RECORDS"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;
        var currentUser = App.AppBase.getCurrentUser(appState);
        result.apiErrorMessage = '';
        result.vm.apiErrorMessage = '';
        globalState.globalUIState.apiErrorMessage  = '';
    
        let selectedWorkspace= globalState.workspaceVm?.selectedWorkspace;
        let workspaceId = selectedWorkspace?.workspaceId;
  
        let listDataService = new App.ListDataService(workspaceId);
        let listSettingsService = new App.ListSettingsService(workspaceId);
        let linkedListDataService = new App.LinkedListDataService(workspaceId);

        
 
        // const action = incomingAction as KnownAction;
        switch (action.type) {

             
            
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        

        return result;
    }



}