import "./LY_NotesModule_Notes.css";

import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as Api from "app-api";

import React from "react";

import { Highlight } from "@mantine/core";
import { LY_NoteModule_Input } from "../LY_NotesModule_Input/LY_NoteModule_Input";
import { LY_NotesModule_Notes_Reply_Card } from "../LY_NotesModule_Notes_Reply_Card.tsx/LY_NotesModule_Notes_Reply_Card";
import { LY_NoteModule_Note_Menu } from "../LY_NotesModule_Note_Menu/LY_NotesModule_Note_Menu";
import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";

import { MDXProvider } from "@mdx-js/react";
import MDX from "@mdx-js/runtime";

import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";
import { LY_NotesModule_Update_Input } from "../LY_NotesModule_Update_Input/LY_NotesModule_Update_Input";
import LY_NotesModule_User_Card from "../LY_NotesModule_User_Card/LY_NotesModule_User_Card";

export const LY_NotesModule_Notes: React.FC<LY_NotesModuleProps> = (props) => {
  const context = useNotesModuleContext();

  function noteTextContainerRenderer(note: Api.Note) {
    const currentSelectedNote = context.state.selectedNote?.id === note.id;

    if (currentSelectedNote && context.state.updatingNote) {
      return <LY_NotesModule_Update_Input {...props} note={note} />;
    } else {
      return (
        <Highlight
          className="LY_NotesModule_Note_Card_Content_Text"
          highlight={context.state.searchTextInNotes}>
          {note.message!}
        </Highlight>
      );
    }
  }

  function notePinBtnRenderer(note: Api.Note) {
    function onNotePinClick(id: string) {
      context.selectNote(id);
      context.toggleNotePin();
    }

    if (note.pinned) {
      return (
        <Icons.IconPinFilled
          title="Unpin"
          className="LY_NotesModule_Note_Reply_Card_Pin_Filled"
          onClick={() => {
            onNotePinClick(note.id!);
          }}
        />
      );
    } else {
      return (
        <Icons.IconPin
          title="Pin"
          className="LY_NotesModule_Note_Reply_Card_Pin"
          onClick={() => {
            onNotePinClick(note.id!);
          }}
        />
      );
    }
  }

  function noteFooterRenderer(note: Api.Note) {
    function onNoteReplyBtnClick(id: string) {
      if (context.state.selectedNote?.id === id) {
        context.setReplyInputOn(!context.state.replyInputOn);
      } else {
        context.selectNote(id);
        context.setReplyInputOn(true);
      }
      context.setNewReplyMessage("");
    }

    return (
      <div className="LY_NotesModule_Note_Reply_Card_Footer LY_Flex_Row">
        {notePinBtnRenderer(note)}
        <span
          className="LY_NotesModule_Note_Reply_Card_Reply_Button"
          onClick={() => onNoteReplyBtnClick(note.id!)}>
          Reply
        </span>
      </div>
    );
  }

  function noteRepliesRenderer(note: Api.Note) {
    if (Array.isArray(note.replies)) {
      return (
        <div className="LY_NotesModule_Note_Card_Replies">
          {note?.replies?.map((reply) => (
            <LY_NotesModule_Notes_Reply_Card
              {...props}
              note={note}
              reply={reply}
              key={reply.id}
            />
          ))}
        </div>
      );
    } else {
      return null;
    }
  }

  function noteReplyInputRenderer(note: Api.Note) {
    const isCurrentNote = context.state.selectedNote?.id === note.id;

    if (isCurrentNote && context.state.replyInputOn) {
      return <LY_NoteModule_Input {...props} reply={true} />;
    }
  }

  function noteMenuRenderer(note: Api.Note) {
    return <LY_NoteModule_Note_Menu note={note} {...props} />;
  }

  function noteUserAvatarRenderer(note: Api.Note) {
    return <LY_NotesModule_User_Card note={note} {...props} />;
  }

  function notesLoadingRenderer() {
    return (
      <div className="LY_NotesModule_Loading_Container">
        <LIB.Loading show={true} />
      </div>
    );
  }

  function notesRenderer() {
    var notes = context.state.searchTextInNotes
      ? context.state.searchNotes
      : context?.state?.notes;

    return (
      <div
        ref={context.state.scrollContainerRef}
        className="LY_NotesModule_scrollbar">
        {notes?.map((note, index) => (
          <div className="LY_NotesModule_Note_Card" key={index} id={note.id}>
            <div className="LY_NotesModule_Note_Card_Content">
              {noteUserAvatarRenderer(note)}
              <div className="LY_Flex_Row">
                <div className="LY_NotesModule_Note_Card_Content_Date">
                  {LIB.DateHelper.getFriendlyDate(note._ly_date_created)}
                </div>
                {noteMenuRenderer(note)}
              </div>
            </div>

            {noteTextContainerRenderer(note)}
            {noteFooterRenderer(note)}
            {noteReplyInputRenderer(note)}
            {noteRepliesRenderer(note)}
          </div>
        ))}
      </div>
    );
  }

  function notesNewNoteInputRenderer() {
    return <LY_NoteModule_Input {...props} />;
  }

  function notesMainContentRenderer() {
    if (context.state.isActionInProgress) {
      return notesLoadingRenderer();
    } else {
      return notesRenderer();
    }
  }

  function render() {
    return (
      <>
        {notesMainContentRenderer()}
        {notesNewNoteInputRenderer()}
      </>
    );
  }

  return render();
};
