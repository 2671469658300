import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';

import './DetailRightPanel.css';
import { Badge, Tooltip, CloseButton } from '@mantine/core';
import { DetailContainer } from '../DetailContainer/DetailContainer';

interface DetailRightPanelProps {
  state: App.ListState;
  model: LY.DataItemModel;
  onChange?: Function;

}

export const DetailRightPanel: React.FC<DetailRightPanelProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();


  function onClosePanelClick() {
    var vm = props.state.vm;
    vm.isDetailRightPanelOpen = false;

    App.AppStore.dispatch(App.ActionTypes.LIST_SETTINGS_DETAIL_PANEL_CHANGE, vm);
  }

  function render() {

    var item = props.model;
    var listName = props.state?.list.name || '';
    var name = item?.row['name'] || '';

    return (

      <div className='ListDetailPanelContainer' >

        <div className='DetailRightPanelHeader'>
          <CloseButton className='DetailRightPanelCloseBtn'
            onClick={() => onClosePanelClick()}
          />

        <h1>{`${listName}`}</h1>
 

        </div>
        <h2 style={{marginTop:'.6rem', marginBottom:'.6rem'}}>{`${name}`}</h2>


        <div className='ListDetailPanelContent'>


        <DetailContainer state={props.state} model={props.model} />


        </div>

      </div>

    );

  }




  return render();
};
