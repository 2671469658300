import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';

import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import axios from 'axios';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

//import { AlignJustify } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import * as Icons2 from '@mui/icons-material/';


interface PasswordResetSuccessProps {
  //vm: App.ProjectListState;
}

class PasswordResetSuccess extends React.Component<PasswordResetSuccessProps, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('PasswordResetSuccess componentDidMount');

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('PasswordResetSuccess componentDidUpdate');
  }

  public render() {

    console.log("PasswordResetSuccess Render");
 

    return <App.QuickMessageContainer 
    iconType='success'
      message='Thank You, your password has successfully been reset!'
  >
 
      <div  >You can now Login with your new Password!</div>

      <div style={{marginTop:30, textAlign:'center'}}>
      <MT.Button variant="contained" color="primary" 
                onClick={(e) => App.RouteManager.routeToPath("/Login")} 
       >Login</MT.Button>
       </div>
 
    </App.QuickMessageContainer>;



  }
};
export default connect(
  (state: App.AppState) => ({ ...state.globalData })
)(PasswordResetSuccess as any);
