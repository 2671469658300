import * as Api from "app-api";
import * as LIB from "_LIB";

import { LY_ListFilterModuleState } from "../Context/LY_ListFilterModuleState";

export class ValidateManager extends LIB.BaseManager {

    public state: LY_ListFilterModuleState;
    public forceUpdate: Function;

    constructor(state: LY_ListFilterModuleState, forceUpdate: Function) {
        super("id");
        this.state = state;
        this.forceUpdate = forceUpdate;
    }

    public validateOne<Data>(validateData: Data, fields: (keyof Data)[], errorMessages: Record<string, string>, rowIndex: number, groupIndex?: number) {
        var errors: any = this.state.errors;
        fields.forEach((field) => {
            var errorKey = groupIndex !== undefined ? groupIndex + (field as string) + rowIndex : rowIndex + (field as string);
            if (LIB.Common.isNullOrEmpty(validateData[field])) {
                errors.set(errorKey, errorMessages[field as string]);

            } else {
                errors.delete(errorKey);
            }
        })
        this.forceUpdate();
        return errors.length > 0;
    }
    public validateAll() {
        const errorMessages = { name: 'Name is required', value: 'Value is required', op: 'Operator is required' }
        this?.state?.view?.filter?.conditionSets?.forEach((conditionSet, groupIndex) => {
            conditionSet.conditions.forEach((condition, rowIndex) => {
                this.validateOne<Api.FilterCondition>(condition, ["name", "value", "op"], errorMessages, rowIndex, groupIndex)
            })
        })
        this?.state?.view?.filter?.conditionSet?.conditions?.forEach((condition, rowIndex) => {
            this.validateOne<Api.FilterCondition>(condition, ["name", "value", "op"], errorMessages, rowIndex)
        })
    }
}
