import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_StatusDropDownState } from "../Context/LY_StatusDropDownState";

export class LY_DropDownModuleManager extends LIB.BaseManager {
    public state: LY_StatusDropDownState;
    public forceUpdate: Function;
    public props: LY.LY_StatusDropDownProps;

    constructor(props: LY.LY_StatusDropDownProps, state: LY_StatusDropDownState, forceUpdate: Function) {
        super("id");
        this.state = state;
        this.forceUpdate = forceUpdate;
        this.props = props;
    }



    public getStatuses() {


        var service = new App.StaticDropDownService(this.props.workspaceId);
        this.state.isActionInProgress = true;
        this.forceUpdate();

        return service.getDropDownItems(this.state.model?.listId, this.state.model?.column?.listColumnId!,
            (result: Api.SystemLookupType[]) => {
                this.state.statuses = result;
                this.state.isActionInProgress = false;
                this.forceUpdate();

            },
            this.onServiceError.bind(this)
        );
    }

    public onColumnChange() {


        var service = new App.ListColumnService(this.props.workspaceId);
        this.state.isActionInProgress = true;
        this.forceUpdate();

        var col = this.state?.model?.column;
        if (!col || !col.listColumnId) {
            console.error('error: LY_DropDownModuleManager onColumnChange invalid column:', col);
            return;
        }


        service.updateColumn(col,
            () => {
                App.AppBase.showSaveSuccess();
                this.state.isActionInProgress = false;
                this.forceUpdate();

                if (this.props.onEditSaveSuccess)
                    this.props.onEditSaveSuccess(this.state.model, this.state.statuses, this.state.selectedItem)
            },
            this.onServiceError.bind(this)
        );

    }

    public saveChanges(sm: Api.SystemLookupType[]) {


        console.log('LY_DropDownModuleManager saveChanges sm:', sm);

        var service = new App.StaticDropDownService(this.props.workspaceId);

        service.saveChanges(sm,
            //onSuccess
            (result: Api.SystemLookupType[]) => {
                console.log('LY_DropDownModuleManager saveChanges onSuccess:', result);

                this.state.isActionInProgress = false;

                this.state.statuses = result;
                this.state.chagnedItems = [];
                this.state.isEditMode = false;
                this.forceUpdate();

                if (this.props.onEditSaveSuccess)
                    this.props.onEditSaveSuccess(this.state.model, result, this.state.selectedItem);

                App.AppBase.showSaveSuccess();


            },
            //onError
            this.onServiceError.bind(this)
        );

        this.state.isActionInProgress = true;
        this.forceUpdate();

    }



    public update(sm: Api.SystemLookupType[]) {

        return;


        var service = new App.StaticDropDownService(this.props.workspaceId);


        this.state.isActionInProgress = true;
        this.forceUpdate();


        const updatePromises = sm.map(item => {
            return new Promise((resolve, reject) => {
                service.update(
                    item,
                    (i: unknown) => {
                        resolve(i)
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
            });
        });


        return Promise.all(updatePromises)
            .then(() => {
                //console.log('Updated')
                this.state.isActionInProgress = false;
                this.forceUpdate();
            })
            .catch(error => {
                this.onServiceError(error);
            });

    }

    public delete(id: string) {

        var service = new App.StaticDropDownService(this.props.workspaceId);

        this.state.isActionInProgress = true;
        this.forceUpdate();

        return service.delete(id,
            () => {
                const updatedStatuses = this.state.statuses.filter(item => item.id !== id)
                this.state.statuses = updatedStatuses;
                this.state.isActionInProgress = false;
                this.forceUpdate();

                App.AppBase.showSaveSuccess();


            },
            this.onServiceError.bind(this)
        );
    }

    public onServiceError(error: any) {

        console.log('LY_DropDownModuleManager onServiceError error:', error);
        console.log('LY_DropDownModuleManager onServiceError state:', this.state);


        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
        this.state.isActionInProgress = false;
        this.state.errors.set('all', errorMessage);
        this.forceUpdate();

    }
}
