import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
import * as MT from '@mantine/core';

import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, Accordion, rem, Tooltip } from '@mantine/core';

import { useEffect, useState, useImperativeHandle, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';
import { ListColumnRelationshipSettingsVm } from '_LY_Components/List/Models/ListColumnRelationshipSettingsVm';



interface ListColumnRelationshipSettingsProps {
  state: App.ListState;
  column?: Api.ListColumn;
  //onValidationDone?: (errors: Map<string, any>) => void;
  onSelectionsDone?: (selections: ListColumnRelationshipSettingsVm) => void;
  isAddColumnView?: boolean;
}

interface ListColumnRelationshipSettingsRef {
  validateAll: () => void;
}


export const ListColumnRelationshipSettings = React.forwardRef<ListColumnRelationshipSettingsRef, ListColumnRelationshipSettingsProps>((
  {
    state,
    isAddColumnView = false,
    ...props
  },
  ref  
) => {


  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
  const { view, list, selectedColumn, columnTypes } = state;
  const columns = view?.columns;
  
  const currentWorkspace = App.AppBase.currentWorkspace;
  var column = props.column || selectedColumn;

  const initialVm = new ListColumnRelationshipSettingsVm(column);
  initialVm.selectedWorkspace = column?.fkWorkspace || currentWorkspace;


  const [ vm, setVm ] = React.useState<ListColumnRelationshipSettingsVm>(initialVm);
  //const [ selectedList, setSelectedList ] = React.useState<Api.List>(new Api.List());



  //const [ relList, setRelList ] = React.useState<Api.List>();
  //const [ relColumn, setRelColumn ] = React.useState<Api.ListColumn>();


  const propsVm = state.vm;

  React.useEffect(() => {
    console.log('ListColumnRelationshipSettings useEffect currentWorkspace: ', currentWorkspace);
    console.log('ListColumnRelationshipSettings useEffect vm: ', vm);
    console.log('ListColumnRelationshipSettings useEffect column: ', column);

    // console.log('ListColumnRelationshipSettings useEffect state.listViews: ', state.listViews);

    if (currentWorkspace)
      onWorkspaceChange(currentWorkspace);

    if (column?.fkWorkspace && currentWorkspace?.workspaceId != column?.fkWorkspace?.workspaceId)
      onWorkspaceChange(column.fkWorkspace);

    /*    if(column?.fkList)
          onListChange(column.fkList); */


  }, [ currentWorkspace ]);

  useImperativeHandle(ref, () => ({
    validateAll
  }));

  function onServiceError(error: any) {

    console.error('ListColumnRelationshipSettings onServiceError:', error);
    let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
    vm.isActionInProgress = false;
    vm.errors.set('all', errorMessage);
    forceUpdate();

  }

  function onListChange(item: Api.List) {

    console.log('ListColumnRelationshipSettings vv onListChange item:', item);

    // setRelList(item);
    vm.selectedList = item;
    vm.selectedDisplayColumn = new Api.ListColumn();
    vm.selectedValueColumn = new Api.ListColumn();

    vm.listColumns = item?.columns || [];
    // vm.listColumns = vm.listColumns.filter(x => !x.db);
    vm.listColumns = LIB.ObjectHelper.sortArray(vm.listColumns, 'columnOrder');
    vm.listViews = [];

    vm.childListViews = vm.selectedWorkspace.listViews?.filter(x=>x.listId==column.listId) || [];

    console.log('ListColumnRelationshipSettings vv selectedWorkspace:', vm.selectedWorkspace);
    console.log('ListColumnRelationshipSettings vv childListViews:', vm.childListViews);

    column.fkListId = vm.selectedList.listId;

    validate('selectedList');

    setVm({ ...vm });

    if (!item || !item.listId) {
      return;
    }

    var service = new App.LinkedListService(currentWorkspace?.workspaceId);
    service.getListViews(item.listId, vm.selectedWorkspace,
      //onSuccess
      (result: any) => {
        console.log('getListViews onSuccess:', result);

        vm.listViews = result;
        setVm({ ...vm });

        // forceUpdate();
      },
      //onError
      onServiceError
    );

    vm.isActionInProgress = true;
    forceUpdate();

  }

  function onListViewChange(item: Api.ListView) {
    vm.selectedListView = item;
    validate('selectedListView');
    column.fkListViewId = vm.selectedListView.listViewId;

    setVm({ ...vm });
  }


  function onDisplayColumnChange(item: Api.ListColumn) {

    vm.selectedDisplayColumn = item;
    validate('selectedDisplayColumn');


    column.fkDisplayListColumnId = vm.selectedDisplayColumn.listColumnId;

    setVm({ ...vm });
    // setRelColumn(item);

  }



  function onChildListViewChange(item: Api.ListView) {
    vm.selectedChildListView = item;
    validate('selectedChildListView');

    column.fkChildListViewId = vm.selectedChildListView?.listViewId;

    setVm({ ...vm });
  }


  function onValueColumnChange(item: Api.ListColumn) {
    vm.selectedValueColumn = item;
    validate('selectedValueColumn');


    column.fkListColumnId = vm.selectedValueColumn.listColumnId;

    setVm({ ...vm });
    // setRelColumn(item);
  }

  function onWorkspaceChange(workspace: Api.Workspace) {

    console.log('ListColumnRelationshipSettings onWorkspaceChange workspace:', workspace);
    console.log('ListColumnRelationshipSettings onWorkspaceChange state.listViews:', workspace.listViews);

    // e.preventDefault();

    vm.selectedWorkspace = workspace;

    column.fkWorkspaceId = vm.selectedWorkspace.workspaceId;
    column.fkIsLinkedWorkspace = vm.selectedWorkspace.isLinkedWorkspace;

    setVm({ ...vm });

    console.log('ListColumnRelationshipSettings onWorkspaceChange vm.childListViews:', vm.childListViews);


    if (!workspace)
      return;


    /*  var isValid = validate('all');
     if (!isValid) {
       console.log('ListColumnRelationshipSettings onSubmit isValid:', isValid);
       forceUpdate();
       return false;
     } */

    var workspaceId = workspace?.workspaceId;

    if (!currentWorkspace || !workspaceId)
      return;

    var service = new App.WorkspaceService(currentWorkspace?.workspaceId);

    service.getWorkspaceListsWithColumns(workspaceId,
      //onSuccess
      (result: any) => {
        console.log('getWorkspaceListsWithColumns onSuccess:', result);

        /*  var updatedVm = new ListColumnRelationshipSettingsVm();
         updatedVm.selectedWorkspace = workspace;
         updatedVm.workspaceLists = result;
         updatedVm.childListViews = state.listViews; */
        // setSelectedList(updatedVm.selectedList)

        vm.workspaceLists = result;

        setVm({ ...vm });

        if (column.fkList) {

          var fkList = vm.workspaceLists.find(x => x.listId == column.fkListId);
          if (fkList) {
            onListChange(fkList);
          }

          if (column.fkListColumn)
            onValueColumnChange(column.fkListColumn);

          if (column.fkDisplayListColumn)
            onDisplayColumnChange(column.fkDisplayListColumn);

          if (column.fkListView)
            onListViewChange(column.fkListView);

        }

        console.log('getWorkspaceListsWithColumns onSuccess updatedVm:', vm);
        forceUpdate();
      },
      //onError
      onServiceError
    );

    vm.isActionInProgress = true;
    forceUpdate();

  }

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('ListColumnRelationshipSettings onInputChange key:', key);
    // console.log('ListColumnRelationshipSettings onInputChange selectedValueColumn:', selectedValueColumn);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('ListColumnRelationshipSettings onValueChange key:', key);
    //console.log('ListColumnRelationshipSettings onValueChange value:', value);

    //console.log('ListColumnRelationshipSettings onValueChange selectedValueColumn:', selectedValueColumn);

    if (key == 'name') {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }

    //@ts-ignore
    vm[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedValueColumn', selectedValueColumn);

    forceUpdate();


  }

  function checkIfValidSendSelectionDone() {

    var isValid = validateAll();

    if (!isValid) {
      vm.errors.clear();
      return;
    }

    var item = column;
    /*     item.fkWorkspaceId = vm.selectedWorkspace.workspaceId;
        item.fkListId = vm.selectedList.listId;
        item.fkListViewId = vm.selectedListView.listViewId;
        item.fkListColumnId = vm.selectedValueColumn.listColumnId;
        item.fkDisplayListColumnId = vm.selectedDisplayColumn.listColumnId;
        item.fkChildListViewId = vm.selectedChildListView?.listViewId; */

    if (props.onSelectionsDone) {
      props.onSelectionsDone(vm);

    }

  }


  function validateAll() {
    console.log('ListColumnRelationshipSettings validateAll');

    var isValid = validate('all');

    if (isValid && props.onSelectionsDone)
      props.onSelectionsDone(vm);

    return isValid;
  }

  function validate(key: string): boolean {

    console.log('ListColumnRelationshipSettings validate key:', key);
    console.log('ListColumnRelationshipSettings validate vm:', vm);
    console.log('ListColumnRelationshipSettings validate column:', column);
    console.log('ListColumnRelationshipSettings validate listColumnClientType:', column?.listColumnClientType);


    var vAll = (key === 'all'); //validate all
    //var item = column;
    var item = vm;

    vm.errors.clear();
    //Relationship
    if ((vAll || key == 'selectedWorkspace') && !vm.selectedWorkspace?.workspaceId || vm.selectedWorkspace.workspaceId! < 1) {
      vm.errors.set('selectedWorkspace', `Workspace Selection is required`);
    }
    if ((vAll || key == 'selectedList') && !vm.selectedList?.listId) {
      vm.errors.set('selectedList', `List is required`);
    }
    if ((vAll || key == 'selectedValueColumn') && !vm.selectedValueColumn?.listColumnId) {
      vm.errors.set('selectedValueColumn', `Value Column is required`);
    }

    if ((vAll || key == 'selectedDisplayColumn') && !vm.selectedDisplayColumn?.listColumnId) {
      vm.errors.set('selectedDisplayColumn', `Display Column is required`);
    }


    if ((vAll || key == 'selectedValueColumn') && !vm.selectedValueColumn?.isUnique && vm.selectedValueColumn?.name!='id') {
      vm.errors.set('selectedValueColumn', `Value column MUST be either Unique or ID column`);
    }
/*     else if ((vAll || key == 'selectedValueColumn') && 
        vm.selectedValueColumn?.maxLength != column?.maxLength && column?.listColumnClientType!='uuid') {
      vm.errors.set('selectedValueColumn', `Value column MUST be either Unique or ID column`);
    } */


    /*   if ((vAll || key == 'selectedListView') && !vm.selectedListView?.listViewId) {
        vm.errors.set('selectedListView', `List View is required`);
      }  */
    /*  if ((vAll || key == 'selectedChildListView') && !vm.selectedChildListView?.listViewId) {
       vm.errors.set('selectedChildListView', `Child List View is required`);
     }  */

    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    //  console.log('ListColumnRelationshipSettings validate errors:', vm.errors);


    return isValid;

  }
  /* 
  
    // when Type changes, let's change what's selected 
    var listColumnTypeId = item.listColumnTypeId;
  
    //console.log('ListColumnRelationshipSettings watch listColumnTypeId:', listColumnTypeId);
    //console.log('ListColumnRelationshipSettings selectedValueColumn:', selectedValueColumn);
    //console.log('ListColumnRelationshipSettings columnTypes:', columnTypes);
    //console.log('ListColumnRelationshipSettings selectedValueColumnType:', selectedValueColumnType);
  
    var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == item?.listColumnTypeId);
  
    if (listColumnTypeId) {
      defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);
  
    } */


  function render() {

    console.log('ListColumnRelationshipSettings render vm:', vm);

    var item = column;

    // console.log('ListColumnRelationshipSettings render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) ||
      !state.hasDataLoaded ||
      !column ||
      //!propsVm.isColumnSettingsScreenOpen ||
      !column.isRelationship

    ) {
      return null;
    }



    var globalError = vm?.errors?.get('all');

    var workspacesVm = App.AppBase.getCurrentWorkspaceVm();
    var selectedWorkspace = workspacesVm?.selectedWorkspace;

    var workspaces = workspacesVm.allWorkspaces;// LIB.ObjectHelper.sortArray(workspacesVm.allWorkspaces, 'name');



    return <div className='UpdateColumnAdvancedTabGroups' >

      <MT.Alert variant="light" color="cyan"
        style={{ marginTop: 0, minHeight: 30, padding: 8 }} >
        Select Relationship settings, it should map to another List/Column.
      </MT.Alert>

      {!isAddColumnView &&
        <div>
          <h2>Current List:</h2>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
            <span> Current List: <b>{list?.name}</b> </span>

            <span> Current Column: <b>{item?.displayName}</b></span>
 
          </div>

          <LY.LY_DropDown
            key={'ListColumnRelationshipSettings_ChildListView' + item.listColumnId}
            name='selectedChildListView'
            label={'Detail List View'}
            helpText={<span>Optional: <b>{list?.name}</b> List View that will appear on Detail page of the Related Record</span>}
            isRequired={false}
            isAutoCompleteStyle={true}
            displayProperty={'name'}
            valueProperty={'listViewId'}
            optionsContainerStyle={{ minHeight: 200 }}
            optionsMaxHeight={150}
            selectedItem={vm.selectedChildListView}
            error={vm.errors.get('selectedChildListView')}

            placeholder='Select Child View'
            data={vm.childListViews}
            // disabled={!vm.selectedChildListView}
            onValueChange={(m: LY.DataItemModel, item: any) => {
              // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange m: ', m);
              // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange item: ', item);
              onChildListViewChange(item);
            }
            }
          />
        </div>
      }

      <h2>Relates To:</h2>
      <div className='LY_ListAndColumnSelection'
        style={{
          display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 5

        }}
      >

        <LY.LY_DropDown
          key={'ListColumnRelationshipSettings_workspace' + item.listColumnId}
          name='selectedWorkspace'
          label={'Relates To Workspace'}
          isRequired={true}
          //   value={model?.value}
          //   model={model}

          displayProperty={'name'}
          optionsContainerStyle={{ minHeight: 200 }}
          optionsMaxHeight={140}
          valueProperty={'workspaceId'}
          selectedItem={vm.selectedWorkspace}
          isAutoCompleteStyle={true}
          data={workspaces}
          error={vm.errors.get('selectedWorkspace')}
          //style={{width:width}}
          // anchorElement={context?.state?.selectedCellElement}
          onValueChange={(m: LY.DataItemModel, item: any) => {
            console.log('ListColumnRelationshipSettings Workspace onValueChange onChange m: ', m);
            console.log('ListColumnRelationshipSettings Workspace onValueChange onChange item: ', item);

            onWorkspaceChange(item);
          }

          }
        />

        <LY.LY_DropDown
          key={'ListColumnRelationshipSettings_list' + item.listColumnId}
          name='selectedList'
          label={'Relates To List'}
          isRequired={true}
          //   value={model?.value}
          //   model={model}

          isAutoCompleteStyle={true}
          displayProperty={'name'}
          optionsContainerStyle={{ minHeight: 200 }}
          optionsMaxHeight={150}
          error={vm.errors.get('selectedList')}
          valueProperty={'listId'}
          selectedItem={vm.selectedList}
          placeholder='Select List'
          data={vm.workspaceLists}
          //style={{width:width}}
          // anchorElement={context?.state?.selectedCellElement}
          onValueChange={(m: LY.DataItemModel, item: any) => {
            console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange m: ', m);
            console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange item: ', item);
            onListChange(item);

          }

          }
        />

        <LY.LY_DropDown
          key={'ListColumnRelationshipSettings_ListView' + item.listColumnId}
          name='selectedListView'
          label={'Relates To List View'}
          // helpText='Select the column that relates to this column'
          isRequired={false}
          isAutoCompleteStyle={true}
          displayProperty={'name'}
          valueProperty={'listViewId'}

          optionsContainerStyle={{ minHeight: 200 }}
          optionsMaxHeight={150}
          selectedItem={vm.selectedListView}
          error={vm.errors.get('selectedListView')}

          placeholder='Select View'
          data={vm.listViews}
          disabled={!vm.selectedList}
          onValueChange={(m: LY.DataItemModel, item: any) => {
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange m: ', m);
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange item: ', item);
            onListViewChange(item);
          }
          }
        />



        <LY.LY_DropDown
          key={'ListColumnRelationshipSettings_ValueColumn' + item.listColumnId}
          name='selectedValueColumn'
          label={'Related Value Column'}
          // helpText='Select the column that relates to this column'
          isRequired={true}
          //   value={model?.value}
          //   model={model}

          isAutoCompleteStyle={true}
          displayProperty={'displayName'}
          optionsContainerStyle={{ minHeight: 200 }}
          optionsMaxHeight={150}
          valueProperty={'listColumnId'}
          selectedItem={vm.selectedValueColumn}
          placeholder='Select Column'
          data={vm.listColumns}
          disabled={!vm.selectedList}
          error={vm.errors.get('selectedValueColumn')}

          //style={{width:width}}
          // anchorElement={context?.state?.selectedCellElement}
          onValueChange={(m: LY.DataItemModel, item: any) => {
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange m: ', m);
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange item: ', item);
            onValueColumnChange(item);
          }
          }
        />


        <LY.LY_DropDown
          key={'ListColumnRelationshipSettings_DisplayColumn' + item.listColumnId}
          name='selectedDisplayColumn'
          label={'Display Column'}
          isRequired={true}
          //   value={model?.value}
          //   model={model}
          error={vm.errors.get('selectedDisplayColumn')}

          isAutoCompleteStyle={true}
          displayProperty={'displayName'}
          optionsContainerStyle={{ minHeight: 200 }}
          optionsMaxHeight={150}

          valueProperty={'listColumnId'}
          selectedItem={vm.selectedDisplayColumn}
          placeholder='Select Column'
          disabled={!vm.selectedList}
          data={vm.listColumns}
          //style={{width:width}}
          // anchorElement={context?.state?.selectedCellElement}
          onValueChange={(m: LY.DataItemModel, item: any) => {
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange m: ', m);
            // console.log('ListColumnRelationshipSettings Workspace List onValueChange onChange item: ', item);
            onDisplayColumnChange(item);

          }
          }
        />



      </div>



    </div>;

  }

  return render();
});

