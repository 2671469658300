import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class LinkedDropDownService extends App.BaseAppService {


 
    constructor(workspaceId: number | undefined, linkedWorkspaceId: number | undefined = undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        this.linkedWorkspaceId = linkedWorkspaceId;
     }


    public async getData(sm: Api.ListGetDropDownDataSm, onSuccess: Function, onError: Function | undefined = undefined) {
        console.log("LinkedDropDownService.getRecords sm: ", sm);

       // if(workspace.isLinkedWorkspace)
      //  this.linkedWorkspaceId = workspace.workspaceId;


        const apiUrl = `${App.AppBase.appApiBaseUrl}/LinkedDropDown/getData`;
 
            await this.executeApi({
                apiUrl: apiUrl,
                data: sm,
                onSuccess: onSuccess.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("LinkedDropDownService.getRecords onError: ", error);
                        this.onErrorDefault(error);

                    }
                },
                method: 'POST'
            });
        

    }

    
}
