//import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import { Provider } from 'react-redux';
//import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as App from 'AppReferences';
import MainApp from './App';
import * as LIB from '_LIB';


import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
//import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from "react-router-dom";
import { Notifications } from '@mantine/notifications';

import { createTheme, MantineColorsTuple, MantineProvider } from '@mantine/core';
// core styles are required for all packages
//import 'path/to/your/global/styles.css';  
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';


const licenseKey = process.env.REACT_APP_MUI_KEY;
if(process.env.REACT_APP_ENV_NAME!='DEBUG'){
  LicenseInfo.setLicenseKey(licenseKey!);
}

import Test from 'Pages/Test/Test';

//import registerServiceWorker from './registerServiceWorker';

// Create browser history to use in the Redux store
//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const baseUrl = process.env.REACT_APP_APP_PATH;
//const history = createBrowserHistory({ basename: baseUrl });
const history = createBrowserHistory();



// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export default interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => App.AppState): void;
}


const colors: MantineColorsTuple = [
  '#e1f9ff',
  '#ccedff',
  '#9ad7ff',
  '#64c1ff',
  '#3baefe',
  '#20a2fe',
  '#099cff',
  '#0088e4',
  '#0078cd',
  '#0069b6'
];

const theme = createTheme({
  /** Your theme override here */
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },
  colors: {
    iblue: colors,
  },
  primaryColor: 'iblue', 
 
  
});


var store = App.AppStore.configureStore(history);
App.AppBase.init(store, history, theme);

var rootElement: any = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>

    <BrowserRouter>

      <MantineProvider theme={theme}>
      <Notifications limit={5} position="top-right"  zIndex={99999} className='Ly_Global_Notifications'  />

        <MainApp />
      </MantineProvider>

    </BrowserRouter>

  </Provider>

);

//registerServiceWorker();