import * as React from 'react';
import {CircularProgress, Backdrop} from '@mui/material';
import * as LIB from '_LIB';
import Alert from '@mui/material/Alert';


export class ValidationHelper {

 
    public static isValidRegex(regexStr:string, value:any): boolean {

        if (!regexStr)
            return true;

       if (LIB.Common.isNullOrEmpty(value))
            return false; 
        
        var regex = new RegExp(regexStr);
 
        var result = regex.test(value);

        return result;


    }


    public static isValidUrl(url: any, checkHttpStart=true): boolean {

        if (url == null)
            return false;

        var regex = /^(http|https):\/\/[^ "]+$/g;

        if(!checkHttpStart)
            regex = /^[^ "]+$/g

        var result = regex.test(url);

        return result;
 
    }

    
 
     public static isValidEmail(email: any): boolean {

        if (email == null)
            return false;

        var regex = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/g;

        var result = regex.test(email);

        //console.log('isValidEmail result:',result);

        return result;
    }

    public static hasNumber(name: any):boolean {

        var regex = /[0-9]/g;

        var result = regex.test(name);

        return result;
    }

    
    public static hasLettersAndSpaceOnly(name: any):boolean {

        var regex = /^[a-zA-Z\s]*$/;  

        var result = regex.test(name);

        return result;
    }

    public static hasSymbolsExceptSpace(name: any):boolean {

        if (name == null)
            return false;

        var regex = /^[\w\s]+$/g;

        var result = regex.test(name);

        return result;
    }

    public static hasSpaces(name: any):boolean {

        var regex = /\s/g;

        var result = regex.test(name);

        return result;
    }

    
    public static hasUpperCaseLetter(string: any):boolean {

        if (string == null)
            return false;

        var regex = /[A-Z]/g;

        var result = regex.test(string);

        return result;
    }

    public static hasLowerCaseLetter(string: any):boolean {

        if (string == null)
            return false;

        var regex = /[a-z]/g;

        var result = regex.test(string);

        return result;
    }

    
    public static isValidPhoneNumber(phone: string) {


        if(phone?.length<10 || phone?.indexOf('/')>-1)
           return false;

        var regex = /^(?:\+\d{1,3}|0\d{1,3}|00\d{1,2})?(?:\s?\(\d+\))?(?:[-\/\s.]|\d)+$/;

        var result = regex.test(phone);

        return result;
    }

    public static isFreeEmailProvider(email: string): boolean {
        console.log('isFreeEmailProvider email:',email);

        try {
            const freeEmailProvidersCsv: string =
                "gmail.com,yahoo.com,outlook.com,hotmail.com,aol.com,protonmail.com," +
                "icloud.com,zoho.com,yandex.com,mail.com,inbox.com,gmx.com,mail.ru,lycos.com," +
                "tutanota.com,cock.li,disroot.org,riseup.net,openmailbox.org,fastmail.com,me.com,mailfence.com";
    
            // Split the CSV string to create an array of provider domains
            const freeEmailProviders: string[] = freeEmailProvidersCsv.split(',');
    
            const domain: string = ValidationHelper.getEmailDomain(email?.toLocaleLowerCase());
    
            console.log('isFreeEmailProvider domain:',domain);

            if (!domain) {
                return false;
            }
    
            // Check if the domain is in the list of free email providers
            return freeEmailProviders.includes(domain);

        } catch (error) {
            // Handle any exceptions that may occur during the check
            return true;
        }
    }
    
    public static getEmailDomain(email: string): string {
        const atIndex: number = email.indexOf('@');
        if (atIndex !== -1) {
            return email.slice(atIndex + 1);
        }
        return '';
    }

}