import * as App from 'AppReferences';
import * as Redux from 'redux';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class GuestUserReducers {


    constructor() {

    }

    public static getActionHandlersReducers(state: App.GuestUserState | undefined, action: App.AppAction): App.GuestUserState {
        var str = "";

        var result = new App.GuestUserState();

        if (state === undefined) {
            return result;
        }

        if (!action.type.startsWith("GUESTUSER"))
            return state;

        var appState = action.appState;
        var globalState = appState.globalData;

        console.log('GuestUser.getActionHandlersReducers', appState);

        var userService = new App.UserService();

        // const action = incomingAction as KnownAction;
        switch (action.type) {

            case App.ActionTypes.GUESTUSER_API_ERROR:
                console.log('GUESTUSER_API_ERROR:', action.payload);
                
                result = { ...state };
                
                App.AppBase.setApiError(action,appState);
                
                 result.vm.apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action.payload);
                //var apiErrorMessage = LIB.ErrorHelper.getStringErrorMessage("error", action.payload);
                //appState.globalData.globalUIState.apiErrorMessage = apiErrorMessage;

                result.isInitialDataLoaded = false;

                result.isServiceCallPending = false;
                console.log('GUESTUSER_API_ERROR apiErrorMessage:' + result.vm.apiErrorMessage);

                console.log('GUESTUSER_API_ERROR state:', state);
                console.log('GUESTUSER_API_ERROR result:', result);

                result.forceUpdateCount++;

                //return result;
                break;

            case App.ActionTypes.GUESTUSER_GET_PAGE_DATA:{
                console.log('GUESTUSER_GET_PAGE_DATA');
                console.log('GUESTUSER_GET_PAGE_DATA', action.payload);


                result.vm.apiErrorMessage = '';

                let sm = action.payload;//Api.RegisterFormSm
                //let offerId = sm?.offerId;
                let coupon = sm?.coupon;    
                let planName = sm?.planName;
                let offer = state?.vm?.offer;

                console.log('GUESTUSER_GET_PAGE_DATA vm', state?.vm);

                console.log('GUESTUSER_GET_PAGE_DATA coupon', coupon);

                if (result.isInitialDataLoaded &&
                    (LIB.Common.isNullOrEmpty(coupon) || (!LIB.Common.isNullOrEmpty(offer) && offer?.coupon === coupon))) {
                    console.log('GUESTUSER_GET_PAGE_DATA unchanged');
                    return state;
                }
 
               // result.registerSm.offerId = offerId;
                result.registerSm.coupon = coupon;

              //  result.registerSm.planName = planName;
                result.registerSm.planId = sm?.planId;

                userService.getGuestUserData(sm);
                result.isServiceCallPending = true;

                break;

            }
            case App.ActionTypes.GUESTUSER_PAGE_DATA_RECEIVED:
                console.log('GUESTUSER_PAGE_DATA_RECEIVED:', action.payload);

                let data = action.payload;

                result = { ...state };
                result.vm = action.payload.vm;
                result.isInitialDataLoaded = true;
                result.isServiceCallPending = false;

                result.billingPlans = data.billingPlans;

                
                result.forceUpdateCount++;
                break;

            case App.ActionTypes.GUESTUSER_LOGIN_REQUEST:
                console.log('GUESTUSER_LOGIN_REQUEST');
               // console.log(action.payload);
                result = { ...state };
                var loginSm = action.payload;

                userService.login(loginSm);
                result.isServiceCallPending = true;

                break;

            case App.ActionTypes.GUESTUSER_LOGIN_SUCCESS:
                console.log('GUESTUSER_LOGIN_SUCCESS:', action.payload);
                 
                //result = { ...state };
                result=state; // no need to refresh, let redirect work
                var currentUser = action.payload;

                appState.globalData.initialData.currentUser.setData(currentUser);

                App.AuthManager.saveUserToLocalStorage(currentUser);
                //result.isServiceCallPending = false;

                console.log('GUESTUSER_LOGIN_SUCCESS companyName:', currentUser.companyName);
                console.log('GUESTUSER_LOGIN_SUCCESS company:', currentUser.company);

                //appState.globalData.forceUpdateCount++;

                //Force refresh 
                App.RouteManager.redirect("/");
                // App.RouteManager.routeToPath("/");

                console.log('GUESTUSER_LOGIN_SUCCESS redirect done1', new Date());

                //LIB.Common.sleep(5000);

                //console.log('GUESTUSER_LOGIN_SUCCESS redirect done2', new Date());

                break;

            case App.ActionTypes.GUESTUSER_REGISTER_REQUEST:
                console.log('GUESTUSER_REGISTER_REQUEST');
                console.log(action.payload);
                result = { ...state };
                let sm = action.payload;
                result.vm.apiErrorMessage = '';

                userService.register(sm);
                result.isServiceCallPending = true;

                break;

            case App.ActionTypes.GUESTUSER_REGISTER_SUCCESS:{
                console.log('GUESTUSER_REGISTER_SUCCESS:', action.payload);
                console.log(action.payload);
                result = { ...state };
                var currentUser = action.payload;

                appState.globalData.initialData.currentUser.setData(currentUser);

                App.AuthManager.saveUserToLocalStorage(currentUser);
                result.isServiceCallPending = false;
  
                result.forceUpdateCount++;

                 //Force refresh 
                 App.RouteManager.redirect("/");

                break;
            }
            case App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_REQUEST:{
                console.log('GUESTUSER_FORGOT_PASSWORD_REQUEST');
                console.log(action.payload);
                result = { ...state };
                let sm = action.payload;
                result.vm.apiErrorMessage = '';

                userService.forgotPassword(sm);
                result.isServiceCallPending = true;

                break;
            }
            case App.ActionTypes.GUESTUSER_FORGOT_PASSWORD_SUCCESS:
                console.log('GUESTUSER_FORGOT_PASSWORD_SUCCESS');
                console.log(action.payload);
                result = { ...state };

                result.isServiceCallPending = false;
                appState.globalData.forceUpdateCount++;

                break;

            case App.ActionTypes.GUESTUSER_PASSWORD_RESET_REQUEST:{
                console.log('GUESTUSER_PASSWORD_RESET_REQUEST');
                console.log(action.payload);
                result = { ...state };
                let sm = action.payload;
                result.vm.apiErrorMessage = '';

                userService.resetPassword(sm);
                result.isServiceCallPending = true;

                break;
            }
            case App.ActionTypes.GUESTUSER_PASSWORD_RESET_SUCCESS:
                console.log('GUESTUSER_PASSWORD_RESET_SUCCESS');
                console.log(action.payload);
                result = { ...state };

                result.isServiceCallPending = false;
                appState.globalData.forceUpdateCount++;

                break;
            default:
                result = state;
                //return result;
                break;
        }

        if (action.postActionCallBack)
            action.postActionCallBack(result);

        return result;
    }



}