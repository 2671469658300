import * as React from 'react';

import { useState } from 'react';

import { connect } from 'react-redux';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as MT from '@mantine/core';

import * as Icons from '@mui/icons-material/';

import { BrowserRouter, Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';
import { CSSProperties } from "react";

//import { RouterState } from 'connected-react-router';
import './WorkspaceLinkedWorkspaces.css';

import '../Menus/LeftMenu.css';

import { LY_TreeMenu } from '_LY_Components/TreeMenu/LY_TreeMenu/LY_TreeMenu';



export const WorkspaceLinkedWorkspaces: React.FC<App.GlobalState> = (props) => {


    var selectedTab: string | undefined;
    var params = useParams();
    var location = useLocation();
    var locationState = location.state;


    /*   React.useEffect(() => {
          console.log("WorkspaceLinkedWorkspaces useEffect update");
  
      }); */

    //rct-tree-item-button selected btn

    var globalVm = props.globalUIState;
    var vm = props.workspaceVm;


    // console.log('WorkspaceLinkedWorkspaces globalUIState:', vm);

    var appPath = "";//App.AppBase.appInfo.appPath;

    // console.log('router: ');
    // console.log(this.props.location);

    // var selectedItem = App.AppBase.history?.location?.pathname;
    var selectedItem = App.AppBase.location?.pathname;
    var currentUser = App.AppBase.currentUser;



    const [ focusedItem, setFocusedItem ] = useState();


    var ListItemStyle: CSSProperties = {
        paddingTop: 4,
        paddingBottom: 4,
    };

    var variant: any = 'permanent';

    if (globalVm.isMobile)
        variant = undefined;

    //console.log('WorkspaceLinkedWorkspaces render:', vm);
 

    function onSelectedItemsChanged(e: React.MouseEvent<HTMLSpanElement, MouseEvent>, item: Api.WorkspaceItem) {
        console.log('onSelectedItemsChanged', item);

        //e.preventDefault();
        var vm:any = {};
        vm.selectedItemElement = e.currentTarget.parentNode;
        vm.selectedItem = item;

        console.log('onSelectedItemsChanged', vm);

        App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

    };

    
    var linkedWorkspaces = vm?.selectedWorkspace?.linkedWorkspaces;

    if (!linkedWorkspaces || linkedWorkspaces.length == 0)
        return <div></div>;

    /*  console.log("WorkspaceMenuD: render treeData", treeData);
     console.log("WorkspaceMenuD: isItemDeleteOpen", vm.isItemDeleteOpen);
  */

     console.log('WorkspaceLinkedWorkspaces vm2', vm);

    return <div>
 
        {linkedWorkspaces.map((ws, index) => {
           
           var linkedSelectedItem:any  = ws.workspaceItems?.find(x=>x.defaultViewId == vm.selectedItem?.defaultViewId);
 
           return <div key={'workspace_'+ws.workspaceId}> 
                    <div className='LY_WorkspaceLinkedWorkspacesTitle'>{ws.name}</div>
                    <LY_TreeMenu
                            preItemIcon={<Icons.ViewStreamOutlined />}
                            name={'LinkedWorkspaceNav'+ws.workspaceId} 
                            items={ws.workspaceItems}
                            //onItemContextMenuClick={onMenuItemContextMenuClick}
                            onSelectItem={onSelectedItemsChanged}
                            hideContextMenu={true}
                            selectItem={linkedSelectedItem}
                           // onDeleteKeyPress={onDeleteClick}
                        />
               </div>;
        })}
  

  </div>;
}
//}
export default connect(
    (state: App.AppState) => ({ ...state.globalData })
)(WorkspaceLinkedWorkspaces as any);


