import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class EmailTemplateService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getEmailTemplatesListData(filter:Api.EmailTemplateFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailTemplate/getAll?${urlPrams}`;
        console.log(" getEmailTemplatesListData filter", filter, urlPrams);
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
     public create(data:Api.EmailTemplate, onAfterSuccess:Function|null=null) {
        console.log("EmailTemplateService.create");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailTemplate/create`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR, '', onAfterSuccess
            );
 
    }
    
    public update(data:Api.EmailTemplate) {
        console.log("EmailTemplateService.update");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailTemplate/update`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS, 
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public delete(emailTemplateId:number) {
        console.log("EmailTemplateService.deleteUser");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/EmailTemplate/delete?emailTemplateId=${emailTemplateId}`;
        this.post(apiUrl, emailTemplateId, 
            App.ActionTypes.ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    

    
 
}
