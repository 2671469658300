import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class WorkspaceService extends App.BaseAppService  {
 
 
    constructor(workspaceId:number|undefined) { 

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
     } 
     
 
    public getWorkspaceListsWithColumns(workspaceId:number, onSuccess: Function | undefined, onError: Function | undefined = undefined) {
        console.log("WorkspaceService.getWorkspaceListsWithColumns workspaceId: ", workspaceId);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/getWorkspaceListsWithColumns?workspaceId=${workspaceId}`;
 
            this.executeApi({
                apiUrl: apiUrl,
                onSuccess: onSuccess?.bind(this),
                onError: (error: any) => {
                    if (onError)
                        onError(error);
                    else {
                        console.error("WorkspaceService.getWorkspaceListsWithColumns onError: ", error);
                        this.onErrorDefault(error);
                    }
                },
                method: 'GET'
            });
        

    }
 
 
    public getWorkspaceWithItems(workspaceId:number) {
        console.log("WorkspaceService.getWorkspaceWithItems workspaceId: ", workspaceId);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/getWorkspaceWithItems?workspaceId=${workspaceId}`;
        this.get(apiUrl, 
            App.ActionTypes.GLOBAL_WORKSPACE_CHANGE_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            '',null,
            //onAfterSuccess
            (result:any)=>{
                console.log("WorkspaceService.getWorkspaceWithItems result: ", result);
                
                 var vm = new App.WorkspaceVm();
                //vm.selectedItemElement = e.currentTarget.parentNode;
                vm.selectedItem = result?.workspaceVm?.selectedItem;
                //var newUrl = `/list/${vm.selectedItem.defaultViewId}`;

                //console.log("WorkspaceService.getWorkspaceWithItems newUrl: ", newUrl);
                
               //TODO App.RouteManager.routeToView(vm?.selectedItem?.defaultViewId);

              //  console.log("WorkspaceService.getWorkspaceWithItems vm: ", vm);
              //  App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

            } 
        );
 
    }

    public createItem(data: Api.Workspace, onSuccess:Function, onError:Function) {

        console.log("WorkspaceService.createItem data: ", data);
        console.log("WorkspaceService.createItem workspaceId: ", this.workspaceId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/createItem`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                console.error("WorkspaceService.createItem onError: ", error);

                onError(error);
                this.onErrorDefault(error);
            },
            method: 'POST'
        }); 
        
    }


    public updateItem(data: Api.Workspace, onSuccess:Function, onError:Function) {

        console.log("WorkspaceService.updateItem data: ", data);
        console.log("WorkspaceService.updateItem workspaceId: ", this.workspaceId);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/updateItem`;

        this.executeApi({
            apiUrl: apiUrl,
            data: data,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {
                console.error("WorkspaceService.updateItem onError: ", error);

                onError(error);
                this.onErrorDefault(error);
            },
            method: 'POST'
        }); 
        
    }

    
    public createItem2(data:Api.Workspace) {
        console.log("WorkspaceService.createItem data: ", data);
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/createItem`;
        this.post(apiUrl, data, 
            App.ActionTypes.GLOBAL_WORKSPACE_NEW_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            //onAfterSuccess
          /*   ()=>{
                App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

            } */
        );
 
    }

    public deleteOld(workspaceId:number) {
        console.log("WorkspaceService.delete");
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/delete`;
        this.get(apiUrl, 
            App.ActionTypes.GLOBAL_WORKSPACE_DELETE_SUCCESS,
            App.ActionTypes.GLOBAL_WORKSPACE_API_ERROR,
            //onAfterSuccess
          /*   ()=>{
                App.AppStore.dispatch(App.ActionTypes.GLOBAL_WORKSPACE_ITEM_CHANGE, vm);

            } */
        );
 
    }
    public delete(workspaceId:number, onSuccess: Function, onError: Function) {

        console.log("WorkspaceService.delete");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Workspace/delete?workspaceId=${workspaceId}`;

        this.executeApi({
            apiUrl: apiUrl,
            onSuccess: onSuccess.bind(this),
            onError: (error: any) => {

                if (onError)
                    onError(error);
                else {
                    console.error("WorkspaceService.delete onError: ", error);
                    this.onErrorDefault(error);

                }
            },
            method: 'delete'
        });

    }
  
}
