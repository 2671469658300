import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';

import {
  Button, Icon, Tooltip, CircularProgress
} from '@mui/material';
import axios from 'axios';

import * as RIcons from '@radix-ui/react-icons';


import * as DropdownMenu from '@radix-ui/react-dropdown-menu';


import Box from '@mui/material/Box';

import * as Icons from '@mui/icons-material/';

import * as Api from 'app-api';



import './AddColumnContextMenu.css';

interface AddColumnContextMenuProps {
  state: App.ListState;
  onForceUpdate?: () => void;

}

export const AddColumnContextMenu: React.FC<AddColumnContextMenuProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const newItemInput = useRef<HTMLInputElement>(null);
  const newItem = useRef<LY.LY_TextInputHandle>(null);

  const [ bookmarksChecked, setBookmarksChecked ] = React.useState(true);
  const [ urlsChecked, setUrlsChecked ] = React.useState(false);
  const [ person, setPerson ] = React.useState('pedro');

  var list = props.state.list;



   
  function onItemClick(listColumnTypeId: App.ListColumnTypeEnum) {

    console.log('onItemClick listColumnTypeId:', listColumnTypeId);
    var vm = props.state.vm;
    var state = props.state;

    if (listColumnTypeId === undefined) {
      return;
    }

    var sm = new Api.ListColumn();

    if (listColumnTypeId != undefined)
      sm.listColumnTypeId = listColumnTypeId;

    if (list != undefined) {
      sm.workspaceId = list.workspaceId;
      sm.listId = list.listId!;
    }

    state.newColumn =  sm;
    vm.isAddColumnScreenOpen=true;
   // forceUpdate();

   if(props.onForceUpdate!=undefined)
      props.onForceUpdate();
    
 
  }


  function render() {
    // console.log('AddColumnContextMenu render');
    var state = props.state;
    var vm = state.vm;
    var newItem = state.newItem;

    //console.log('AddColumnContextMenu render state', state);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded)
      return null;


    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild >

          <Icons.AddOutlined className='IconButton' />

        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content className="AddColumnMenuContent"
            onClick={(e: any) => {
              console.log('AddColumnMenuContent onItemClick', e.target);
              // onItemClick(e.target.id);
            }

            }

            sideOffset={2}>

            <DropdownMenu.Item id='SingleLineText' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.SingleLineText)}
            >
              <RIcons.TextIcon className='AddColumnMenuItemIcon' /> <span>Single Line Text</span>
            </DropdownMenu.Item>

            <DropdownMenu.Item id='LongText'
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.LongText)}

              className="AddColumnMenuItem">
              <RIcons.ReaderIcon className='AddColumnMenuItemIcon' /> Long Text
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Phone' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Phone)}

            >
              <RIcons.MobileIcon className='AddColumnMenuItemIcon' />  Phone
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Email' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Email)}

            >
              <RIcons.EnvelopeClosedIcon className='AddColumnMenuItemIcon' />  Email
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Number' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Number)}

            >
              <RIcons.FrameIcon className='AddColumnMenuItemIcon' />  Number
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Date' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Date)}

            >
              <RIcons.CalendarIcon className='AddColumnMenuItemIcon' /> Date
            </DropdownMenu.Item>

            <DropdownMenu.Item id='User' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.User)}

            >
              <RIcons.PersonIcon className='AddColumnMenuItemIcon' /> User
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Dropdown' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Dropdown)}

            >
              <RIcons.CalendarIcon className='AddColumnMenuItemIcon' />  Dropdown
            </DropdownMenu.Item>

            <DropdownMenu.Item id='Status' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Status)}

            >
              <RIcons.ViewHorizontalIcon className='AddColumnMenuItemIcon' /> <span>Status</span>
            </DropdownMenu.Item>

            {/* --------------------------------------------------------- */}
            <DropdownMenu.Separator className="AddColumnMenuSeparator" />
            {/* --------------------------------------------------------- */}

            <DropdownMenu.Item id='More' className="AddColumnMenuItem"
              onClick={(e: any) => onItemClick(App.ListColumnTypeEnum.Other)}

            >
              <div style={{ textAlign: 'center', width: '100%' }}>More Options</div>
            </DropdownMenu.Item>


          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );




  }

  return render();
};

