
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class AdministrationVm extends LIB.BaseVmModel {

    public selectedTabIndex: number;
    public selectedTab: string;
//AdminHomUsers.onCreateClick

    public isUsersAddOpen: boolean = false;
    public isUsersEditOpen: boolean = false;
    public isUsersDeleteOpen: boolean = false;
    public isUsersChangePasswordOpen: boolean = false;
    public isUsersEditRolesOpen: boolean = false;
 

    public isEmailTemplateTableOpen: boolean = true;
    public isEmailTemplateCreateOpen: boolean = false;
    public isEmailTemplateDeleteOpen: boolean = false;
    public isEmailTemplateEditOpen: boolean = false;


    public isProspectsAddOpen: boolean = false;
    public isProspectsEditOpen: boolean = false;
    public isProspectDeleteOpen: boolean = false;
    public isProspectsBulkImportOpen: boolean = false;

    public isCandidatesAddOpen: boolean = false;
    public isCandidatesDeleteOpen: boolean = false;
    public isCandidatesEditOpen: boolean = false;

    
    public isJobsAddOpen: boolean = false;
    public isJobsDeleteOpen: boolean = false;
    public isJobsEditOpen: boolean = false;

    public isCompaniesAddOpen: boolean = false;
    public isCompaniesDeleteOpen: boolean = false;
    public isCompaniesEditOpen: boolean = false;

    public adminPasswordChangeSm:Api.AdminPasswordChangeSm = new Api.AdminPasswordChangeSm();

    public adminChangeUserRolesSm:Api.AdminChangeUserRolesSm = new Api.AdminChangeUserRolesSm();

    //used in methods below
  
    public selectedUser: Api.AppUser;
    public selectedUserCreate: Api.AdminCreateUserFormSm;
    public selectedProspect: Api.Prospect;
    public selectedEmailTemplate : Api.EmailTemplate;
    public selectedCompany : Api.Company;

    
  
    public selectedUserOriginal: Api.AppUser;
    public selectedProspectOriginal: Api.Prospect;
    public selectedEmailTemplateOriginal: Api.EmailTemplate;
    public selectedCompanyOriginal : Api.Company;

   public candidateTableLoading:boolean = false;
 

    constructor() {

        super();
    }

    public setSelectedUser(item: Api.AppUser | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.AppUser();


        this.selectedUser = item;
        this.selectedUserOriginal = LIB.ObjectHelper.clone(this.selectedUser);

        this.adminChangeUserRolesSm.userRoleTypes = LIB.ObjectHelper.clone(this.selectedUser?.userRoleTypes);
        this.adminChangeUserRolesSm.userId = item.userId;
    }

    
    public setSelectedUserCreate(item: Api.AdminCreateUserFormSm | undefined=undefined) {
        console.log("setSelectedUserCreate");
     
        if (item === undefined)
            item = new Api.AdminCreateUserFormSm();


        this.selectedUserCreate = item;
        this.selectedUserOriginal = LIB.ObjectHelper.clone(this.selectedUserCreate);

    }

    public setSelectedProspect(item: Api.Prospect | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.Prospect();


        this.selectedProspect = item;
        this.selectedProspectOriginal = LIB.ObjectHelper.clone(this.selectedProspect);

    }   

    public setSelectedEmailTemplate(item: Api.EmailTemplate | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.EmailTemplate();


        this.selectedEmailTemplate = item;
        this.selectedEmailTemplateOriginal = LIB.ObjectHelper.clone(this.selectedEmailTemplate);

    }
 
 

    public setSelectedCompany(item: Api.Company | undefined=undefined) {

     
        if (item === undefined)
            item = new Api.Company(); 
        this.selectedCompany= item;
        this.selectedCompanyOriginal = LIB.ObjectHelper.clone(this.selectedCompany);

    }

    
    

}