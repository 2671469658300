import * as LIB from "_LIB";
import * as Api from "app-api";


export class LY_NotesModuleState extends LIB.BaseVmModel {
  public notes: Api.Note[] | [] = [];
  public searchNotes: Api.Note[] | [] = [];

  public selectedNote: Api.Note;
  public selectedReply: Api.NoteReply;
  public updatingNote: boolean = false;
  public updatingReply: boolean = false;
  public replyInputOn: boolean = false;
  public searchTextInNotes: string = "";
  public newNoteId: string = "";
  public newNoteMessage: string = "";
  public newReplyMessage: string = "";
  public updatedMessage: string = "";
  public scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  constructor() {
    super();
  }
}
