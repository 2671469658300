import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class BaseManager {


  public idProperty: string;

  constructor(idProperty: string) {

    this.idProperty = idProperty;
  }

  public getItemIndexById(id: any, data: any[], idProperty:string='') {

    if(LIB.Common.isNullOrEmpty(idProperty))
         idProperty=this.idProperty;

    let index = data.findIndex((item: any) => item[this.idProperty] === id);
    return index;

  }

  

  public getIndexes(data: any[], key: any, value:any):number[] {

    if(LIB.Common.isNullOrEmpty(data) || LIB.Common.isNullOrEmpty(key))
      return [];
      var result = [];
      for(var i=0; i<data.length; i++){
          if(data[i][key]===value){
            result.push(i);
          }
       
      }
    return result;
  }

  public getItemById(id: any, data: any[], idProperty:string='') {

    if(LIB.Common.isNullOrEmpty(data) || !Array.isArray(data) || LIB.Common.isNullOrEmpty(id))
      return null;

    if(LIB.Common.isNullOrEmpty(idProperty))
      idProperty=this.idProperty;

    var result = data?.find((item: any) => item[this.idProperty] === id);

    return result;
  }
 
  
  public deleteById(id: any, data:any[]): boolean {

    if (!Array.isArray(data))
      return false;
    let index = data.findIndex((item) => item[this.idProperty] === id);
    if (index < 0)
      return false;
      
    data.splice(index, 1);
    return true;
  }
 
  
  public updateById(item:any, data:any[]): boolean {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(item) || item[this.idProperty] < 1)
      return false;

    let index = data.findIndex((c) => c[this.idProperty] === item[this.idProperty]);
    if (index < 0)
      return false;
      
    data[index] = item;

    return true;
  }


  public setNextId(item:any, data:any[]):any{

    item[this.idProperty] = LIB.ObjectHelper.getMaxValue(data,this.idProperty);
    item[this.idProperty]++;
    return item;
  }

  public getLookupById(lookupTypeId?:number, data:Api.LookupType[] | undefined=undefined) {

    if (!Array.isArray(data) || LIB.Common.isNullOrEmpty(lookupTypeId) || data===undefined || lookupTypeId===undefined)
      return null;

    var found = data?.find((c) => c.lookupTypeId === lookupTypeId);
    
    if(LIB.Common.isNullOrEmpty(found))
      return null;
 
    return found;
  }


}