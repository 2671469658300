import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';


export class TestDataManager {


  constructor() {

  }
  
  public static setTestRegisterData(sm:Api.RegisterFormSm | Api.AdminCreateUserFormSm): Api.RegisterFormSm | Api.AdminCreateUserFormSm {

    if (LIB.Common.isNullOrEmpty(sm))
      return sm;

      
      sm.firstName = "Mike";
      sm.lastName = "Demo";
      sm.companyName = "Need CRM Company"
      sm.email = "michaelpal@shootbit.com";
      sm.password = "oneoneA1!";
      sm.acceptTerms=true;
      sm.phone = "3333444333";
    
      return sm;
  } 
  


}