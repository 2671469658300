import * as React from 'react';
import { connect } from 'react-redux';
import * as UI from '@mui/material/';
import * as Icons from '@mui/icons-material/';
import MenuIcon from '@mui/icons-material/Menu';

import { Table, Typography,  Paper, Container, TextField, Box, Button, Grid,  InputAdornment } from '@mui/material';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import { Tabs, Tab } from "@mui/material";
import { CSSProperties } from "react";


import { createStyles,  Theme } from '@mui/material/styles';
import { useParams, useLocation } from 'react-router';

 

interface StripePricingTableProps {
  tableId: string;
  publishableKey:string;
  children?: React.ReactNode;

}

export const StripePricingTable: React.FC<StripePricingTableProps> = (props) => {
 

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };
}, []);

return React.createElement("stripe-pricing-table", {
    "pricing-table-id": props.tableId,
    "publishable-key": props.publishableKey,
});
 
 
}

