import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
 
import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge,  Accordion, rem,Tooltip } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


  
interface UpdateColumnAdvancedTab_CalculationsProps {
  state: App.ListState;
  column?: Api.ListColumn;
}

export const UpdateColumnAdvancedTab_Calculations: React.FC<UpdateColumnAdvancedTab_CalculationsProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
    
  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;

  const vm = props.state.vm;
  const state = props.state;

 // const [ column, setColumn ] = React.useState<Api.ListColumn>();
  var column = props.column || selectedColumn;
/* 
  useEffect(() => {
    console.log('UpdateColumnAdvancedTab_Calculations useEffect column', column);
    console.log('UpdateColumnAdvancedTab_Calculations useEffect selectedColumn', selectedColumn);

    if(props.column)
      column = props.column;
 //     setColumn(props.column);
    else
      column = selectedColumn;
    //  setColumn(column);

    console.log('UpdateColumnAdvancedTab_Calculations useEffect column2', column);

  }, [ selectedColumn, props.column ]); */
 
 

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

    // console.log('UpdateColumnAdvancedTab_Calculations onInputChange key:', key);
    // console.log('UpdateColumnAdvancedTab_Calculations onInputChange column:', column);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('UpdateColumnAdvancedTab_Calculations onValueChange key:', key);
    //console.log('UpdateColumnAdvancedTab_Calculations onValueChange value:', value);

    //console.log('UpdateColumnAdvancedTab_Calculations onValueChange column:', column);

    if (key == 'name') {
      value = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }  

    //@ts-ignore
    column[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange column', column);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = column;
    if(item == undefined)
      return false;

    vm.errors.clear();

    if (vAll || key == 'maxLength') {
       if (item.maxLength!=undefined && item.maxLength <1)
        vm.errors.set('maxLength', `Max Length must be more than 0`);
    }

    var isCalculatedType =  LY.ListColumnManager.isCalculatedColumn(item,true)

    if (vAll && isCalculatedType && 
      (LIB.Common.isNullOrEmpty(item.clientCalculation) || 
      LIB.Common.isNullOrEmpty(item.backendCalculation))
      ) {
        var calcMsg = `Either Client Calculation or Backend Calculation is Required!`;
        vm.errors.set('clientCalculation', calcMsg);
        vm.errors.set('backendCalculation', calcMsg);
      }
     
    /* if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required!`);
    }
    else if ((vAll || key == 'listColumnTypeId') && isNaN(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type must be a number!`);
    } */


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }
 

  // when Type changes, let's change what's selected 
  var listColumnTypeId = column?.listColumnTypeId;

  //console.log('UpdateColumnAdvancedTab_Calculations watch listColumnTypeId:', listColumnTypeId);
  //console.log('UpdateColumnAdvancedTab_Calculations column:', column);
  //console.log('UpdateColumnAdvancedTab_Calculations columnTypes:', columnTypes);
  //console.log('UpdateColumnAdvancedTab_Calculations columnType:', columnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == column?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
   

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

   // console.log('UpdateColumnAdvancedTab_Calculations render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

   console.log('UpdateColumnAdvancedTab_Calculations useEffect column3', column);

    if (LIB.Common.isNullOrEmpty(state) || !column)
      return null;
  
      let columns = props.state?.view?.columns?.filter(x=>!x.isSystemHidden) || [];

      var globalError = vm.errors.get('all');
      var item = column;

 
    return  <div className='UpdateColumnAdvancedTabGroups' >
  
          <LY.LY_TextArea
            name="clientCalculation"
            value={item.clientCalculation}
            label="Client Calculation"
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('clientCalculation')}

            helpText="Client Side Liquid Template style formatting/calculations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          
          /> 

      <LY.LY_TextArea
            name="backendCalculation"
            value={item.backendCalculation}
            label="Backend Calculation"
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('backendCalculation')}

            helpText="Backend Side Liquid Template style formatting/calculations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          
          /> 

         
 
      </div>;
   
  }

  return render();
};

