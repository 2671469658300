import './LY_StaticDropDown.css';

import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_StaticDropDownContextProvider } from './Context/LY_StaticDropDownContext';
import { LY_StaticDropDown_Container } from './Components/LY_StaticDropDown_Container/LY_StaticDropDown_Container';
import React from 'react';

export const LY_StaticDropDown = React.forwardRef<HTMLSelectElement, LY.LY_StaticDropDownProps>((
    props, ref
) => {

    return (
        <LY_StaticDropDownContextProvider {...props}>
            <LY_StaticDropDown_Container {...props} />
        </LY_StaticDropDownContextProvider>

    );
});
