import React, { ChangeEventHandler, HTMLAttributes, MouseEventHandler } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';
import * as App from 'AppReferences';
import * as MT from '@mantine/core';


import './LY_NameColumnInput.css';

import { Button, Badge, Tooltip } from '@mantine/core';
import { useToggle } from '@mantine/hooks';

interface LY_NameColumnInputProps extends LY.LY_InputBaseProps {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  onNameChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;  
  //onNameClick?: Function; //React.MouseEvent<HTMLElement>
  onNameClick?: (model: LY.DataItemModel | undefined, event: React.MouseEvent<HTMLElement> | undefined) => void;  
  onOpenItemAction?: 'detail_view' | 'detail_view_new_tab' | 'panel_view' ;
}

//export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((

type combinedProps = LY_NameColumnInputProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element
export const LY_NameColumnInput = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    name,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    style,
    model,
    onNameClick,
    onNameChange,
    onChange,
    readOnly,
    onOpenItemAction = 'detail_view',
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();

  const [ mode, setMode ] = React.useState('view'); // Add this line
  const [ localValue, setLocalValue ] = React.useState(''); // Add this line

  const combinedInputClassName = `mantine-Input-input mantine-TextInput-input LY_InputInput ${inputClassName || ''}`;

  //const [ mode, toggle ] = useToggle([ 'view', 'edit' ]);


/*    React.useEffect(() => {

     console.log('LY_NameColumnInput useEffect model', model);
     setLocalValue(model?.value);
   

 }, [ model?.value ]);  */


 // React.useEffect(() => {


  /*   if (model?.isEditMode) {
    //  console.log('LY_NameColumnInput useEffect model?.isEditMode', model?.isEditMode);

      setMode('edit');

    } */

 // }, [ model?.isEditMode ]);

 

  //console.log('LY_Input name', name);
  //console.log('LY_Input value', value);


  

 /*  if (!form) {
    var newForm = useForm({
      //  resolver: zodResolver(validationSchema),
      defaultValues: value,
       
    });
    register = newForm.register;

  }
  else
    register = form.register; */



  function getActions() {


    // console.log('LY_NameColumnInput getActions mode:', mode);

 

    return <div className='LY_NameColumnInputActions'>

      {(mode == 'view' && !readOnly) &&

        <Tooltip label='Edit Item' position='right'
          withArrow
          arrowSize={6}
        >
          <div onClick={() => setMode('edit')}
            className='LY_NameColumnInputActionsButton'

          >
            <RIcons.Pencil1Icon />

          </div>
        </Tooltip>
      }

      {
        (mode == 'edit' && !readOnly) &&
        <div onClick={(e:any) => onLocalChange(model!,e)}
          className='LY_NameColumnInputActionsButton'
        >
          <RIcons.CheckIcon />
        </div>
      }

      <Tooltip label='Open Item' position='right'
        withArrow
        arrowSize={6}
      >
        <div
          className='LY_NameColumnInputActionsButton'
          onClick={onOpenItemClick}
        >
          <RIcons.OpenInNewWindowIcon />

        </div>

      </Tooltip>

    </div >
  }


  function onLocalNameClick(e: React.MouseEvent<HTMLElement>) {

      e.preventDefault();

    if (onNameClick)
      onNameClick(model, e);

    /*  console.log('LY_NameColumnInput onNameClick');
     var detailUrl = getDetailUrl();
 
     console.log('LY_NameColumnInput onNameClick URL: ',detailUrl);
     console.log('LY_NameColumnInput onNameClick Model: ',model);
  */

  }
  function onOpenItemClick(e: React.MouseEvent<HTMLElement>) {

    //e.preventDefault();

    var detailUrl = getDetailUrl();

    if (onOpenItemAction=='detail_view')
      App.RouteManager.routeToPath(detailUrl);
    else if (onOpenItemAction=='detail_view_new_tab')
      window.open(detailUrl, '_blank');
    else if (onOpenItemAction=='panel_view')
     onLocalNameClick(e); 
  }

  

  function getDetailUrl() {

    var page = LY.LYTemplates.getPageName('item', model);
 

    var detailUrl = `/${model?.workspaceId}/${page}/${model?.listId}/${model?.rowId}/${model?.value || value}`;
    return detailUrl;
  }

  function getViewMode() {

   // if(model?.rowId=='7da8432c-ec88-47bd-93e0-584d530bfd11')
   //   console.log('LY_NameColumnInput getViewMode model: ',model);


    var detailUrl = getDetailUrl();
    let value = model?.value;
    if(LIB.Common.isNullOrEmpty(value))
        value = '--';

    return <div
      //onClick={onLocalNameClick}
      className='LY_NameColumnInputContainer'>
 
      <a href={detailUrl}
        onClick={onLocalNameClick}
        className='LY_NameColumnInputLink' >{value}</a>

      {getActions()}
    </div>

  }


  function onLocalChange(m: LY.DataItemModel, e: React.ChangeEventHandler<HTMLElement>) {

    console.log('LY_NameColumnInput onLocalChange model: ',m);
     

    if(model==undefined)
          model = m; 

     if(!m.valueChanged){
            setMode('view');
            return;
      }

      model.value = m.value?.trim();

   if(value)
      value = m.value?.trim();

     if (onNameChange)
        onNameChange(model!, e);

      //console.log('LY_NameColumnInput getViewMode model.value2: ',model?.value);
      //console.log('LY_NameColumnInput getViewMode modelT1111: ',model);

      
      forceUpdate();

     setMode('view');

   
  }


  function getEditMode() {

    var col = model?.column;
    var result = (
      <div className='LY_NameColumnInputContainer' >
        <LY.LY_TextInput
          viewId={model?.viewId}
          rowId={model?.rowId}
          name={col?.name!}
          value= {model?.value}
          
          type='text'
          originalValue={model?.originalValue}
          inputStyle={{ borderColor: 'transparent',paddingLeft:0  }}
          style={{ width: '100%', height: '100%', paddingLeft:0, marginLeft:0 }}
          onChange={onLocalChange}
          onEnterBlur={true}
          onChangesDone={onLocalChange}
          autoFocus={true}
          onEnter={onLocalChange}
        />
        {getActions()}
      </div>
    );


    return result;
  }


  function getEditMode2() {

    return (
      <div className='LY_NameColumnInputContainer'>
        <LY.LY_InputBase   >
          <input
            
            type='text'
            value={value}
            className={combinedInputClassName}
            {...props}
          />
        </LY.LY_InputBase>
        {getActions()}
      </div>
    );

  }

  function render() {

    if (mode == 'view')
      return getViewMode();

    return getEditMode();

  }

/* 
  function render2() {

    return (
      <LY.LY_InputBase  {...props} style={style}
      //inputContainerClassName='LY_TagsInputContainer'  
      >
        {getRenderByMode()}

      </LY.LY_InputBase>
    );
  } */
  

  return render();

});


