import "./LY_NotesModule_SearchBar.css";

import React from "react";

import { IconReload, IconSearch } from "@tabler/icons-react";
import { Input } from "@mantine/core";

import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";
import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";

export const LY_NotesModule_SearchBar: React.FC<LY_NotesModuleProps> = (
  props
) => {
  const context = useNotesModuleContext();

  function render() {
    const disabled = context.state.notes.length === 0;

    if(disabled)
    return <div>
            No Notes Found – Write a note and click on Send button to save your note.
    </div>;

    return (
      <div className="LY_NotesModule_SearchBar LY_Flex_Row">
        <Input
          radius="lg"
          onChange={context.onSearchChange}
          value={context.state.searchTextInNotes}
          size="md"
          className="LY_Notes_Input"
          placeholder={"search"}
          leftSection={<IconSearch size={16} />}
          disabled={disabled}
        />
        <div
          onClick={() => context.fetchNotesAndSort()}
          className="LY_Notes_Icon"
          >
          <IconReload color="#757B87" size={20} title="Refresh" />
        </div>
      </div>
    );
  }

  return render();
};
