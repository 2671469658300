import "./LY_ListFilterModule.css";

import * as Api from "app-api"
import * as LY from '_LY_Components';
import * as React from "react";

import { LY_FiltersModule_Columns } from "./Components/LY_ListFilterModule_Filter_Columns/LY_ListFilterModule_Filter_Columns";
import { LY_FiltersModule_Filter } from "./Components/LY_ListFilterModule_Filter/LY_ListFilterModule_Filter";
import { LY_FiltersModule_Save } from "./Components/LY_ListFilterModule_Save/LY_ListFilterModule_Save";
import { LY_ListFilterModule_Search } from "./Components/LY_ListFilterModule_Search/LY_ListFilterModule_Search";
import { LY_FiltersModule_Sort } from "./Components/LY_ListFilterModule_Sort/LY_ListFilterModule_Sort";
import { LY_ListFilterModuleContextProvider } from "./Context/LY_ListFilterModuleContext";

export const LY_ListFilterModule: React.FC<LY.LY_ListFilterModuleProps> = (props) => {


  function render() {
    var view = props.view;
    // console.log('LY_FiltersModule render view:', view);
    // console.log('LY_FiltersModule render view.filter:', view?.filter);
    //console.log('LY_FiltersModule render view.orderByColumns:', view?.filter?.orderByColumns);

    return (
      <LY_ListFilterModuleContextProvider
        {...props}

      >

        <div className="LY_ListFilterModule">


          <div className="LY_ListFiltersModule_FiltersWrapper">
            <LY_ListFilterModule_Search />


            <div className="LY_ListFiltersModule_FiltersWrapper_Filters">


              {!props.hideColumnSelection &&
                <LY_FiltersModule_Columns />
              }
              {!props.hideSort &&
                <LY_FiltersModule_Sort {...props} />
              }
              {!props.hideFilters &&
                <LY_FiltersModule_Filter {...props} />
              }

              {!props.hideSave &&
                <LY_FiltersModule_Save />
              }
            </div>


          </div>


        </div>
      </LY_ListFilterModuleContextProvider>
    );
  }




  return render();
};
