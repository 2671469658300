
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { ComboboxOptionProps } from '@mantine/core';


export class CopyListScreenVm extends LIB.BaseVmModel {

    public currentResult: Api.List = new Api.List();
  
    public isCopyListScreenOpen: boolean = false;


    constructor() {
        super();
    }



    
    

}