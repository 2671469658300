import "./LY_NotesModule_User_Card.css";

import { Avatar } from "@mantine/core";

import React from "react";
import * as Api from "app-api";

import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";

interface LY_NotesModule_User_Card_Props extends LY_NotesModuleProps {
  note?: Api.Note;
  reply?: Api.NoteReply;
}

const LY_NotesModule_User_Card: React.FC<LY_NotesModule_User_Card_Props> = (
  props
) => {
  const isAuthor = props.currentUser?.id2 === props.reply?.author_id;
  let mainContainerClass: string;
  let authorName: string;

  if (props.reply) {
    mainContainerClass =
      "LY_NotesModule_User_Card LY_NotesModule_User_Card_Reply";
    authorName = props.reply?.author?.name!;
  } else {
    mainContainerClass = "LY_NotesModule_User_Card";
    authorName = props.note?.author?.name!;
  }

  return (
    <div className={mainContainerClass}>
      <Avatar
        className="LY_NotesModule_User_Card_Avatar"
        size={"md"}
        alt={authorName}
      />
      <span>{authorName}</span>
      {isAuthor && (
        <div className="LY_NotesModule_User_Card_Author_Badge">Author</div>
      )}
    </div>
  );
};

export default LY_NotesModule_User_Card;
