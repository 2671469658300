import "./LY_DoubleColorInput.css"

import LY_DoubleColorInputPopover from './components/LY_DoubleColorInputPopover/LY_DoubleColorInputPopover';
import React from 'react';
import { TextInput } from '@mantine/core';

interface LY_DoubleColorInputProps {
    item?: {
        name: string;
        text_color: string;
        color: string;
    };
    value?: string;
    index?: number;
    placeholder?: string;
    onInputChange: (value: string) => void;
    error?: string;
    onColorChange: (type: string, value: string) => void;
}

export const LY_DoubleColorInput: React.FC<LY_DoubleColorInputProps> = ({
    item,
    index,
    onInputChange,
    onColorChange,
    error,
    placeholder,
    value,
}) => {
    return (
        <TextInput
            key={index}
            name={item?.name}
            value={value}
            placeholder={placeholder}
            leftSection={
                <div className="LY_DoubleColorInput_item_left_section">
                    <LY_DoubleColorInputPopover
                        tooltip="Text Color"
                        initialColor={item?.text_color}
                        onColorChange={(color: string) => onColorChange('text_color', color)}
                        colorType="text"
                    />
                    <LY_DoubleColorInputPopover
                        tooltip="Background Color"
                        initialColor={item?.color}
                        onColorChange={(color: string) => onColorChange('color', color)}
                        colorType="background"
                    />
                </div>
            }
            onChange={(e) => onInputChange(e.target.value)}
            styles={{
                wrapper: {
                    minHeight: "30px"
                },
                input: {
                    minHeight: "30px",
                    height: "30px",
                }
            }}
            classNames={{
                section: 'LY_DoubleColorInput_item_left_section_wrapper',
                wrapper: 'LY_DoubleColorInput_item_input_wrapper',
                root: 'LY_DoubleColorInput_item_input_root',
                input: 'LY_DoubleColorInput_item_input',
            }}
            error={error}
        />
    );
};

