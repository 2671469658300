import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';

import './DetailContainer.css';
import { Badge, Tooltip, Tabs, rem } from '@mantine/core';
import * as Icons from '@tabler/icons-react';
import { LY_NotesModule } from '_LY_Components/_Modules/LY_NotesModule/LY_NotesModule';
 

interface DetailContainerProps {
  state?: App.ListState | App.DetailState;
  model?: LY.DataItemModel;
  onChange?: Function;

}

export const DetailContainer: React.FC<DetailContainerProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const currentUser = App.AppBase.currentUser;


  function onClosePanelClick() {

    console.log('onClosePanelClick onClose');


    var vm = props.state?.vm;

    if(vm instanceof  App.ListVm){
      vm.isDetailRightPanelOpen = false;
    }
    else if(vm instanceof  App.DetailVm){
      vm.isEditMode = false;
    }
   
    App.AppStore.dispatch(App.ActionTypes.LIST_SETTINGS_DETAIL_PANEL_CHANGE, vm);
  }

  function render() {

    var item = props.model;
    var state = props.state;

    var forceReadOnly =false;

    if(state instanceof App.ListState)
      forceReadOnly = state?.forceReadOnly;


    
    const iconStyle = { width: rem(12), height: rem(12) };

    return (
      <div className="DetailContainer">

        <Tabs defaultValue="form" className='DetailContainerTabs'>
          <Tabs.List>
          <Tabs.Tab value="form" leftSection={<Icons.IconForms style={iconStyle} />}>
              Form
            </Tabs.Tab>
            <Tabs.Tab value="notes" leftSection={<Icons.IconMessage style={iconStyle} />}>
              Notes 
            </Tabs.Tab>
            <Tabs.Tab value="emails" leftSection={<Icons.IconMessage style={iconStyle} />}>
              Emails
            </Tabs.Tab>
            <Tabs.Tab value="sms" leftSection={<Icons.IconMessageCircle style={iconStyle} />}>
              SMS
            </Tabs.Tab>
          </Tabs.List>


          <Tabs.Panel value="form" className='DetailContainerTabPanel'>


          <App.DefaultListTableForm 
           state={state}
           model={item} 
           onClose={onClosePanelClick}
           readOnly={forceReadOnly}
           formContainerClassName={'DetailContainerFormContainer'}
           formContainerItemClassName={'DetailContainerFormContainerItem'}


           />
 
          </Tabs.Panel>

          <Tabs.Panel value="notes" className='DetailContainerTabPanel'>
          <LY_NotesModule
                workspaceId={state?.workspaceId}
                currentUser={currentUser}
                recordId={item?.rowId}
              />

          </Tabs.Panel>

        
          <Tabs.Panel value="emails" className='DetailContainerTabPanel'>
          emails content
          </Tabs.Panel>

          <Tabs.Panel value="sms" className='DetailContainerTabPanel'>

          {item?.column?.displayName} <br />

ListID: {state?.list?.listId}<br />
RowId: {item?.rowId}<br />


          </Tabs.Panel>

        </Tabs>


      </div>
    );

  }




  return render();
};
