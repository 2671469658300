import React, { ChangeEvent, ChangeEventHandler } from 'react';

import * as LY from '_LY_Components';

import './LY_Select.css';

interface LY_SelectProps extends LY.LY_InputBaseProps {
  name: string;
  data: any[];
  labelProperty: string;
  valueProperty: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  //onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  //onChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;
  // onChange?: (model: LY.DataItemModel | undefined, event: ChangeEvent<HTMLInputElement> | undefined) => void;

  selectOptionValue?: any
  selectProps?: React.InputHTMLAttributes<HTMLSelectElement>;
  onChange?: ( model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}

//export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((


// Use React.forwardRef to forward the ref to the select element
export const LY_Select = React.forwardRef<HTMLSelectElement, LY_SelectProps>((
  {
    name,
    data,
    labelProperty,
    valueProperty,
    placeholder = "Select",
    selectOptionValue = '',
    defaultValue,
    inputClassName,
    model,
    value,
    onChange,
    selectProps,
    ...inputBaseProps

  },
  ref // This is the forwarded ref
) => {

  const combinedInputClassName = `mantine-Input-input mantine-NativeSelect-input LY_SelectInput ${inputClassName || ''}`;

 
  /*  console.log('LY_Select name', name);
   console.log('LY_Select value', value);
   console.log('LY_Select form', form);
  */
  //console.log('LY_Select value', value);

  var typeOfOptions = typeof selectOptionValue;

  return (
    <LY.LY_InputBase  {...inputBaseProps} >
      <select

        name={name}
        ref={ref}
        id={selectProps?.id}
        
        value={value || ''}
        className={combinedInputClassName}
        placeholder={placeholder}
        // autoComplete={Math.random().toString()}
        onChange={(e) => {
          if (model)
            model.value = e.target.value;
          if (onChange)
            onChange(model, e)
        }
        }

        {...selectProps}
        

      >
        <option value={selectOptionValue}>
          {placeholder}
        </option>
        {data.map((item, index) => (
          <option key={index} value={item[ valueProperty ]}>
            {item[ labelProperty ]}
          </option>
        ))}
      </select>
    </LY.LY_InputBase>
  );
});


