import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';
 
import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge,  Accordion, rem,Tooltip } from '@mantine/core';

import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


  
interface UpdateColumnAdvancedTab_ValueOptionsProps {
  state: App.ListState;
  onForceUpdate?: () => void;
 
}

export const UpdateColumnAdvancedTab_ValueOptions: React.FC<UpdateColumnAdvancedTab_ValueOptionsProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();
    
  const { view, list, selectedColumn, columnTypes } = props.state;
  const columns = view?.columns;
  
  const vm = props.state.vm;
  const state = props.state;
 

  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

     console.log('UpdateColumnAdvancedTab_ValueOptions onInputChange key:', key);
     console.log('UpdateColumnAdvancedTab_ValueOptions onInputChange selectedColumn:', selectedColumn);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


    //console.log('UpdateColumnAdvancedTab_ValueOptions onValueChange key:', key);
    //console.log('UpdateColumnAdvancedTab_ValueOptions onValueChange value:', value);

    //console.log('UpdateColumnAdvancedTab_ValueOptions onValueChange selectedColumn:', selectedColumn);

    if (key == 'isRequired') {
      selectedColumn.isNull = false;
    }  
    else if (key == 'isUnique') {
      selectedColumn.defaultValue = undefined;
    }  

    //@ts-ignore
    selectedColumn[ key ] = value;


    validate(key);

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange selectedColumn', selectedColumn);

    forceUpdate();

    if(props.onForceUpdate)
      props.onForceUpdate();

  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = selectedColumn;

    vm.errors.clear();

    if (vAll || key == 'maxLength') {
       if (item.maxLength!=undefined && item.maxLength <1)
        vm.errors.set('maxLength', `Max Length must be more than 0`);
    }
     
    /* if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required!`);
    }
    else if ((vAll || key == 'listColumnTypeId') && isNaN(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type must be a number!`);
    } */


    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }
 

  // when Type changes, let's change what's selected 
  var listColumnTypeId = selectedColumn.listColumnTypeId;

  //console.log('UpdateColumnAdvancedTab_ValueOptions watch listColumnTypeId:', listColumnTypeId);
  //console.log('UpdateColumnAdvancedTab_ValueOptions selectedColumn:', selectedColumn);
  //console.log('UpdateColumnAdvancedTab_ValueOptions columnTypes:', columnTypes);
  //console.log('UpdateColumnAdvancedTab_ValueOptions selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == selectedColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
   

  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var column = selectedColumn 

   // console.log('UpdateColumnAdvancedTab_ValueOptions render vm.isColumnSettingsScreenOpen', vm.isColumnSettingsScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || selectedColumn == undefined || !vm.isColumnSettingsScreenOpen)
      return null;
  
      var columns = props.state.view?.columns?.filter(x=>!x.isSystemHidden);

      var globalError = vm.errors.get('all');
      var item = selectedColumn;

      var isCalculatedType =  LY.ListColumnManager.isCalculatedColumn(item,true)

    return  <div className='UpdateColumnAdvancedTabGroups' >

      <LY.LY_Input
            name="defaultValue"
            value={item.defaultValue}
            type='text'
            label="Default Value"
            placeholder={item.isUnique?'Disabled when Unique Values Only ': 'Default Value' }
            labelPlacement='top'
           // withStar={true}
            error={vm.errors.get('defaultValue')}

            helpText="This value will be the default value for this column"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
            disabled = {item.isUnique}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 
  
  
        <LY.LY_Switch
            name="isRequired"
            value={item.isRequired}
            checked={item.isRequired}
            label="Required"
            labelPlacement='left'
           // withStar={true}
            error={vm.errors.get('isRequired')}

           // helpText="Force unique values in this column."
            // helpTextPlacement='bottom'
            onChange={(m, e) => {
              onInputChange(e!);
            }}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 

          <LY.LY_Switch
            name="isUnique"
            checked={item.isUnique}
            label="Unique Values Only"
            labelPlacement='left'
           // withStar={true}
            error={vm.errors.get('isUnique')}

           // helpText="Force unique values in this column."
            // helpTextPlacement='bottom'
            onChange={(m, e) => {
              onInputChange(e!);
            }}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 

         <LY.LY_Switch
            name="isNull"
            checked={item.isNull}
            label="Allow DB NULL Values"
            labelPlacement='left'
           // withStar={true}
            error={vm.errors.get('isNull')}

            //helpText="Technical setting, leave the default value."
            // helpTextPlacement='bottom'
            onChange={(m, e) => {
              onInputChange(e!);
            }}

            disabled = {item.isRequired && item.isNull}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 

        <LY.LY_Switch
            name="isIndex"
            checked={item.isIndex}
            label="Add DB Index For This Column"
            labelPlacement='left'
           // withStar={true}
            error={vm.errors.get('isIndex')}
            onChange={(m, e) => {
              onInputChange(e!);
            }}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 

          <LY.LY_Switch
              name="isRelationship"
              checked={item.isRelationship}
              label="Relationship"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('isRelationship')}

   /*            helpText="Force Relationship with Another List/Column"
              helpTextPlacement='bottom' */
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />


          <LY.LY_Switch
              name="showSum"
              checked={item.showSum}
              label="Show Summation"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('showSum')}

 
              onChange={(m, e) => {
                onInputChange(e!);
              }}
               
            />
        
      </div>;
   
  }

  return render();
};

