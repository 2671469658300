import "./LY_NotesModule.css";

import * as React from "react";

import { LY_NotesModuleContextProvider } from "./Context/LY_NotesModuleContext";
import { LY_NotesModule_Notes } from "./Components/LY_NotesModule_Notes/LY_NotesModule_Notes";
import { LY_NotesModule_SearchBar } from "./Components/LY_NotesModule_SearchBar/LY_NotesModule_SearchBar";
import { LY_NotesModuleProps } from "./Interfaces/LY_NotesModuleProps";

export const LY_NotesModule: React.FC<LY_NotesModuleProps> = (props) => {
  function render() {
    return (
      <LY_NotesModuleContextProvider {...props}>
        <div className="LY_NotesModule">
          <LY_NotesModule_SearchBar {...props} />
          <LY_NotesModule_Notes {...props} />
        </div>
      </LY_NotesModuleContextProvider>
    );
  }

  return render();
};
