import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class MyAccountService extends App.BaseAppService  {
 

    private attachmentService:App.AttachmentService;

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
        this.attachmentService = new App.AttachmentService();
    } 
 
/*
    public getCandidateListData(filter:Api.CandidateFilterVm|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/getCandidateListData?${urlPrams}`;
 
        this.get(apiUrl,
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_LIST_DATA_RECEIVED,
            App.ActionTypes.MY_ACCOUNT_API_ERROR 
            );

    }
     
    public createCandidate(data:Api.Candidate) {
        console.log("MyAccountService.createCandidate data: ", data);

        var formData = LIB.FormDataHelper.getFormData(data);

        console.log("MyAccountService.createCandidate formData: ",formData);
 
        if(data.resume!=undefined){
            formData.set('resumeStr',JSON.stringify(data.resume));
            formData.append('resumeFile',data.resumeFile as any);
        }

        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/createCandidate`;
        this.post(apiUrl, formData, 
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_ADD_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_API_ERROR
            ,'',null,null,false,true
 
 
             );
 
    } 

    public updateCandidate(data:Api.Candidate) {
        console.log("MyAccountService.updateCandidate data: ", data);

        var formData = LIB.FormDataHelper.getFormData(data);

        console.log("MyAccountService.updateCandidate formData: ",formData);

        if(data.resume!=undefined){
            formData.set('resumeStr',JSON.stringify(data.resume));
            formData.append('resumeFile',data.resumeFile as any);
        }
      
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/updateCandidate`;
        this.post(apiUrl, formData, 
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_API_ERROR
            ,'',null,null,false,true
 
 
    }

    public deleteCandidate(candidateId:number) {
        console.log("MyAccountService.deleteCandidate");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/deleteCandidate?candidateId=${candidateId}`;
        this.post(apiUrl, candidateId, 
            App.ActionTypes.MY_ACCOUNT_CANDIDATE_DELETE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
 
    }

    public createJob(data:Api.Job) {
        console.log("MyAccountService.createJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/createJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.MY_ACCOUNT_JOB_ADD_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
    
    }
    public updateJob(data:Api.Job) {
        console.log("MyAccountService.updateJob");
    
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/updateJob`;
        this.post(apiUrl, data, 
            App.ActionTypes.MY_ACCOUNT_JOB_UPDATE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
    
    }

    public deleteJob(jobId:number) {
        console.log("MyAccountService.deleteJob");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/deleteJob?jobId=${jobId}`;
        this.post(apiUrl, jobId, 
            App.ActionTypes.MY_ACCOUNT_JOB_DELETE_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR
            );
 
    }
 
    public getCandidateSubmits(){//filter:Api.CandidateFilterVm|null=null) {

        //var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/getCandidateSubmits`;//?${urlPrams}
 
        this.get(apiUrl,
            App.ActionTypes.MY_ACCOUNT_GET_CANDIDATE_SUBMITS_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR 
            );

    }

    
    public getResumeRequests(){//filter:Api.CandidateFilterVm|null=null) {

        //var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
        const apiUrl = `${App.AppBase.appApiBaseUrl}/MyAccount/getResumeRequests`;//?${urlPrams}
 
        this.get(apiUrl,
            App.ActionTypes.MY_ACCOUNT_GET_RESUME_REQUESTS_SUCCESS,
            App.ActionTypes.MY_ACCOUNT_API_ERROR 
            );

    }

    */
}
