import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';


export class ProspectsService extends App.BaseAppService  {
 

    constructor() { 

        super();
        //this.isAccessTokenRequired = true;
    } 

        public getProspectsListData(filter:Api.ProspectFilterVm|null=null, onAfterStateSet:Function|null=null) {

        var urlPrams = LIB.UrlHelper.convertToQueryString(filter);
       const apiUrl = `${App.AppBase.appApiBaseUrl}/Prospects/getProspects?${urlPrams}`;
        console.log("testing : getProspectsListData");
        
        this.get(apiUrl,
       App.ActionTypes.ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED,
           App.ActionTypes.ADMINISTRATION_API_ERROR 

            );

    }
     
     public create(data:Api.Prospect) {
        console.log("ProspectsService.create");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Prospects/create`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_PROSPECTS_ADD_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public update(data:Api.Prospect) {
        console.log("ProspectsService.update");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Prospects/update`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    public import(data:Api.BulkEmailProspectsSm, onAfterSuccess:Function|null=null) {
        console.log("ProspectsService.import");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Prospects/import`;
        this.post(apiUrl, data, 
            App.ActionTypes.ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR, '', onAfterSuccess
            );
 
    }

    public deleteProspect(prospectId:number) {
        console.log("ProspectsService.deleteProspect");

        const apiUrl = `${App.AppBase.appApiBaseUrl}/Prospects/deleteProspect?prospectId=${prospectId}`;
        this.post(apiUrl, prospectId, 
            App.ActionTypes.ADMINISTRATION_PROSPECTS_DELETE_SUCCESS,
            App.ActionTypes.ADMINISTRATION_API_ERROR
            );
 
    }

    
    
 
}
