import "./LY_ListFilterModule_Filter_Header.css";

import React from "react";

interface ILY_FiltersModule_Filter_Header {
  fieldName?: string;
  selectedItemCount?: number;
}
export const LY_FiltersModule_Filter_Header: React.FC<
  ILY_FiltersModule_Filter_Header
> = ({ selectedItemCount, fieldName }) => {
  return (
    <div className="LY_ListFiltersModule_Filter_Header">
      <div>{fieldName}</div>
      <div>{selectedItemCount} selected</div>
    </div>
  );
};
