import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class GlobalUIStateVm  extends LIB.BaseVmModel {
 
 
    //public isImpersonateModalOpen:boolean;
    public isAdminImpersonateModalOpen:boolean=false;
    public isMobile:boolean=false;
    public isMobileMenuOpen:boolean=false;
    public selectedMyAccountRootMenuIds:any;
    public selectedMyAccountMenuIds:string[]=[]; 
    public selectLeftMenuItem:string='';
 
    public companyStats:Api.CompanyStats;
   

    constructor() { 
 
        super();
       // this.isImpersonateModalOpen = false;

    }
     


}