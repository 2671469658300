import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import { Combobox, ComboboxOptionProps } from '@mantine/core';

export class ListTableViewContextState {// extends LIB.BaseStateModel {

 
  public selectedColumn: Api.ListColumn = new Api.ListColumn();
  public selectedCellModel?: LY.DataItemModel;
  public selectedCellElement: HTMLElement;
 
  constructor() {

   
  }



}