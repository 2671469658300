import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';

export class AdministrationState extends LIB.BaseStateModel {


  public vm: App.AdministrationVm;

  public appUsers: Api.AppUser[];
  public appUsersCount:number=0;
  public userRoleTypes: Api.UserRoleType[];
  public connectionPools: Api.ConnectionPoolVm[];

  public appUsersFullList: Api.AppUser[];
  public emailTemplates: Api.EmailTemplate[];
  public emailTemplatesFullList: Api.EmailTemplate[];
  public prospects: Api.Prospect[];
  public prospectsCount:number=0;
  public prospectsFullList: Api.Prospect[];
 
  public companies: Api.Company[];
  public companiesCount:number=0;
  public companiesFullList: Api.Company[];
  public companySearchFilter: Api.CompanyFilterVm = new Api.CompanyFilterVm();


  public appUsersSearchFilter: Api.AdminUserFilterVm = new Api.AdminUserFilterVm();
  public emailTemplateSearchFilter: Api.EmailTemplateFilterVm = new Api.EmailTemplateFilterVm();
  public prospectSearchFilter: Api.ProspectFilterVm = new Api.ProspectFilterVm();


  public hasUserDataLoaded: boolean = false;
  public hasEmailTemplateDataLoaded: boolean = false;
  public hasProspectDataLoaded: boolean = false;
  public hasCandidateDataLoaded: boolean = false;
  public hasJobDataLoaded: boolean = false;
  public hasCompanyDataLoaded: boolean = false;

   

  public adminUserSm: Api.AppUser = new Api.AppUser();
  public adminUpdateUserFormSm: Api.AdminUpdateUserFormSm = new Api.AdminUpdateUserFormSm();
  public adminCreateUserFormSm: Api.AdminCreateUserFormSm = new Api.AdminCreateUserFormSm();
  public adminUpdateProspectSm: Api.Prospect = new Api.Prospect();
  public adminCreateProspectSm: Api.Prospect = new Api.Prospect();
  public adminBulkImportProspectsSm: Api.BulkEmailProspectsSm = new Api.BulkEmailProspectsSm();
  public adminCreateEmailTemplateSm: Api.AdminCreateEmailTemplateSm = new Api.AdminCreateEmailTemplateSm();
  public adminUpdateEmailTemplateSm: Api.EmailTemplate = new Api.EmailTemplate();
   
  public listingStatusTypes:Api.ListingStatusType[];
 

  public isSearching: boolean;

  public sailyEmailSm: Api.DailyEmailSm = new Api.DailyEmailSm();


  constructor() {

    super();

    this.vm = new App.AdministrationVm();
    this.sailyEmailSm.testEmail="test@talgy.com";
    this.adminCreateProspectSm.enableProductUpdatesEmail = true;
    this.adminCreateEmailTemplateSm.isActive = true;


  }



}