import "./LY_ListFilterModule_Filter_Row.css";

import * as Api from "app-api";
import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB"
import * as LY from '_LY_Components';

import React from "react";
import useIsMobile from "_LIB/Components/Hooks/UseIsMobile";

interface LYFiltersModuleFilterRowProps extends LY.LY_ListFilterModuleProps {
  isFirstCondition?: boolean;
  isDeletable?: boolean;
  withDisabledOperations?: boolean;
  condition?: Api.FilterCondition;
  joinOperator?: Api.FilterConditionJoinOperatorEnum;
  rowIndex: number;
  groupIndex?: number;
  isConditionSet?: boolean;
  isConditionSets?: boolean;
  scrollToTop?: boolean;
  setScrollToTop?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LYFiltersModuleFilterRow: React.FC<LYFiltersModuleFilterRowProps> = ({
  isFirstCondition = false,
  isDeletable = true,
  withDisabledOperations = false,
  condition,
  joinOperator,
  rowIndex,
  groupIndex,
  scrollToTop,
  setScrollToTop
}) => {
  if (!Boolean(condition)) return null
  const checkMobile = useIsMobile(768);
  const context = LY.useListFilterModuleContext();
  const { columns, errors, view, } = context.state;
  const latestRowRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (scrollToTop && !groupIndex && setScrollToTop) {
      latestRowRef.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollToTop(false);
    }
  }, [scrollToTop]);
  function onInputChange(
    field: { value: any, key: keyof Api.FilterCondition },
    groupIndex: number | undefined,
    rowIndex: number,
  ) {
    const { value, key } = field;
    const requierdData: (keyof Api.FilterCondition)[] = ["name", "value", "op"];
    enum errorMessages {
      'name' = 'Name is required',
      'value' = 'Value is required',
      'op' = 'Operator is required',
    }

    if (groupIndex !== undefined && view?.filter?.conditionSets?.[groupIndex]?.conditions?.[rowIndex]) {

      var data = view.filter.conditionSets[groupIndex].conditions[rowIndex];
      data[key] = value || "" as string;

      context.validateManager.validateOne<Api.FilterCondition>(data, requierdData, errorMessages, rowIndex, groupIndex)


    } else if (view?.filter?.conditionSet?.conditions?.[rowIndex]) {

      var data = view.filter.conditionSet.conditions?.[rowIndex]
      data[key] = value || "" as string;

      context.validateManager.validateOne<Api.FilterCondition>(data, requierdData, errorMessages, rowIndex)

    }
    context.forceUpdate();
  }
  function firstConditionRenderer() {


    const joinOperatorData = Object.values(Api.FilterConditionJoinOperatorEnum).map(i => ({ value: i }))


    return <div className="LY_ListFiltersModuleFilterRow_wrapper">
      {isFirstCondition ? (
        <span className="LY_ListFiltersModuleFilterRow_condition">Where</span>
      ) : (
        <LY.LY_DropDown
          disabled={withDisabledOperations}
          name="joinOperator"
          defaultValue={joinOperator || "AND"}
          isClearable={false}
          valueProperty="value"
          displayProperty="value"
          data={joinOperatorData}
          placeholder="Op"
          isAutoCompleteStyle={true}
          selectedItem={joinOperatorData.find(i => i.value === joinOperator)}
          onChange={(_, option: any) => {
            console.log(option)
            const joinOperator = option.value as Api.FilterConditionJoinOperatorEnum;
            context.onChangeJoinOperator(joinOperator, groupIndex);
          }}
        />
      )}
    </div>
  }


  function rowRenderer() {
    var filterColName = groupIndex !== undefined ? groupIndex + 'name' + rowIndex : rowIndex + 'name';
    var filterCondition = groupIndex !== undefined ? groupIndex + 'op' + rowIndex : rowIndex + 'op';
    var filterValue = groupIndex !== undefined ? groupIndex + 'value' + rowIndex : rowIndex + 'value';
    const nameData = columns?.map((column: any) => ({
      value: column.name,
      label: column.displayName,
    }))
    const selectedFilterCol = nameData?.find(i => i.value === condition?.name)
    const conditionData = Object.values(Api.FilterConditionOperatorEnum).map(i => ({ value: i }))
    return <div className="LY_ListFiltersModuleFilterRow_filter_settings">



      <LY.LY_DropDown
        key={filterColName}
        name={filterColName}
        displayProperty="label"
        valueProperty="value"
        value={condition?.name}
        data={nameData}
        selectedItem={selectedFilterCol ? selectedFilterCol : undefined}
        placeholder="Name"
        isAutoCompleteStyle={true}
        error={errors?.get(filterColName)}
        onChange={(_, option: any) => {
          onInputChange({ value: option?.value, key: "name" }, groupIndex, rowIndex)
        }}
      />


      <LY.LY_DropDown
        key={filterCondition}
        name={filterCondition}
        placeholder="Operator"
        value={condition?.op}
        valueProperty="value"
        displayProperty="value"
        error={errors?.get(filterCondition)}
        data={conditionData}
        selectedItem={conditionData.find(i => i.value === condition?.op)}
        isAutoCompleteStyle={true}
        onChange={(_, option: any) => {
          console.log(option)
          onInputChange({ value: option?.value, key: "op" }, groupIndex, rowIndex)
        }}
      />
      <LY.LY_Input
        type={"input"}
        placeholder="Value"
        key={filterValue}
        name={filterValue}
        error={errors?.get(filterValue)}
        value={condition?.value}
        onChange={(_, event) => {
          const value = event?.target?.value;
          onInputChange({ value, key: "value" }, groupIndex, rowIndex)

        }}
      />
    </div>
  }


  function trashIconRenderer() {
    if (!isDeletable) return null;
    return <Icons.IconTrash
      className="LY_ListFiltersModuleFilterRow_deleteIcon"
      onClick={() => context.onDelete(rowIndex, groupIndex)}
      width={16}
      height={16}
    />

  }


  function render() {
    return <div
      ref={latestRowRef}
      className="LY_ListFiltersModuleFilterRow"
      style={{ paddingRight: groupIndex === undefined && checkMobile.isMobile ? "12px" : 0 }}
    >
      {firstConditionRenderer()}
      <div className="LY_ListFiltersModuleFilterRow_filter">
        {rowRenderer()}
        {trashIconRenderer()}
      </div>
    </div>
  }

  return render();

};

export default LYFiltersModuleFilterRow;
