import * as React from 'react';
import * as MT from '@mantine/core';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import * as LY from '_LY_Components';
import * as Icons from '@tabler/icons-react';


import { connect } from 'react-redux';
import { Table, Typography, Card, CardContent, Paper, Container, TextField, Box, Button, Grid, Backdrop, CircularProgress, InputAdornment } from '@mui/material';
import { withStyles, Theme, createStyles, makeStyles } from '@mui/material/styles';

import { Link as RouterLink } from 'react-router-dom';
import { CSSProperties } from "react";
import { Alert } from '@mui/material';

 

class PaymentSuccess extends React.Component<App.AccountState, {}> {

  public validationManager: App.ValidationManager;

  constructor(props: any) {
    super(props);
    this.validationManager = new App.ValidationManager();

  }

  // This method is called when the component is first added to the document
  public componentDidMount() {
    console.log('PaymentSuccess componentDidMount');
 
    if(App.AppBase.isUserAuthenticated){
      
       //call logout
      App.AppStore.dispatch(App.ActionTypes.ACCOUNT_LOGOUT_REQUEST);
     

    }  

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log('PaymentSuccess componentDidUpdate');
  }
 

  public getSuccessMsg(){

    var state=this.props;
    var vm = state.confirmEmailVm;

    if(vm.iconType!=='success')
        return null;

   var appInfo = App.AppBase.appInfo;
 
    return <div style={{paddingLeft:'8rem', paddingRight:'8rem', margin:'3rem'}}>
    
    <Alert severity="success">Successfully Confirmed! You can now Login and continue!</Alert>

    <div style={{marginTop:30, textAlign:'center'}}>
      <MT.Button variant="contained" color="primary" 
                onClick={(e) => App.RouteManager.routeToPath("/Login")} 
       >Login</MT.Button>
       </div>
     </div>
  }

  public render() {

    console.log("PaymentSuccess Render");
    var state=this.props;
    var vm = state.confirmEmailVm;
    var sm = state.confirmEmailSm;
    var currentUser = App.AppBase.currentUser;
 

    return <App.MainSplitPage
      state={vm}
      hasDataLoaded={true}
      loading={false}//state.isServiceCallPending}
      showContentWhenInProgress={true}
      showModalForLoading={true}
      hasNoPageDesign={true}
      mainPageContainerClassName='MainPageContentTop'
    >
      
       <App.QuickMessageContainer
      iconType={vm.iconType}
      message='Payment Success'
    >
 
    <div style={{paddingLeft:'8rem', paddingRight:'8rem', margin:'3rem'}}>
    
    <Alert severity="success">Thank You! Your payment was successfully processed. Please Login to continue.</Alert>

    <div style={{marginTop:30, textAlign:'center'}}>
      <MT.Button 
      variant="contained" color="primary" 
      onClick={(e) => App.RouteManager.routeToPath("/Login")} 
       >Login</MT.Button>
       </div>
     </div>

    </App.QuickMessageContainer>;


    </App.MainSplitPage>;

  }
};
export default connect(
  (state: App.AppState) => ({ ...state.account })
)(PaymentSuccess as any);
