
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as Api from 'app-api';
import { ComboboxOptionProps } from '@mantine/core';


export class DynamicDropDownModelVm extends LIB.BaseVmModel {

    public currentResult: Api.ListDropDownDataResult = new Api.ListDropDownDataResult();
  
    public enableServerSideSearch: boolean = false;


    constructor() {
        super();
    }



    
    

}