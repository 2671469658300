import * as Api from "app-api";
import * as App from "AppReferences";
import * as LIB from "_LIB";
import * as LY from "_LY_Components";

import { LY_StaticDropDownState } from "../Context/LY_StaticDropDownState";

export class LY_StaticDropDownModuleManager extends LIB.BaseManager {
    public state: LY_StaticDropDownState;
    public forceUpdate: Function;
    public props: LY.LY_StaticDropDownProps;

    constructor(props: LY.LY_StaticDropDownProps, state: LY_StaticDropDownState, forceUpdate: Function) {
        super("id");
        this.state = state;
        this.forceUpdate = forceUpdate;
        this.props = props;
    }



    public getStatices() {
        var service = new App.StaticDropDownService(this.props.workspaceId);
        this.state.isActionInProgress = true;
        this.forceUpdate();

        return service.getDropDownItems(this.state.model?.listId, this.state.model?.column?.listColumnId!,
            (result: Api.SystemLookupType[]) => {
                this.state.items = result;
                this.state.isActionInProgress = false;
                this.forceUpdate();

            },
            this.onServiceError.bind(this)
        );
    }

    public onColumnChange() {


        var service = new App.ListColumnService(this.props.workspaceId);
        this.state.isActionInProgress = true;
        this.forceUpdate();

        var col = this.state?.model?.column;

        if (!col || !col.listColumnId) {
            console.error('error: LY_DropDownModuleManager onColumnChange invalid column:', col);
            return;
        }


        service.updateColumn(col,
            () => {
                App.AppBase.showSaveSuccess();
                this.state.isActionInProgress = false;
                this.forceUpdate();

                if (this.props.onEditSaveSuccess)
                    this.props.onEditSaveSuccess(this.state.model, this.state.items, this.state.selectedItems)
            },
            this.onServiceError.bind(this)
        );

    }

    public saveChanges(sm: Api.SystemLookupType[]) {


        console.log('LY_DropDownModuleManager saveChanges sm:', sm);

        var service = new App.StaticDropDownService(this.props.workspaceId);

        service.saveChanges(sm,
            //onSuccess
            (result: Api.SystemLookupType[]) => {
                console.log('LY_DropDownModuleManager saveChanges onSuccess:', result);

                this.state.isActionInProgress = false;

                this.state.items = result;
                this.state.chagnedItems = [];
                this.state.isEditMode = false;
                this.forceUpdate();

                if (this.props.onEditSaveSuccess)
                    this.props.onEditSaveSuccess(this.state.model, result, this.state.selectedItems);

                App.AppBase.showSaveSuccess();


            },
            //onError
            this.onServiceError.bind(this)
        );

        this.state.isActionInProgress = true;
        this.forceUpdate();

    }


    public delete(id: string) {

        var service = new App.StaticDropDownService(this.props.workspaceId);

        this.state.isActionInProgress = true;
        this.forceUpdate();

        return service.delete(id,
            () => {
                const updatedStatices = this.state.items.filter(item => item.id !== id)
                this.state.items = updatedStatices;
                this.state.isActionInProgress = false;
                this.forceUpdate();

                App.AppBase.showSaveSuccess();


            },
            this.onServiceError.bind(this)
        );
    }

    public onServiceError(error: any) {

        console.log('LY_DropDownModuleManager onServiceError error:', error);
        console.log('LY_DropDownModuleManager onServiceError state:', this.state);


        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);
        this.state.isActionInProgress = false;
        this.state.errors.set('all', errorMessage);
        this.forceUpdate();

    }
}
