import * as App from 'AppReferences';
import { type } from 'os';
 
export enum ActionTypes {

    ERROR_API_ERROR = "ERROR_API_ERROR", 


    GLOBAL_INITIAL_DATA_RECEIVED = "GLOBAL_INITIAL_DATA_RECEIVED", 
    GLOBAL_LOG_API_RESULT = "GLOBAL_LOG_API_RESULT", 
    GLOBAL_UI_STATE_CHANGED = "GLOBAL_UI_STATE_CHANGED", 
    GLOBAL_UI_SELECT_LEFT_MENU = "GLOBAL_UI_SELECT_LEFT_MENU", 
    GLOBAL_API_ERROR = "GLOBAL_API_ERROR", 
    GLOBAL_APPINFO_DATA_RECEIVED="GLOBAL_APPINFO_DATA_RECEIVED",
    GLOBAL_WINDOW_SIZE_CHANGED = "GLOBAL_WINDOW_SIZE_CHANGED", 

    //Workspaces 
    GLOBAL_WORKSPACE_API_ERROR = "GLOBAL_WORKSPACE_API_ERROR", 
    GLOBAL_WORKSPACE_CHANGE_REQUEST = "GLOBAL_WORKSPACE_CHANGE_REQUEST", 
    GLOBAL_WORKSPACE_CHANGE_SUCCESS = "GLOBAL_WORKSPACE_CHANGE_SUCCESS", 

    GLOBAL_WORKSPACE_DELETE_REQUEST = "GLOBAL_WORKSPACE_DELETE_REQUEST", 
    GLOBAL_WORKSPACE_DELETE_SUCCESS = "GLOBAL_WORKSPACE_DELETE_SUCCESS", 
    GLOBAL_WORKSPACE_NEW_REQUEST = "GLOBAL_WORKSPACE_NEW_REQUEST", 
    GLOBAL_WORKSPACE_NEW_SUCCESS = "GLOBAL_WORKSPACE_NEW_SUCCESS", 

    GLOBAL_WORKSPACE_RENAME_REQUEST = "GLOBAL_WORKSPACE_RENAME_REQUEST", 
    GLOBAL_WORKSPACE_RENAME_SUCCESS = "GLOBAL_WORKSPACE_RENAME_SUCCESS", 

    GLOBAL_WORKSPACE_CHANGE_SETTINGS_REQUEST = "GLOBAL_WORKSPACE_CHANGE_SETTINGS_REQUEST", 
    GLOBAL_WORKSPACE_CHANGE_SETTINGS_SUCCESS = "GLOBAL_WORKSPACE_CHANGE_SETTINGS_SUCCESS", 


    //Workspace Item
    GLOBAL_WORKSPACE_ITEM_CHANGE = "GLOBAL_WORKSPACE_ITEM_CHANGE", 
    GLOBAL_WORKSPACE_MODAL_CHANGE = "GLOBAL_WORKSPACE_MODAL_CHANGE", 

    GLOBAL_WORKSPACE_LIST_CHANGED = "GLOBAL_WORKSPACE_LIST_CHANGED", 

    GLOBAL_WORKSPACE_ITEM_NEW_REQUEST = "GLOBAL_WORKSPACE_ITEM_NEW_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_NEW_SUCCESS = "GLOBAL_WORKSPACE_ITEM_NEW_SUCCESS", 

    GLOBAL_WORKSPACE_ITEM_MOVE_REQUEST = "GLOBAL_WORKSPACE_ITEM_MOVE_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_MOVE_SUCCESS = "GLOBAL_WORKSPACE_ITEM_MOVE_SUCCESS", 

    GLOBAL_WORKSPACE_ITEM_RENAME_REQUEST = "GLOBAL_WORKSPACE_ITEM_RENAME_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_RENAME_SUCCESS = "GLOBAL_WORKSPACE_ITEM_RENAME_SUCCESS", 

    GLOBAL_WORKSPACE_ITEM_DELETE_REQUEST = "GLOBAL_WORKSPACE_ITEM_DELETE_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS = "GLOBAL_WORKSPACE_ITEM_DELETE_SUCCESS", 

    GLOBAL_WORKSPACE_ITEM_COPY_REQUEST = "GLOBAL_WORKSPACE_ITEM_COPY_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_COPY_SUCCESS = "GLOBAL_WORKSPACE_ITEM_COPY_SUCCESS", 
    GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST = "GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_REQUEST", 
    GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_SUCCESS = "GLOBAL_WORKSPACE_ITEM_CHANGE_SETTINGS_SUCCESS", 

 
    ITEMLIST_NEW_VIDEO = "ITEMLIST_NEW_VIDEO", 
    ITEMLIST_CHANGE_FILTER = "ITEMLIST_CHANGE_FILTER",
    ITEMLIST_CHANGE_COLUMNS = "ITEMLIST_CHANGE_COLUMNS",
    ITEMLIST_PAGE_DATA_RECEIVED  = "ITEMLIST_PAGE_DATA_RECEIVED", 
    ITEMLIST_API_ERROR  = "ITEMLIST_API_ERROR", 
    ITEMLIST_CREATE_VIDEO_SUCCESS  = "ITEMLIST_CREATE_VIDEO_SUCCESS", 
    ITEMLIST_YTDETAIL_DATA_RECEIVED  = "ITEMLIST_YTDETAIL_DATA_RECEIVED", 
    ITEMLIST_UPDATE_VIDEO  = "ITEMLIST_UPDATE_VIDEO", 
    ITEMLIST_CHANNEL_EMAIL_UPDATED  = "ITEMLIST_CHANNEL_EMAIL_UPDATED", 
    ITEMLIST_SETTINGS_CHANGED  = "ITEMLIST_SETTINGS_CHANGED", 
 
    GUESTUSER_API_ERROR = "GUESTUSER_API_ERROR", 
    GUESTUSER_GET_PAGE_DATA  = "GUESTUSER_GET_PAGE_DATA", 
    GUESTUSER_PAGE_DATA_RECEIVED  = "GUESTUSER_PAGE_DATA_RECEIVED", 
    GUESTUSER_LOGIN_REQUEST = "GUESTUSER_LOGIN_REQUEST", 
    GUESTUSER_LOGIN_SUCCESS = "GUESTUSER_LOGIN_SUCCESS", 
    GUESTUSER_REGISTER_SUCCESS  = "GUESTUSER_REGISTER_SUCCESS", 
    GUESTUSER_REGISTER_REQUEST = "GUESTUSER_REGISTER_REQUEST", 
    GUESTUSER_FORGOT_PASSWORD_REQUEST  = "GUESTUSER_FORGOT_PASSWORD_REQUEST", 
    GUESTUSER_FORGOT_PASSWORD_SUCCESS  = "GUESTUSER_FORGOT_PASSWORD_SUCCESS", 
    GUESTUSER_PASSWORD_RESET_REQUEST  = "GUESTUSER_PASSWORD_RESET_REQUEST", 
    GUESTUSER_PASSWORD_RESET_SUCCESS  = "GUESTUSER_PASSWORD_RESET_SUCCESS", 

    GUESTUSER_CONFIRM_EMAIL_SUCCESS  = "GUESTUSER_CONFIRM_EMAIL_SUCCESS", 

    USER_RESEND_CONFIRM_EMAIL_SUCCESS  = "USER_RESEND_CONFIRM_EMAIL_SUCCESS", 

    ADMIN_USER_PAGE_DATA_RECEIVED  = "ADMIN_USER_PAGE_DATA_RECEIVED", 
    ADMIN_USER_API_ERROR  = "ADMIN_USER_API_ERROR", 
    ADMIN_USER_IMPERSONATE_USER_SUCCESS  = "ADMIN_USER_IMPERSONATE_USER_SUCCESS", 
    ADMIN_USER_RESET_SESSION_SUCCESS  = "ADMIN_USER_RESET_SESSION_SUCCESS", 

   /*  USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST", 
    USER_LOGOUT_SUCCESS = "USER_LOGOUT_REQUEST", 
    USER_API_ERROR = "USER_API_ERROR",  */

    ACCOUNT_API_ERROR = "ACCOUNT_API_ERROR", 
    ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST  = "ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_REQUEST", 
    ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS  = "ACCOUNT_CONFIRM_EMAIL_RESEND_INVITE_SUCCESS", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_REQUEST", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_SUCCESS", 
    ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR  = "ACCOUNT_CONFIRM_EMAIL_TOKEN_ERROR", 
    
    ACCOUNT_GET_PAGE_DATA_REQUEST  = "ACCOUNT_GET_PAGE_DATA_REQUEST", 
    ACCOUNT_GET_PAGE_DATA_SUCCESS  = "ACCOUNT_GET_PAGE_DATA_SUCCESS", 
 
    ACCOUNT_LOGOUT_REQUEST   = "ACCOUNT_LOGOUT_REQUEST", 
    ACCOUNT_LOGOUT_SUCCESS  = "ACCOUNT_LOGOUT_SUCCESS",  

    ACCOUNT_PROFILE_UPDATE_REQUEST  = "ACCOUNT_PROFILE_UPDATE_REQUEST", 
    ACCOUNT_PROFILE_UPDATE_SUCCESS  = "ACCOUNT_PROFILE_UPDATE_SUCCESS", 

    ACCOUNT_PASSWORD_CHANGE_REQUEST  = "ACCOUNT_PASSWORD_CHANGE_REQUEST", 
    ACCOUNT_PASSWORD_CHANGE_SUCCESS  = "ACCOUNT_PASSWORD_CHANGE_SUCCESS", 

    ACCOUNT_COMPANY_UPDATE_REQUEST  = "ACCOUNT_COMPANY_UPDATE_REQUEST", 
    ACCOUNT_COMPANY_UPDATE_SUCCESS  = "ACCOUNT_COMPANY_UPDATE_SUCCESS", 

    ACCOUNT_BILLING_PLANS_REQUEST  = "ACCOUNT_BILLING_PLANS_REQUEST", 
    ACCOUNT_BILLING_PLANS_SUCCESS  = "ACCOUNT_BILLING_PLANS_SUCCESS", 

    ACCOUNT_BILLING_CREATE_STRIPE_SESSION_REQUEST  = "ACCOUNT_BILLING_CREATE_STRIPE_SESSION_REQUEST", 
    ACCOUNT_BILLING_CREATE_STRIPE_SESSION_SUCCESS  = "ACCOUNT_BILLING_CREATE_STRIPE_SESSION_SUCCESS", 

   
    CANDIDATE_LIST_PAGE_DATA_REQUEST  = "CANDIDATE_LIST_PAGE_DATA_REQUEST", 
    CANDIDATE_LIST_PAGE_DATA_RECEIVED  = "CANDIDATE_LIST_PAGE_DATA_RECEIVED", 
    CANDIDATE_LIST_API_ERROR  = "CANDIDATE_LIST_API_ERROR", 
    CANDIDATE_LIST_REQUEST_RESUME  = "CANDIDATE_LIST_REQUEST_RESUME", 
    CANDIDATE_LIST_REQUEST_RESUME_SUCCESS  = "CANDIDATE_LIST_REQUEST_RESUME_SUCCESS", 
    CANDIDATE_LIST_PAGE_RESET = "CANDIDATE_LIST_PAGE_RESET",

    JOB_LIST_PAGE_DATA_REQUEST = "JOB_LIST_PAGE_DATA_REQUEST",
    JOB_LIST_PAGE_DATA_RECEIVED  = "JOB_LIST_PAGE_DATA_RECEIVED", 
    JOB_LIST_API_ERROR  = "JOB_LIST_API_ERROR",
    JOB_LIST_PAGE_RESET = "JOB_LIST_PAGE_RESET",
    JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST = "JOB_LISTING_CANDIDATE_LIST_DATA_REQUEST",
    JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED = "JOB_LISTING_CANDIDATE_LIST_DATA_RECEIVED",
    JOB_LIST_SUBMIT_CANDIDATE = "JOB_LIST_SUBMIT_CANDIDATE",
    JOB_LIST_SUBMIT_CANDIDATE_SUCCESS = "JOB_LIST_SUBMIT_CANDIDATE_SUCCESS", 

    MY_ACCOUNT_CANDIDATE_LIST_DATA_RECEIVED  = "MY_ACCOUNT_CANDIDATE_LIST_DATA_RECEIVED", 
    MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED  = "MY_ACCOUNT_JOBS_LIST_DATA_RECEIVED", 
    MY_ACCOUNT_API_ERROR   = "MY_ACCOUNT_API_ERROR", 

    MY_ACCOUNT_CANDIDATE_API_ERROR  = "MY_ACCOUNT_CANDIDATE_API_ERROR", 
    MY_ACCOUNT_CANDIDATE_ADD_REQUEST  = "MY_ACCOUNT_CANDIDATE_ADD_REQUEST", 
    MY_ACCOUNT_CANDIDATE_ADD_SUCCESS  = "MY_ACCOUNT_CANDIDATE_ADD_SUCCESS", 
    MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST  = "MY_ACCOUNT_CANDIDATE_UPDATE_REQUEST", 
    MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS  = "MY_ACCOUNT_CANDIDATE_UPDATE_SUCCESS", 
    MY_ACCOUNT_CANDIDATE_DELETE_REQUEST  = "MY_ACCOUNT_CANDIDATE_DELETE_REQUEST", 
    MY_ACCOUNT_CANDIDATE_DELETE_SUCCESS  = "MY_ACCOUNT_CANDIDATE_DELETE_SUCCESS", 
    MY_ACCOUNT_CANDIDATE_UPLOAD_REQUEST  = "MY_ACCOUNT_CANDIDATE_UPLOAD_REQUEST", 
    MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS  = "MY_ACCOUNT_CANDIDATE_UPLOAD_SUCCESS", 

    MY_ACCOUNT_JOB_ADD_REQUEST  = "MY_ACCOUNT_JOB_ADD_REQUEST", 
    MY_ACCOUNT_JOB_ADD_SUCCESS  = "MY_ACCOUNT_JOB_ADD_SUCCESS", 
    MY_ACCOUNT_JOB_UPDATE_REQUEST  = "MY_ACCOUNT_JOB_UPDATE_REQUEST", 
    MY_ACCOUNT_JOB_UPDATE_SUCCESS  = "MY_ACCOUNT_JOB_UPDATE_SUCCESS", 
    MY_ACCOUNT_JOB_DELETE_REQUEST  = "MY_ACCOUNT_JOB_DELETE_REQUEST", 
    MY_ACCOUNT_JOB_DELETE_SUCCESS  = "MY_ACCOUNT_JOB_DELETE_SUCCESS", 

    MY_ACCOUNT_GET_RESUME_REQUESTS_REQUEST  = "MY_ACCOUNT_GET_RESUME_REQUESTS_REQUEST", 
    MY_ACCOUNT_GET_RESUME_REQUESTS_SUCCESS  = "MY_ACCOUNT_GET_RESUME_REQUESTS_SUCCESS", 
    MY_ACCOUNT_GET_CANDIDATE_SUBMITS_REQUEST  = "MY_ACCOUNT_GET_CANDIDATE_SUBMITS_REQUEST", 
    MY_ACCOUNT_GET_CANDIDATE_SUBMITS_SUCCESS  = "MY_ACCOUNT_GET_CANDIDATE_SUBMITS_SUCCESS", 

    
    
    ADMINISTRATION_USERS_ADD_REQUEST  = "ADMINISTRATION_USERS_ADD_REQUEST", 
    ADMINISTRATION_USERS_ADD_SUCCESS  = "ADMINISTRATION_USERS_ADD_SUCCESS", 
    ADMINISTRATION_USERS_UPDATE_REQUEST  = "ADMINISTRATION_USERS_UPDATE_REQUEST", 
    ADMINISTRATION_USERS_UPDATE_SUCCESS  = "ADMINISTRATION_USERS_UPDATE_SUCCESS", 
    ADMINISTRATION_USERS_DELETE_REQUEST  = "ADMINISTRATION_USERS_DELETE_REQUEST", 
    ADMINISTRATION_USERS_DELETE_SUCCESS  = "ADMINISTRATION_USERS_DELETE_SUCCESS",

    ADMINISTRATION_USERS_LIST_DATA_RECEIVED  = "ADMINISTRATION_USERS_LIST_DATA_RECEIVED", 
    ADMINISTRATION_API_ERROR   = "ADMINISTRATION_API_ERROR", 
    ADMINISTRATION_USERS_LIST_PAGE_RESET = "ADMINISTRATION_USERS_LIST_PAGE_RESET",

    ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_USERS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_USERS_LIST_PAGE_DATA_RECEIVED = "ADMINISTRATION_USERS_LIST_PAGE_DATA_RECEIVED",

    ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST = "ADMINISTRATION_USERS_PASSWORD_CHANGE_REQUEST",
    ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS = "ADMINISTRATION_USERS_PASSWORD_CHANGE_SUCCESS",

    ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST = "ADMINISTRATION_USERS_ROLE_CHANGE_REQUEST",
    ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS = "ADMINISTRATION_USERS_ROLE_CHANGE_SUCCESS",


    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_REQUEST  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_REQUEST", 
    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_CANDIDATES_SUCCESS", 

    ADMINISTRATION_DAILY_EMAILS_SEND_HOT_JOBS_REQUEST  = "ADMINISTRATION_DAILY_EMAILS_SEND_HOT_JOBS_REQUEST", 

    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST ="ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET = "ADMINISTRATION_EMAILTEMPLATES_LIST_PAGE_RESET",

    ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_ADD_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_ADD_SUCCESS",

    ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_UPDATE_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_UPDATE_SUCCESS",
    
    ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST = "ADMINISTRATION_EMAILTEMPLATES_DELETE_REQUEST",
    ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS = "ADMINISTRATION_EMAILTEMPLATES_DELETE_SUCCESS",

    ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED = "ADMINISTRATION_PROSPECTS_LIST_DATA_RECEIVED",
    ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_PROSPECTS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET = "ADMINISTRATION_PROSPECTS_LIST_PAGE_RESET",

    ADMINISTRATION_PROSPECTS_ADD_REQUEST = "ADMINISTRATION_PROSPECTS_ADD_REQUEST",
    ADMINISTRATION_PROSPECTS_ADD_SUCCESS = "ADMINISTRATION_PROSPECTS_ADD_SUCCESS",

    ADMINISTRATION_PROSPECTS_UPDATE_REQUEST = "ADMINISTRATION_PROSPECTS_UPDATE_REQUEST",
    ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS = "ADMINISTRATION_PROSPECTS_UPDATE_SUCCESS",

    ADMINISTRATION_PROSPECTS_IMPORT_REQUEST = "ADMINISTRATION_PROSPECTS_IMPORT_REQUEST",
    ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS = "ADMINISTRATION_PROSPECTS_IMPORT_SUCCESS",

    ADMINISTRATION_PROSPECTS_DELETE_REQUEST = "ADMINISTRATION_PROSPECTS_DELETE_REQUEST",
    ADMINISTRATION_PROSPECTS_DELETE_SUCCESS = "ADMINISTRATION_PROSPECTS_DELETE_SUCCESS",

    ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_CANDIDATES_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_CANDIDATES_LIST_PAGE_RESET = "ADMINISTRATION_CANDIDATES_LIST_PAGE_RESET",

    ADMINISTRATION_CANDIDATES_ADD_REQUEST = "ADMINISTRATION_CANDIDATES_ADD_REQUEST",
    ADMINISTRATION_CANDIDATES_ADD_SUCCESS = "ADMINISTRATION_CANDIDATES_ADD_SUCCESS",

    ADMINISTRATION_CANDIDATES_UPDATE_REQUEST = "ADMINISTRATION_CANDIDATES_UPDATE_REQUEST",
    ADMINISTRATION_CANDIDATES_UPDATE_SUCCESS = "ADMINISTRATION_CANDIDATES_UPDATE_SUCCESS",

    ADMINISTRATION_CANDIDATES_DELETE_REQUEST = "ADMINISTRATION_CANDIDATES_DELETE_REQUEST",
    ADMINISTRATION_CANDIDATES_DELETE_SUCCESS = "ADMINISTRATION_CANDIDATES_DELETE_SUCCESS",
 
    ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_JOBS_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_JOBS_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_JOBS_LIST_PAGE_RESET = "ADMINISTRATION_JOBS_LIST_PAGE_RESET",

    ADMINISTRATION_JOBS_ADD_REQUEST = "ADMINISTRATION_JOBS_ADD_REQUEST",
    ADMINISTRATION_JOBS_ADD_SUCCESS = "ADMINISTRATION_JOBS_ADD_SUCCESS",

    ADMINISTRATION_JOBS_UPDATE_REQUEST = "ADMINISTRATION_JOBS_UPDATE_REQUEST",
    ADMINISTRATION_JOBS_UPDATE_SUCCESS = "ADMINISTRATION_JOBS_UPDATE_SUCCESS",

    ADMINISTRATION_JOBS_DELETE_REQUEST = "ADMINISTRATION_JOBS_DELETE_REQUEST",
    ADMINISTRATION_JOBS_DELETE_SUCCESS = "ADMINISTRATION_JOBS_DELETE_SUCCESS",

    ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST = "ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_REQUEST",
    ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_RECEIVED ="ADMINISTRATION_COMPANIES_LIST_PAGE_DATA_RECEIVED",
    ADMINISTRATION_COMPANIES_LIST_PAGE_RESET = "ADMINISTRATION_COMPANIES_LIST_PAGE_RESET",

    ADMINISTRATION_COMPANIES_ADD_REQUEST = "ADMINISTRATION_COMPANIES_ADD_REQUEST",
    ADMINISTRATION_COMPANIES_ADD_SUCCESS = "ADMINISTRATION_COMPANIES_ADD_SUCCESS",

    ADMINISTRATION_COMPANIES_UPDATE_REQUEST = "ADMINISTRATION_COMPANIES_UPDATE_REQUEST",
    ADMINISTRATION_COMPANIES_UPDATE_SUCCESS = "ADMINISTRATION_COMPANIES_UPDATE_SUCCESS",

    ADMINISTRATION_COMPANIES_DELETE_REQUEST = "ADMINISTRATION_COMPANIES_DELETE_REQUEST",
    ADMINISTRATION_COMPANIES_DELETE_SUCCESS = "ADMINISTRATION_COMPANIES_DELETE_SUCCESS",
 
    
    CMS_API_ERROR   = "CMS_API_ERROR", 
    CMS_CONTENT_PAGES_LIST_DATA_RECEIVED ="CMS_CONTENT_PAGES_LIST_DATA_RECEIVED",
    CMS_CONTENT_PAGES_LIST_DATA_REQUEST ="CMS_CONTENT_PAGES_LIST_DATA_REQUEST",
    CMS_CONTENT_PAGES_LIST_RESET = "CMS_CONTENT_PAGES_LIST_RESET",
    CMS_CONTENT_PAGES_ADD_REQUEST = "CMS_CONTENT_PAGES_ADD_REQUEST",
    CMS_CONTENT_PAGES_ADD_SUCCESS = "CMS_CONTENT_PAGES_ADD_SUCCESS",
    CMS_CONTENT_PAGES_UPDATE_REQUEST = "CMS_CONTENT_PAGES_UPDATE_REQUEST",
    CMS_CONTENT_PAGES_UPDATE_SUCCESS = "CMS_CONTENT_PAGES_UPDATE_SUCCESS",
    CMS_CONTENT_PAGES_DELETE_REQUEST = "CMS_CONTENT_PAGES_DELETE_REQUEST",
    CMS_CONTENT_PAGES_DELETE_SUCCESS = "CMS_CONTENT_PAGES_DELETE_SUCCESS",

    CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_REQUEST = "CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_REQUEST",
    CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_SUCCESS = "CMS_CONTENT_PAGES_GENERATE_AI_CONTENT_SUCCESS",

    
    LIST_API_ERROR   = "LIST_API_ERROR", 
  /*   LIST_SETTINGS_GET_VIEW_REQUEST ="LIST_SETTINGS_GET_VIEW_REQUEST",
    LIST_SETTINGS_GET_VIEW_SUCCESS ="LIST_SETTINGS_GET_VIEW_SUCCESS", */
    LIST_SETTINGS_DETAIL_PANEL_CHANGE ="LIST_SETTINGS_DETAIL_PANEL_CHANGE",

 
 
      //LIST_COLUMN
    LIST_COLUMN_SHOW_ADD_SCREEN ="LIST_COLUMN_SHOW_ADD_SCREEN",
    LIST_COLUMN_ADD_REQUEST ="LIST_DATA_GROUP_ADD_REQUEST",
    LIST_COLUMN_ADD_SUCCESS ="LIST_DATA_GROUP_ADD_SUCCESS",
    LIST_COLUMN_UPDATE_REQUEST ="LIST_DATA_GROUP_UPDATE_REQUEST",
    LIST_COLUMN_UPDATE_SUCCESS ="LIST_DATA_GROUP_UPDATE_SUCCESS",
    LIST_COLUMN_DELETE_REQUEST ="LIST_DATA_GROUP_DELETE_REQUEST",
    LIST_COLUMN_DELETE_SUCCESS ="LIST_DATA_GROUP_DELETE_SUCCESS",

    //LIST_DATA_ITEM
    LIST_DATA_ITEM_ADD_REQUEST ="LIST_DATA_ITEM_ADD_REQUEST",
    LIST_DATA_ITEM_ADD_SUCCESS ="LIST_DATA_ITEM_ADD_SUCCESS",
/*     LIST_DATA_ITEM_UPDATE_REQUEST ="LIST_DATA_ITEM_UPDATE_REQUEST",
    LIST_DATA_ITEM_UPDATE_SUCCESS ="LIST_DATA_ITEM_UPDATE_SUCCESS", */
    LIST_DATA_ITEM_DELETE_REQUEST ="LIST_DATA_ITEM_DELETE_REQUEST",
    LIST_DATA_ITEM_DELETE_SUCCESS ="LIST_DATA_ITEM_DELETE_SUCCESS",
 
    LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE ="LIST_DATA_ITEM_VIEW_DETAIL_POPUP_CHANGE",
 

     //LIST_GROUP
     LIST_DATA_GROUP_ADD_REQUEST ="LIST_DATA_GROUP_ADD_REQUEST",
     LIST_DATA_GROUP_ADD_SUCCESS ="LIST_DATA_GROUP_ADD_SUCCESS",
     LIST_DATA_GROUP_UPDATE_REQUEST ="LIST_DATA_GROUP_UPDATE_REQUEST",
     LIST_DATA_GROUP_UPDATE_SUCCESS ="LIST_DATA_GROUP_UPDATE_SUCCESS",
     LIST_DATA_GROUP_DELETE_REQUEST ="LIST_DATA_GROUP_DELETE_REQUEST",
     LIST_DATA_GROUP_DELETE_SUCCESS ="LIST_DATA_GROUP_DELETE_SUCCESS",


    ATTACHMENT_API_ERROR = "ATTACHMENT_API_ERROR", 
    ATTACHMENT_UPLOAD_REQUEST  = "ATTACHMENT_UPLOAD_REQUEST", 
    ATTACHMENT_UPLOAD_SUCCESS  = "ATTACHMENT_UPLOAD_SUCCESS", 

    ROUTE_CHANGE  = "ROUTE_CHANGE", 

} 

 