import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class ListSettingsService extends App.BaseAppService  {
 


    constructor(workspaceId:number|undefined) { 

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
     } 
     
/* 
     public getListByViewId(viewId:number) {
        console.log("ListSettingsService.getListByViewId viewId: ", viewId);

  
        const apiUrl = `${App.AppBase.appApiBaseUrl}/List/getListByViewId?viewId=${viewId}`;
        this.get(apiUrl, 
            App.ActionTypes.LIST_SETTINGS_GET_VIEW_SUCCESS,
            App.ActionTypes.LIST_API_ERROR,
            //onAfterSuccess
         
        );
 
    } */
 
  
}
