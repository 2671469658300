import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import axios from 'axios';
import * as Api from 'app-api';



export class StaticDropDownService extends App.BaseAppService {


    //private linkedStaticDropDownService: App.LinkedStaticDropDownService | undefined = undefined

    constructor(workspaceId: number | undefined) {

        super();
        //this.isAccessTokenRequired = true;

        this.workspaceId = workspaceId;
        //  this.linkedStaticDropDownService = new App.LinkedStaticDropDownService(workspaceId);
    }


    public async getDropDownItems(listId:string, listColumnId:string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!listId || !listColumnId) {
            console.log('StaticDropDownService.getDropDownItems listId and listColumnId is required');
            return;
        }
 
        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/getDropDownItems?listId=${listId}&listColumnId=${listColumnId}`;

        await this.executeApi({
            apiUrl: apiUrl,
           // data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.getDropDownItems onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'get'
        });


    }
 


    public async create(sm: Api.SystemLookupType, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('StaticDropDownService.create sm.listId is required', sm);
            return;
        }

        console.log("StaticDropDownService.create sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/create`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.create onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }

 
    public async createItems(list: Api.SystemLookupType[], onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!list || list.length === 0) {
            console.log('StaticDropDownService.createItems list', list);
            return;
        }

        console.log("StaticDropDownService.createItems list: ", list);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/createItems`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: list,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.createItems onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }

    public async saveChanges(list: Api.SystemLookupType[], onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!list || list.length === 0) {
            console.log('StaticDropDownService.saveChanges list', list);
            return;
        }

        console.log("StaticDropDownService.saveChanges list: ", list);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/saveChanges`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: list,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.saveChanges onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'POST'
        });


    }


    

    public async update(sm: Api.SystemLookupType, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!sm) {
            console.log('StaticDropDownService.update sm.listId is required', sm);
            return;
        }

        console.log("StaticDropDownService.update sm: ", sm);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/update`;

        await this.executeApi({
            apiUrl: apiUrl,
            data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.update onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'patch'
        });


    }
 

    public async delete(id: string, onSuccess: Function | undefined = undefined, onError: Function | undefined = undefined) {

        if (!id) {
            console.log('StaticDropDownService.delete id is required', id);
            return;
        }

        console.log("StaticDropDownService.delete id: ", id);

        const apiUrl = `${App.AppBase.appApiBaseUrl}/StaticDropDown/delete?id=${id}`;

        await this.executeApi({
            apiUrl: apiUrl,
            //   data: sm,
            onSuccess: onSuccess?.bind(this),
            onError: (error: any) => {
                if (onError)
                    onError(error);
                else {
                    console.error("StaticDropDownService.delete onError: ", error);
                    this.onErrorDefault(error);
                }
            },
            method: 'delete'
        });


    }
  


}
