import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';

import { useRef } from 'react';


import axios from 'axios';

import Box from '@mui/material/Box';

import * as Api from 'app-api';

import './ListKanbanView.css';
import { Badge, Tooltip, Tabs, rem } from '@mantine/core';
import * as Icons from '@tabler/icons-react';
import { DefaultListTableForm } from 'Pages/Form/DefaultListTableForm/DefaultListTableForm';
import { MultipleContainers } from './dnd/MultipleContainers';


interface ListKanbanViewProps {
 
  

}

export const ListKanbanView: React.FC<ListKanbanViewProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();


  function render() {

     const iconStyle = { width: rem(12), height: rem(12) };

    return  <MultipleContainers
    containerStyle={{
      maxHeight: '80vh',
    }}
    itemCount={15}
    scrollable
    
  />


  }




  return render();
};
