import * as React from 'react';
import * as App from 'AppReferences';
import * as LIB from '_LIB';
import * as LY from '_LY_Components';
import * as Api from 'app-api';



import { TextInput, Select, Box, } from '@mantine/core';
import { Modal, Button, Group, Text, Badge, ScrollArea } from '@mantine/core';
import * as MT from '@mantine/core';
import { useEffect, useState, useRef } from 'react';

import * as RIcons from '@radix-ui/react-icons';

import { useFocusTrap } from '@mantine/hooks';


import * as Icons from '@tabler/icons-react';


import { useDisclosure } from '@mantine/hooks';

import './_AddColumnScreen.css';
import { UpdateColumnAdvancedTab_Calculations } from '../UpdateColumnScreen/UpdateColumnAdvancedTabGroups/UpdateColumnAdvancedTab_Calculations';
import { ListColumnRelationshipSettings } from '../_shared/ListColumnRelationshipSettings';

interface AddColumnScreenProps {
  state: App.ListState;
  refreshData: () => void;


}

export const AddColumnScreen: React.FC<AddColumnScreenProps> = (props) => {

  const forceUpdate = LIB.useForceUpdate();
  const focusTrapRef = useFocusTrap();

  const relationshipSettingsRef = useRef<any>();


  /*   const newItemInput = useRef<HTMLInputElement>(null);
    const newItem = useRef<LY.LY_TextInputHandle>(null); */

  const [ open, setOpen ] = React.useState(false);

  const { view, list, newColumn, columnTypes } = props.state;
  const columns = view?.columns || [];

  var vm = props.state.vm;
  const state = props.state;


  useEffect(() => {
    console.log('AddColumnScreen useEffect newColumn', newColumn);
    // vm.isActionInProgress=true;


  }, [ newColumn ]);



  function onInputChange(e: React.ChangeEvent<any>) {

    var key = LIB.FormHelper.getChangeTargetName(e.target);
    var value = LIB.FormHelper.getChangeTargetValue(e.target);

     console.log('AddColumnScreen onInputChange key:', key);
    // console.log('AddColumnScreen onInputChange newWorkspace:', newWorkspace);

    onValueChange(key, value);

  }

  function onValueChange(key: string, value: any) {


     console.log('AddColumnScreen onValueChange key:', key);
     console.log('AddColumnScreen onValueChange value:', value);

    if (key == 'displayName' || key == 'name') {
      newColumn.name = LIB.StringHelper.replaceAll(value, ' ', '_')?.toLowerCase();
    }

    //@ts-ignore
    newColumn[ key ] = value;
 
    validate(key);

    console.log('AddColumnScreen onValueChange newColumn.isRelationship:', newColumn.isRelationship);

    

    //todo this.validationManager.validateAccountProfileEdit(key, item);

    //console.log('onInputChange newWorkspace', newWorkspace);

    forceUpdate();


  }

  function validate(key: string): boolean {

    var vAll = (key === 'all'); //validate all
    var item = newColumn;

    if(item.isRelationship){
      var isRelValid = relationshipSettingsRef?.current?.validateAll();
      if (!isRelValid){
        console.log('AddColumnScreen isRelationship isValid:', isRelValid);
        return false;
      }
    
    }
 

    vm.errors.clear();

    if (vAll || key == 'name') {
      if (LIB.Common.isNullOrEmpty(item.name))
        vm.errors.set('name', `Internal Name is required`);
      else if (item.name.length > 50)
        vm.errors.set('name', `Internal Name must be less than 50 characters`);
    }
    if (vAll || key == 'displayName') {
      if (LIB.Common.isNullOrEmpty(item.displayName))
        vm.errors.set('displayName', `Name is required!`);
      else if (item.displayName.length > 50)
        vm.errors.set('displayName', `Name must be less than 50 characters`);
    }

    if ((vAll || key == 'listColumnTypeId') && LIB.Common.isNullOrEmpty(item.listColumnTypeId)) {
      vm.errors.set('listColumnTypeId', `Column Type is required`);
    }
    else if ((vAll || key == 'listColumnTypeId') && (isNaN(item.listColumnTypeId) || item.listColumnTypeId < 1)) {
      vm.errors.set('listColumnTypeId', `Column Type is required`);
    }

    if ((vAll || key == 'defaultValue') && LIB.Common.isNullOrEmpty(item.defaultValue) && item.isNull===false) {
      vm.errors.set('defaultValue', `Default Value is required`);
    }

    var isCalculatedType = LY.ListColumnManager.isCalculatedColumn(item, true)

    if (vAll && isCalculatedType &&
      (LIB.Common.isNullOrEmpty(item.clientCalculation) &&
        LIB.Common.isNullOrEmpty(item.backendCalculation))
    ) {
      var calcMsg = `Either Client Calculation or Backend Calculation is Required`;
      vm.errors.set('clientCalculation', calcMsg);
      vm.errors.set('backendCalculation', calcMsg);
    }

    var isValid = (vm.errors.size < 1);
    //console.log('validateOne isValid:', isValid);
    //console.log('validateOne vm.errors:', vm.errors);
    //console.log('validateOne col.name error:', vm.errors[col.name]);

    return isValid;

  }




  function onSubmit(e: React.FormEvent<HTMLFormElement>) {

    e.preventDefault();

    console.log('AddColumnScreen onSubmit newColumn:', newColumn);
    //console.log('AddColumnScreen onSubmit configId:', data?.configId);
    //console.log('AddColumnScreen onSubmit  newWorkspace configId:', newWorkspace?.configId);

    var isValid = validate('all');
    if (!isValid) {
      console.log('AddColumnScreen onSubmit isValid:', isValid);
      forceUpdate();
      return false;
    }
    var sm = newColumn;

    //place it at the end for now
    sm.columnOrder = (columns.length + 1);// * 10;
    sm.workspaceId = view?.workspaceId;
    sm.listViewId = state.view?.listViewId;

    console.log('AddColumnScreen sm send:', sm);
    //   return;

    var service = new App.ListColumnService(sm.workspaceId);

    service.addColumn(sm,
      //onSuccess
      (result: any) => {
        console.log('AddColumnScreen onSuccess:', result);

        vm.errors.clear();
        vm.isActionInProgress = false;
        vm.isAddColumnScreenOpen = false;

        
 

        props.refreshData();
        forceUpdate();

      },
      //onError
      (error: any) => {

        console.error('AddColumnScreen onError:', error);

        let errorMessage = LIB.ErrorHelper.getErrorMessage(error);

        vm.isActionInProgress = false;
        vm.errors.set('all', errorMessage);
        forceUpdate();
      });

 
    vm.isActionInProgress = true;
    forceUpdate();

 


  }

  // when Type changes, let's change what's selected 
  var listColumnTypeId = newColumn.listColumnTypeId;

  //console.log('AddColumnScreen watch listColumnTypeId:', listColumnTypeId);
  //console.log('AddColumnScreen newColumn:', newColumn);
  //console.log('AddColumnScreen columnTypes:', columnTypes);
  //console.log('AddColumnScreen selectedColumnType:', selectedColumnType);

  var defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == newColumn?.listColumnTypeId);

  if (listColumnTypeId) {
    defaultTypeValue = columnTypes.find(item => item.listColumnTypeId == listColumnTypeId);

  }
  /* if(newColumn.listColumnTypeId!=listColumnTypeId){
    newColumn.listColumnTypeId=listColumnTypeId;
    forceUpdate();
  } */



  // console.log('AddColumnScreen defaultTypeValue:', defaultTypeValue?.listColumnTypeId);
  //console.log('AddColumnScreen errors:', errors);



  function getForm(column: Api.ListColumn) {
    var vm = props.state.vm;
    var item = newColumn;
    var columnTypes = props.state.columnTypes;
    let columns = props.state?.view?.columns?.filter(x => !x.isSystemHidden) || [];
 

    var globalError = vm.errors.get('all');
    var isCalculatedType = LY.ListColumnManager.isCalculatedColumn(item, true)

    console.log('AddColumnScreen getForm isRelationship:', item.isRelationship);


    var form = (
      <form
        onSubmit={onSubmit}
        className='AddColumnScreenForm'
        ref={focusTrapRef}>

        {globalError && <div style={{ color: 'red', marginBottom: 10 }}  >{globalError}</div>}

        <div className='AddColumnScreenFormScrollArea' >

        <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
          <LY.LY_Input
            name="displayName"
            value={item.displayName}
            type='text'
            label="Display Name"
            placeholder="Display Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('displayName')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            containerStyle={{flex:1}}
            
            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />

      <LY.LY_Input
            name="defaultValue"
            value={item.defaultValue}
            type='text'
            label="Default Value"
            placeholder={item.isUnique?'Disabled when Unique Values Only ': 'Default Value' }
            labelPlacement='top'
            withStar={item.isNull==false}
            error={vm.errors.get('defaultValue')}
 

            onChange={(m, e) => {
              onInputChange(e!);
            }}
            disabled = {item.isUnique}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 
  

        </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
            <LY.LY_Switch
              name="isRequired"
              value={item.isRequired}
              label="Required"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('isRequired')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />
            <LY.LY_Switch
              name="isRelationship"
              value={item.isRelationship}
              label="Relationship"
              labelPlacement='left'
              // withStar={true}
              error={vm.errors.get('isRelationship')}

              // helpText="Force unique values in this column."
              // helpTextPlacement='bottom'
              onChange={(m, e) => {
                onInputChange(e!);
              }}
              hidden={isCalculatedType}
            />

        <LY.LY_Switch
            name="isNull"
            checked={item.isNull || item.isNull===undefined}
            label="Is Null"
            labelPlacement='left'
           // withStar={true}
            error={vm.errors.get('isNull')}

            //helpText="Technical setting, leave the default value."
            // helpTextPlacement='bottom'
            onChange={(m, e) => {
              onInputChange(e!);
            }}

            disabled = {item.isRequired && item.isNull}
            hidden={LY.ListColumnManager.isCalculatedColumn(item)}
          /> 
          </div>

        {/*   <LY.LY_Select
            name="listColumnTypeId"
            value={item.listColumnTypeId}
            label="Column Type"
            placeholder="Select"
            selectOptionValue={App.ListColumnTypeEnum.Other}

            data={columnTypes}
            //value={defaultTypeValue?.listColumnTypeId}
            labelProperty="name"
            valueProperty="listColumnTypeId"
            withStar={true}
            error={vm.errors.get('listColumnTypeId')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          /> */}

        <LY.LY_DropDown
            name="listColumnTypeId"
            label="Column Type"
            withStar={true}
            value={item.listColumnTypeId}
            displayProperty="name"
            valueProperty="listColumnTypeId"
            data={columnTypes}

            selectedItem={columnTypes?.find((col: any) => col.listColumnTypeId === item.listColumnTypeId)}
            isAutoCompleteStyle={true}
            error={vm.errors.get('listColumnTypeId')}
           
            onChange={(value, option:any) => {

              console.log('AddColumnScreen LY_DropDown onLocalValueChange value:', value);
              console.log('AddColumnScreen LY_DropDown onLocalValueChange option:', option);

              onValueChange('listColumnTypeId', option?.value)
            }}

           // className="LY_FiltersModule_Sort_Select_Columns"
            //inputContainerClassName="LY_FiltersModule_Sort_Select_Columns"
          />


          {!isCalculatedType && defaultTypeValue &&
            <MT.Alert variant="light" color="cyan" title={`Selected Type: ${defaultTypeValue?.name || ''}`}
              style={{ marginTop: 0, minHeight: 50, padding: 8 }} >
              {defaultTypeValue?.description}
            </MT.Alert>
          }

          {isCalculatedType &&
            <UpdateColumnAdvancedTab_Calculations
              column={item}
              state={props.state} />
          }
          {!isCalculatedType && item.isRelationship &&
        
            <div className='LY_AddColumn_RelationshipSettings'>
              <ListColumnRelationshipSettings
              ref={relationshipSettingsRef}
              state={props.state}
              column={item}
              isAddColumnView={true}
              onSelectionsDone={(svm: LY.ListColumnRelationshipSettingsVm) => {
                console.log('ListColumnRelationshipSettings onSelectionsDone svm: ', svm);


              }}
            /> 
            </div>
          }



          <LY.LY_Input
            name="name"
            value={item.name}
            type='text'
            label="Internal Name"
            placeholder="Internal Name"
            labelPlacement='top'
            withStar={true}
            error={vm.errors.get('name')}

            helpText="This will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />


          <LY.LY_Select
            name="afterColumnOrder"
            value={item.afterColumnOrder}
            label="Add After Column"
            placeholder="Add at the end"
            // selectOptionValue={App.ListColumnTypeEnum.Other}

            data={columns}
            //value={defaultTypeValue?.listColumnTypeId}
            labelProperty="displayName"
            valueProperty="columnOrder"
            // withStar={true}
            error={vm.errors.get('afterColumnOrder')}

            // helpText="This name will be used in APIs or Integrations"
            // helpTextPlacement='bottom'

            onChange={(m, e) => {
              onInputChange(e!);
            }}
          />


          {/* <Controller
            name="name"
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                // required={true}
                withAsterisk={true}
                label="Internal Name"
                placeholder="Internal Name"

                description="This name will be used in APIs or Integrations"

                error={errors.name?.message}
              />
            )}
          />
 */}
        </div>


        <Group mt="xl" justify='right'>
          <MT.Button variant='default' onClick={() => onOpenChange(false)} >Cancel</MT.Button>
          <MT.Button type='submit'
            loading={vm.isActionInProgress} loaderProps={{ type: 'dots' }}


          >
            Add
          </MT.Button>
        </Group>

      </form>
    );

    return form;

  }





  function onOpenChange(open: boolean) {
    var vm = props.state.vm;

    vm.isAddColumnScreenOpen = open;
    vm.errors.clear();

    //setOpen(open);
    forceUpdate();
  }


  function render() {
    //const [opened, { open, close }] = useDisclosure(false);

    var state = props.state;
    var vm = state.vm;
    var column = props.state.newColumn

    // console.log('AddColumnScreen render vm.isAddColumnScreenOpen', vm.isAddColumnScreenOpen);

    if (LIB.Common.isNullOrEmpty(state) || !state.hasDataLoaded || column == undefined || !vm.isAddColumnScreenOpen)
      return null;



    return (
      <>
        <Modal
          id='AddColumnScreenModal'
          opened={vm.isAddColumnScreenOpen}
          onClose={() => onOpenChange(false)}
          title="Add Column"
          //scrollAreaComponent={ScrollArea.Autosize}
          centered
          overlayProps={{
            backgroundOpacity: 0.1,
            blur: 0,
          }}
          transitionProps={{ duration: 0, timingFunction: 'linear' }}
          className={'LY_Global_Modals'}
          size={'lg'}
        >

          {getForm(column)}

        </Modal>

      </>
    );
  }

  return render();
};

