import "./LY_NotesModule_Notes_Reply_Card.css";
import React from "react";

import * as Icons from "@tabler/icons-react";
import * as LIB from "_LIB";
import * as Api from "app-api";

import { Highlight } from "@mantine/core";

import { useNotesModuleContext } from "../../Context/LY_NotesModuleContext";
import { LY_NoteModule_Note_Menu } from "../LY_NotesModule_Note_Menu/LY_NotesModule_Note_Menu";

import { LY_NotesModuleProps } from "../../Interfaces/LY_NotesModuleProps";
import LY_NotesModule_User_Card from "../LY_NotesModule_User_Card/LY_NotesModule_User_Card";
import { LY_NotesModule_Update_Input } from "../LY_NotesModule_Update_Input/LY_NotesModule_Update_Input";

interface LY_NotesModule_Notes_Reply_Card_props extends LY_NotesModuleProps {
  note?: Api.Note;
  reply?: Api.NoteReply;
}

export const LY_NotesModule_Notes_Reply_Card: React.FC<
  LY_NotesModule_Notes_Reply_Card_props
> = (props) => {
  const context = useNotesModuleContext();

  function replyUserAvatarRenderer() {
    return <LY_NotesModule_User_Card {...props} reply={props.reply} />;
  }

  function replyMenuRenderer() {
    return <LY_NoteModule_Note_Menu reply={props.reply} {...props} />;
  }

  function replyTextContainerRenderer() {
    const currentReply = props.reply?.id === context.state.selectedReply?.id;

    if (currentReply && context.state.updatingReply) {
      return <LY_NotesModule_Update_Input {...props} reply={props.reply} />;
    } else {
      return (
        <Highlight
          className="LY_NotesModule_Note_Reply_Card_Message_Container"
          highlight={context.state.searchTextInNotes}>
          {props.reply?.message!}
        </Highlight>
      );
    }
  }

  function replyPinBtnRenderer() {
    function onReplyPinClick(reply: Api.NoteReply) {
      context.selectReply(reply.note_id!, reply.id!);
      context.toggleReplyPin();
    }

    if (props.reply?.pinned) {
      return (
        <Icons.IconPinFilled
          title="Unpin"
          className="LY_NotesModule_Note_Reply_Card_Pin_Filled"
          onClick={() => onReplyPinClick(props.reply!)}
        />
      );
    } else {
      return (
        <Icons.IconPin
          title="Pin"
          className="LY_NotesModule_Note_Reply_Card_Pin"
          onClick={() => onReplyPinClick(props.reply!)}
        />
      );
    }
  }

  function render() {
    return (
      <div className="LY_NotesModule_Note_Reply_Card">
        <div className="LY_NotesModule_Note_Reply_Card_Content">
          {replyUserAvatarRenderer()}
          <div className="LY_Flex_Row">
            <div className="LY_NotesModule_Note_Reply_Card_Content_Date">
              {LIB.DateHelper.getFriendlyDate(
                props.reply?._ly_date_created
              )}
            </div>
            {replyMenuRenderer()}
          </div>
        </div>
        {replyTextContainerRenderer()}

        {replyPinBtnRenderer()}
      </div>
    );
  }

  return render();
};
