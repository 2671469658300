export const buttonStyles = [
    'Filled', 'Outline', 'Transparent'
];
export const valuePropertyNames = ['id', 'value', 'value_id'];

const colorPalette = [
    "#a9adb9", "#ffefcc", "#a2e67e", "#9ae2ff", "#fba0a1",
    "#9093a4", "#ffdd9b", "#87de57", "#64d2ff", "#f76d6d",
    "#808496", "#ffca64", "#75d940", "#3cc5fe", "#f34141",
    "#767c91", "#ffba38", "#6bd731", "#23bcfe", "#f22625",
    "#656a7e", "#ffb01b", "#59be23", "#09b8ff", "#f21616",
    "#585e72", "#ffab09", "#4da91b", "#00a1e4", "#d8070b",
    "#4a5167", "#e39500", "#3d920c", "#0090cd", "#c10008",
    "#ca8500", "#007cb5", "#af7100"
];
export function getNewSelectedColor(index: number): string {
    return index > colorPalette.length ? colorPalette[colorPalette.length - index] : colorPalette[index];
}


export const radiusValues = [
    { value: 0 },
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
]
export const fontSizeValues = [
    { value: 7 },
    { value: 8 },
    { value: 9 },
    { value: 10 },
    { value: 11 },
    { value: 12 },
    { value: 13 },
    { value: 14 },
]