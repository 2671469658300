import React, { ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';
import * as RIcons from '@radix-ui/react-icons';
import * as LIB from '_LIB';

import './LY_Relationship.css';

import { Button, Badge, Tooltip } from '@mantine/core';
import { useToggle } from '@mantine/hooks';

interface LY_RelationshipProps extends LY.LY_InputBaseProps {
  name: string;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
  onValueChange?: (model: LY.DataItemModel, event: ChangeEventHandler | undefined) => void;
  onItemClick?: Function; //React.MouseEvent<HTMLElement>
  viewRenderer?: React.ReactNode;
  editRenderer?: React.ReactNode;

}

//export const LY_InputBase: React.FC<LY.LY_InputBaseProps> = ({
//export const LY_TextInput = forwardRef<LY_TextInputHandle, LY_TextInputProps>((

type combinedProps = LY_RelationshipProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element
export const LY_Relationship = React.forwardRef<HTMLInputElement, combinedProps>((
  {
    model,
    onItemClick,
    onValueChange,
    onChange,
    viewRenderer,
    editRenderer,
    ...props
  },
  ref // This is the forwarded ref
) => {


  const forceUpdate = LIB.useForceUpdate();

  const [ mode, setMode ] = React.useState('view'); // Add this line
  const [ localValue, setLocalValue ] = React.useState(''); // Add this line

  //const combinedInputClassName = `mantine-Input-input mantine-TextInput-input LY_InputInput ${inputClassName || ''}`;

  //const [ mode, toggle ] = useToggle([ 'view', 'edit' ]);


/*    React.useEffect(() => {

     console.log('LY_Relationship useEffect model', model);
     setLocalValue(model?.value);
   

 }, [ model?.value ]);  */


 // React.useEffect(() => {


  /*   if (model?.isEditMode) {
    //  console.log('LY_Relationship useEffect model?.isEditMode', model?.isEditMode);

      setMode('edit');

    } */

 // }, [ model?.isEditMode ]);

 

  //console.log('LY_Input name', name);
  //console.log('LY_Input value', value);


  

 /*  if (!form) {
    var newForm = useForm({
      //  resolver: zodResolver(validationSchema),
      defaultValues: value,
       
    });
    register = newForm.register;

  }
  else
    register = form.register; */

  
  function onLocalItemClick(e: React.MouseEvent<HTMLElement>) {

     e.preventDefault();

    //  console.log('LY_Relationship onLocalItemClick Model: ',model);

    if (onItemClick)
      onItemClick(model, e);

    /*  console.log('LY_Relationship onItemClick');
     var detailUrl = getDetailUrl();
 
     console.log('LY_Relationship onItemClick URL: ',detailUrl);
     console.log('LY_Relationship onItemClick Model: ',model);
  */

  }

   

  function getDetailUrl() {

    var detailUrl = `/${model?.workspaceId}/item/${model?.rowId}`;
    return detailUrl;
  }



/*   function onLocalChange(m: LY.DataItemModel, e: React.ChangeEventHandler<HTMLElement>) {

    console.log('LY_Relationship onLocalChange model: ',m);
     

    if(model==undefined)
          model = m;

      model.value = m.value;

   if(value)
      value = m.value;

     if (onItemChange)
        onItemChange(model!, e);
  
      forceUpdate();

     setMode('view');

   
  }
 */

  function getEditMode() {

 
    return <div
      //onClick={onLocalNameClick}
      className='LY_RelationshipContainer'>
 
  Edit Mode
 
    </div>

  }

  function getViewMode() {

    var col = model?.column;
    //var detailUrl = getDetailUrl();

    var result = <div
      //onClick={onLocalNameClick}
      className='LY_RelationshipContainer'>

      <a 
        href=''
        onClick={onLocalItemClick}
        className='LY_RelationshipViewLink' >{model?.value}</a>

    </div>

    return result;
  }
 
  function render() {
 

    var result = <LY._LY_InputBaseViewEdit 
    //inputViewEditContainerClassName={props.inputContainerClassName}
 
      {...props}
       
      model={model}
      viewRenderer={getViewMode()}
      editRenderer={getEditMode()}
      onValueChange={onValueChange}
      readOnly={props.readOnly}
      />;

    return result;

  }


  return render();

});


