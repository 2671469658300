import React, { ChangeEvent, ChangeEventHandler, HTMLAttributes } from 'react';

import * as LY from '_LY_Components';

import './LY_Switch.css';
import moment from 'moment';
import { Switch, SwitchProps } from '@mantine/core';

interface LY_SwitchProps extends LY.LY_InputBaseProps {
  name: string;
  defaultChecked?: boolean;
  checked?: boolean;
  inputClassName?: string;
  style?: React.CSSProperties;
  switchProps?: SwitchProps;
  onChange?: ( model: LY.DataItemModel | undefined, event?: ChangeEvent<any> | undefined) => void;
}


type combinedProps = LY_SwitchProps & React.InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward the ref to the select element - LY_Switch
export const LY_Switch = React.forwardRef<HTMLInputElement, LY_SwitchProps>((
  {
    name,
    defaultChecked,
    placeholder,
    defaultValue,
    inputClassName,
    value,
    disabled,
    style,
    onChange,
    model,
    readOnly,
    switchProps,
    inputContainerClassName='LY_SwitchInputContainerForm',
    ...props
  },
  ref // This is the forwarded ref
) => {


  const [checked, setChecked] = React.useState<boolean>(props.checked || false);


  React.useEffect(() => {

   /*  if(name=='isRelationship'){
      console.log('LY_Switch useEffect name: ', name );
      console.log('LY_Switch useEffect checked: ', props.checked );
  
    }  */

    if(props.checked !== undefined)
     setChecked(props.checked);

  }, [ props.checked ]); 
 

  React.useEffect(() => {

    if(model?.value!== undefined)
      setChecked(model.value);

  }, [ model?.value ]); 



  function onLocalChange(e: React.ChangeEvent<HTMLInputElement>) {

    //console.log('LY_CheckBox onLocalChange model: ', e);

    if (model)
        model.value = e.currentTarget.checked;
 
    setChecked(e.currentTarget.checked);

  if (onChange)
    onChange(model, e)
 
 

  }


  function render() {


    var combinedInputClassName = ` `;
 

    if (inputClassName)
      combinedInputClassName += ' ' + inputClassName;

    var readOnlyValue = model?.value;
    if (!readOnlyValue && value)
      readOnlyValue = value;

   // if (checked === undefined)
  //    checked = value;
 
  
   /*  if(name=='yes_or_no'){
    console.log('LY_Switch render name: ', name );
    console.log('LY_Switch render props.checked: ', props.checked );
    console.log('LY_Switch render checked: ', checked );
    console.log('LY_Switch render model: ', model );
    console.log('LY_Switch render tooltip: ', props.tooltip );
    console.log('LY_Switch render helpText: ', model?.column?.helpText );

  }
  */

    return (
      <LY.LY_InputBase  {...props} style={style} tooltip={props.tooltip || model?.column?.helpText}
      inputContainerClassName={inputContainerClassName}  
      >

        <Switch
          name={name}
          ref={ref}
          id={switchProps?.id}

          checked={checked}
          //defaultChecked={defaultChecked}
         // className={combinedInputClassName}
         classNames={{ track: 'LY_SwitchTrack', thumb: 'LY_SwitchThumb' }}

          style={{ width: 'auto', height: 'auto' }}
          disabled={readOnly || disabled}
          {...switchProps}
          onChange={onLocalChange}
        />



      </LY.LY_InputBase>
    );

  }


  return render();




});


